import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  createFilterOptions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addToOrCreateLists, fetchSavedListsNames } from "./SavedListQueries";
import { Spinner } from "../Utils/utils";
import { useSnackbar } from "notistack";
import { getSearchCategory } from "../Utils/categoryUtils";
import { useLocation } from "react-router-dom";

const invalidListName = (listName) => {
  const maxLength = 20;

  if (!listName.trim()) {
    return "List name cannot be empty.";
  }

  if (listName.length > maxLength) {
    return `List name cannot exceed ${maxLength} characters.`;
  }

  if (!/^[a-zA-Z0-9_-]+$/.test(listName)) {
    return "List name can only contain alphanumeric characters and underscores.";
  }

  return null;
};

const defaultFilterOptions = createFilterOptions();

const removeDuplicatesByProperty = (arr, property) => {
  const uniqueArray = Array.from(new Set(arr.map((obj) => obj[property]))).map(
    (value) => arr.find((obj) => obj[property] === value)
  );
  return uniqueArray;
};

const isStringOnlySpaces = (str) => {
  return /^\s*$/.test(str);
};

const SaveListModal = ({
  setSavedRealtime,
  openModal,
  setOpenModal,
  saveListNames,
  saveListItem,
  setSaveListNames,
  itemType,
  simpleSearchId,
}) => {
  console.log("saveListItem", saveListItem);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();

  console.log("value :", value);

  const inputContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (inputContainerRef.current) {
      inputContainerRef.current.scrollTop =
        inputContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const list_names = saveListNames.map((listInfo) => {
      return {
        label: listInfo["list_name"],
        value: listInfo["list_name"],
      };
    });
    setOptions(list_names);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [value]);

  const handleAddToLists = async () => {
    try {
      if (value.length === 0) {
        setError(true);
        setHelperText("At least one list is required...");
        return;
      }
      setLoading(true);

      const inputLists = value.map((item) => {
        return item.value.trim();
      });

      const category = itemType === "geolocator" ? "item_category" : "category";

      const requestPayload = {
        save_list_input: {
          ...saveListItem,
          item_type: itemType,
          [category]: getSearchCategory(location.pathname),
        },
        save_list_names: inputLists,
      };

      if (itemType === "geolocator") {
        requestPayload.save_list_input.simpleSearchId = simpleSearchId;
      }

      const data = await addToOrCreateLists(requestPayload);

      setLoading(false);
      setOpenModal(false);

      if (data?.["message"] === "success") {
        enqueueSnackbar("Added to lists successfully", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setSavedRealtime(true);
        return;
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setOpenModal(false);
      enqueueSnackbar("Error , Please try again later", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      let saveListNames = await fetchSavedListsNames();
      setSaveListNames(saveListNames?.["list_names"]);
    }
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "auto",
          "@media (min-width:600px)": {
            width: "50%",
            top: "40%",
          },
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 2,
          overflow: "auto",
          maxWidth: "1200px",
        }}
      >
        <Typography component="span" fontWeight={500} fontSize={16}>
          Add to lists
        </Typography>
        <Box mt={2}>
          <Autocomplete
            multiple
            size="small"
            id="tags-standard"
            value={value || null}
            onChange={(event, newValueArray) => {
              if (newValueArray.length === 0) {
                setValue(newValueArray);
                return;
              }
              const uniqueArray = removeDuplicatesByProperty(
                newValueArray,
                "value"
              );
              const newListName = uniqueArray[uniqueArray.length - 1].value;
              const invalidNameError = invalidListName(newListName);
              if (invalidNameError) {
                setError(true);
                setHelperText(invalidNameError);
              } else {
                setValue(uniqueArray);
                setError(false);
              }
            }}
            options={options}
            autoHighlight
            freeSolo
            forcePopupIcon
            getOptionLabel={(option) => option.value || option}
            filterOptions={(options, state) => {
              let results = defaultFilterOptions(options, state);

              const newOption = {
                label: `Create new list "${state.inputValue}"`,
                value: state.inputValue,
              };

              if (results.length === 0) {
                if (isStringOnlySpaces(state.inputValue)) {
                  return [];
                }
              }
              console.log("stateInput", state.inputValue);

              const searchTermMatchingList = results.filter(
                (item) => item.value === state.inputValue
              );

              if (searchTermMatchingList.length === 0 && state.inputValue) {
                results = [newOption, ...results];
              }

              return results;
            }}
            renderInput={(params) => {
              const { InputProps, ...restParams } = params;
              const { startAdornment, ...restInputProps } = InputProps;
              return (
                <TextField
                  {...restParams}
                  placeholder={
                    value.length === 0
                      ? "Enter or create lists (After typing press enter)..."
                      : ""
                  }
                  multiline
                  error={error}
                  helperText={error ? helperText : ""}
                  InputProps={{
                    ...restInputProps,
                    startAdornment: (
                      <div
                        style={{
                          maxHeight: "80px",
                          overflowY: "auto",
                        }}
                        ref={inputContainerRef}
                      >
                        {startAdornment}
                      </div>
                    ),
                  }}
                  variant="outlined"
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  size="small"
                />
              );
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
          />
          <Box mt={2}>
            {loading ? (
              <Spinner />
            ) : (
              <Button
                variant="contained"
                onClick={handleAddToLists}
                color="primary"
                sx={{
                  borderRadius: "25px",
                  boxShadow: "none",
                  "&:hover": {
                    bgcolor: "#f11313",
                  },
                }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>

        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            zIndex: "9999 !important",
            right: 8,
            top: 8,
            color: "#000",
            bgcolor: "transparent",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default SaveListModal;
