import { useEffect, useState } from "react";
import SelectedLeads from "./SelectLeads";
import Profile from "./Profile";
import UnlockProfileEmail from "./UnlockProfileEmail";
import SavedListButton from "../../SavedList/SavedListButton";
import { useLocation } from "react-router-dom";

const SearchResultItem = ({
  index,
  data,
  currentPage,
  selectedLeads,
  setSelectedLeads,
  simpleSearchId,
  setSearchId,
  workEmailId,
  setWorkEmailId,
  realtimeNonLinkedinProfileEmailUnlock,
  isOpen,
  setIsOpen,
  hash_key,
  onViewProfileOrWorkEmail,
  isRealTime,
  selectedButton,
  saveListNames,
  setSaveListNames,
  saved,
}) => {
  const location = useLocation();

  const [finalSimpleSearchID, setFinalSimpleSearchID] =
    useState(simpleSearchId);
  const [savedData, setSavedData] = useState([]);
  const sheetName =
    location.state?.searchInput?.index || location.state?.sheetName;
  console.log("location", location);
  useEffect(() => {
    if (location.pathname === "/RealtimePeopleSearch") {
      setFinalSimpleSearchID(data.id);
    } else {
      setFinalSimpleSearchID(simpleSearchId);
    }
  }, [data]);

  console.log("data unlock", data);
  return (
    <div key={index} className="search-user-container" data-cy="search-results">
      <SelectedLeads
        data={data}
        selectedLeads={selectedLeads}
        setSelectedLeads={setSelectedLeads}
      />

      <Profile data={data} flexBasis="40%" sheetName={sheetName} />
      <UnlockProfileEmail
        index={index}
        data={data}
        hash_key={hash_key}
        currentPage={currentPage}
        simpleSearchId={finalSimpleSearchID}
        setSearchId={setSearchId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        workEmailId={workEmailId}
        setWorkEmailId={setWorkEmailId}
        realtimeNonLinkedinProfileEmailUnlock={
          realtimeNonLinkedinProfileEmailUnlock
        }
        isRealTime={isRealTime}
        onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
      />

      <SavedListButton
        pageNumber={currentPage}
        saved={saved}
        data={data}
        savedData={savedData}
        saveListNames={saveListNames}
        setSaveListNames={setSaveListNames}
        itemType="person"
      />
    </div>
  );
};

export default SearchResultItem;
