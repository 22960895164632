import { isObjectValuesEmpty } from "../Utils/utils";
import { getFetch, postFetch } from "../../Config/facade";
import { apiServer } from "../../config";
import Cookies from "js-cookie";

/**
 * Fetches all records in a user saved_list
 * @return {Promise<*>}
 */
export const fetchSavedList = async () => {
  return await getFetch("/save_list/all");
};

/**
 * Fetches all saved list_names for a user
 * @return {Promise<*>}
 */
export const fetchSavedListsNames = async () => {
  return await getFetch("/named_save_lists/get_all_lists");
};

/**
 * Fetches all saved list_items for a given list and item type
 * @return {Promise<*>}
 */
export const fetchSavedListItems = async (page, itemType, listName) => {
  return await getFetch(`/named_save_lists/get_list_items/${listName}`, {
    page: page,
    item_type: itemType,
  });
};

/**
 * searches a saved list_items for a given list and search_term
 * @return {Promise<*>}
 */
export const searchSavedListItems = async (
  page,
  itemType,
  listName,
  searchTerm
) => {
  return await getFetch(`/named_save_lists/search/${listName}`, {
    page: page,
    item_type: itemType,
    search_term: searchTerm,
  });
};

/**
 * gets saved_items_metadata to know whether a particular item has been saved or not
 * @return {Promise<*>}
 */
export const getSaveListMetadata = async (hashKeyInputs) => {
  return await postFetch(`/named_save_lists/get_saved_list_metadata`, {
    hash_key_inputs: hashKeyInputs,
  });
};

/**
 * Upserts item in one or more lists
 * @return {Promise<*>}
 */
export const addToOrCreateLists = async (payload) => {
  return await postFetch("/named_save_lists/add_or_create", payload);
};

/**
 * Removes single item from a saved list
 * @return {Promise<*>}
 */
export const removeItemFromList = async (listName, itemId) => {
  let response = await fetch(
    `${apiServer}/named_save_lists/remove_item/${listName}/${itemId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("user_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

/**
 * Removes a single list
 * @return {Promise<*>}
 */
export const removeList = async (listName) => {
  let response = await fetch(
    `${apiServer}/named_save_lists/remove_list/${listName}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("user_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

/**
 * Adds record into saved_list and returns saved_id (old savelist)
 * @param payload
 * @return {Promise<*>}
 */
export const addToSavedList = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/save_list/add", payload);
};

/**
 * Remove record from saved_list and returns saved_id (old savelist)
 * @param payload
 * @return {Promise<*>}
 */
export const removeFromSavedList = async (id, payload) => {
  if (!isObjectValuesEmpty(payload)) {
    let response = await fetch(`${apiServer}/save_list/id/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("user_token")}`,
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();
    console.log("data fetch: " + data);
  }
};
