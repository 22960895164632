import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import debounce from "../../Utils/debounce";
import { useSnackbar } from "notistack";

/**
 * ### Renders a searchbar that updates current leads based on search inputs within results
 *
 * @param {Array.<Object>} original - Contains a copy of current leads of a search result
 * @param {function} setLeads - Hook that renders current leads on Search Results
 * @param {string} props - String to trigger input reset
 * @param {boolean} hideExportBar - Flag to adjust input width
 */

const ResultsSearchBar = ({
  original,
  setLeads,
  props,
  hideExportBar,
  geolocator,
  geoLeads,
  setTotalSearchedLeads,
  setsearchbar,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState("");

  // Refer Fuse docs to know more about options
  const options = {
    keys: [
      { name: "full_name", weight: 2 },
      "name",
      "company_name",
      "job",
      "location",
      "latitude",
      "longitude",
      "category",
      "industry",
      "address",
      "pincode",
      "linkedin",
      "social_url",
    ],
    threshold: 0.4,
  };

  // Initializes Fuse to make leads searchable based on keys defined
  const fuse = new Fuse(original, options);

  console.log(geoLeads);
  // Debounced input to reduce unnecessary function calls
  const handleInput = (e) => {
    const { value } = e.target;
    setInputValue(value); // Update the state with the input value

    let searched = [];
    const searchedValue = value.trim();
    // Dynamically updates leads or back to original leads if no search value
    if (searchedValue) {
      searched = fuse.search(searchedValue)?.map((lead) => lead.item);
      if (geolocator) {
        setsearchbar(true);
        setTotalSearchedLeads([...searched]);
        let result = searched.slice(0, 14);
        setLeads([...result]);
      } else {
        setLeads([...searched]);
      }

      if (!searched.length) {
        enqueueSnackbar(
          <div>
            No records found for <strong>{value}</strong>, Please Retry.
          </div>,
          {
            variant: "info",
          }
        );
      }
    } else {
      if (geolocator) {
        setLeads(geoLeads);
        setsearchbar(false);
      } else setLeads(original);
    }
  };

  // Effect to clear the input field when props changes
  useEffect(() => {
    setInputValue(""); // Clear the input value
  }, [props]); // Run effect when props changes

  return (
    <input
      className={`form-control ${hideExportBar ? "w-50" : "w-25"}`}
      type="text"
      value={inputValue} // Set the input value from state
      onChange={(e) => handleInput(e)}
      placeholder="Search within results"
      data-cy="search-within"
    />
  );
};

export default ResultsSearchBar;
