import {
  AssociatedPhones,
  AssociatedWorkEmail,
} from "./SpecificInfoComponents";
import React, { useState } from "react";
import { motion } from "framer-motion";

import { slideInSlideOut } from "../../Config/motions";
import { Spinner } from "../Utils/utils";

export const UnlockedProfileAndEmailDetails = ({
  isRealTime,
  userDetails,
  onViewProfileOrWorkEmail,
  workEmailId,
  workEmailLoading,
  phoneLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [message, setMessage] = useState("");

  const handleClickOpen = async () => {
    setOpen(true);
    setMessage("email");
    onViewProfileOrWorkEmail();
  };
  const handleWhatsapp = (phone) => {
    setData(phone);
    setOpen(true);
    setMessage("whatsapp");
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <motion.div {...slideInSlideOut}>
      <div
        style={{
          background: "white",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <section className="item-section">
          <div className="phone-child-div">
            {phoneLoading ? (
              <Spinner />
            ) : (
              <AssociatedPhones
                isRealTime={isRealTime}
                phones={userDetails?.phones}
                handleWhatsapp={handleWhatsapp}
                onlyEmail={workEmailId}
                popupOpen = {open}
                // phoneLoading={phoneLoading}
              />
            )}
            {workEmailLoading ? (
              <Spinner />
            ) : (
              <AssociatedWorkEmail
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                workEmailInfo={userDetails?.workEmailDetails}
                workEmailLoading={workEmailLoading}
                onlyProfile={!workEmailId}
              />
            )}
          </div>
        </section>
        <section className="item-section">
          <div className="phone-child-div">
            <div
              style={{
                display: userDetails?.category ? "block" : "none",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <p className="specific-page-title">Category</p>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  padding: "10px 0 10px 5px",
                  marginTop: "-8px",
                }}
              >
                {userDetails.category}
              </div>
            </div>
            <div
              style={{
                display:
                  userDetails?.city ||
                  userDetails?.state ||
                  userDetails?.country
                    ? "block"
                    : "none",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <p className="specific-page-title">Location</p>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  padding: "10px 0 10px 5px",
                  marginTop: "-8px",
                }}
              >
                {[userDetails?.city, userDetails?.state, userDetails?.country]
                  .filter(Boolean) // Remove undefined values
                  .join(", ")}
              </div>
            </div>

            <div
              style={{
                display: userDetails?.company_name ? "block" : "none",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <p className="specific-page-title">Company Name</p>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  padding: "10px 0 10px 5px",
                  marginTop: "-8px",
                }}
              >
                {userDetails.company_name}
              </div>
            </div>

            <div
              style={{
                display:
                  userDetails?.company_city ||
                  userDetails?.company_state ||
                  userDetails?.company_country
                    ? "block"
                    : "none",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <p className="specific-page-title">Company Location</p>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  padding: "10px 0 10px 5px",
                  marginTop: "-8px",
                }}
              >
                {[
                  userDetails?.company_city,
                  userDetails?.company_state,
                  userDetails?.company_country,
                ]
                  .filter(Boolean) // Remove undefined values
                  .join(", ")}
              </div>
            </div>

            <div
              style={{
                display: userDetails?.addresses ? "block" : "none",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <p className="specific-page-title">Company Address</p>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  padding: "10px 0 10px 5px",
                  marginTop: "-8px",
                }}
              >
                {userDetails.addresses}
              </div>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
};
