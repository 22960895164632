import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import extractDomain from "extract-domain";

const SidebarExtractContact = (data) => {
  const [socialMediaData, setSocialMediaData] = useState({
    url: null,
    type: "Likers",
  });
  const [socialChecked, setsocialChecked] = useState();
  const history = useHistory();
  const allowedDomains = ["linkedin.com"];

  const handleTypeChange = (e) => {
    console.log("handleTypeChange..", e.target.value);
    setSocialMediaData({ ...socialMediaData, type: e.target.value });
  };

  const handleUrlChange = (e) => {
    console.log("handleUrlChange..", e.target.value);
    setSocialMediaData({ ...socialMediaData, url: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(socialMediaData);

    if (!socialMediaData.url) {
      console.log("No Social Media URL");
      setsocialChecked(true);
      return;
    }

    const urlDomain = extractDomain(socialMediaData.url);

    console.log(`urlDomain: ${urlDomain}`);
    console.log(
      "socialMediaData.type: ",
      socialMediaData.type,
      socialMediaData.url
    );

    if (!allowedDomains.includes(urlDomain)) {
      alert(
        `Only URLs from the following domains are supported at this time: \n\n${allowedDomains}`
      );
      return;
    }

    history.push({
      pathname: "/SocialMediaSearch",
      state: {
        searchInput: JSON.stringify({
          url: socialMediaData.url,
          type: socialMediaData.type,
        }),
      },
    });
  };

  const closeModal = () => {
    document.getElementById("searchError").style.display = "none";
    setsocialChecked(false);
  };

  return (
    <div>
      <div
        style={{ pointerEvents: "none", opacity: "0.4" }}
        className="sidebar-search-for sidebar-widget p-4 mt-3 d-flex align-items-center justify-content-center"
      >
        <div>
          <div
            className="tool-d"
            tooltip-data="Paste the URL of any LinkedIn post to obtain the contact information of all likers or commenters."
          >
            <h6 className="text-danger mb-3">
              Now Fetch Contacts
              <img
                src="/assets/images/new_icons/i_button.png"
                alt="#"
                className="mx-2"
                style={{ width: "15px", height: "15px", marginTop: "-.1em" }}
              />
            </h6>
          </div>

          <p>of Likers and Commenters From Supported Social Media Posts</p>
          <ul className="sidebar-social mt-3 mb-2 list-inline">
            <li className="list-inline-item">
              <a href="#">
                <img src="/assets/images/social-linkedin.png" alt="title" />
              </a>
            </li>
          </ul>

          <form>
            <div className="mb-3">
              <input
                id="sidebar-extract-contact-url-input"
                type="url"
                className="form-control"
                onBlur={handleUrlChange}
                onInput={handleUrlChange}
                placeholder="Paste Social Media URL"
                disabled={data.data}
              />
            </div>
            <div className="dropdown mb-3">
              <input
                className="form-control dropdown-toggle"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                placeholder={socialMediaData.type}
                disabled={data.data}
              />

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <div className="dropdown-wraper">
                  <div className="radio-bg">
                    <span>Likers</span>
                    <input
                      type="checkbox"
                      id="Likers"
                      value="Likers"
                      name="inlineRadioOptions"
                      onChange={handleTypeChange}
                      checked={socialMediaData.type.includes("Likers")}
                    />
                  </div>
                  <div className="radio-bg">
                    <span>Commenters</span>
                    <input
                      type="checkbox"
                      id="Commenters"
                      value="Commenters"
                      name="inlineRadioOptions"
                      onChange={handleTypeChange}
                      checked={socialMediaData.type.includes("Commenters")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="btn text-white og-red global-hover"
              type="submit"
              disabled={data.data}
            >
              <span className="pe-1 text-center d-flex align-items-center">
                <img
                  src="/assets/images/new_icons/search.png"
                  alt="title"
                  style={{ width: "15px", height: "15px" }}
                />
                Search
              </span>
            </button>
            {socialChecked ? (
              <div
                className="modal popup-size"
                style={{ display: "block", margin: "auto" }}
                id="searchError"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row d-flex align-items-center">
                        <div className="col-4">
                          <div className="d-flex justify-content-center">
                            <img
                              className="popup-img"
                              src="/assets/images/g10 (2).png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="text-center">
                            <p className="text-danger ">Oops</p>
                            <p>
                              Looks like you haven't given me a linkedIn Post
                              URL. Please enter a link and try again
                            </p>
                            <button
                              className="btn text-white og-red global-hover"
                              onClick={closeModal}
                            >
                              Retry
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <p style={{ paddingTop: "1em" }}>
              <Link to={data.data ? "" : "/userGuide"} className="text-danger">
                Learn More
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SidebarExtractContact;
