import React, {useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import {AppContext} from "../../Context";
import {useSnackbar} from "notistack";
import {stop_word_list} from "../Utils/utils"

const SearchBarSearch = (props) => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const [searchBarItem, setSearchBarItem] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const blockKeywords = ["facebook", "instagram"]

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!window.navigator.onLine){
      props.setpopup(true)
      return;
    }
    dispatch({ type: "upsetHistorySearchTerm" });
    if (searchBarItem) {
      if (blockKeywords.some(blockKeywords => searchBarItem.includes(blockKeywords) )) {
        console.warn("SearchBarSearch: searchBarItem is not a Linkedin link");
        enqueueSnackbar("Please add a Linkedin URL", {
          variant: "error",
        });
        return;
      }
      //checking for stopwords
      const isStopWord = stop_word_list.includes(searchBarItem.toLowerCase());
      if (isStopWord) {
        enqueueSnackbar("Search term is a generic word yielding irrelevant matches. Please refine for accurate results.", {
          variant: "error",
        });
        return;
      }
      console.log(`SearchBarSearch: searchBarItem= ${searchBarItem}`);
      history.push({
        state: {
          searchInput: { search_text: searchBarItem },
        },
        pathname: "/SearchBarSearch",
      });
      setSearchBarItem("");
    } else {
      console.warn("SearchBarSearch: searchBarItem is empty");
    }
  };

  return (
    <div
      className="user-widget-box p-4 mt-2"
      style={{ margin: "0 auto", width: "97%", height: "fit-content" }}
    >
      <div className="user-search-wrapper">
        <img
          className="search-promote-image"
          src="/assets/images/user-success-image.png"
          alt="title"
        />
        <div className="search-promote-content">
          <form
            onSubmit={handleSubmit}
            className={
              props.resultPage
                ? "search-form4 d-flex mb-3 ml-2"
                : "search-form4 d-flex mt-3 ml-2"
            }
          >
            <div className="input-group">
              <div className="input-placeholder">
                <input
                  type="text"
                  id="user-search-input"
                  className="ps-3 tool"
                  name="searchString"
                  onChange={(e) => setSearchBarItem(e.target.value)}
                  onInput={(e) => setSearchBarItem(e.target.value)}
                  value={searchBarItem}
                  required
                  data-cy="search"
                />
                <div className="placeholder">
                  Enter <span>name, email or Linkedin URL</span>
                </div>
              </div>
              <button
                className="btn text-white og-red global-hover"
                type="submit"
              >
                <span
                  className="pe-1 text-center d-flex align-items-center justify-content-between"
                  style={{ width: "105%" }}
                >
                  <img
                    src="/assets/images/new_icons/search.png"
                    alt="title"
                    className="search-icon"
                    style={{ width: "15px", height: "15px" }}
                  />
                  Search
                </span>{" "}
              </button>
            </div>
          </form>
          {props.resultPage && (
            <>
              <p className="fst-italic ml-2">
                Hey, Get started by putting a{" "}
                <span className="text-danger">name, email, linkedIn URL.</span>
              </p>
              {/* <Link to="/userGuide" className="text-danger ml-2">
                Learn more
              </Link> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBarSearch;
