import { fadeInFadeOut } from "../../Config/motions";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom/esm/react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import RealTimeItemsList from "./RealTimeItemsList";
import Pagination from "../Utils/Pagination";
import RealtimeFilters from "./filters/RealtimeFilters";

import {
  cachedGetResultsByPolling,
  cachedPostForExecutionAndGetId,
} from "../SharedComponent/api";
import { Loader } from "../Utils/utils";
import InputOrHistorySearchText from "../SearchResult/Optimized/InputOrHistorySearchText";
import { Button } from "react-bootstrap";
import RealtimeExportAll from "./RealTimeIndividualItems/RealtimeExportAll";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useLeadsContext } from "../../Context/useLeadsContext";

const RealTimeResult = (props) => {
  const [companyPageRecords, setCompanyPageRecords] = useState([]);
  const [allCompanyPageRecords, setAllCompanyPageRecords] = useState([]);
  const [sheetName, setSheetName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [loading, setLoading] = useState(() => true);
  const [jobUrlList, setJobUrlList] = useState([]);
  const history = useHistory();
  const [realtimeSelectedLeads, setRealtimeSelectedLeads] = useState([]);
  const [isRealtimeCheckAll, setIsRealtimeCheckAll] = useState(false);
  const [customCount, setCustomCount] = useState(totalCount);
  const [nextPageToken, setNextPageToken] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const RECORDS_PER_SHEET = 20;
  const searchedTerm = props?.props?.location?.state?.payload;
  const location = useLocation();
  const { leads, dispatch } = useLeadsContext();

  async function getRecords(pageNumber) {
    try {
      setSheetName(props?.location?.state?.index);
      let searchInputBody = {
        sheet_name: props?.location?.state?.index,
      };
      if (!searchInputBody) {
        console.warn("BasicSearchResults: Search Bar: No Search Input Body");
        return;
      }
      console.log("searchInputBody", searchInputBody);
      apiProcess(
        searchInputBody,
        "/realtime/records",
        "/realtime/records/result/",
        onSuccess,
        onError,
        onNotFound
      ).then(() => {});
    } catch (error) {
      console.error("In handle company>>", error);
      enqueueSnackbar(
        "Error in fetching data, Please try again after sometime!",
        {
          variant: "error",
        }
      );
    }
  }

  const onSuccess = (json, searchIdExe) => {
    // setSimpleSearchId(searchIdExe);
    setCurrentPage(0);
    setLoading(false);
    const result = removeDuplicatesCompanies(json?.search_results)
    // setTotalResultsCount(json?.count);
    setTotalCount(result?.length);
    setAllCompanyPageRecords(result);
    setNextPageToken(json?.next_page_token);
    dispatch({ type: "SET_LEADS", payload: result });
  };

  useEffect(() => {
    // if (sheetName === "realtime-companies_hiring_recently") {
    //   setCompanyPageRecords(leads && Array.isArray(leads) ? leads : []);
    // } else {
    setCompanyPageRecords(
      leads && Array.isArray(leads) ? leads.slice(0, RECORDS_PER_SHEET) : []
    );
    // }
  }, [leads]);

  const onError = () => {
    setCompanyPageRecords("");
    setAllCompanyPageRecords("");
    setTotalCount(0);
    setLoading(false);
  };

  const onNotFound = () => {
    setCompanyPageRecords("");
    setAllCompanyPageRecords("");
    setTotalCount(0);
    setLoading(false);
  };

  async function getFilterRecords(searchInputBody) {
    if (!searchInputBody) {
      console.warn("filterRecord realtime: Search Bar: No Search Input Body");
      return;
    }
    console.log("in filterRecord");
    apiProcess(
      searchInputBody,
      "/realtime/filters",
      "/realtime/filters/result/",
      onSuccess,
      onError,
      onNotFound
    ).then(() => {});
  }

  const apiProcess = useCallback(
    async (
      requestBody,
      apiEndpoint = "/realtime/records",
      apiEndpointResult = "/realtime/records/result/",
      onSuccess,
      onError,
      onNotFound
    ) => {
      console.log("apiProcess: ", apiEndpoint, "requestBody: ", requestBody);
      setLoading(true);

      const executionId = await cachedPostForExecutionAndGetId(
        apiEndpoint,
        JSON.stringify(requestBody)
      );

      console.log("New executionId: ", executionId);

      if (!executionId) {
        console.error("Invalid Execution Data", executionId);
        onError();
        return;
      }

      cachedGetResultsByPolling(
        `${apiEndpointResult}${executionId}`,
        (result) => onSuccess(result, executionId),
        () => onError(),
        () => onNotFound()
      );
    },
    []
  );

  async function getHistoryRecords(props) {
    let sheetNames = null;
    setLoading(false);

    if ((props?.location?.state?.details?.search_type).includes(",")) {
      sheetNames = (props?.location?.state?.details?.search_type).split(",")[0];
    }

    // this is temporary as main index used on prod new index has been created for realtime funded company filter

    if (sheetNames === "realtime-recently_funded_companies") {
      setSheetName("realtime-recently_funded_company");
    } else setSheetName(sheetNames);

    setTotalCount(
      props?.location?.state?.details?.search_results[0]?.company_records
        ?.length
    );
    setTotalResultsCount(
      props?.location?.state?.details?.search_results[0]?.company_records
        ?.length
    );
    if (
      (props?.location?.state?.details?.search_type).includes("realtime.filter")
    ) {
      console.log(
        'in if props?.location?.state?.details?.search_type).includes("realtime.filter")'
      );
      setCompanyPageRecords(
        props?.location?.state?.details?.search_results[0]?.company_records &&
          Array.isArray(
            props?.location?.state?.details?.search_results[0]?.company_records
          )
          ? props?.location?.state?.details?.search_results[0]?.company_records.slice(
              0,
              RECORDS_PER_SHEET
            )
          : []
      );
      setAllCompanyPageRecords(
        props?.location?.state?.details?.search_results[0]?.company_records
      );
    } else {
      setCompanyPageRecords(
        props?.location?.state?.details?.search_results[0]?.company_records &&
          Array.isArray(
            props?.location?.state?.details?.search_results[0]?.company_records
          )
          ? props?.location?.state?.details?.search_results[0]?.company_records.slice(
              0,
              RECORDS_PER_SHEET
            )
          : []
      );
    }
  }

  useEffect(() => {
    (async () => {
      if (props?.location?.pathname.includes("/realtime/filter")) {
        console.log("in filter: ", props?.location?.state);
        let sheet_name = props?.location?.state?.sheetName;
        setSheetName(sheet_name);
        let currentPages = 1;
        console.log("currentPages: ", currentPages);
        if (currentPages === 1) {
          setRealtimeSelectedLeads([]);
          setCustomCount(0);
        }
        if (
          sheet_name === "realtime-companies_hiring_recently" &&
          props?.location?.pathname === "/realtime/filter"
        ) {
          currentPages = 0;
        }
        let searchInputBody = {
          sheet_name: sheet_name,
          payload: props?.location?.state?.payload,
          page: currentPages,
        };
        await getFilterRecords(searchInputBody);
      } else if (props?.location?.pathname.includes("/realtime_history")) {
        await getHistoryRecords(props);
      } else {
        await getRecords(currentPage + 1);
      }
    })();
  }, [props]);

  async function handleMoreRecords() {
    let searchInputBody = {
      sheet_name: sheetName,
      payload: props?.location?.state?.payload,
      page: loadMoreCount + 1,
      records_per_page: RECORDS_PER_SHEET,
      next_page_token: nextPageToken,
    };
    setLoadMoreCount(loadMoreCount + 1);

    await getFilterRecords(searchInputBody);
  }

  const paginate = async (pageNumber) => {
    console.log("in paginate: ", pageNumber, props?.props?.location?.pathname);

    pageNumber = pageNumber.selected;
    setCurrentPage(pageNumber);
    setCompanyPageRecords(
      allCompanyPageRecords && Array.isArray(allCompanyPageRecords)
        ? allCompanyPageRecords.slice(
            (pageNumber + 1) * RECORDS_PER_SHEET - RECORDS_PER_SHEET,
            (pageNumber + 1) * RECORDS_PER_SHEET
          )
        : []
    );
  };
  const removeDuplicatesJobs = (jobs) => {
    const seen = new Set();
    const uniqueJobs = [];

    for (const job of jobs) {
      const name = job?.title;
      const location = job?.location;
      const job_id = job?.job_id;
      const company_name = job?.company_name;
      const combinedKey = `${name}-${location}-${job_id}}-${company_name}`;

      if (!seen.has(combinedKey)) {
        seen.add(combinedKey);
        uniqueJobs.push(job);
      }
    }

    return uniqueJobs;
  };

  const removeDuplicatesCompanies = (companys) => {
    const seen = new Set();
    const uniqueCompany = [];

    for (const company of companys) {
      const combinedKey = Object.values(company).slice(1,-1).toString();

      if (!seen.has(combinedKey)) {
        seen.add(combinedKey);
        uniqueCompany.push(company);
      }
    }

    return uniqueCompany;
  };
  console.log(
    sheetName === "realtime-companies_hiring_recently"
      ? removeDuplicatesJobs(leads || [])
      : sheetName === "realtime-recently_funded_company" 
        ? removeDuplicatesCompanies(companyPageRecords || [])
        : companyPageRecords
  );
  return (
    <>
      <motion.div className="realtime" {...fadeInFadeOut}>
        <RealtimeFilters
          sheetName={sheetName}
          setRealtimeSelectedLeads={setRealtimeSelectedLeads}
          setCurrentPage={setCurrentPage}
          setCustomCount={setCustomCount}
        />
        <div className="realtime__results-container">
          {searchedTerm && (
            <InputOrHistorySearchText searchTerm={searchedTerm} />
          )}
          <>
            {" "}
            <div className="sheetRecords__total-records justify-content-between align-con">
              <span>
                <button
                  className="sheetRecords__back-btn ml-3"
                  onClick={() => {
                    if (location.pathname === "/realTimeResults")
                      history.push("/realTimePage");
                    else if (location.pathname === "/realtime_history")
                      history.push("/history");
                    else history.goBack();
                    dispatch({ type: "RESET_LEADS" });
                  }}
                >
                  <img src="/assets/images/new_icons/Back.png" alt="#" />
                  <span className="pl-2">Back</span>
                </button>
                <span className="pl-2">
                  Total Count:{" "}
                  <b>
                    {sheetName === "realtime-companies_hiring_recently" &&
                    props?.location?.pathname === "/realtime/filter"
                      ? removeDuplicatesJobs(leads || [])?.length
                      : totalCount}
                  </b>
                </span>
              </span>
              <div className="me-2">
                <span>
                  {sheetName === "realtime-companies_hiring_recently" &&
                  props?.location?.pathname === "/realtime/filter" ? (
                    <>
                      <b>{removeDuplicatesJobs(leads || [])?.length}</b> of{" "}
                      <b>{removeDuplicatesJobs(leads || [])?.length}</b>{" "}
                      searched Profiles{" "}
                    </>
                  ) : (
                    <>
                      <b>{companyPageRecords?.length}</b> of <b>{totalCount}</b>{" "}
                      searched Profiles{" "}
                    </>
                  )}
                </span>
                <b>
                  {" "}
                  from{" "}
                  {props?.location?.state?.sheetName
                    ? props?.location?.state?.sheetName
                    : sheetName}{" "}
                </b>
              </div>
            </div>
            <RealtimeExportAll
              leads={removeDuplicatesJobs(leads || [])}
              realtimeSelectedLeads={realtimeSelectedLeads}
              setRealtimeSelectedLeads={setRealtimeSelectedLeads}
              companyPageRecords={companyPageRecords}
              allCompanyPageRecords={allCompanyPageRecords}
              totalCount={totalCount}
              sheetName={sheetName}
              pathName={props?.location?.pathname}
              customCount={customCount}
              setCustomCount={setCustomCount}
              isRealtimeCheckAll={isRealtimeCheckAll}
              setIsRealtimeCheckAll={setIsRealtimeCheckAll}
            />
          </>
          {loading ? (
            <Loader />
          ) : companyPageRecords?.length > 0 ? (
            <div
              // style={
              //   sheetName === "realtime-companies_hiring_recently" &&
              //   props?.location?.pathname === "/realtime/filter"
              //     ? { overflowY: "scroll", height: "1560px" }
              //     : null
              // }
            >
              {sheetName === "realtime-companies_hiring_recently" &&
              props?.location?.pathname === "/realtime/filter"
                ? removeDuplicatesJobs(leads || [])?.map((item, index) => (
                    // <ExportContext.Provider value={realtimeSelectedLeads}>
                    <RealTimeItemsList
                      companyPageRecord={item}
                      sheetName={sheetName}
                      index={index}
                      currentPage={currentPage}
                      key={index}
                      jobUrlList={jobUrlList}
                      setJobUrlList={setJobUrlList}
                      pathName={props?.location?.pathname}
                      realtimeSelectedLeads={realtimeSelectedLeads}
                      setRealtimeSelectedLeads={setRealtimeSelectedLeads}
                      customCount={customCount}
                      setCustomCount={setCustomCount}
                    />
                    // </ExportContext.Provider>
                  ))
                :  removeDuplicatesCompanies(companyPageRecords || [])?.map((item, index) => (
                    // <ExportContext.Provider value={realtimeSelectedLeads}>
                    <RealTimeItemsList
                      companyPageRecord={item}
                      sheetName={sheetName}
                      index={index}
                      currentPage={currentPage}
                      key={index}
                      jobUrlList={jobUrlList}
                      setJobUrlList={setJobUrlList}
                      pathName={props?.location?.pathname}
                      realtimeSelectedLeads={realtimeSelectedLeads}
                      setRealtimeSelectedLeads={setRealtimeSelectedLeads}
                      customCount={customCount}
                      setCustomCount={setCustomCount}
                    />
                    // </ExportContext.Provider>
                  ))
                 }
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center mt-2">
                <h5>No records found :{companyPageRecords?.length}</h5>
              </div>
            </>
          )}
          {sheetName === "realtime-companies_hiring_recently" &&
          props?.location?.pathname === "/realtime/filter" &&
          !loading ? (
            <div className="pagination-bar mt-3 mb-5">
              {companyPageRecords?.length !== 0 &&
              <Button className="mt-4" onClick={handleMoreRecords}>
                Load more
              </Button>
              }
            </div>
          ) : (
            !loading && (
              <Pagination
                pageCount={Math.ceil(totalCount / RECORDS_PER_SHEET)}
                pageNumber={currentPage}
                onPageChange={paginate}
              />
            )
          )}
        </div>
      </motion.div>
    </>
  );
};
export default RealTimeResult;
