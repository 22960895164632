import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import {
  cachedGetResultsByPolling,
  cachedPostForExecutionAndGetId,
} from "../api";
import { Badge, ListGroup } from "react-bootstrap";
import {
  isObjectValuesEmpty,
  isObjectValuesEmptyArray,
} from "../../Utils/utils";

const ProspectMatchCount = ({ queryState, type }) => {
  const [matchCountState, setMatchCountState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const memoizedFields = useMemo(() => {
    if (type === "company") {
      return {
        company_name: queryState?.companyNames,
        industry: queryState?.industries,
        founded: queryState?.foundedYear,
        company_size: queryState?.companyEmployeeCounts,
        city: queryState?.companyHQCities,
        country: queryState?.companyHQCountry,
        categories: queryState?.categories,
        specialties: queryState?.specialties,
        company_type: queryState?.companyType,
        domain: queryState?.domains,
        keywords: queryState?.keywords,
        funded: queryState?.funded,
        // exclude
        exclude_company_name: queryState?.excludeCompanyNames,
        exclude_city: queryState?.excludeCompanyHQCities,
        exclude_country: queryState?.excludeCompanyHQCountry,
        exclude_industry: queryState?.excludeIndustries,
        exclude_categories: queryState?.excludeCategories,
        exclude_specialties: queryState?.excludeSpecialties,
        exclude_founded: queryState?.excludeFoundedYear,
        exclude_domain: queryState?.excludeDomains,
        exclude_keywords: queryState?.excludeKeywords,
      };
    } else {
      return {
        department: queryState?.department?.join(","),
        seniority: queryState?.seniority?.join(","),
        industries: queryState?.jobIndustries?.join(","),
        person_names: queryState?.personNames?.join(","),
        person_cities: queryState?.personCities?.join(":"),
        person_states: queryState?.personStates?.join(","),
        person_countries: queryState?.personCountries?.join(","),
        person_regions: queryState?.personRegions?.join(","),
        person_educations: queryState?.personEducations?.join(","),
        job_titles: queryState?.jobTitles?.join(","),
        job_years_of_experience: queryState?.jobYearsOfExperiences[0],
        job_roles: queryState?.jobRoles?.join(","),
        job_sub_roles: queryState?.jobSubRoles?.join(","),
        job_levels: queryState?.jobLevels?.join(","),
        job_departments: queryState?.jobDepartments?.join(","),
        company_names: queryState?.companyNames?.join(","),
        company_domains: queryState?.companyDomains?.join(","),
        company_employee_counts: queryState?.companyEmployeeCounts?.join(","),
        company_hq_cities: queryState?.companyHQCities?.join(":"),
        company_hq_states: queryState?.companyHQStates?.join(","),
        company_hq_countries: queryState?.companyHQCountries?.join(","),
        company_hq_regions: queryState?.companyHQRegions?.join(","),
        exclude_domains: queryState?.excludeDomains?.join(","),
        keywords: queryState?.keywords?.join(","),
      };
    }
  }, [queryState, type]);
  console.log(queryState?.domains)
  console.log(memoizedFields)
  const apiProcess = useCallback(
    async (requestBody, onSuccess, onError, onNotFound) => {
      setMatchCountState(-1);

      const apiEndpoint =
        type === "company" ? "/prospect/company/count/" : "/prospect/count/";
      const executionId = await cachedPostForExecutionAndGetId(
        apiEndpoint,
        JSON.stringify(requestBody)
      );

      if (!executionId) {
        console.error("Invalid Execution Data", executionId);
        onError();
        return;
      }

      cachedGetResultsByPolling(
        `/prospect/count/result/${executionId}`,
        (result) => onSuccess(result),
        () => onError(),
        () => onNotFound()
      );
    },
    [type]
  );

  useEffect(() => {
    const isEmpty =
      type === "company" ? isObjectValuesEmptyArray : isObjectValuesEmpty;

    if (!queryState || isEmpty(memoizedFields)) {
      setMatchCountState(0);
    }
  }, [queryState, memoizedFields, type]);

  useEffect(() => {
    let active = true;

    const checkEmpty = (data) =>
      type === "company"
        ? isObjectValuesEmptyArray(data)
        : isObjectValuesEmpty(data);

    if (checkEmpty(memoizedFields)) {
      console.log(
        type === "company"
          ? "%c No Filters Added in the Array"
          : "%c No Filters Added",
        "color:red"
      );
      return;
    }

    setIsLoading(true);

    const onSuccess = (result) => {
      if (active) {
        setMatchCountState(result?.count || 0);
        setIsLoading(false);
      }
    };

    const onError = () => {
      if (active) {
        setMatchCountState(0);
        setIsLoading(false);
      }
    };

    const onNotFound = () => {
      if (active) {
        setMatchCountState(0);
        setIsLoading(false);
      }
    };
    apiProcess(memoizedFields, onSuccess, onError, onNotFound).then(() => {});
    console.log(memoizedFields);
    return () => {
      active = false;
    };
  }, [apiProcess, memoizedFields, type]);

  return (
    <>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <span>
            {isLoading ? (
              <h4>
                <Badge pill bg="warning" text="dark">
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    size="lg"
                    className="fa-spin"
                  />
                  {"  "}
                  Searching
                </Badge>
              </h4>
            ) : (
              <h4>
                <span>
                  <Badge pill bg="success" text="dark">
                    {Number(matchCountState).toLocaleString("en-US")} Matches
                  </Badge>
                </span>
              </h4>
            )}
          </span>
        </ListGroup.Item>
        {/* <ListGroup.Item disabled>
          <span className="fst-italic">
            ({type === "company" ? "2,524,368" : "255,313,087"} Total)
          </span>
        </ListGroup.Item> */}
      </ListGroup>
    </>
  );
};

export default ProspectMatchCount;
