import Cookies from "js-cookie";

const apiServerUrl = `${process.env.REACT_APP_CONFIG_API_SERVER}`;

export const mapMetaData = async (currentLeads) => {
  const ids = currentLeads?.map((lead) => {
    if (lead["id"]) {
      return lead["id"];
    }
    return lead["saved_item"]["id"];
  });
  try {
    if (ids?.length > 0) {
      await (async () => {
        let metadata = await fetch(apiServerUrl + "/history/metadata", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify({ ids: ids, search_type: "map" }),
        });

        if (!metadata.ok) {
          return;
        }
        metadata = await metadata.json();
        if (metadata) {
          currentLeads = currentLeads?.map((record, index) => {
            if (record.saved_item) {
              return {
                ...record,
                saved_item: {
                  ...record.saved_item,
                  meta_data: {
                    ...record.meta_data,
                    user_unlocked: metadata?.user_metadata
                      ? { ...metadata.user_metadata[index] }
                      : null,
                    team_unlocked: metadata?.unlocked_by_others
                      ? { ...metadata.unlocked_by_others[index] }
                      : null,
                  },
                },
              };
            }
            return {
              ...record,
              meta_data: {
                ...record.meta_data,
                user_unlocked: metadata?.user_metadata
                  ? { ...metadata.user_metadata[index] }
                  : null,
                team_unlocked: metadata?.unlocked_by_others
                  ? { ...metadata.unlocked_by_others[index] }
                  : null,
              },
            };
          });
        }
      })();
    }
    return currentLeads;
  } catch (e) {
    console.warn(e);
  }
};
