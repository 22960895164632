import React, { useEffect } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { Map } from "react-lodash";

const CommonSelectBoxItem = ({
  filterRequest,
  setFilterRequest,
  label,
  icon,
  attribute,
  collection,
}) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);

  const handleInput = (inputKey) => {
    console.log("in hit inputKey: ", inputKey);
    if (!filterRequest.hasOwnProperty(attribute)) {
      filterRequest[attribute] = [];
    }
    if (filterRequest[attribute].indexOf(inputKey) < 0) {
      filterRequest[attribute].push(inputKey);
    }

    setFilterRequest(() => {
      return { ...filterRequest };
    });
  };
  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed realtime__accordion-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + attribute}
          >
            <img src={icon} alt="#" className="realtime__img" />

            <span className="px-2">{label}</span>
          </button>
        </h2>
        <div
          id={attribute}
          className="collapse"
          data-bs-parent={`#${attribute}`}
        >
          <ListGroup>
            <Map
              collection={collection}
              iteratee={(x, index) => {
                return (
                  <ListGroup.Item key={index}>
                    <div className="d-grid gap-0">
                      <Button
                        variant="outline-dark"
                        onClick={() => handleInput(x)}
                        data-cy="filter-btn"
                      >
                        {x}
                      </Button>
                    </div>
                  </ListGroup.Item>
                );
              }}
            />
          </ListGroup>
          {/*<AutoSuggestInputForFilter*/}
          {/*  filterRequest={filterRequest}*/}
          {/*  setFilterRequest={setFilterRequest}*/}
          {/*  sheetName={sheetName}*/}
          {/*  attribute={itemClass}*/}
          {/*/>*/}
        </div>
      </div>
    </>
  );
};

export default CommonSelectBoxItem;
