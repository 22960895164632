import React from "react";

const MapCheckBox = ({ data, setMapSelectedLeads, mapSelectedLeads }) => {
  function addToExport(data, e) {
    const { checked } = e.target;
    setMapSelectedLeads((prev) => [...prev, data?.id]);
    if (!checked) {
      setMapSelectedLeads(
        mapSelectedLeads?.filter((item) => item !== data?.id)
      );
    }
  }

  return (
    <div>
      <input
        type="checkbox"
        className="result-container__checkbox"
        onChange={(e) => addToExport(data, e)}
        checked={mapSelectedLeads?.includes(data?.id)}
        data-cy="geolocator-list-checkbox"
      />
    </div>
  );
};
export default MapCheckBox;
