import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { fadeInFadeOut } from "../../Config/motions";
import UserProfileItems from "./Components/UserProfileItems";
import UserPersonalInformation from "./Components/UserPersonalInformation";
import CurrentTeam from "./Components/CurrentTeam";
import { apiServer } from "../../config";

const apiHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${Cookies.get("user_token")}`,
};
const UserProfile = () => {
  const [itemSelected, setItemSelected] = useState("profile");
  const [userDetails, setUserDetails] = useState({});
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const fetchResponse = await fetch(apiServer + `/users/me`, {
          headers: apiHeader,
        });
        const data = await fetchResponse.json();
        setUserDetails(data);
        console.log("UserProfile data is : ", data);
      } catch (err) {
        console.log("Error: ", err);
      }
    })();
  }, []);

  return (
    <>
      <div className=" mt-5 mb-2 ml-5">
        <IoArrowBackOutline
          onClick={() => history.goBack()}
          className="h3"
          style={{
            cursor: "pointer",
          }}
        />
      </div>
      <motion.div {...fadeInFadeOut}>
        <div className="main-content-areas pb-6 pt-2" id="main-content-areas">
          <div className="main-wrapper container-fluid">
            <div className="row" id="user_profile_item">
              <div className="col-md-4 col-lg-3">
                <div className="py-2">
                  <UserProfileItems
                    itemSelected={itemSelected}
                    setItemSelected={setItemSelected}
                    userDetails={userDetails}
                  />
                </div>
              </div>
              <div className="col-md-8 col-lg-9">
                <div
                  className="main-content-areas pt-2"
                  id="main-content-area"
                  style={{ paddingRight: "0px" }}
                >
                  <div className="main-wrappers">
                    {itemSelected === "profile" ? (
                      <UserPersonalInformation />
                    ) : // <NewUserPersonalInformation />
                    itemSelected === "admin" &&
                      userDetails?.signup_source === "platform" ? (
                      <CurrentTeam />
                    ) : // <NewCurrentTeam />
                    null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default UserProfile;
