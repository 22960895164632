import React from "react";
import paymentImage from "../../../images/payment.png";
const PaymentTopContainer = () => {
  return (
    <div className="row">
      <div className="col-md-6 col-lg-6">
        <img src={paymentImage} alt="payment" className="img-fluid" />
      </div>
      <div
        className="col-md-6 col-lg-6"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <div
          className="user-widget-box p-4 mb-3"
          style={{ textAlign: "right", width: "100%" }}
          data-cy="bulk-search"
        >
          <h2 className="text-danger">Unlimited Freemium</h2>
          <p className="text-dark mb-3 font-weight-bold">
            Smart Data at Smart Prices
          </p>
          <p className="text-dark mb-3">
            Compare packages and find the one that works best for you.
          </p>
          <p className="text-dark mb-3">Grab your Leads Now !</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentTopContainer;
