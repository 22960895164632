import React from "react";
import App from "./App";
import { AppProvider } from "./Context";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { store } from "./_store/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { LeadsContextProvider } from "./Context/LeadsContext";
import "./index.css";
import "instantsearch.css/themes/satellite.css";
import * as amplitude from "@amplitude/analytics-browser";
// import { ReactQueryDevtools } from "react-query/devtools";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import Cookies from "js-cookie";

const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

// Uncomment these lines of code
// Sentry.init({
//   dsn: process.env.REACT_APP_CONFIG_SENTRY_DSN,
//   initialScope: {
//     // tags: { "my-tag": "my value" },
//     user: {
//       id: Cookies.get("user_id"),
//       email: Cookies.get("user_email"),
//       username: Cookies.get("username"),
//     },
//   },
//   environment: process.env.REACT_APP_CONFIG_SENTRY_ENV,
//   debug: !!process.env.REACT_APP_CONFIG_SENTRY_DEBUG,
//   // beforeSend(event, hint) {
//   //   // Check if it is an exception, and if so, show the report dialog
//   //   if (event.exception) {
//   //     Sentry.showReportDialog({ eventId: event.event_id });
//   //   }
//   //   return event;
//   // },
//   integrations: [new BrowserTracing()],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 0.2,
// });

// Initialize Amplitude with your API key
amplitude.init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`);
root.render(
  <QueryClientProvider client={queryClient}>
    <LeadsContextProvider>
      <Provider store={store}>
        <AppProvider>
          <Router>
            <ThemeProvider theme={theme}>
              <App />
              {/*<ReactQueryDevtools initialIsOpen={false} />*/}
            </ThemeProvider>
          </Router>
        </AppProvider>
      </Provider>
    </LeadsContextProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
