import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import ProspectFilterBasic from "./FilterType/ProspectFilterBasic";
import ProspectMatchCount from "./ProspectMatchCount";
import ProspectFilterAutoSuggest from "./FilterType/ProspectFilterAutoSuggest";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import ProspectFilterLabel from "./ProspectFilterLabel";
import ProspectFilterList from "./FilterType/ProspectFilterList";
import {
  isObjectValuesEmptyArray,
  isObjectValuesEmpty,
} from "../../Utils/utils";
import { Button } from "react-bootstrap";
import ProspectFilterLinks from "./FilterType/ProspectFilterLinks";
import CompanyFilters from "../../CompanySharedComponent/CompanyFilters";
import ProspectFilterRangeMinMax from "./FilterType/ProspectFilterRangeMinMax";

const ProspectSearch = (props) => {
  const [multipleDomain, setMultipleDomain] = useState("");
  const [toggleCheck, setToggleCheck] = useState(true);
  const emptyFilters = {
    jobIndustries: [],
    personRegions: [],
    personNames: [],
    personCountries: [],
    personCities: [],
    personStates: [],
    personEducations: [],
    department: [],
    seniority: [],
    jobTitles: [],
    jobRoles: [],
    jobSubRoles: [],
    jobLevels: [],
    jobDepartments: [],
    jobYearsOfExperiences: [],
    companyNames: [],
    companyDomains: [],
    companyEmployeeCounts: [],
    companyHQRegions: [],
    companyHQCities: [],
    companyHQStates: [],
    companyHQCountries: [],
    excludeDomains: [],
    keywords: [],
  };

  const handleChange = (e) =>
    e.target.checked ? setToggleCheck(true) : setToggleCheck(false);

  const alternateEmptyFilters = {
    companyName: "",
    companyDomain: "",
  };

  const maxSearchItemsPerFilter = 5;

  const history = useHistory();
  const location = useLocation();
  // const { dispatch } = useContext(AppContext);
  const [queryState, setQueryState] = useState(emptyFilters);
  const [alternateQueryState, setAlternateQueryState] = useState(
    alternateEmptyFilters
  );
  const queryContainer = useRef(null);
  const queryParentContainer = useRef(null);
  const { enqueueSnackbar } = useSnackbar();


  const searchClient = instantMeiliSearch(
    `${process.env.REACT_APP_CONFIG_API_MEILISEARCH_URL}`,
    `${process.env.REACT_APP_CONFIG_API_MEILISEARCH_KEY}`,
    {
      placeholderSearch: false,
      primaryKey: "id",
    }
  );

  useEffect(() => {
    if (
      location.pathname === "/CompanySearch" ||
      location.pathname === "/CompanySearchHistory"
    ) {
      setToggleCheck(false);
    }
  }, [location.pathname]);

  const handleSearchButtonClicked = () => {
    if (!window.navigator.onLine) {
      props.setpopup(true);
      return;
    }
    const data = {
      industries: queryState?.jobIndustries?.join(","),

      person_names: queryState?.personNames?.join(","),
      person_cities: queryState?.personCities?.join(":"),
      person_states: queryState?.personStates?.join(","),
      person_countries: queryState?.personCountries?.join(","),
      person_regions: queryState?.personRegions?.join(","),
      person_educations: queryState?.personEducations?.join(","),

      department: queryState?.department?.join(","),
      seniority: queryState?.seniority?.join(","),
      job_titles: queryState?.jobTitles?.join(","),
      job_roles: queryState?.jobRoles?.join(","),
      job_sub_roles: queryState?.jobSubRoles?.join(","),
      job_levels: queryState?.jobLevels?.join(","),
      job_departments: queryState?.jobDepartments?.join(","),
      job_years_of_experience: queryState?.jobYearsOfExperiences?.join(","),

      company_names: queryState?.companyNames?.join(","),
      company_domains: queryState?.companyDomains?.join(","),
      company_employee_counts: queryState?.companyEmployeeCounts?.join(","),
      company_hq_cities: queryState?.companyHQCities?.join(":"),
      company_hq_states: queryState?.companyHQStates?.join(","),
      company_hq_countries: queryState?.companyHQCountries?.join(","),
      company_hq_regions: queryState?.companyHQRegions?.join(","),

      exclude_domains: queryState?.excludeDomains?.join(","),
      keywords: queryState?.keywords?.join(","),
    };

    if (isObjectValuesEmpty(data)) {
      enqueueSnackbar("Please select one of the filters", {
        variant: "error",
      });
      return;
    }

    // dispatch({ type: "upsetDemo" });

    history.push({
      pathname: "/ProspectSearch",
      state: {
        searchInput: JSON.stringify(data),
      },
    });
  };

  function handleAlternateButtonClicked() {
    if (
      !(alternateQueryState?.companyDomain || alternateQueryState?.companyName)
    ) {
      console.error(`Invalid Alt Call: ${alternateQueryState}`);
      return;
    }

    const data = {
      company_name: alternateQueryState?.companyName,
      company_domain: alternateQueryState?.companyDomain,
      limit: 20,
    };

    history.push({
      pathname: "/ProspectSearchAlternate",
      state: {
        searchInput: JSON.stringify(data),
      },
    });
  }

  const checkValidityOfNewValueForSubState = (subStates, newValue) => {
    if (subStates?.length >= maxSearchItemsPerFilter) {
      enqueueSnackbar(
        `Upto ${maxSearchItemsPerFilter} Filter Items Can be Selected At A Time`,
        {
          variant: "warning",
        }
      );
      return false;
    }

    if (newValue && subStates?.includes(newValue)) {
      enqueueSnackbar(`Ignoring ${newValue}, It Was Already Added`, {
        variant: "warning",
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (props.customSearch) {
      setQueryState(props.customSearch);
    }
  }, [props]);

  // Adds animation to queries container
  const handleClearAllSelectedFilters = () => {
    const element = queryContainer.current;
    element.classList.add("fadeout-active");
    setTimeout(() => {
      element.classList.remove("fadeout-active");
      setQueryState(emptyFilters);
    }, 400);
  };

  return (
    <>
      <div className="prospect-toggle-container">
        <div className="btnn btn-1">
          <input
            type="checkbox"
            name="switch"
            id="switch"
            checked={toggleCheck}
            onClick={handleChange}
            data-cy="switch"
          />
          <label htmlFor="switch" />
        </div>
      </div>
      {toggleCheck ? (
        <>
          <Button
            type="button"
            className="btn btn-light position-relative"
            style={{ cursor: "default" }}
          >
            <p className="fs-4 text-danger">Prospect Search</p>
          </Button>
          <div
            style={{
              transition: "all 2s linear",
            }}
          >
            <div className="px-4">
              <div className="query-parent" ref={queryParentContainer}>
                <div
                  className="query-container d-flex flex-wrap gap-2 mt-2"
                  id="query_container"
                  ref={queryContainer}
                  data-cy="query-container"
                >
                  <ProspectFilterLabel
                    collection={queryState?.department}
                    iconUrl={"/assets/images/new_icons/Department.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, department: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.seniority}
                    iconUrl={"/assets/images/new_icons/Seniority.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, seniority: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.jobTitles}
                    iconUrl={"/assets/images/new_icons/award.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, jobTitles: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.personCities}
                    iconUrl="/assets/images/new_icons/Location.png"
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, personCities: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.personEducations}
                    iconUrl={"/assets/images/new_icons/Education.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, personEducations: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.jobYearsOfExperiences}
                    iconUrl={"/assets/images/new_icons/date.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, jobYearsOfExperiences: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.companyNames}
                    iconUrl={"/assets/images/new_icons/company_name.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, companyNames: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.companyEmployeeCounts}
                    iconUrl={"/assets/images/new_icons/Employee Count.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, companyEmployeeCounts: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.companyDomains}
                    iconUrl={"/assets/images/new_icons/Link.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, companyDomains: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.excludeDomains}
                    iconUrl={"/assets/images/new_icons/Link.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, excludeDomains: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.companyHQCities}
                    iconUrl={"/assets/images/new_icons/Location.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, companyHQCities: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.jobIndustries}
                    iconUrl={"/assets/images/new_icons/Industry.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, jobIndustries: x })
                    }
                  />
                  <ProspectFilterLabel
                    collection={queryState?.keywords}
                    iconUrl={"/assets/images/new_icons/keywords.png"}
                    onCloseIconClicked={(x) =>
                      setQueryState({ ...queryState, keywords: x })
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  style={{ color: "#FB3E3E !important" }}
                  className="btn btn-link text-decoration-none p-0 text-danger cursor-default"
                  onClick={handleClearAllSelectedFilters}
                  disabled={isObjectValuesEmptyArray(queryState)}
                  data-cy="clear-all-btn"
                >
                  Clear All
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <ProspectMatchCount
                  queryState={queryState}
                ></ProspectMatchCount>
              </div>
            </div>
            <div
              className="sidebar-search-for sidebar-widget px-4 pb-3 my-3"
              style={{ minHeight: "375px" }}
              data-cy="sidebar-search-filter-container"
            >
              <div
                className="sidebar-accordion accordion"
                id="accordionExample"
                data-cy="filters"
              >
                <ProspectFilterList
                  displayName={"Department"}
                  tooltipText={"Select Department"}
                  iconUrl={"/assets/images/new_icons/Department.png"}
                  stateName={"department"}
                  queryState={queryState}
                  collection={[
                    "Management Level",
                    "Marketing",
                    "Finance",
                    "Sales",
                    "Consulting",
                    "Operations",
                    "Human Resources",
                    "IT",
                    "Engineering",
                    "Education",
                    "Medical & Health",
                    "Legal Department",
                    "Design",
                  ]}
                  onTargetValueSet={(v) => {
                    console.log(`Department: ${v}`);
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.department,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.department.push(v);
                    setQueryState({
                      ...queryState,
                      department: queryState.department,
                    });
                  }}
                />
                <ProspectFilterList
                  displayName={"Seniority"}
                  tooltipText={"Select Position"}
                  iconUrl={"/assets/images/new_icons/Seniority.png"}
                  stateName={"seniority"}
                  queryState={queryState}
                  collection={[
                    "Owner",
                    "CxO",
                    "Director",
                    "Partner",
                    "Senior",
                    "VP",
                    "Manager",
                    "Entry",
                    "Training",
                    "Unpaid",
                  ]}
                  onTargetValueSet={(v) => {
                    console.log(`Seniority Position: ${v}`);
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.seniority,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.seniority.push(v);
                    setQueryState({
                      ...queryState,
                      seniority: queryState.seniority,
                    });
                  }}
                />
                <ProspectFilterAutoSuggest
                  displayName={"Job Title"}
                  iconUrl={"/assets/images/new_icons/award.png"}
                  inputPlaceholder={"e.g., Chief Executive Officer"}
                  indexName={"job_titles"}
                  indexAttribute={"job_title"}
                  searchClient={searchClient}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.jobTitles,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.jobTitles.push(v);
                    setQueryState({
                      ...queryState,
                      jobTitles: queryState.jobTitles,
                    });
                  }}
                />
                {/*Hide: Not in use */}

                {/*<ProspectFilterRangeMinMax*/}
                {/*  displayName={"Job Experience"}*/}
                {/*  iconUrl={"/assets/images/new_icons/date.png"}*/}
                {/*  tooltipText={"Select Range"}*/}
                {/*  disabled={true}*/}
                {/*  minValue={0}*/}
                {/*  maxValue={80}*/}
                {/*  onTargetValueSet={(v) => {*/}
                {/*    console.log(`Job Experience: ${v}`);*/}
                {/*    queryState.jobYearsOfExperiences.pop();*/}
                {/*    queryState.jobYearsOfExperiences.push(*/}
                {/*      `${v?.rangeMinValue}-${v?.rangeMaxValue}`*/}
                {/*    );*/}
                {/*    setQueryState({*/}
                {/*      ...queryState,*/}
                {/*      jobYearsOfExperiences: queryState.jobYearsOfExperiences,*/}
                {/*    });*/}
                {/*  }}*/}
                {/*/>*/}
                <ProspectFilterAutoSuggest
                  displayName={"Company Name"}
                  iconUrl={"/assets/images/new_icons/company_name.png"}
                  inputPlaceholder={"e.g., HDFC"}
                  indexName={"company_names"}
                  indexAttribute={"company_name"}
                  searchClient={searchClient}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.companyNames,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.companyNames.push(v);
                    setQueryState({
                      ...queryState,
                      companyNames: queryState.companyNames,
                    });
                  }}
                />
                {/*<ProspectFilterAutoSuggestWithFallback*/}
                {/*    displayName={"Company Domain"}*/}

                {/*    iconUrl={"/assets/images/new_icons/Link.png"}*/}
                {/*    inputPlaceholder={"e.g., hdfc.com"}*/}
                {/*    indexName={"company_domains"}*/}
                {/*    indexAttribute={"company_domain"}*/}
                {/*    searchClient={searchClient}*/}
                {/*    multipleDomain={multipleDomain}*/}
                {/*    setMultipleDomain={setMultipleDomain}*/}
                {/*    onTargetValueSet={(v) => {*/}
                {/*        if (*/}
                {/*            !checkValidityOfNewValueForSubState(*/}
                {/*                queryState.companyDomains,*/}
                {/*                v*/}
                {/*            )*/}
                {/*        ) {*/}
                {/*            return;*/}
                {/*        }*/}
                {/*        queryState.companyDomains.push(v);*/}
                {/*        setQueryState({*/}
                {/*            ...queryState,*/}
                {/*            companyDomains: queryState.companyDomains,*/}
                {/*        });*/}
                {/*    }}*/}
                {/*    onTargetValueNotFound={(v) => {*/}
                {/*        if (!v) {*/}
                {/*            console.warn("Invalid Company Domain Value");*/}
                {/*            return;*/}
                {/*        }*/}

                {/*        enqueueSnackbar(`Searching for Company Domain: ${v}`);*/}
                {/*        setAlternateQueryState({*/}
                {/*            ...alternateQueryState,*/}
                {/*            companyDomain: v,*/}
                {/*        });*/}

                {/*        handleAlternateButtonClicked();*/}
                {/*    }}*/}
                {/*    onAddMultipleDomains={(v) => {*/}
                {/*        let valueCheck = v.split(",");*/}
                {/*        for (let i = 0; i < valueCheck.length; i++) {*/}
                {/*            const value = valueCheck[i];*/}
                {/*            if (!value.includes(".")) {*/}
                {/*                enqueueSnackbar(`Enter valid domain ${value}`, {*/}
                {/*                    variant: "warning",*/}
                {/*                });*/}
                {/*                return;*/}
                {/*            }*/}
                {/*            if (valueCheck.length > 5) {*/}
                {/*                enqueueSnackbar(`Entered more than 5 domains.`, {*/}
                {/*                    variant: "warning",*/}
                {/*                });*/}
                {/*                setMultipleDomain("");*/}
                {/*                return;*/}
                {/*            }*/}
                {/*            if (*/}
                {/*                !checkValidityOfNewValueForSubState(*/}
                {/*                    queryState.companyDomains,*/}
                {/*                    value*/}
                {/*                )*/}
                {/*            ) {*/}
                {/*                return;*/}
                {/*            }*/}
                {/*            const isPresent = queryState.companyDomains.some((item) =>*/}
                {/*                item.includes(value)*/}
                {/*            );*/}
                {/*            if (isPresent) {*/}
                {/*                enqueueSnackbar(`Alreay present ${value}.`, {*/}
                {/*                    variant: "warning",*/}
                {/*                });*/}
                {/*                return;*/}
                {/*            }*/}
                {/*        }*/}
                {/*        queryState.companyDomains.push(v);*/}
                {/*        setQueryState({*/}
                {/*            ...queryState,*/}
                {/*            companyDomains: queryState.companyDomains,*/}
                {/*        });*/}
                {/*    }}*/}
                {/*/>*/}
                <ProspectFilterBasic
                  displayName={"Company Domain"}
                  iconUrl={"/assets/images/new_icons/Link.png"}
                  inputPlaceholder={"e.g.hdfc, hdfc.com"}
                  tooltipText={"General Query that dont fit anywhere else"}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.companyDomains,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.companyDomains.push(v);
                    setQueryState({
                      ...queryState,
                      companyDomains: queryState.companyDomains,
                    });
                  }}
                />
                <ProspectFilterList
                  displayName={"Employee Size"}
                  tooltipText={"Select Employee Size"}
                  iconUrl={"/assets/images/new_icons/Employee Count.png"}
                  stateName={"companyEmployeeCounts"}
                  queryState={queryState}
                  collection={[
                    "1",
                    "2-10",
                    "11-50",
                    "51-200",
                    "201-500",
                    "501-1000",
                    "1001-5000",
                    "5001-10000",
                    "10001+",
                  ]}
                  onTargetValueSet={(v) => {
                    // console.log(`Employee Count: ${v}`);
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.companyEmployeeCounts,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.companyEmployeeCounts.push(v);
                    setQueryState({
                      ...queryState,
                      companyEmployeeCounts: queryState.companyEmployeeCounts,
                    });
                  }}
                />
                <ProspectFilterAutoSuggest
                  displayName={"Person Location"}
                  iconUrl={"/assets/images/new_icons/Location.png"}
                  inputPlaceholder={"e.g., Mumbai"}
                  indexName={"person_locations"}
                  indexAttribute={"person_location"}
                  searchClient={searchClient}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.personCities,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.personCities.push(v);
                    setQueryState({
                      ...queryState,
                      personCities: queryState.personCities,
                    });
                  }}
                />
                <ProspectFilterAutoSuggest
                  displayName={"Company Location"}
                  iconUrl="/assets/images/new_icons/Location.png"
                  inputPlaceholder={"e.g., Bangalore"}
                  indexName={"job_locations"}
                  indexAttribute={"job_location"}
                  searchClient={searchClient}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.companyHQCities,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.companyHQCities.push(v);
                    setQueryState({
                      ...queryState,
                      companyHQCities: queryState.companyHQCities,
                    });
                  }}
                />
                <ProspectFilterAutoSuggest
                  displayName={"Person Education"}
                  iconUrl={"/assets/images/new_icons/Education.png"}
                  inputPlaceholder={"e.g., Indian Institute of Management"}
                  indexName={"educations"}
                  indexAttribute={"education"}
                  searchClient={searchClient}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.personEducations,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.personEducations.push(v);
                    setQueryState({
                      ...queryState,
                      personEducations: queryState.personEducations,
                    });
                  }}
                />
                <ProspectFilterBasic
                  displayName={"Exclude Company Domain"}
                  iconUrl={"/assets/images/new_icons/eye-off.png"}
                  inputPlaceholder={"e.g.hdfc, hdfc.com"}
                  tooltipText={"General Query that dont fit anywhere else"}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.excludeDomains,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.excludeDomains.push(v);
                    setQueryState({
                      ...queryState,
                      excludeDomains: queryState.excludeDomains,
                    });
                  }}
                />
                {/*Replaced with basic exclude domain filter*/}
                {/*<ProspectFilterAutoSuggest*/}
                {/*  displayName={"Exclude Company Domain"}*/}
                {/*  multipleDomain={multipleDomain}*/}
                {/*  setMultipleDomain={setMultipleDomain}*/}
                {/*  iconUrl={"/assets/images/new_icons/eye-off.png"}*/}
                {/*  inputPlaceholder={"e.g., yesbank.in"}*/}
                {/*  indexName={"company_domains"}*/}
                {/*  indexAttribute={"company_domain"}*/}
                {/*  searchClient={searchClient}*/}
                {/*  identifier={"exclude_domain"}*/}
                {/*  onTargetValueSet={(v) => {*/}
                {/*    if (*/}
                {/*      !checkValidityOfNewValueForSubState(*/}
                {/*        queryState.excludeDomains,*/}
                {/*        v*/}
                {/*      )*/}
                {/*    ) {*/}
                {/*      return;*/}
                {/*    }*/}
                {/*    queryState.excludeDomains.push(v);*/}
                {/*    setQueryState({*/}
                {/*      ...queryState,*/}
                {/*      excludeDomains: queryState.excludeDomains,*/}
                {/*    });*/}
                {/*  }}*/}
                {/*  onAddMultipleDomains={(v) => {*/}
                {/*    let valueCheck = v.split(",");*/}

                {/*    for (let i = 0; i < valueCheck.length; i++) {*/}
                {/*      const value = valueCheck[i];*/}
                {/*      if (!value.includes(".")) {*/}
                {/*        enqueueSnackbar(`Enter valid domain`, {*/}
                {/*          variant: "warning",*/}
                {/*        });*/}
                {/*        return;*/}
                {/*      }*/}
                {/*      if (valueCheck.length > 5) {*/}
                {/*        enqueueSnackbar(`Entered more than 5 domains.`, {*/}
                {/*          variant: "warning",*/}
                {/*        });*/}
                {/*        setMultipleDomain("");*/}
                {/*        return;*/}
                {/*      }*/}
                {/*      if (queryState.excludeDomains.includes(value)) {*/}
                {/*        enqueueSnackbar(*/}
                {/*          `Ignoring ${value}.It was already added`,*/}
                {/*          { variant: "warning" }*/}
                {/*        );*/}
                {/*        return;*/}
                {/*      }*/}
                {/*      const isPresent = queryState.excludeDomains.some((item) =>*/}
                {/*        item.includes(value)*/}
                {/*      );*/}
                {/*      if (isPresent) {*/}
                {/*        enqueueSnackbar(`Alreay present ${value}.`, {*/}
                {/*          variant: "warning",*/}
                {/*        });*/}
                {/*        return;*/}
                {/*      }*/}
                {/*    }*/}
                {/*    queryState.excludeDomains.push(v);*/}
                {/*    setQueryState({*/}
                {/*      ...queryState,*/}
                {/*      excludeDomains: queryState.excludeDomains,*/}
                {/*    });*/}
                {/*  }}*/}
                {/*/>*/}
                <ProspectFilterAutoSuggest
                  displayName={"Industry"}
                  iconUrl={"/assets/images/new_icons/Industry.png"}
                  inputPlaceholder={"e.g., banking"}
                  tooltipText={
                    "Enter the first letter and choose upto five from the list"
                  }
                  indexName={"industries_1"}
                  indexAttribute={"industry"}
                  searchClient={searchClient}
                  onTargetValueSet={(v) => {
                    // Limit to a few choices
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.jobIndustries,
                        v
                      )
                    ) {
                      return;
                    }

                    queryState.jobIndustries.push(v);
                    setQueryState({
                      ...queryState,
                      jobIndustries: queryState.jobIndustries,
                    });
                  }}
                />
                <ProspectFilterBasic
                  displayName={"Keywords"}
                  iconUrl={"/assets/images/new_icons/keywords.png"}
                  inputPlaceholder={"e.g., Options trader"}
                  tooltipText={"General Query that dont fit anywhere else"}
                  onTargetValueSet={(v) => {
                    if (
                      !checkValidityOfNewValueForSubState(
                        queryState.keywords,
                        v
                      )
                    ) {
                      return;
                    }
                    queryState.keywords.push(v);
                    setQueryState({
                      ...queryState,
                      keywords: queryState.keywords,
                    });
                  }}
                />
                <ProspectFilterLinks
                  displayName={"Special Leads"}
                  iconUrl={"/assets/images/new_icons/Funded.png"}
                  tooltipText={"Show List of Recently Funded Companies"}
                />
              </div>
              <button
                onClick={handleSearchButtonClicked}
                className="btn text-white og-red global-hover"
                type="submit"
                data-cy="search"
              >
                <span className="pe-1 text-center d-flex align-items-center">
                  <img
                    src="/assets/images/new_icons/search.png"
                    alt="title"
                    className="search-icon"
                    style={{ width: "15px", height: "15px" }}
                  />
                  Search
                </span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <CompanyFilters />
      )}
    </>
  );
};

export default ProspectSearch;
