export const reducer = (state, action) => {
  if (action.type === "setDemo") {
    state.isDemo = true;

    for (let key in state.resultBy) {
      if (key === action.payload) {
        state.resultBy[key] = true;
      } else {
        state.resultBy[key] = false;
      }
    }
    return { ...state };
  }
  if (action.type === "upsetDemo") {
    state.isDemo = false;
    for (let key in state.resultBy) {
      state.resultBy[key] = false;
    }
    return { ...state };
  }

  if (action.type === "setCustomCompanySearch") {
    state.customCompanySearch = action.payload;
    return { ...state };
  }
  if (action.type === "setCustomCompanyInfo") {
    state.customCompanyInfo = action.payload;
    state.companyLoading = false;
    return { ...state };
  }
  if (action.type === "upSetCustomCompanyInfo") {
    state.customCompanyInfo = null;
    return { ...state };
  }
  if (action.type === "setCompanyLoading") {
    state.companyLoading = true;
    return { ...state };
  }
  if (action.type === "setPersonLoading") {
    state.personLoading = true;
    return { ...state };
  }
  if (action.type === "setCustomPersonInfo") {
    state.customPersonInfo = action.payload;
    if (
      action.payload?.urls !== undefined &&
      action.payload?.names !== undefined &&
      action.payload?.jobs !== undefined
    ) {
      state.customPersonInfo["details_url"] = action.payload?.urls[0]?.url;
      state.customPersonInfo[
        "full_name"
      ] = `${action.payload?.names[0].first} ${action.payload?.names[0].last}`;
      state.customPersonInfo["job"] = `${action.payload?.jobs[0]?.display}`;
    }
    state.personLoading = false;
    return { ...state };
  }

  if (action.type === "setUserName") {
    state.customPersonInfo = {
      location: action.payload.location,
      name: action.payload.name,
      details_url: action.payload.profile_url,
      summary: action.payload.summary,
    };
    state.tmpData = {
      location: action.payload.location,
      name: action.payload.name,
      details_url: action.payload.profile_url,
      summary: action.payload.summary,
    };
    state.personLoading = true;
    return { ...state };
  }

  if (action.type === "setSpecificUserInfo") {
    if (action.payload === "failed/unknown") {
      state.customPersonInfo = state.tmpData;
      state.personLoading = false;
      return { ...state };
    }
    state.customPersonInfo = { ...action.payload };
    state.customPersonInfo["details_url"] = state.tmpData?.details_url;
    if (action.payload?.jobs !== undefined && action.payload?.jobs !== null) {
      state.customPersonInfo[
        "job"
      ] = `${action.payload?.jobs[0]?.company_name} as ${action.payload?.jobs[0]?.job_name}`;
    }
    state.customPersonInfo["summary"] = state.tmpData?.summary;
    state.personLoading = false;
    return { ...state };
  }

  if (action.type === "setHistorySearchTerm") {
    state.historySearchTerm = action.payload;
    return { ...state };
  }
  if (action.type === "upsetHistorySearchTerm") {
    state.historySearchTerm = "";
    return { ...state };
  }

  if (action.type === "setMicrointraction") {
    state.microIntraction = action.payload;
    return { ...state };
  }

  if (action.type === "setPersonalisedDetails") {
    state.personalisedDetails = action.payload;
    return { ...state };
  }
};
