import React from "react";
import LinkedInImage from "./LinkedInImage";
import { ValidateLinkedinUrl } from "../../Utils/utils";

const PublicListedCompanies = ({ companyPageRecord }) => {
  return (
    <>
      <div className="ml-2 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord?.company_name === "object" &&
          companyPageRecord?.company_name.length > 0
            ? companyPageRecord?.company_name[0]
            : companyPageRecord?.company_name
            ? companyPageRecord?.company_name
            : "-"}
        </h6>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.linkedin_url === "object" &&
            companyPageRecord?.linkedin_url.length > 0
            ? companyPageRecord?.linkedin_url[0]
            : companyPageRecord?.linkedin_url
            ? companyPageRecord?.linkedin_url
            : null
        )}
      />{" "}
      <div className="realtime-result__child">
        <>
          Industry
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.industry === "object" &&
            companyPageRecord?.industry[0].length > 0
              ? companyPageRecord?.industry[0]
              : companyPageRecord?.industry
              ? companyPageRecord?.industry
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Company Type
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.company_type === "object" &&
            companyPageRecord?.company_type[0].length > 0
              ? companyPageRecord?.company_type[0]
              : companyPageRecord?.company_type
              ? companyPageRecord?.company_type
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          IPO Status
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.ipo_status === "object" &&
            companyPageRecord?.ipo_status[0].length > 0
              ? companyPageRecord?.ipo_status[0]
              : companyPageRecord?.ipo_status
              ? companyPageRecord?.ipo_status
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Location
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.city === "object" &&
            companyPageRecord?.city[0].length > 0
              ? companyPageRecord?.city[0]
              : companyPageRecord?.city
              ? companyPageRecord?.city
              : "-"}
          </span>
        </>
      </div>
    </>
  );
};

export default PublicListedCompanies;
