import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import StripePlanDetails from "./StripePlanDetails";
import { getFetch } from "../../../Config/facade";
import PlanHeader from "../components/PlanHeader";
import PaymentTopContainer from "../components/PaymentTopContainer";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_CONFIG_STRIPE_KEY);
const StripeCreditPlanContainer = () => {
  const [toggleButton, setToggleButton] = useState("monthly");
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [annuallyPlans, setAnnuallyPlans] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPlanIds, setCurrentPlanIds] = useState([]);
  const [currentPlan, setCurrentPlan] = useState([]);
  const [currency, setCurrency] = useState("INR");
  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggleButton(newToggle);
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    const url =
      path === "/payment/extension"
        ? "extension"
        : path === "/payment/platform"
        ? "platform"
        : "outreach";
    setName(url);

    // Before redirecting to the login page
    if (!Cookies.get("user_token"))
      localStorage.setItem("redirectUrl", window.location.href);
    setLoading(true);
    (async () => {
      let planIds = [];
      const result = await getFetch("/payment/stripe/subscription");
      console.log("get subscription result: ", result);
      result?.filter((item) => {
        planIds.push(item.plan_id);
      });
      console.log("planIdts " + JSON.stringify(planIds));
      setCurrentPlanIds(planIds);
      setCurrentPlan(result);
    })();
    (async () => {
      console.log("url: ", url);
      const res = await getFetch(`/payment/stripe/plans/${url}`);
      console.log("res: ", res);
      const monthly = res?.filter((obj) => obj?.metadata?.interval === "month");

      const annually = res?.filter((obj) => obj?.metadata?.interval === "year");
      console.log("monthly: ", monthly);
      setMonthlyPlans(monthly);
      setPaymentData(monthly);
      setAnnuallyPlans(annually);
      setLoading(false);
    })();
  }, []);
  return (
    <div className="main-content-area pb-6 pt-2">
      <div className="main-wrapper container-fluid">
        <PaymentTopContainer />
        <div className="user-widget-box mt-2">
          <PlanHeader
            monthlyPlans={monthlyPlans}
            annualyPlans={annuallyPlans}
            setPaymentData={setPaymentData}
            toggleButton={toggleButton}
            handleToggle={handleToggle}
            planName={name}
            currency={currency}
            setCurrency={setCurrency}
          />
          <div className="row pb-3 mt-8 mb-2 d-flex justify-content-center">
            <Elements stripe={stripePromise}>
              <StripePlanDetails
                currentPlanIds={currentPlanIds}
                loading={loading}
                data={paymentData}
                currency={currency}
                currentPlan={currentPlan}
              />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeCreditPlanContainer;
