import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import MessageDialog from "../PersonalizedMessages/MessageDialog";
import { useState } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

const CompanyProfileImage = ({ company_name, image_url }) => {
  return (
    <div className="profile-image text-danger">
      <img
        src={image_url || "/assets/images/new_icons/ProfilePic.png"}
        alt={`Company Profile of ${company_name}`}
      />
    </div>
  );
};

const CompanyProfileDescription = ({ company_name, headquarters }) => {
  return (
    <div className="profile-description">
      <h4>{company_name}</h4>
      {headquarters && <small className="d-block">{headquarters}</small>}
    </div>
  );
};

const CompanyProfileIndustry = ({ industry }) => {
  return (
    <div className="profile-description">
      <h6 style={{ fontWeight: "400", textAlign: "left" }}>{industry}</h6>
    </div>
  );
};

const CompanyProfileLinkedIn = ({
  organization_linkedin_numerical_urls,
  handleLinkedin,
  showMessageModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const currentPath = location.pathname;
  const currenUrl = currentPath.includes("CompanySearch");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLink = (link) => {
    handleClose();
    window.open(link, "_blank");
  };
  return (
    <div className="profile-linkedin">
      {organization_linkedin_numerical_urls && (
        <>
          <Tooltip
            title={
              currenUrl || !showMessageModal
                ? "Click to open linkedin profile"
                : "Tap for more actions"
            }
            arrow
          >
            {currenUrl || !showMessageModal ? (
              <a
                href={organization_linkedin_numerical_urls}
                target="_blank"
                alt="Links"
                rel="noreferrer"
              >
                <img
                  style={{ width: "80%", height: "80%" }}
                  src="/assets/images/linkedin1.png"
                  alt="LinkedIn Logo"
                />
              </a>
            ) : (
              <Button
                data-cy="linkedin-link"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  style={{ width: "80%", height: "80%" }}
                  src="/assets/images/linkedin1.png"
                  alt="LinkedIn Logo"
                />
              </Button>
            )}
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              borderRadius: "50px",
            }}
          >
            <MenuItem
              role="link"
              href={organization_linkedin_numerical_urls}
              target="_blank"
              onClick={() => handleLink(organization_linkedin_numerical_urls)}
              sx={{
                display: "flex",
                gap: "4px",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
              <PersonOutlineOutlinedIcon style={{ color: "#7B7A80" }} />
              Open Linkedin Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleLinkedin();
              }}
              sx={{
                display: "flex",
                gap: "4px",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
              <EmailOutlinedIcon style={{ color: "#7B7A80" }} />
              Send Personalized Message
            </MenuItem>
            <MenuItem
              // onClick={handleClose}
              sx={{
                display: "flex",
                gap: "4px",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
              onClick={async () => {
                handleClose();
                await navigator.clipboard.writeText(
                  organization_linkedin_numerical_urls
                );
                enqueueSnackbar("LinkedIn profile copied!");
              }}
            >
              <ContentCopyOutlinedIcon style={{ color: "#7B7A80" }} />
              Copy Linkedin Link
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

const CompanyProfile = ({ data, flexBasis, showMessageModal }) => {
  const { company_name, industry, headquarters, social_url, image_url } = data;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleLinkedin = () => {
    setMessage("linkedin");
    setOpen(true);
  };
  return (
    <div style={{ flexBasis: flexBasis }}>
      <div className="profile-container">
        <CompanyProfileImage
          company_name={company_name}
          image_url={image_url}
        />
        <CompanyProfileDescription
          company_name={company_name}
          headquarters={headquarters}
        />
        <CompanyProfileIndustry industry={industry} />
        <CompanyProfileLinkedIn
          organization_linkedin_numerical_urls={social_url}
          handleLinkedin={handleLinkedin}
          showMessageModal={showMessageModal}
        />
      </div>
      <MessageDialog
        title={message}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default CompanyProfile;
