export const getSearchCategory = (pathname) => {
    switch (pathname) {
      case '/RealtimePeopleSearch':
        return "realtime-people-search";
      
      case '/ProspectSearch':
        return "prospect-search";
  
      case '/SearchBarSearch':
        return "searchbar-search";
  
      case '/smeCompanies':
        return "sme-companies";
      
      case '/RealtimePeopleSearch':
        return "real-time-results";
      
      case '/UnlockProfile':
          return "associated-search";
          
      case '/people/realtime/filter':
          return "realtime-people-search";
    
      default:
        return "prospect-search";
    }
  };
  