import React, { useEffect, useState } from "react";
import { selectMapBoxAutocomplete } from "../../../_reducer/areaDetail";
import {
  saveLeadsData,
  saveLeadsForMapMarker,
  selectLeadsData,
  selectLeadsForMapMarker,
} from "../../../_reducer/leads";
import { fetchSavedListsNames } from "../../SavedList/SavedListQueries";
import {
  saveCategorySelected,
  saveLeadsId,
  saveLoaderState,
  selectCategorySelected,
  selectLoaderState,
} from "../../../_reducer/shared";
import { useDispatch, useSelector } from "react-redux";
import { httpService } from "../geolocatorHttpServices/geolocatorHttpServices";
import "./LeadsData.css";
import { useImmer } from "use-immer";
import MapExport from "../../mapResultFromHistory/MapExport";
import ResultsSearchBar from "../../SharedComponent/Components/ResultsSearchBar";
import MapResultItem from "../../mapResultFromHistory/MapResultItem";
import Emitter from "../../Utils/emitter";
import SmeListView from "../../RealTime/SmeCompanies/SmeListView";
import { useSnackbar } from "notistack";
import { mapMetaData } from "../../mapResultFromHistory/api";
import { Loader } from "../../Utils/utils";
import { getSaveListMetadata } from "../../SavedList/SavedListQueries";
import Pagination from "../../Utils/Pagination";

function LeadsData({
  setSimpleSearchId,
  simpleSearchId,
  center,
  setCategory,
  allCoordinates,
  mapSelectedLeads,
  setMapSelectedLeads,
}) {
  const itemPerPage = 15;

  const [singleSelectedCategory, setSingleSelectedCategory] = useState("none");
  const [leads, setLeads] = useState([]);
  const [searchbar, setsearchbar] = useState(false);
  const [totalLeads, setTotalLeads] = useState([]);
  const [totalSearchedLeads, setTotalSearchedLeads] = useState(totalLeads);
  const [copyLeads, setCopyLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [type, setType] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOuOfShowing, setRecordsOuOfShowing] = useState(itemPerPage);
  const leadsData = useSelector(selectLeadsData);
  const mapBoxAutocomplete = useSelector(selectMapBoxAutocomplete);
  const loaderState = useSelector(selectLoaderState);
  const categorySelected = useSelector(selectCategorySelected);
  const leadsForMapMarker = useSelector(selectLeadsForMapMarker);
  const [isOpen, setIsOpen] = useState(() => "");
  const [open, setOpen] = useState(() => false);
  const dispatch = useDispatch();
  const [leadsToShowOnMap, setLeadsToShowOnMap] = useImmer(leadsForMapMarker);
  const [saveListNames, setSaveListNames] = useState([]);
  const [saveListMetadata, setSaveListMetadata] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  console.log("mapBoxAutocomplete", mapBoxAutocomplete);

  useEffect(() => {
    (async () => {
      // Existing records in saved_list
      let saveListNames = await fetchSavedListsNames();
      console.log("saveListNames", saveListNames);

      if (saveListNames?.["list_names"]) {
        setSaveListNames(saveListNames["list_names"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (Object?.keys(leadsData)?.length > 0) {
        let currentLeads =
          leads && Array.isArray(leads) ? leadsData?.search_result : [];
        let result = await mapMetaData(removeDuplicates(currentLeads));
        setLeads(result);
        setCopyLeads(result);
        setActivePage(0);
        dispatch(saveLoaderState(false));
        setRecordsOuOfShowing(currentLeads?.length);
      } else {
        setLeads([]);
        setCopyLeads([]);
        dispatch(saveLoaderState(false));
      }
    })();
  }, [leadsData]);

  const removeDuplicates = (leads) => {
    const seen = new Set();
    const uniqueLeads = [];

    for (const lead of leads) {
      const name = lead?.name;
      const latitude = lead?.latitude;
      const longitude = lead?.longitude;
      const combinedKey = `${name}-${latitude}-${longitude}`;

      if (!seen.has(combinedKey)) {
        seen.add(combinedKey);
        uniqueLeads.push(lead);
      }
    }

    return uniqueLeads;
  };

  useEffect(() => {
    (async () => {
      let { place_name, bbox, type, center } = mapBoxAutocomplete;
      setType(type);

      const latLonList = allCoordinates?.map((item) => {
        return {
          lat: item[1],
          lon: item[0],
        };
      });

      let data = {
        data_layer_type: singleSelectedCategory,
        latitude_north_w: type === "Feature" ? bbox?.[3] : null,
        longitude_north_w: type === "Feature" ? bbox?.[0] : null,
        latitude_south_e: type === "Feature" ? bbox?.[1] : null,
        longitude_south_e: type === "Feature" ? bbox?.[2] : null,
        lat_lon_list: type === "Polygon" ? latLonList : null,
        center: center,
        search_term:
          type === "Polygon" ? `${JSON.stringify(latLonList)}` : place_name,
        limit: 10000,
        type: type,
        offset: 0,
      };
      httpService.getPaginateLeads(data).then(async (response) => {
        const metaDataResult = await mapMetaData(
          response?.search_results?.search_result
        );
        const result = removeDuplicates(metaDataResult || []);
        setTotalLeads(result);
        setTotalPages(Math.ceil(result?.length / itemPerPage));
      });
    })();
  }, [
    allCoordinates,
    mapBoxAutocomplete,
    singleSelectedCategory,
    categorySelected,
  ]);

  useEffect(() => {
    if (searchbar)
      setTotalPages(Math.ceil(totalSearchedLeads?.length / itemPerPage));
    else setTotalPages(Math.ceil(totalLeads?.length / itemPerPage));
  }, [leads, mapBoxAutocomplete]);

  useEffect(() => {
    if (leadsToShowOnMap?.length > 0) {
      dispatch(saveLeadsForMapMarker(leadsToShowOnMap));
    } else {
      dispatch(saveLeadsForMapMarker([]));
    }
  }, [leadsToShowOnMap]);

  // useEffect(() => {
  //   if(searchbar) setTotalPages()
  //   else setTotalPages(totalLeads.length / )
  // }, [mapBoxAutocomplete]);

  useEffect(() => {
    if (Object?.keys(mapBoxAutocomplete)?.length > 0) {
      let { place_name } = mapBoxAutocomplete;
      setSearchTerm(place_name);
    }
    setSingleSelectedCategory(categorySelected);
    console.log("this");
    setActivePage(0);
    // setTotalPages(0);
  }, [categorySelected, mapBoxAutocomplete]);

  useEffect(() => {
    (async () => {
      // console.log("pgdataa", leads);
      if (leads?.length === 0) return;
      let hashKeyInputs;
      if (leads?.length > 15) {
        hashKeyInputs = leads?.slice(0, 14).map((lead) => lead?.id);
      } else hashKeyInputs = leads?.map((lead) => lead?.id);
      const saveListMetadata = await getSaveListMetadata(hashKeyInputs);
      setSaveListMetadata(saveListMetadata);
      console.log("saveListMetadata", saveListMetadata);
    })();
  }, [leads]);

  useEffect(() => {
    if (saveListMetadata) setSaveListMetadata([]);
  }, [activePage]);

  const handlePageClick = async (page) => {
    let offset = 0;
    page = page?.selected;
    console.log("page", page);
    if (page) {
      offset = page * itemPerPage;
    }
    if (searchbar) {
      setLeads(totalSearchedLeads.slice(offset, offset + 14));
    } else {
      dispatch(saveLoaderState(true));
      if (singleSelectedCategory === "people") {
        let mapLeads =
          leadsData?.search_result && Array.isArray(leadsData?.search_result)
            ? leadsData?.search_result?.slice(
                page * itemPerPage - itemPerPage,
                page * itemPerPage
              )
            : [];
        const result = await mapMetaData(mapLeads);
        setLeads(result);
        setCopyLeads(result);
        dispatch(saveLoaderState(false));
      } else {
        let { place_name, bbox, type, center } = mapBoxAutocomplete;
        setType(type);
        const latLonList = allCoordinates?.map((item) => {
          return {
            lat: item[1],
            lon: item[0],
          };
        });
        let data = {
          data_layer_type: singleSelectedCategory,
          latitude_north_w: type === "Feature" ? bbox?.[3] : null,
          longitude_north_w: type === "Feature" ? bbox?.[0] : null,
          latitude_south_e: type === "Feature" ? bbox?.[1] : null,
          longitude_south_e: type === "Feature" ? bbox?.[2] : null,
          lat_lon_list: type === "Polygon" ? latLonList : null,
          center: center,
          search_term:
            type === "Polygon" ? `${JSON.stringify(latLonList)}` : place_name,
          limit: offset === 9990 ? itemPerPage - 5 : itemPerPage,
          type: type,
          offset: offset,
        };
        httpService
          .getPaginateLeads(data)
          .then(async (response) => {
            setActivePage(page);
            console.log(response);
            const metaDataResult = await mapMetaData(
              response?.search_results?.search_result
            );
            setLeadsToShowOnMap((leads) => {
              leads?.push({
                category: categorySelected,
                data: response?.search_results?.search_result,
              });
            });
            setLeads(metaDataResult);
            setCopyLeads(metaDataResult);
            setSearchTerm(place_name);
            setRecordsOuOfShowing(metaDataResult?.length);
            dispatch(saveLoaderState(false));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  function getSimpleResult(
    value,
    bbox,
    place_name,
    type,
    limit = itemPerPage,
    offset = 0
  ) {
    console.log("bboxHehe", bbox);
    console.log("in getSimpleResult", type, place_name);
    setType(type);
    setActivePage(0);
    if (type === "Feature" && !place_name) {
      enqueueSnackbar("Please Select Region");
      return;
    }
    let coordinates1 = [];
    let coordinates2 = [];
    if (type === "Polygon") {
      coordinates1 = allCoordinates[0];
      coordinates2 = allCoordinates[2];
    }
    let data = {
      data_layer_type: value,
      latitude_north_w: type === "Polygon" ? coordinates1[1] : bbox[3],
      longitude_north_w: type === "Polygon" ? coordinates1[0] : bbox[0],
      latitude_south_e: type === "Polygon" ? coordinates2[1] : bbox[1],
      longitude_south_e: type === "Polygon" ? coordinates2[0] : bbox[2],
      search_term: type === "Polygon" ? allCoordinates?.join(" ") : place_name,
      limit: limit,
      offset: offset,
      center: center,
      type: type,
    };

    httpService
      .saveToHistory(data)
      .then(async (data) => {
        let { id } = data;
        if (id) {
          dispatch(saveLeadsId(id));
          setSimpleSearchId(id);

          const intervalId = setInterval(async () => {
            httpService?.getLeads(id).then((data) => {
              let { search_results, status } = data;

              if (status === "success") {
                console.log(
                  "type of search result: ",
                  typeof search_results,
                  search_results
                );
                dispatch(saveCategorySelected(value));
                dispatch(saveLeadsData(search_results));
                setLeadsToShowOnMap((leads) => {
                  leads?.push({
                    category: value,
                    data: search_results?.search_result,
                  });
                });
                clearInterval(intervalId);
              } else if (status === "failed") {
                dispatch(saveLeadsData({}));
                dispatch(saveLoaderState(false));
                clearInterval(intervalId);
              }
            });
          }, 5 * 1000);
        } else {
          dispatch(saveLeadsData({}));
          dispatch(saveLoaderState(false));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCategorySelectChange = (event) => {
    dispatch(saveLoaderState(true));
    setMapSelectedLeads([]);
    if (Object?.keys(mapBoxAutocomplete)?.length === 0) {
      dispatch(saveLoaderState(false));
      enqueueSnackbar("Please Select Region");
      return;
    }
    if (event?.target?.value === "none") {
      dispatch(saveLoaderState(false));
      return;
    }
    setSingleSelectedCategory(event?.target?.value);
    setCategory(event?.target?.value);
    if (Object?.keys(mapBoxAutocomplete)?.length > 0) {
      let { bbox, place_name, type } = mapBoxAutocomplete;
      setSearchTerm(place_name);
      setLeadsToShowOnMap([]);
      setActivePage(0);
      console.log("or this");
      setTotalPages(0);
      console.log("in handle category select", type, place_name);
      if (type === "Feature" && !place_name) {
        dispatch(saveLoaderState(false));
        enqueueSnackbar("Please Select Region");
        return;
      }
      if (!event.target?.value?.includes("sme")) {
        getSimpleResult(event?.target?.value, bbox, place_name, type);
      } else dispatch(saveLoaderState(false));
    }
  };

  const onViewProfileOrWorkEmail = async () => {
    const result = await mapMetaData(leads);
    setLeads(result);
    setCopyLeads(result);
    Emitter.emit("updateCredits", true);
  };

  return (
    <div className="leads_mainContainer">
      <div className="result-cotainer__row">
        <p className="result-cotainer__total">
          Search results at {mapBoxAutocomplete?.place_name}
        </p>
      </div>
      <div
        className="result-container__results"
        data-cy="geolocatorResultContainer"
      >
        <div className="result-container__index">
          {Object?.keys(leadsData)?.length === 0 ? (
            <h4 className="result-container__sub-heading">Results</h4>
          ) : !singleSelectedCategory?.includes("sme") ? (
            <h4 className="result-container__sub-heading">
              {activePage === 667
                ? 10
                : recordsOuOfShowing > leads?.length
                ? leads?.length
                : recordsOuOfShowing}{" "}
              of {searchbar ? totalSearchedLeads?.length : totalLeads?.length}{" "}
              Searched Results
            </h4>
          ) : (
            ""
          )}
          {!singleSelectedCategory?.includes("sme") ? (
            leads && (
              <ResultsSearchBar
                original={totalLeads}
                geoLeads={copyLeads}
                setLeads={setLeads}
                setTotalSearchedLeads={setTotalSearchedLeads}
                setsearchbar={setsearchbar}
                geolocator={true}
              />
            )
          ) : (
            <div></div>
          )}
          <div className="result-container__buttons">
            <select
              className="result-container__dropdown pointer-cursor"
              autoComplete="off"
              onChange={(e) => handleCategorySelectChange(e)}
              data-cy="geolocator-category-selector"
              value={singleSelectedCategory}
            >
              <option value="none">Select Option</option>
              {/*<option value="people">People</option>*/}
              {/* <option value="sme">SME</option> */}
              <option value="retails">Retails</option>
              <option value="education">Educational</option>
              <option value="restaurants">Restaurant, Bars, Eateries</option>
              <option value="hotels">Hotel and Dorms</option>
              <option value="banks">Banks</option>
              <option value="atm">ATM</option>
              <option value="medical">Medical</option>
              <option value="professional">Professionals- CA, Lawyer</option>
              <option value="appartment">Apartments</option>
              <option value="financialandlegal">Legal and Financial</option>
              <option value="government">Government</option>
              <option value="others">Others</option>
            </select>

            <MapExport
              mapSelectedLeads={mapSelectedLeads}
              searchTerm={singleSelectedCategory}
            />
          </div>
        </div>
        <hr className="divider" />
        <div className="result-container__text-result">
          {Object?.keys(leadsData)?.length === 0 && !loaderState && (
            <h2> Select Option From Dropdown </h2>
          )}
          {loaderState ? (
            <Loader />
          ) : singleSelectedCategory?.includes("sme") ? (
            <SmeListView
              input={searchTerm}
              center={center}
              type={type}
              allCoordinates={allCoordinates}
              requestType="geolocator_sme"
            />
          ) : leads && leads?.length > 0 ? (
            leads?.map((data, i) => {
              return (
                <div key={i}>
                  <MapResultItem
                    data={data}
                    searchTerm={singleSelectedCategory}
                    i={i}
                    setMapSelectedLeads={setMapSelectedLeads}
                    mapSelectedLeads={mapSelectedLeads}
                    setIsOpen={setIsOpen}
                    activePage={activePage}
                    isOpen={isOpen}
                    open={open}
                    setOpen={setOpen}
                    simpleSearchId={simpleSearchId}
                    onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                    saveListNames={saveListNames}
                    setSaveListNames={setSaveListNames}
                    saved={saveListMetadata?.[i]?.["saved"]}
                  />
                </div>
              );
            })
          ) : (
            <h2> Records Not Found </h2>
          )}
        </div>

        {leads?.length > 0 &&
        // !loaderState &&

        !singleSelectedCategory?.includes("sme") ? (
          <div className="pagination_maincontainer">
            <div
              className="pagination_container"
              style={{ width: "30%" }}
              data-cy="pagination-container"
            >
              <Pagination
                onPageChange={handlePageClick}
                pageNumber={activePage}
                pageCount={totalPages}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default LeadsData;
