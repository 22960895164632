import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiServer } from "../../../config";
import Cookies from "js-cookie";
import AddTeammatesPopUp from "./AddTeammatesPopUp";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import History from "../../History/History";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import AllocateCreditsPopUp from "./AllocateCreditsPopUp";
import RevertCreditsPopUp from "./RevertCreditsPopUp";
import Emitter from "../../Utils/emitter";
import { Loader } from "../../Utils/utils";

const apiHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${Cookies.get("user_token")}`,
};
const CurrentTeam = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openItem, setOpenItem] = useState();
  const [childEmail, setChildEmail] = useState();
  const [childCredits, setChildCredits] = useState([
    { profile_credits: 0, email_credits: 0 },
  ]);
  const [loading, setLoading] = useState(true);
  const [isCreditUpdated, setIsCreditUpdated] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    (async () => {
      await listAllChildren();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await listAllChildren();
      Emitter.emit("fetchCredits", true);
    })();
  }, [isCreditUpdated]);

  const listAllChildren = async () => {
    try {
      setLoading(true);
      const fetchResponse = await fetch(apiServer + `/user/list_children`, {
        headers: apiHeader,
      });
      const data = await fetchResponse.json();
      console.log("data: ", data);
      setTeamMembers(data);
      setLoading(false);
    } catch (err) {
      console.log("");
    }
  };
  const handleDeleteTeammates = async (e, email) => {
    e.preventDefault();
    console.log("In handleDeleteTeammates");
    let answer = window.confirm("Do you want to delete the child?");
    if (answer) {
      const payload = {
        children: email,
      };
      try {
        const response = await fetch(apiServer + "/user/children", {
          method: "DELETE",
          headers: apiHeader,
          body: JSON.stringify(payload),
        });
        switch (response.status) {
          case 200:
            enqueueSnackbar("Teammate Deleted Successfully.");
            await listAllChildren();
            break;
          case 401:
            enqueueSnackbar("Please Logout and Login again.");
            break;
          case 404:
            enqueueSnackbar(
              "Entered Email Not Found. Please Enter Valid Email."
            );
            break;

          default:
            enqueueSnackbar("Something Wrong. Try After Sometime");
            break;
        }
      } catch (err) {
        console.error("Error: ", err);
      }
    }
  };
  console.log("team members: ", teamMembers);
  return (
    <>
      <div
        className="user-widget-box p-4 mt-3"
        style={{ textAlign: "center", width: "100%" }}
        data-cy="user_profile_item"
      >
        <AddTeammatesPopUp
          dataTarget="add-teammate-modal"
          setTeamMembers={setTeamMembers}
        />
        <AllocateCreditsPopUp
          dataTarget="allocate-credits-modal"
          childEmail={childEmail}
          setIsCreditUpdated={setIsCreditUpdated}
          isCreditUpdated={isCreditUpdated}
        />
        <RevertCreditsPopUp
          dataTarget="revert-credits-modal"
          childEmail={childEmail}
          childCredits={childCredits}
          isCreditUpdated={isCreditUpdated}
          setIsCreditUpdated={setIsCreditUpdated}
        />
        <Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            justifyItems={"center"}
            mt={5}
            sx={{
              paddingX: "10px",
              paddingY: "20px",
              marginBottom: "5px",
            }}
          >
            <button
              data-toggle="modal"
              data-target="#add-teammate-modal"
              className="btn text-white og-red global-hover"
              type="submit"
              disabled={false}
              data-cy="add-teammate-search"
              style={{
                textAlign: "center",
                paddingY: ".7rem",
                paddingX: "2rem",
                backgroundColor: "#fb3e3e",
                color: "#fff",
                maxWidth: "190px",
                marginX: "auto",
                marginY: "10px",
                borderRadius: "50px",
                "&:hover": {
                  backgroundColor: "#fb3e3e",
                  color: "#fff",
                },
              }}
            >
              Add Teammates
            </button>
          </Box>
          {/*Team Mates */}
          {loading ? (
            <Loader />
          ) : teamMembers.length > 0 ? (
            teamMembers?.map((item, index) => (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  justifyItems={"left"}
                  textAlign={"left"}
                  mt={3}
                  sx={{
                    background: "#F4F7FC",
                    paddingX: "10px",
                    paddingY: "20px",
                    marginBottom: "5px",
                    borderRadius: "10px",
                  }}
                >
                  {/*<Box display={"flex"} gap={1}>*/}
                  {/*  /!*<Avatar> {item?.username.slice(0, 1).toUpperCase()}</Avatar>*!/*/}
                  {/*  <Box*/}
                  {/*    display={"flex"}*/}
                  {/*    flexDirection={"column"}*/}
                  {/*    minWidth={"fit-content"}*/}
                  {/*    width={"2px"}*/}
                  {/*  >*/}
                  {/*    <Typography color={"#000"} fontWeight={600}>*/}
                  {/*      {item?.username}*/}
                  {/*    </Typography>*/}
                  {/*    /!*<Typography variant="body2" color={"#263238"}>*!/*/}
                  {/*    /!*  Senior developer at Hershey*!/*/}
                  {/*    /!*</Typography>*!/*/}
                  {/*  </Box>*/}
                  {/*</Box>*/}
                  <Box display={"flex"}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      minWidth={"fit-content"}
                    // width={"100px"}
                    >
                      <Typography>{item?.email}</Typography>
                    </Box>
                  </Box>
                  {/*<Button sx={{ backgroundColor: "#D9D9D9", color: "#333" }}>*/}
                  {/*  {item?.children ? "Admin" : "User"}*/}
                  {/*</Button>*/}
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <Typography color={"#000"} fontWeight={600}>
                      Profile | Work Email
                    </Typography>
                    {/*<Typography variant="body2" color={"#fb3e3e"}>*/}
                    {/* */}
                    {/*</Typography>*/}
                    <Typography variant="body2" color={"#fb3e3e"}>
                      {item?.profile_credit} | {item?.email_credit}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <Typography color={"#000"} fontWeight={600}>
                      Total Credits Used: {item?.total_unlocked_credits}
                    </Typography>
                    <Typography color={"#000"} fontWeight={600}>
                      Profile | Work Email
                    </Typography>
                    <Typography variant="body2" color={"#fb3e3e"}>
                      {item?.unlocked_profile_credit} |{" "}
                      {item?.unlocked_email_credit}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <button
                      style={{ background: "none", border: "none" }}
                      type="submit"
                      data-cy="#allocate-credits-btn"
                      data-toggle="modal"
                      data-target="#allocate-credits-modal"
                      disabled={false}
                      onClick={() => setChildEmail(item?.email)}
                    >
                      <Tooltip title="Allocate the credits">
                        <div>
                          <IoAddCircleOutline color="#fb3e3e" size={30} />
                        </div>
                      </Tooltip>
                    </button>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <button
                      style={{ background: "none", border: "none" }}
                      type="button"
                      data-cy="#revert-credits-btn"
                      data-toggle="modal"
                      data-target="#revert-credits-modal"
                      disabled={false}
                      onClick={() => {
                        setChildEmail(item?.email);
                        setChildCredits({
                          profile_credits: item?.profile_credit,
                          email_credits: item?.email_credit,
                        });
                      }}
                    >
                      <Tooltip title="Revert the credits">
                        <div>
                          <IoRemoveCircleOutline color="#fb3e3e" size={30} />
                        </div>
                      </Tooltip>
                    </button>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <Button
                      onClick={(e) => handleDeleteTeammates(e, item?.email)}
                      sx={{
                        border: "1px solid #fb3e3e",
                        borderRadius: "30px",
                        color: "#fb3e3e",
                        paddingX: "20px",
                        alignItems: "center",
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <Button
                      onClick={() => {
                        setOpenItem(item?.email);
                        setOpen(!open);
                      }}
                      sx={{
                        border: "1px solid #fb3e3e",
                        borderRadius: "30px",
                        color: "#fb3e3e",
                        paddingX: "20px",
                        alignItems: "center",
                      }}
                    >
                      View History
                    </Button>
                  </Box>
                </Box>
                <div
                  style={{
                    backgroundColor: "rgba(211,211,211,0.4)",
                  }}
                >
                  <Collapse
                    in={open && openItem === item?.email}
                    timeout="auto"
                    unmountOnExit
                  >
                    <CardContent>
                      <Container>
                        <History onRepeatedPage={true} child={item?.email} />
                      </Container>
                    </CardContent>
                  </Collapse>
                </div>
              </>
            ))
          ) : (
            !loading && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                justifyItems={"center"}
                mt={3}
                sx={{
                  background: "#F4F7FC",
                  paddingX: "10px",
                  paddingY: "20px",
                  marginBottom: "5px",
                  borderRadius: "10px",
                }}
              >
                Record Not found{" "}
              </Box>
            )
          )}
        </Box>
      </div>
    </>
  );
};

export default CurrentTeam;