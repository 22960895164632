import { Tooltip } from "@mui/material";

const LinkedInImage = ({ link }) => {
  return (
    <>
      <div className="realtime-result__image">
        {link ? (
          <Tooltip title={link} arrow>
            <a href={link} target="_blank" rel="noreferrer">
              <img
                className="realtime-result__img"
                src="/assets/images/social-linkedin.png"
                alt="title"
                style={{ opacity: link ? "100%" : "50%" }}
              />
            </a>
          </Tooltip>
        ) : (
          <div>
            <img
              className="realtime-result__img"
              src="/assets/images/social-linkedin.png"
              alt="title"
              style={{ opacity: link ? "100%" : "50%" }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default LinkedInImage;
