import React, { useEffect, useState, useReducer } from "react";
import "./Styles/SavedList.css";
import Cookies from "js-cookie";
import Modal from "../SharedComponent/Modal";
import SearchSpecificUser from "../DetailedInfo/SearchSpecificUser";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";
import { apiServer } from "../../config";
import { useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import SavedListButtons from "./SavedListButtons";
import PersonSavedListView from "./PersonSavedListView";
import CompanySavedListView from "./CompanySavedListView";
import { initialState, saveListReducer } from "./SavedListReducer";
import GeolocatorSavedListView from "./GeolocatorSavedListView";

const SavedList = () => {
  const [state, dispatch] = useReducer(saveListReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { name } = useParams();

  const location = useLocation();

  const handleSearch = (e) => {
    console.log("handleSearch called...");
    dispatch({
      type: "SET_SEARCH_TEXT",
      payload: e.target.value.toLowerCase(),
    });
    dispatch({ type: "SET_VIEW", payload: "normal" });
    dispatch({ type: "SET_PAGE", payload: 0 });
  };

  const handleSubmit = (e) => {
    console.log("handleSubmit called...");
    e.preventDefault();
    dispatch({ type: "SET_PAGE", payload: 0 });
    dispatch({ type: "SET_SEARCH_CACHE", payload: {} });
    dispatch({ type: "SET_TOTAL_SEARCH_RECORDS", payload: 0 });
    dispatch({ type: "SET_VIEW", payload: "search" });
  };

  return (
    <>
      <motion.div
        className="text-center p-4 my-3 container-fluid"
        {...fadeInFadeOut}
      >
        <div className="global-width user-lead-top mb-2 head-btn-style mb-3 global-shadow">
          <div className="d-flex align-items-center">
            <Link
              to={{
                pathname: "/savedLists",
                state: location.state,
              }}
              className="me-1"
            >
              <h5 className="m-0">
                <img
                  src={"/assets/images/new_icons/Back.png"}
                  alt="#"
                  style={{
                    width: "22px",
                    height: "22px",
                    marginRight: "0.25rem",
                  }}
                />
                {`Saved Lists : ${name}`}
              </h5>
            </Link>
          </div>
          <form className="search-form-sm">
            <div className="input-group">
              <input
                type="text"
                value={state.searchText}
                className="form-control"
                placeholder={
                  state.toggleButton === "people"
                    ? "Search in People.."
                    : state.toggleButton === "company"
                    ? "Search in Company.."
                    : "Search in Geolocator.."
                }
                onChange={handleSearch}
              />
              <button
                className="btn btn-danger"
                onClick={handleSubmit}
                type="submit"
              >
                <img src="/assets/images/small-search-icon.png" alt="title" />
              </button>
            </div>
          </form>
        </div>

        <div className="mt-3">
          <SavedListButtons state={state} dispatch={dispatch} />
        </div>

        <div className={`pt-0 accordion-body global-child-width`}>
          {state.toggleButton === "people" ? (
            <PersonSavedListView
              listName={name}
              state={state}
              dispatch={dispatch}
            />
          ) : state.toggleButton === "company" ? (
            <CompanySavedListView
              listName={name}
              state={state}
              dispatch={dispatch}
            />
          ) : (
            <GeolocatorSavedListView
              listName={name}
              state={state}
              dispatch={dispatch}
            />
          )}
        </div>
      </motion.div>
    </>
  );
};

export default SavedList;
