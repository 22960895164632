import React, { useState } from "react";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import SelectBulkExportTypePopup from "../../Utils/SelectBulkExportTypePopup";
import { apiServer } from "../../../config";

const ExportResult = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [exportType, setExportType] = useState("phone");
  const handleLeadSelectionExportExcel = async (e) => {
    e.preventDefault();
    const executeExport = async () => {
      function handleError(response = null) {
        console.error(`Error, Status Code: ${response?.status}`);
        enqueueSnackbar("Error Exporting File", { variant: "error" });
      }

      try {
        const inputData = {
          profile_urls: props.selectedLeads
            .filter((x) => x?.details_url)
            .map((x) => x.details_url),
          ids: props.selectedLeads
            .filter((x) => !x.details_url)
            .map((x) => x.id),
          phone_or_email: exportType,
          cookie: Cookies.get("user_linkedin_cookie")
            ? Cookies.get("user_linkedin_cookie")
            : null,
        };

        console.log(inputData);
        const response = await fetch(apiServer + "/bulk_upload/export/excel", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(inputData),
        });

        async function handleSuccess(response) {
          // Direct Download in case if only one selected lead.
          if (inputData.profile_urls.length === 1) {
            const data = await response.json();
            if (!data) {
              return handleError();
            }
            const username = Cookies.get("username");
            enqueueSnackbar(
              <div>
                Hey {username}! We have started working on your file. <br />
                We will take some time to revert back. <br />
                Be rest assured, You'll get an email once it is ready.
                <br />
                After exporting, please ensure to refresh your credit for
                seamless service. Thank you!
              </div>,
              { variant: "info" }
            );
            const timeoutId = setTimeout(() => {
              clearInterval(intervalId);
            }, 5 * 60 * 1000);
            const intervalId = setInterval(async () => {
              console.log("Checking For File state Every 3 Seconds");
              let fileName = data.output_filename;
              let url = `${apiServer}/${fileName
                ?.replace('"', "")
                ?.replace('"', "")}`;
              try {
                const fetchPromise = await fetch(url);
                switch (fetchPromise.status) {
                  case 200:
                    if (fileName) {
                      window.location.href = url;
                    } else {
                      console.error("Error in download link");
                    }
                    if (timeoutId) clearTimeout(timeoutId);

                    clearInterval(intervalId);
                    break;
                  case 400:
                    console.log(fetchPromise);
                    return;
                  default:
                    console.log(fetchPromise);
                }
              } catch (e) {
                console.error("Exception", e);
              }
            }, 3 * 1000);
          } else {
            const data = await response.json();
            if (!data) {
              return handleError();
            }
            const username = Cookies.get("username");
            enqueueSnackbar(
              <div>
                Hey {username}! We have started working on your file. <br />
                We will take some time to revert back. <br />
                Be rest assured, You'll get an email once it is ready.
                <br />
                After exporting, please ensure to refresh your credit for
                seamless service. Thank you!
              </div>,
              { variant: "info" }
            );
          }
        }

        function handleUnAuthorized() {
          console.log("User is UnAuthorized");
          enqueueSnackbar("Please Logout and LogIn Again");
        }

        function handleInsufficientBalance(response) {
          console.error("Insufficient credits...", response);
          enqueueSnackbar(
            "You have insufficient profile credits. Buy Credits to get details.",
            { variant: "warning" }
          );
        }

        switch (response.status) {
          case 200:
            return await handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 402:
            return handleInsufficientBalance(response);
          default:
            return handleError(response);
        }
      } catch (err) {
        console.error("Error: ", err);
        handleError();
      }
    };
    if (!props.selectedLeads) {
      console.warn("No Selected Leads");
      return;
    }
    await executeExport();
  };

  const checkErrors = async (e) => {
    e.preventDefault();
    if (
      ((props.customCount <= 0) |
        (props.customCount > props.props.currentLeads.length)) &
      (props.selectedLeads.length === 0)
    ) {
      enqueueSnackbar(
        "Value should be in between 1 - " +
          props.props.currentLeads.length +
          " !",
        { variant: "error" }
      );
      props.setCustomCount(props.props.currentLeads.length);
    }
  };

  return (
    <div>
      <SelectBulkExportTypePopup
        exportType={exportType}
        setExportType={setExportType}
        handleSubmit={handleLeadSelectionExportExcel}
        dataTarget="export-modal"
      />

      <button
        data-toggle="modal"
        data-target="#export-modal"
        className="export-btn"
        data-cy="export-btn"
        disabled={props.selectedLeads.length === 0 || props?.selected}
        onClick={checkErrors}
      >
        Export ({props.selectedLeads.length})
        <img
          className="ps-3"
          src="/assets/images/export.png"
          alt=""
          style={{ width: "30px", height: "15px" }}
        />
      </button>
    </div>
  );
};

export default ExportResult;
