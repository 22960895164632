import React from "react";
import dayjs from "dayjs";

const NewlyRegisteredSubsidiariesOfForeignCompanies = ({
  companyPageRecord,
}) => {
  return (
    <>
      {/*<div className="realtime-result">*/}
      <div className="ml-2 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord?.company_name === "object" &&
          companyPageRecord?.company_name.length > 0
            ? companyPageRecord?.company_name[0]
            : companyPageRecord?.company_name
            ? companyPageRecord?.company_name
            : "-"}
        </h6>
      </div>
      {/*{typeof companyPageRecord?.linkedin_of_company_that_acquired ===*/}
      {/*  "object" &&*/}
      {/*companyPageRecord?.linkedin_of_company_that_acquired.length > 0 ? (*/}
      {/*  <LinkedInImage*/}
      {/*    link={companyPageRecord?.linkedin_of_company_that_acquired[0]}*/}
      {/*  />*/}
      {/*) : companyPageRecord?.linkedin_of_company_that_acquired ? (*/}
      {/*  <LinkedInImage*/}
      {/*    link={companyPageRecord?.linkedin_of_company_that_acquired}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  "-"*/}
      {/*)}*/}

      <div className="realtime-result__child">
        <>
          Founder Name
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.founder === "object" &&
            companyPageRecord?.founder[0].length > 0
              ? companyPageRecord?.founder[0]
              : companyPageRecord?.founder
              ? companyPageRecord?.founder
              : "Undisclosed"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Location
          <br />
          <span className="text-red">
            {(typeof companyPageRecord?.city === "object" &&
            companyPageRecord?.city[0].length > 0
              ? companyPageRecord?.city[0]
              : companyPageRecord?.city
              ? companyPageRecord?.city
              : "-") ||
              (typeof companyPageRecord?.area === "object" &&
              companyPageRecord?.area[0].length > 0
                ? companyPageRecord?.area[0]
                : companyPageRecord?.area
                ? companyPageRecord?.area
                : "-") ||
              (typeof companyPageRecord?.state === "object" &&
              companyPageRecord?.state[0].length > 0
                ? companyPageRecord?.state[0]
                : companyPageRecord?.state
                ? companyPageRecord?.state
                : "-") ||
              (typeof companyPageRecord?.country === "object" &&
              companyPageRecord?.country[0].length > 0
                ? companyPageRecord?.country[0]
                : companyPageRecord?.country
                ? companyPageRecord?.country
                : "-")}
          </span>
        </>
      </div>
      <div
        className="realtime-result__child"
        style={{ textTransform: "capitalize" }}
      >
        Date Of Incorporation <br />
        <>
          <span className="text-danger">
            {typeof companyPageRecord?.date_of_incorporation === "object" &&
            companyPageRecord?.date_of_incorporation[0].length > 0
              ? dayjs(companyPageRecord?.date_of_incorporation[0]).format(
                  "DD MMM YYYY"
                )
              : "-"}{" "}
          </span>
        </>
        {/*) : null}*/}
      </div>
    </>
  );
};

export default NewlyRegisteredSubsidiariesOfForeignCompanies;
