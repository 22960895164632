import React, { Component } from "react";
import {
  connectInfiniteHits,
  Highlight,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { Map } from "react-lodash";

const AutoSuggestInputForFilter = ({
  filterRequest,
  setFilterRequest,
  sheetName,
  attribute,
}) => {
  const searchClient = instantMeiliSearch(
    `${process.env.REACT_APP_CONFIG_API_MEILISEARCH_URL}`,
    `${process.env.REACT_APP_CONFIG_API_MEILISEARCH_KEY}`,
    {
      placeholderSearch: false,
      primaryKey: "id",
      paginationTotalHits: 5,
    }
  );
  const Hit = ({ hit }) => <Highlight attribute={attribute} hit={hit} />;

  const handleInput = (inputKey) => {
    console.log(
      "in hit inputKey: ",
      inputKey[attribute],
      filterRequest[attribute]
    );
    if (!filterRequest.hasOwnProperty(attribute)) {
      filterRequest[attribute] = [];
    }
    if (
      filterRequest[attribute].indexOf(inputKey[attribute].toLowerCase()) < 0
    ) {
      filterRequest[attribute].push(inputKey[attribute].toLowerCase());
    }

    setFilterRequest(() => {
      return { ...filterRequest };
    });
  };
  class InfiniteHits extends Component {
    render() {
      const { hits } = this.props;

      return (
        <div
          className="ais-InfiniteHits"
          style={{ width: "100%", display: "block", resize: "none" }}
        >
          <ul className="ais-InfiniteHits-list" data-cy="search-query-list">
            <Map
              collection={hits}
              iteratee={(hit) => {
                return (
                  <li
                    key={hit.objectID}
                    className="ais-InfiniteHits-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleInput(hit)}
                  >
                    <Hit hit={hit} />
                  </li>
                );
              }}
            />
          </ul>
        </div>
      );
    }
  }
  const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);
  return (
    <>
      <div className="d-flex flex-column align-items-center realtime__accordion-body">
        <InstantSearch
          indexName={sheetName + "_" + attribute}
          searchClient={searchClient}
        >
          <SearchBox />
          <CustomInfiniteHits hitComponent={Hit} />
        </InstantSearch>
      </div>
    </>
  );
};
export default AutoSuggestInputForFilter;
