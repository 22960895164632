import { getFetch, postFetch } from "../../Config/facade";

/** Fetching specific company search id for /company/detailed/ */
export const fetchCompanyId = async (payload) => {
  return await postFetch("/company/detailed/", payload);
};

/** Fetching specific company data for /company/detailed/result/{id} */
export const fetchCompanyData = async (payload) => {
  if (payload) return await getFetch(`/company/detailed/result/${payload.id}`);
};

/** Fetching specific Prospect company data*/
export const fetchProspectCompanyData = async (payload) => {
  if (payload)
    return await getFetch(`/company/detailed/prospect/result/${payload.id}`);
};

/** Fetching history for company search */
export const fetchCompanyHistory = async (hash_key) => {
  if (hash_key)
    return await getFetch(`/history/simple_company_search_es/${hash_key}`);
};
