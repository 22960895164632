import React from "react";

const CompanyButton = ({
  data,
  setButtonOpenState,
  itemIndex,
  handleCompanyProfile,
  currentUnlockButtonId,
  buttonOpenState,
  setCurrentUnlockButtonId,
}) => {
  const handleProfile = (e) => {
    // setButtonOpenState((open) => !open);
    // !buttonOpenState &&
    if (e.target.innerText.includes("Hide Company")) {
      setCurrentUnlockButtonId(false);
      return;
    } else {
      setCurrentUnlockButtonId(data.id);
      handleCompanyProfile(data.id);
    }
  };

  return (
    <div className="result-container__result-btn">
      <a
        id={`companyButton-${itemIndex}`}
        className="btn button v-btn"
        data-toggle="collapse"
        href={`#collapseExample_${itemIndex}`}
        data-target={`#collapseExample_${itemIndex}`}
        onClick={(e) => handleProfile(e)}
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        {currentUnlockButtonId === data?.id ? "Hide " : "View "}
        Company
      </a>
    </div>
  );
};

export default CompanyButton;
