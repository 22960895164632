import React from "react";
import { Dialog, Typography, Button } from "@mui/material";
import { Spinner } from "../Utils/utils";

const SavedListConfirmationDialog = ({
  openModal,
  setOpenModal,
  deleteListName,
  deleteModalLoading,
  handleDeleteList,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={openModal}
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: 16,
        },
      }}
    >
      <Typography
        variant="h6"
        id="confirmation-dialog-title"
        letterSpacing="0.03em"
      >
        Delete List
      </Typography>
      <div
        style={{
          marginTop: 4,
        }}
      >
        <Typography paragraph letterSpacing="0.03em">
          {`Are you sure you want to delete "${deleteListName}" list ? This action cannot be undone.`}
        </Typography>
      </div>
      {deleteModalLoading ? (
        <Spinner />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: 8,
            gap: "1rem",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: 16,
              boxShadow: "none",
            }}
            onClick={() => setOpenModal(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: 16 }}
            sx={{
              borderRadius: 16,
              boxShadow: "none",
              "&:hover": {
                bgcolor: "#f11313",
              },
            }}
            onClick={() => handleDeleteList(deleteListName)}
          >
            Yes
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default SavedListConfirmationDialog;
