export function setLinkedIn(sheetName, companyPageRecord) {
  switch (sheetName) {
    case "realtime-investors_who_funded_recently":
      return companyPageRecord?.investor_linkedin?.length > 0
        ? companyPageRecord?.investor_linkedin[0]
        : companyPageRecord?.investor_linkedin
        ? companyPageRecord?.investor_linkedin
        : null;

    case "realtime-recently_funded_company":
      return companyPageRecord?.linkedin_of_company_that_got_funded?.length > 0
        ? companyPageRecord?.linkedin_of_company_that_got_funded[0]
        : companyPageRecord?.linkedin_of_company_that_got_funded
        ? companyPageRecord?.linkedin_of_company_that_got_funded
        : null;

    case "realtime-recently_acquired_companies":
      return companyPageRecord?.linkedin_of_company_that_got_acquired?.length >
        0
        ? companyPageRecord?.linkedin_of_company_that_got_acquired[0]
        : companyPageRecord?.linkedin_of_company_that_got_acquired
        ? companyPageRecord?.linkedin_of_company_that_got_acquired
        : null;

    case "realtime-companies_acquiring_recently":
      return companyPageRecord?.linkedin_of_company_that_acquired?.length > 0
        ? companyPageRecord?.linkedin_of_company_that_acquired[0]
        : companyPageRecord?.linkedin_of_company_that_acquired
        ? companyPageRecord?.linkedin_of_company_that_acquired
        : null;

    case "realtime-companies_hiring_recently":
      return companyPageRecord?.company_linkedin?.length > 0
        ? companyPageRecord?.company_linkedin[0]
        : companyPageRecord?.company_linkedin
        ? companyPageRecord?.company_linkedin
        : null;

    case "realtime-newly_registered_subsidiaries_of_foreign_companies":
      return companyPageRecord?.company_name?.length > 0
        ? companyPageRecord?.company_name[0]
        : companyPageRecord?.company_name
        ? companyPageRecord?.company_name
        : null;

    case "realtime-newly_registered_companies_on_mca":
      return companyPageRecord?.newly_registered_company_name?.length > 0
        ? companyPageRecord?.newly_registered_company_name[0]
        : companyPageRecord?.newly_registered_company_name
        ? companyPageRecord?.newly_registered_company_name
        : null;

    case "realtime-unfunded_companies":
      return companyPageRecord?.company_linkedin_url?.length > 0
        ? companyPageRecord?.company_linkedin_url[0]
        : companyPageRecord?.company_linkedin_url
        ? companyPageRecord?.company_linkedin_url
        : null;
    case "realtime-financial_intermediaries":
      return companyPageRecord?.person_linkedin_url?.length > 0
        ? companyPageRecord?.person_linkedin_url[0]
        : companyPageRecord?.person_linkedin_url
        ? companyPageRecord?.person_linkedin_url
        : null;

    case "realtime-public_listed_companies":
      return companyPageRecord?.linkedin_url?.length > 0
        ? companyPageRecord?.linkedin_url[0]
        : companyPageRecord?.linkedin_url
        ? companyPageRecord?.linkedin_url
        : null;

    default:
      return null;
  }
}

export function setCompanyName(sheetName, companyPageRecord) {
  switch (sheetName) {
    case "realtime-investors_who_funded_recently":
      return companyPageRecord?.investor_that_funded?.length > 0
        ? companyPageRecord?.investor_that_funded[0]
        : companyPageRecord?.investor_that_funded
        ? companyPageRecord?.investor_that_funded
        : null;

    case "realtime-recently_funded_company":
      return companyPageRecord?.company_that_got_funded?.length > 0
        ? companyPageRecord?.company_that_got_funded[0]
        : companyPageRecord?.company_that_got_funded
        ? companyPageRecord?.company_that_got_funded
        : null;

    case "realtime-recently_acquired_companies":
      return companyPageRecord?.company_that_got_acquired?.length > 0
        ? companyPageRecord?.company_that_got_acquired[0]
        : companyPageRecord?.company_that_got_acquired
        ? companyPageRecord?.company_that_got_acquired
        : null;
    case "realtime-companies_acquiring_recently":
      return companyPageRecord?.company_that_acquired?.length > 0
        ? companyPageRecord?.company_that_acquired[0]
        : companyPageRecord?.company_that_acquired
        ? companyPageRecord?.company_that_acquired
        : null;
    case "realtime-companies_hiring_recently":
      return companyPageRecord?.company_name?.length > 0
        ? companyPageRecord?.company_name[0]
        : companyPageRecord?.company_name
        ? companyPageRecord?.company_name
        : null;
    case "realtime-newly_registered_subsidiaries_of_foreign_companies":
      return companyPageRecord?.company_name?.length > 0
        ? companyPageRecord?.company_name[0]
        : companyPageRecord?.company_name
        ? companyPageRecord?.company_name
        : null;
    case "realtime-newly_registered_companies_on_mca":
      return companyPageRecord?.newly_registered_company_name?.length > 0
        ? companyPageRecord?.newly_registered_company_name[0]
        : companyPageRecord?.newly_registered_company_name
        ? companyPageRecord?.newly_registered_company_name
        : null;
    case "realtime-public_listed_companies":
      return companyPageRecord?.company_name?.length > 0
        ? companyPageRecord?.company_name[0]
        : companyPageRecord?.company_name
        ? companyPageRecord?.company_name
        : null;
    case "realtime-unfunded_companies":
      return companyPageRecord?.company_name?.length > 0
        ? companyPageRecord?.company_name[0]
        : companyPageRecord?.company_name
        ? companyPageRecord?.company_name
        : null;
    case "realtime-financial_intermediaries":
      return companyPageRecord?.company_name?.length > 0
        ? companyPageRecord?.company_name[0]
        : companyPageRecord?.company_name
        ? companyPageRecord?.company_name
        : null;
    default:
      return null;
  }
}

export function setContactPersonLinkedin(sheetName, companyPageRecord) {
  switch (sheetName) {
    case "realtime-investors_who_funded_recently":
      return companyPageRecord?.investor_contact_linkedin_url?.length > 0
        ? companyPageRecord?.investor_contact_linkedin_url[0]
        : companyPageRecord?.investor_contact_linkedin_url
        ? companyPageRecord?.investor_contact_linkedin_url
        : null;

    case "realtime-recently_funded_company":
      return companyPageRecord?.contact_linkedin_of_the_company_that_got_funded
        ?.length > 0
        ? companyPageRecord?.contact_linkedin_of_the_company_that_got_funded[0]
        : companyPageRecord?.contact_linkedin_of_the_company_that_got_funded
        ? companyPageRecord?.contact_linkedin_of_the_company_that_got_funded
        : null;

    case "realtime-recently_acquired_companies":
      return companyPageRecord?.contact_url_of_company_that_got_acquired
        ?.length > 0
        ? companyPageRecord?.contact_url_of_company_that_got_acquired[0]
        : companyPageRecord?.contact_url_of_company_that_got_acquired
        ? companyPageRecord?.contact_url_of_company_that_got_acquired
        : null;
    case "realtime-companies_acquiring_recently":
      return companyPageRecord?.contact_url_of_company_that_acquired?.length > 0
        ? companyPageRecord?.contact_url_of_company_that_acquired[0]
        : companyPageRecord?.contact_url_of_company_that_acquired
        ? companyPageRecord?.contact_url_of_company_that_acquired
        : null;
    case "realtime-companies_hiring_recently":
      return companyPageRecord?.company_founder_linkedin_url?.length > 0
        ? companyPageRecord?.company_founder_linkedin_url[0]
        : companyPageRecord?.company_founder_linkedin_url
        ? companyPageRecord?.company_founder_linkedin_url
        : null;
    case "realtime-newly_registered_subsidiaries_of_foreign_companies":
      return companyPageRecord?.company_founder_linkedin_url?.length > 0
        ? companyPageRecord?.company_founder_linkedin_url[0]
        : companyPageRecord?.company_founder_linkedin_url
        ? companyPageRecord?.company_founder_linkedin_url
        : null;
    case "realtime-newly_registered_companies_on_mca":
      return companyPageRecord?.newly_registered_company_linkedin?.length > 0
        ? companyPageRecord?.newly_registered_company_linkedin[0]
        : companyPageRecord?.newly_registered_company_linkedin
        ? companyPageRecord?.newly_registered_company_linkedin
        : null;
    case "realtime-financial_intermediaries":
      return companyPageRecord?.person_linkedin_url?.length > 0
        ? companyPageRecord?.person_linkedin_url[0]
        : companyPageRecord?.person_linkedin_url
        ? companyPageRecord?.person_linkedin_url
        : null;
    default:
      return null;
  }
}
export function setContactPersonName(sheetName, companyPageRecord) {
  switch (sheetName) {
    case "realtime-investors_who_funded_recently":
      return companyPageRecord?.investor_contact?.length > 0
        ? companyPageRecord?.investor_contact[0]
        : companyPageRecord?.investor_contact
        ? companyPageRecord?.investor_contact
        : null;

    case "realtime-recently_funded_company":
      return companyPageRecord?.contact_name_of_the_company_that_got_funded
        ?.length > 0
        ? companyPageRecord?.contact_name_of_the_company_that_got_funded[0]
        : companyPageRecord?.contact_name_of_the_company_that_got_funded
        ? companyPageRecord?.contact_name_of_the_company_that_got_funded
        : null;

    case "realtime-recently_acquired_companies":
      return companyPageRecord?.contact_name_of_company_that_got_acquired
        ?.length > 0
        ? companyPageRecord?.contact_name_of_company_that_got_acquired[0]
        : companyPageRecord?.contact_name_of_company_that_got_acquired
        ? companyPageRecord?.contact_name_of_company_that_got_acquired
        : null;
    case "realtime-companies_acquiring_recently":
      return companyPageRecord?.contact_name_of_company_that_acquired?.length >
        0
        ? companyPageRecord?.contact_name_of_company_that_acquired[0]
        : companyPageRecord?.contact_name_of_company_that_acquired
        ? companyPageRecord?.contact_name_of_company_that_acquired
        : null;
    case "realtime-companies_hiring_recently":
      return null;
    case "realtime-newly_registered_subsidiaries_of_foreign_companies":
      return null;
    case "realtime-newly_registered_companies_on_mca":
      return companyPageRecord
        ?.newly_registered_company_founder_or_director_name?.length > 0
        ? companyPageRecord
            ?.newly_registered_company_founder_or_director_name[0]
        : companyPageRecord?.newly_registered_company_founder_or_director_name
        ? companyPageRecord?.newly_registered_company_founder_or_director_name
        : null;
    case "realtime-financial_intermediaries":
      return null;
    default:
      return null;
  }
}
