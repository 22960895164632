import { Link } from "react-router-dom";

/**
 * Represents a Popup in the center of the page
 *
 * @param {string} message - Pass the message to be displayed
 * @param {function} closePopup - Hook managing display-ability of Popup
 * @param btnText - Button that displays context
 */

const Popup = ({ message, closePopup, btnText = "Retry" }) => {
  return (
    <div className="popup-bg">
      <div
        className="modal popup-size"
        style={{ display: "block", margin: "auto" }}
        id="searchError"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row d-flex align-items-center">
                <div className="col-4">
                  <div className="d-flex justify-content-center">
                    <img
                      className="popup-img"
                      src="/assets/images/g10 (2).png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-7">
                  <div className="text-center" data-cy="popup-container">
                    {btnText === "Retry" && (
                      <p className="text-danger ">Oops</p>
                    )}
                    <p>{message}</p>
                    {closePopup ? (
                      <button
                        className="btn text-white og-red global-hover"
                        onClick={() => closePopup(false)}
                        data-cy="close-popup"
                      >
                        {btnText}
                      </button>
                    ) : (
                      <Link
                        to="/login"
                        style={{ background: "#fb3e3e", border: "#fb3e3e" }}
                        className="btn btn-primary"
                      >
                        Login Again
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
