import React, { useRef, useEffect, useState } from "react";
//MAPBOX
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
//REDUX
import {
  selectMapBoxAutocomplete,
  saveMapBoxAutocomplete,
  savePincodeDetail,
  saveApartmentDetail,
  saveCurrentSavingDetail,
} from "../../../_reducer/areaDetail";
import {
  selectLeadsForMapMarker,
  saveLeadsForMapMarker,
} from "../../../_reducer/leads";
import { useSelector, useDispatch } from "react-redux";
import { saveClearSearchResult } from "../../../_reducer/shared";
//HTTPS
import { httpService } from "../geolocatorHttpServices/geolocatorHttpServices";
//RXJS
import { forkJoin } from "rxjs";
//CSS
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "./Mapbox.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibXVzYWItbW9taW4iLCJhIjoiY2t5em01b3gxMHhnaTJucXZ1ZzJxbHlpNiJ9.xrVG6rwZ0kJyT8ciaAMVog";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Mapbox = ({ setCenter, setCategory, setAllCoordinates }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(78.9629);
  const [lat, setLat] = useState(20.5937);
  const [zoom, setZoom] = useState(4);
  const mapBoxAutocomplete = useSelector(selectMapBoxAutocomplete);
  const leadsForMapMarker = useSelector(selectLeadsForMapMarker);
  const marker = useRef(null);
  const draw = useRef(null);
  //const modes = useRef(null);
  const allMarkers = useRef([]);
  const dispatch = useDispatch();

  const initialCenterSet = useRef(false);

  useEffect(() => {
    if (!initialCenterSet.current) {
      setCenter([lng, lat]);
      initialCenterSet.current = true;
    }
  }, [setCenter, lng, lat]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
    //modes.current = MapboxDraw.modes;
    //modes.current.draw_rectangle = DrawRectangle;
    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      //modes: modes.current,
      controls: {
        polygon: true,
        trash: true,
      },
      //defaultMode: 'draw_polygon'
    });
    map.current.addControl(draw.current, "top-left");
    map.current.on("draw.create", updateArea);
    map.current.on("draw.delete", updateArea);
    map.current.on("draw.update", updateArea);
    // map.current.
    //draw.current.changeMode('draw_rectangle');
    // console.log(draw.current.getAll());
  }, []);

  useEffect(() => {
    if (
      Object.keys(mapBoxAutocomplete).length > 0 &&
      mapBoxAutocomplete.type !== "Polygon"
    ) {
      let { center } = mapBoxAutocomplete;
      console.log("center: ", center, mapBoxAutocomplete);
      marker.current = new mapboxgl.Marker({
        color: "#364DEE",
      });
      if (!initialCenterSet.current) {
        setCenter(center);
        initialCenterSet.current = true;
      }
      setCategory(null);
      marker.current.setLngLat([center[0], center[1]]).addTo(map.current);
      map.current.flyTo({ center: center, zoom: 13, speed: 1, curve: 2 });
    } else {
      console.log("in else");
      let { center } = mapBoxAutocomplete;
      console.log(
        "in else center: ",
        center,
        mapBoxAutocomplete,
        draw.current.getAll()
      );
      const { features } = draw.current.getAll();
      console.log("features: ", features[0]?.geometry?.coordinates);
      setAllCoordinates(features[0]?.geometry?.coordinates[0]);
      marker?.current?.remove();
      allMarkers?.current?.forEach((allMarkersList) => {
        allMarkersList?.marker?.remove();
      });
    }
  }, [mapBoxAutocomplete]);

  useEffect(() => {
    let marker;
    if (leadsForMapMarker.length > 0) {
      if (allMarkers.current.length > 0) {
        allMarkers?.current?.forEach((allMarkersList) => {
          allMarkersList?.marker?.remove();
        });
      }
      leadsForMapMarker?.forEach((leadsDataWithCategoryName) => {
        let { category, data } = leadsDataWithCategoryName;
        data?.forEach((leadsdata) => {
          let { latitude, longitude, full_address, area } = leadsdata;
          if (latitude && longitude) {
            const el = document.createElement("div");
            el.className = `marker ${category}`;
            el.id = "marker";
            el.style.cursor = "pointer";
            marker = new mapboxgl.Marker(el);
            //let info = title ? title : "not found";
            let info = full_address
              ? full_address
              : area
              ? area
              : "Info Not Found";
            let popup = new mapboxgl.Popup({ offset: 25 }).setText(info);
            marker
              .setLngLat([longitude, latitude])
              .setPopup(popup)
              .addTo(map.current);
            allMarkers.current.push({ marker: marker, markerName: category });
          }
        });
      });
    } else {
      allMarkers?.current?.forEach((allMarkersList) => {
        allMarkersList?.marker?.remove();
      });
    }
  }, [leadsForMapMarker]);
  console.log(mapBoxAutocomplete)
  const updateArea = (e) => {
    const data = draw.current.getAll();
    if (e.type === "draw.create" || e.type === "draw.update") {
      let cordd1 = data.features[0].geometry.coordinates[0][0][0].toFixed(6);
      let cordd2 = data.features[0].geometry.coordinates[0][0][1].toFixed(6);
      let cordd3 = data.features[0].geometry.coordinates[0][1][0].toFixed(6);
      let cordd4 = data.features[0].geometry.coordinates[0][1][1].toFixed(6);
      dispatch(
        saveMapBoxAutocomplete({
          id: data.features[0].id,
          type: data.features[0].geometry.type,
          bbox: [cordd1, cordd2, cordd3, cordd4],
          place_name: mapBoxAutocomplete.place_name
        })
      );
      let bbox = [cordd1, cordd2, cordd3, cordd4];
      // Disabled : Api not in use
      // forkJoin([
      //   httpService.getPincodearea(bbox),
      //   httpService.getApartmentdetail(bbox),
      //   httpService.getCurrentsavings(bbox),
      // ]).subscribe((data) => {
      //   dispatch(savePincodeDetail(data[0]));
      //   dispatch(saveApartmentDetail(data[1]));
      //   dispatch(saveCurrentSavingDetail(data[2]));
      // });
    } else if (
      e.type === "draw.delete" &&
      mapBoxAutocomplete.type !== "Polygon"
    ) {
      console.log("delete");
      // dispatch(savePincodeDetail({}));
      dispatch(saveLeadsForMapMarker([]));
      // dispatch(saveApartmentDetail({}));
      // dispatch(saveCurrentSavingDetail({}));
      dispatch(
        saveMapBoxAutocomplete({})
      );
      dispatch(saveClearSearchResult(true));
      setAllCoordinates(null);
      setCategory(null);
    }
  };

  return (
    <div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default Mapbox;
