import { useSnackbar } from "notistack";
import React, { useState } from "react";
import Cookies from "js-cookie";
import { apiServer } from "../../../config";

const RealTimeExportResult = ({
  realtimeSelectedLeads,
  sheetName,
  pathName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [exportType, setExportType] = useState("both");

  const handleLeadSelectionExportExcel = async (e) => {
    e.preventDefault();
    const executeExport = async () => {
      function handleError(response = null) {
        console.error(`Error, Status Code: ${response?.status}`);
        enqueueSnackbar("Error Exporting File", { variant: "error" });
      }

      try {
        let response = null;
        if (
          sheetName ===
            "realtime-newly_registered_subsidiaries_of_foreign_companies" ||
          sheetName === "realtime-newly_registered_companies_on_mca" ||
          (sheetName === "realtime-companies_hiring_recently" &&
            (pathName === "/realtime/filter" ||
              pathName === "/realtime_history"))
        ) {
          console.log("realtimeSelectedLeads: ", realtimeSelectedLeads);
          const payload = realtimeSelectedLeads.map(
            ({ company_linkedin_url, ...rest }) => rest
          );
          const searchInputBody = {
            company_details: payload,
            phone_or_email: exportType,
          };
          response = await fetch(
            apiServer + "/realtime_bulk/companies_without_linkedin",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("user_token")}`,
              },
              body: JSON.stringify(searchInputBody),
            }
          );
          console.log("response in companies without bulk: ", response);
        } else {
          const inputData = {
            inputs: realtimeSelectedLeads,
            phone_or_email: exportType,
          };
          console.log("input data: ", inputData);
          response = await fetch(apiServer + "/realtime_bulk/export/excel", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
            body: JSON.stringify(inputData),
          });
        }

        async function handleSuccess(response) {
          // Direct Download in case if only one selected lead.
          if (realtimeSelectedLeads.length === 1) {
            const data = await response.json();
            if (!data) {
              return handleError();
            }
            const username = Cookies.get("username");
            enqueueSnackbar(
              <div>
                Hey {username}! We have started working on your file. <br />
                We will take some time to revert back. <br />
                You'll get an email once it is ready.
                <br />
                After exporting, please ensure to refresh your credit for
                seamless service. Thank you!
              </div>,
              { variant: "info" }
            );
            // const timeoutId = setTimeout(() => {
            //   clearInterval(intervalId);
            // }, 5 * 60 * 1000);
            // console.log("data from response ", data);
            // const intervalId = setInterval(async () => {
            //   console.log("Checking For File state Every 5 Seconds");
            //
            //   let fileName = data?.output_filename;
            //
            //   let url = `${apiServer}/${fileName
            //     .replace('"', "")
            //     .replace('"', "")}`;
            //   try {
            //     const fetchPromise = await fetch(url);
            //     switch (fetchPromise.status) {
            //       case 200:
            //         if (fileName) {
            //           window.location.href = url;
            //         } else {
            //           console.error("Error in download link");
            //         }
            //         if (timeoutId) clearTimeout(timeoutId);
            //
            //         clearInterval(intervalId);
            //         break;
            //       case 400:
            //         console.log(fetchPromise);
            //         return;
            //       default:
            //         console.log(fetchPromise);
            //     }
            //   } catch (e) {
            //     console.error("Exception", e);
            //   }
            // }, 5 * 1000);
          } else {
            const data = await response.json();
            if (!data) {
              return handleError();
            }
            const username = Cookies.get("username");
            enqueueSnackbar(
              <div>
                Hey {username}! We have started working on your file. <br />
                We will take some time to revert back. <br />
                Be rest assured, You'll get an email once it is ready.
                <br />
                After exporting, please ensure to refresh your credit for
                seamless service. Thank you!
              </div>,
              { variant: "info" }
            );
          }
        }

        function handleUnAuthorized() {
          console.log("User is UnAuthorized");
          enqueueSnackbar("Please Logout and LogIn Again");
        }

        function handleInsufficientBalance(response) {
          console.error("Insufficient credits...", response);
          enqueueSnackbar(
            "You have insufficient profile credits. Buy Credits to get details.",
            { variant: "warning" }
          );
        }

        switch (response.status) {
          case 200:
            return await handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 402:
            return handleInsufficientBalance(response);
          default:
            return handleError(response);
        }
      } catch (err) {
        console.error("Error: ", err);
        handleError();
      }
    };
    if (!realtimeSelectedLeads) {
      console.warn("No Selected Leads");
      return;
    }

    await executeExport();
  };

  const checkErrors = async (e) => {
    e.preventDefault();
    // if (
    //   ((customCount <= 0) |
    //     (props.customCount > props.props.currentLeads.length)) &
    //   (props.selectedLeads.length === 0)
    // ) {
    //   enqueueSnackbar(
    //     "Value should be in between 1 - " +
    //       props.props.currentLeads.length +
    //       " !",
    //     { variant: "error" }
    //   );
    //   props.setCustomCount(props.props.currentLeads.length);
    // }
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className="modal fade mt-7"
        id="exportModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exportModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <form>
                <p>Select Export With -</p>
                <div>
                  <label htmlFor="phone" value="phone">
                    <input
                      type="radio"
                      id="phone"
                      name="type"
                      value="phone"
                      checked={exportType === "phone"}
                      onChange={(e) => setExportType(e.target.value)}
                      onClick={(e) => setExportType(e.target.value)}
                    />{" "}
                    Only Phone Numbers
                  </label>
                </div>
                <div>
                  <label htmlFor="email" value="email">
                    <input
                      type="radio"
                      id="email"
                      name="type"
                      value="email"
                      checked={exportType === "email"}
                      onChange={(e) => setExportType(e.target.value)}
                      onClick={(e) => setExportType(e.target.value)}
                    />{" "}
                    Only Emails
                  </label>
                </div>
                <div>
                  <label htmlFor="both" value="both">
                    <input
                      type="radio"
                      id="both"
                      name="type"
                      value="both"
                      checked={exportType === "both"}
                      onChange={(e) => setExportType(e.target.value)}
                      onClick={(e) => setExportType(e.target.value)}
                    />{" "}
                    Both Phone Numbers and Emails
                  </label>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={handleLeadSelectionExportExcel}
                  >
                    Export
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <button
        data-toggle="modal"
        data-target="#exportModal"
        className="export-btn"
        data-cy="export-btn"
        disabled={realtimeSelectedLeads.length === 0}
        // onClick={handleLeadSelectionExportExcel}
      >
        Export ({realtimeSelectedLeads.length})
        <img
          className="ps-3"
          src="/assets/images/export.png"
          alt=""
          style={{ width: "30px", height: "15px" }}
        />
      </button>
    </div>
  );
};
export default RealTimeExportResult;
