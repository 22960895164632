import React from "react";

const MapSingleItem = ({ item }) => {
  return (
    <div className="result-container__result-info overflow-hidden">
      <span>{item ? item : "-"}</span>
    </div>
  );
};

export default MapSingleItem;
