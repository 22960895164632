import React, { useEffect } from "react";
import CommonSelectBoxItem from "../CommonSelectBoxItem";
import CommonDateFilter from "../CommonDateFilter";
import CommonBasicFilter from "../CommonBasicFilter";

const FilterNewlyRegisteredSubsidiariesOfForeignCompanies = ({
  filterRequest,
  setFilterRequest,
  sheetName,
}) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);

  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="cin"
          label="CIN"
          icon="/assets/images/new_icons/company_name.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_name"
          label="Company Name"
          icon="/assets/images/new_icons/Industry.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="founder"
          label="Founder/Director Name"
          icon="/assets/images/new_icons/Funded.png"
        />

        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="category"
          label="Category"
          tooltipText={"Select Category"}
          icon="/assets/images/new_icons/Employee Count.png"
          collection={[
            "Company limited by Shares",
            "Company Limited by Guarantee",
          ]}
        />

        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="sub_category"
          label="Sub Category"
          tooltipText={"Select Sub Category"}
          icon="/assets/images/new_icons/award.png"
          collection={["Subsidiary of Foreign Company", "Non-govt company"]}
        />

        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="class"
          label="Class"
          tooltipText={"Select Sub Category"}
          icon="/assets/images/new_icons/bx_category.png"
          collection={["Private", "Private(One Person Company)", "Public"]}
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="location"
          label="Location"
          icon="/assets/images/new_icons/Location.png"
        />

        <CommonDateFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="date_of_incorporation"
          label="Date Of Incorporation"
          tooltipText={"Select Date Range"}
          icon="/assets/images/new_icons/date.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keywords"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};
export default FilterNewlyRegisteredSubsidiariesOfForeignCompanies;
