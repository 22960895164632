import React from "react";
import "../../Style/multidomain.css";
import { Button } from "react-bootstrap";

const MultiDomainPopup = ({
  show,
  setShow,
  multipleDomain,
  setMultipleDomain,
  handleSubmit,
}) => {
  const handleChange = (e) => {
    const regex = /^[a-zA-Z.,\s]*$/; // Regular expression to allow alphabets and spaces
    const value = e.target.value;
    if (regex.test(value)) {
      console.log("value: " + value);
      setMultipleDomain(value);
    }
  };

  return (
    <devicePixelRatio
      className="popup-wrapper"
      style={{
        visibility: `${show ? "visible" : "hidden"}`,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 5,
        boxShadow: "5px 0px 20px rgba(0, 0, 0, 0.5)",
        padding: "10px",
      }}
    >
      <p className="popup-title">Add multiple domains separated with ","</p>{" "}
      <input
        required
        type="text"
        placeholder="Search..."
        className="popup-input"
        value={multipleDomain}
        onChange={(e) => handleChange(e)}
      />{" "}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <Button
          variant="text"
          style={{
            color: "#fff",
            backgroundColor: "#fb3e3e",
            borderRadius: "50px",
            fontSize: "12px",
          }}
          type="button"
          onClick={handleSubmit}
          data-cy="Add"
        >
          {" "}
          Add{" "}
        </Button>{" "}
        <Button
          variant="text"
          style={{
            color: "#000",
            backgroundColor: "#eee",
            borderRadius: "50px",
            fontSize: "12px",
          }}
          onClick={() => setShow(!show)}
        >
          Cancel
        </Button>{" "}
      </div>{" "}
    </devicePixelRatio>
  );
};

export default MultiDomainPopup;
