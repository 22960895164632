import React, { useEffect } from "react";
import CommonSelectedItemInFilter from "../CommonSelectedItemInFilter";

const ItemSelectedNewlyRegisteredCompaniesOnMCA = ({
  filterRequest,
  setFilterRequest,
}) => {
  useEffect(() => {}, [filterRequest]);

  return (
    <>
      <div className="query-parent">
        <div
          className="query-container"
          id="query-container"
          data-cy="query-container"
        >
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="cin"
            image="/assets/images/new_icons/company_name.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="newly_registered_company_name"
            image="/assets/images/new_icons/Industry.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="newly_registered_company_founder_or_director_name"
            image="/assets/images/new_icons/Funded.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="category"
            image="/assets/images/new_icons/Job Title.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="sub_category"
            image="/assets/images/new_icons/award.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="class"
            image="/assets/images/new_icons/bx_category.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="location"
            image="/assets/images/new_icons/Location.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="number_of_members"
            image="/assets/images/new_icons/Employee Count.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="date_of_incorporation"
            image="/assets/images/new_icons/date.png"
          />
          {/*<CommonSelectedItemInFilter*/}
          {/*  filterRequest={filterRequest}*/}
          {/*  setFilterRequest={setFilterRequest}*/}
          {/*  itemName="funding_amount"*/}
          {/*  image="/assets/images/new_icons/revenue.png"*/}
          {/*/>*/}
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="keywords"
            image="/assets/images/new_icons/keywords.png"
          />
        </div>
      </div>
    </>
  );
};
export default ItemSelectedNewlyRegisteredCompaniesOnMCA;
