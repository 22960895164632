import { Box, Modal, Tooltip, Typography } from '@mui/material'
import { IoCloseCircleSharp } from "react-icons/io5";
import Cookies from 'js-cookie';
import { useSnackbar } from "notistack";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

const IntegrationModal = ({openModal, setOpenModal}) => {
  const { enqueueSnackbar } = useSnackbar();
    const handleCloseModal = () => setOpenModal(false);
    const handleCopy = (token) => {
      navigator.clipboard.writeText(token);
      enqueueSnackbar("Token has been Copied", { variant: "success" });
    };

  return (
    <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <header className='d-flex justify-content-between '>
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h2"
        sx={{fontWeight: "bolder", color:"#fb3e3e"}}
      >
        INTEGRATIONS
      </Typography>
      <IoCloseCircleSharp style={{cursor:"pointer"}} size={26} onClick={handleCloseModal}/>
      </header>
      <div className='mt-3 '>
        <div className='fs-4 fw-bold '>Zapier:</div>
        <div className='d-flex justify-content-between fs-5'>
          <div className='mt-2'> Access Token</div>
          <Tooltip title="Tap to Copy" placement="top" arrow>
          <div className='w-75 p-3 rounded' style={{backgroundColor:"#FB3e3e", cursor:"pointer"}} onClick={()=>handleCopy(Cookies.get('user_token'))}>
            <div className='text-break text-white '>{Cookies.get('user_token')}</div>
          </div>
          </Tooltip>
        </div>
      </div>
    </Box>
  </Modal>
  )
}

export default IntegrationModal
