import React, { useEffect } from "react";
import CommonSelectedItemInFilter from "../CommonSelectedItemInFilter";
import CommonBasicFilter from "../CommonBasicFilter";

const ItemSelectedUnfundedCompanies = ({ filterRequest, setFilterRequest }) => {
  useEffect(() => {}, [filterRequest]);

  return (
    <>
      <div className="query-parent">
        <div
          className="query-container"
          id="query-container"
          data-cy="query-container"
        >
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="company_name"
            image="/assets/images/new_icons/company_name.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="industry"
            image="/assets/images/new_icons/Industry.png"
          />

          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="location"
            image="/assets/images/new_icons/Location.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="city"
            image="/assets/images/new_icons/Location.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="state"
            image="/assets/images/new_icons/Location.png"
          />
          <CommonSelectedItemInFilter
            setFilterRequest={setFilterRequest}
            filterRequest={filterRequest}
            itemName="no_of_employees"
            image="/assets/images/new_icons/Employee Count.png"
          />
          <CommonSelectedItemInFilter
            setFilterRequest={setFilterRequest}
            filterRequest={filterRequest}
            itemName="founded_year"
            image="/assets/images/bx_bx-calendar.png"
          />
          <CommonSelectedItemInFilter
            setFilterRequest={setFilterRequest}
            filterRequest={filterRequest}
            itemName="estimated_revenue"
            image="/assets/images/trending-up.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="keywords"
            image="/assets/images/new_icons/keywords.png"
          />
        </div>
      </div>
    </>
  );
};

export default ItemSelectedUnfundedCompanies;
