import React, { useEffect, useState } from "react";
import "./Styles/SavedList.css";
import CompanyProfile from "../CompanyFilter/CompanyProfile";
import CompanyButton from "../CompanyFilter/CompanyButton";
import {
  fetchCompanyData,
  fetchCompanyId,
  fetchProspectCompanyData,
} from "../CompanyFilter/companyQueries";
import CompanyViewProfile from "../CompanyFilter/CompanyViewProfile";
import Cookies from "js-cookie";
import UnlockProfileEmail from "../SearchResult/Optimized/UnlockProfileEmail";
import SearchSpecificUser from "../DetailedInfo/SearchSpecificUser";
import Pagination from "../Utils/Pagination";
import { useSnackbar } from "notistack";
import Emitter from "../Utils/emitter";
import { removeItemFromList } from "./SavedListQueries";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiServer } from "../../config";

import { Spinner } from "../Utils/utils";
import {
  addToSavedList,
  fetchSavedListItems,
  searchSavedListItems,
} from "./SavedListQueries";

const CompanySavedListView = ({ listName, state, dispatch }) => {
  const [savedLeads, setSavedLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUnlockButtonId, setCurrentUnlockButtonId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  console.log("savedLeads", savedLeads);

  const {
    searchText,
    page,
    view,
    searchCache,
    totalSearchRecords,
    companyResultsCache,
    totalCompanyRecords,
    itemsPerPage,
  } = state;

  const itemType = "company";

  const handleDelete = async (itemId) => {
    try {
      const data = await removeItemFromList(listName, itemId);
      if (data?.["message"] === "success") {
        dispatch({ type: "SET_PAGE", payload: 0 });
        dispatch({ type: "SET_SEARCH_CACHE", payload: {} });
        dispatch({ type: "SET_COMPANY_RESULTS_CACHE", payload: {} });
        enqueueSnackbar("Item removed..", {
          variant: "success",
          autoHideDuration: 2000,
        });
        if (view === "search") {
          console.log("view is search");
          searchSavedLeads(true);
        } else {
          console.log("view is normal");
          fetchSavedLeads(true);
        }
      } else {
        enqueueSnackbar("Error removing item..", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Error removing item..", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    if (view === "search") {
      console.log("view is search");
      searchSavedLeads(false);
    } else {
      console.log("view is normal");
      fetchSavedLeads(false);
    }
  }, [page, view]);

  const fetchSavedLeads = async (forceFetch) => {
    try {
      if (!forceFetch && companyResultsCache[page]) {
        setSavedLeads(companyResultsCache[page]);
      } else {
        setLoading(true);
        const pageNo = forceFetch ? 0 : page;
        const data = await fetchSavedListItems(pageNo, itemType, listName);
        dispatch({
          type: "SET_TOTAL_COMPANY_RECORDS",
          payload: data?.["total_item_count"],
        });
        setSavedLeads(data?.["save_list_items"]);
        dispatch({
          type: "SET_COMPANY_RESULTS_CACHE",
          payload: {
            ...companyResultsCache,
            [page]: data?.["save_list_items"],
          },
        });
      }
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("There was an error fetching data...", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
      console.log(err);
    }
  };

  const searchSavedLeads = async (forceFetch) => {
    try {
      if (!forceFetch && searchCache[page]) {
        setSavedLeads(searchCache[page]);
      } else {
        setLoading(true);
        const pageNo = forceFetch ? 0 : page;
        const data = await searchSavedListItems(
          pageNo,
          itemType,
          listName,
          searchText
        );
        dispatch({
          type: "SET_TOTAL_SEARCH_RECORDS",
          payload: data?.["total_item_count"],
        });
        setSavedLeads(data?.["save_list_items"]);
        dispatch({
          type: "SET_SEARCH_CACHE",
          payload: { ...searchCache, [page]: data?.["save_list_items"] },
        });
      }
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("There was an error fetching data...", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <div
        className="lead-accordion accordion-body global-width"
        style={{ marginLeft: "5%" }}
        id="accordionExample2"
      >
        <h5 className="text-start mt-2">{`No. of records : ${
          view === "search" ? totalSearchRecords : totalCompanyRecords
        }`}</h5>
        <div className="accordion-item mb-3 ">
          <div
            id="collapseOne"
            className="accordion-collapse show collapse"
            data-bs-parent="#accordionExample2"
          >
            <div style={{ padding: "0rem" }} className="accordion-body">
              {loading ? (
                <Spinner />
              ) : savedLeads.length > 0 ? (
                savedLeads.map((data, index) => {
                  const companyData = {
                    id: data["saved_item"]["id"],
                    company_name: data["saved_item"]["full_name"],
                    industry: data["saved_item"]["job"],
                    headquarters: data["saved_item"]["location"],
                    social_url: data["saved_item"]["details_url"],
                    image_url: data["saved_item"]["image_url"],
                  };
                  return (
                    <CompanyItem
                      key={index}
                      index={index}
                      itemId={data["id_"]}
                      companyData={companyData}
                      currentUnlockButtonId={currentUnlockButtonId}
                      setCurrentUnlockButtonId={setCurrentUnlockButtonId}
                      handleDelete={handleDelete}
                    />
                  );
                })
              ) : (
                <h5 className="save_list_notFound">NO RESULTS FOUND</h5>
              )}
              <Pagination
                onPageChange={({ selected }) =>
                  dispatch({ type: "SET_PAGE", payload: selected })
                }
                pageNumber={page}
                pageCount={Math.ceil(
                  (view === "search"
                    ? totalSearchRecords
                    : totalCompanyRecords) / itemsPerPage
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CompanyItem = ({
  index,
  itemId,
  companyData,
  currentUnlockButtonId,
  setCurrentUnlockButtonId,
  handleDelete,
}) => {
  const [profileData, setProfileData] = useState({});
  const [companyLoading, setCompanyLoading] = useState(false);

  const handleCompanyProfile = async (id) => {
    let interval;
    const data = {
      identifier: id,
      search_id: null,
      use_thirdparty_api: false, // todo handle realtime, set to true
    };
    console.log("handl compnay profile in savedlsit ", data);
    setCompanyLoading(true);

    const companyId = await fetchCompanyId(data);

    const handleSuccess = async () => {
      const companyResponse = await fetchProspectCompanyData(companyId); //todo handle realtime
      switch (companyResponse.status) {
        case "success":
          setProfileData(companyResponse?.search_result);
          setCompanyLoading(false);
          clearInterval(interval);
          break;
        case "running":
          return;
        case "failed":
          clearInterval(interval);
          setCompanyLoading(false);
          setProfileData([]);
          console.log("failed");
          break;
      }
    };

    const companyData = async () =>
      (interval = setInterval(() => handleSuccess(), 5 * 1000));

    if (companyId) companyData();
  };

  return (
    <div key={index}>
      <div
        className="container-style mt-3 global-shadow"
        style={{ padding: "10px 10px", width: "95%" }}
      >
        <div className="save-list-names-container">
          <CompanyProfile
            data={companyData}
            showMessageModal={false}
            flexBasis="70%"
          />
          <CompanyButton
            data={companyData}
            itemIndex={index}
            handleCompanyProfile={handleCompanyProfile}
            currentUnlockButtonId={currentUnlockButtonId}
            setCurrentUnlockButtonId={setCurrentUnlockButtonId}
          />
          <RemoveItem itemId={itemId} handleDelete={handleDelete} />
        </div>
        {currentUnlockButtonId === companyData.id ? (
          <div className="company-profile-container">
            <CompanyViewProfile data={profileData} loading={companyLoading} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const RemoveItem = ({ itemId, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  // console.log("removeItem data", data);
  return (
    <div style={{ marginRight: "1.5rem" }}>
      {loading ? (
        <Spinner />
      ) : (
        <IconButton
          onClick={async () => {
            setLoading(true);
            await handleDelete(itemId);
            setLoading(false);
          }}
          data-cy="save-list-remove-btn"
        >
          <CloseIcon color="primary" />
        </IconButton>
      )}
    </div>
  );
};

export default CompanySavedListView;
