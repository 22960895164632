import Cookies from "js-cookie";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import validator from "validator";
import { useSnackbar } from "notistack";
import { apiServer } from "../../config";

const ResetPassword = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [confirmShowPass, setConfirmShowPass] = useState(false);
  const [newPassRepeat, setNewPassRepeat] = useState();
  const [newPass, setNewPass] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const username = Cookies.get("username");
  const resetpasswordtoken = useParams();
  const isValid = false;

  const handlePassClick = (e) => {
    e.preventDefault();
    if (showPass === true) setShowPass(false);
    else setShowPass(true);
  };

  const handleConfirmPassClick = (eve) => {
    eve.preventDefault();
    setConfirmShowPass(!confirmShowPass);
  };

  function handleError(status) {
    console.error(`Got HTTP Error ${status}`);
    enqueueSnackbar(
      "Connection to our server has been lost. Please try after some time",
      { variant: "error" }
    );
  }

  const CheckPassword = (newPass, newPassRepeat) => {
    console.log("I am in check Password", isValid, newPass, newPassRepeat);
    if (!newPass && !newPassRepeat) {
      enqueueSnackbar(
        "Hey " +
          username +
          ", looks like you haven't given your new password and confirmed it. Kindly fill the required fields",
        { variant: "error" }
      );
      return false;
    } else if (!newPass) {
      enqueueSnackbar("Hey " + username + ", kindly enter the new password", {
        variant: "error",
      });
      return false;
    } else if (!newPassRepeat) {
      enqueueSnackbar(
        "Hey " + username + ", kindly confirm your new password",
        { variant: "error" }
      );
      return false;
    } else if (
      !newPass ||
      !validator.isStrongPassword(newPass, {
        minLength: 8,
        minLowercase: 1,
        maxlength: 50,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      enqueueSnackbar(
        "Hey " +
          username +
          ", the password needs to be: atleast 8 characters long, with at least 1 uppercase character, atleast 1 number and 1 symbol. Try entering your password with these requirements again.",
        { variant: "error" }
      );
      return false;
    } else if (newPass === newPassRepeat) {
      console.log("password match");
      return true;
    } else {
      enqueueSnackbar(
        "Hey " +
          username +
          ", your new password and confirm password do not match. Kindly try again.",
        { variant: "error" }
      );
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("I am in handle submit isvalid", isValid);
    console.log("I am in submit after validation set valid", isValid);
    if (CheckPassword(newPass, newPassRepeat)) {
      console.log("I am calling api as isvalid value is", isValid);
      setIsDisabled(true);

      let resetPassData = {
        token: resetpasswordtoken.token,
        password: newPass,
      };
      try {
        const fetchResponse = await fetch(
          apiServer + "/email/forgot_password/verify",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(resetPassData),
          }
        );

        if (fetchResponse.status === 200) {
          enqueueSnackbar("Password Reset Successfully. ", {
            variant: "success",
          });
          window.location.href = "/login";
        }
        if (fetchResponse.status === 400) {
          enqueueSnackbar(
            "Hey, looks like the link has expired. Please send a request to reset password again.",
            { variant: "error" }
          );
        }
      } catch (err) {
        handleError(err);
      }

      console.log("Password was reset!");
      console.log("Send email to : " + Cookies.get("forgot_email"));
    }
  };

  return (
    <div>
      <div className="main-content-area overflow-hidden">
        <div className="main-wrapper">
          <div className="reset-main-div">
            <div className="reset-child-div">
              <h5 className="text-center pt-4">Reset Password</h5>
              <form className="sign-up-form p-5">
                <div className="mb-3 password-input">
                  <label htmlFor="enterPassword">New Password</label>
                  <input
                    type={showPass ? "text" : "password"}
                    name="password"
                    className="w-100"
                    placeholder="Enter new password"
                    id="password"
                    onChange={(e) => setNewPass(e.target.value)}
                    disabled={isDisabled}
                    data-cy="password"
                  />
                  <a href="#" onClick={handlePassClick}>
                    <img
                      src={
                        showPass
                          ? "/assets/images/new_icons/eye.png"
                          : "/assets/images/new_icons/eye-off.png"
                      }
                      alt=""
                      style={{
                        position: "absolute",
                        top: "43px",
                        right: "10px",
                        width: "18px",
                        height: "18px",
                      }}
                      data-cy="passwordImg"
                    />
                  </a>
                </div>
                <div className="mb-3 password-input">
                  <label htmlFor="confirmPassword">Confirm New Password</label>
                  <input
                    type={confirmShowPass ? "text" : "password"}
                    name="password"
                    className="w-100"
                    placeholder="Confirm new password"
                    id="confirmPassword"
                    onChange={(e) => setNewPassRepeat(e.target.value)}
                    disabled={isDisabled}
                    data-cy="confirmPassword"
                  />
                  <a href="#" onClick={handleConfirmPassClick}>
                    <img
                      src={
                        confirmShowPass
                          ? "/assets/images/new_icons/eye.png"
                          : "/assets/images/new_icons/eye-off.png"
                      }
                      alt=""
                      style={{
                        position: "absolute",
                        top: "43px",
                        right: "10px",
                        width: "18px",
                        height: "18px",
                      }}
                      data-cy="confirmPasswordImg"
                    />
                  </a>
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn text-white w-100"
                  data-cy="submit-btn"
                >
                  Reset Password
                </button>
              </form>
              <div className="text-center pb-2">
                <Link to="/login" className="text-danger text-decoration-none">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
