import React, { useState } from "react";
//REDUX
import { saveViewDetail } from "../../../_reducer/shared";
import { useDispatch } from "react-redux";
//CSS
import "./ViewDetails.css";
import { useSnackbar } from "notistack";
import { Loader } from "../../Utils/utils";
import {
  AssociatedEmails,
  AssociatedPhones,
} from "../../DetailedInfo/SpecificInfoComponents";
import MessageDialog from "../../PersonalizedMessages/MessageDialog";

const ViewDetails = ({ leadsId, searchTerm, viewDetailsResult, loader, isGeolocator }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState("");

  const handleChangeBackward = () => {
    dispatch(saveViewDetail({ view: false, id: null }));
  };

  const handleWhatsapp = (phone) => {
    setData(phone);
    setOpen(true);
    setMessage("whatsapp");
  };

  return (
    <div>
      {leadsId ? (
        ""
      ) : (
        <button className="btn_backward" onClick={handleChangeBackward}>
          &laquo; Back
        </button>
      )}

      {loader ? (
        <div className="pb-6">
          <Loader />
        </div>
      ) : (
        <section className="details active" data-cy="view-profile-container">
          <div className="details__text" data-cy="geolocator-profile-detail">
            {searchTerm?.includes("people") ? (
              <ul className="details__list">
                <li className="details__list-item">
                  <span>
                    <b>Name: </b>
                  </span>
                  {viewDetailsResult?.name
                    ? viewDetailsResult?.name
                    : "Not Found"}
                </li>
                <li className="details__list-item">
                  <span>
                    <b>Address: </b>
                  </span>{" "}
                  <div className="text-justify w-75">
                    {viewDetailsResult?.address
                      ? viewDetailsResult?.address
                      : "Not Found"}{" "}
                  </div>
                </li>
                <li className="details__list-item">
                  <span>
                    <b>Pincode: </b>
                  </span>
                  {viewDetailsResult?.pincode
                    ? viewDetailsResult?.pincode
                    : "Not Found"}
                </li>

                <li className="details__list-item">
                  <AssociatedPhones
                    phones={viewDetailsResult?.phone_number}
                    handleWhatsapp={handleWhatsapp}
                  />
                  <AssociatedEmails emails={viewDetailsResult?.email} />
                </li>
              </ul>
            ) : (
              <ul className="details__list">
                <li className="details__list-item">
                  <span>
                    <b>Title: </b>
                  </span>
                  {viewDetailsResult?.name
                    ? viewDetailsResult?.name
                    : "Not Found"}
                </li>
                <li className="details__list-item">
                  <span>
                    <b>Category: </b>
                  </span>{" "}
                  {viewDetailsResult?.category
                    ? viewDetailsResult?.category
                    : "Not Found"}{" "}
                </li>
                <li className="details__list-item">
                  <span>
                    <b>Details: </b>
                  </span>
                  <div className="text-justify w-75">
                    {viewDetailsResult?.area
                      ? viewDetailsResult?.area
                      : "Not Found"}
                  </div>
                </li>
                <li className="details__list-item">
                  <span>
                    <b>Description: </b>
                  </span>
                  {viewDetailsResult?.description
                    ? viewDetailsResult.description
                    : "Not Found"}
                </li>
                <li className="details__list-item">
                  <span>
                    <b>Address: </b>
                  </span>
                  {viewDetailsResult?.address
                    ? viewDetailsResult.address
                    : "Not Found"}
                </li>
                <li className="details__list-item">
                  <AssociatedPhones
                    phones={viewDetailsResult?.phone_number}
                    handleWhatsapp={handleWhatsapp}
                  />
                  <li>
                    <b>Website: </b>
                    <p className="details_list-item_website">
                      {viewDetailsResult?.website
                        ? viewDetailsResult.website
                            .replaceAll("{", "")
                            .replaceAll("}", "")
                            .replaceAll("'", "")
                        : "Not Found"}
                    </p>
                  </li>
                </li>
              </ul>
            )}
          </div>
        </section>
      )}
      <MessageDialog
        url = {isGeolocator}
        open={open}
        data={data}
        onClose={() => setOpen(false)}
        title={message}
      />
    </div>
  );
};

export default ViewDetails;
