import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/esm/react-router-dom";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import download from "downloadjs";
import SmeLockedInfo from "./SmeDetailedView";
import SmeFilters from "./SmeFilters";
import { fetchGeoSMERecords, fetchSMEData, fetchSMEFile } from "./SmeQueries";
import { Spinner } from "../../Utils/utils";
import Pagination from "../../Utils/Pagination";

const itemsPerPage = 10;

const SMETopBar = ({ offset, count }) => {
  const history = useHistory();

  return (
    <div className="sheetRecords__total-records">
      <button
        className="sheetRecords__back-btn ml-3"
        onClick={() => {
          history.goBack();
        }}
      >
        <img src="/assets/images/new_icons/Back.png" alt="#" />
        <span data-cy="geolocator-sme-back-btn" className="pl-2">
          Back
        </span>
      </button>
      <p className="mr-3">
        <span style={{ fontSize: "1rem" }}>
          <b>{offset}</b> of <b>{count.toLocaleString()}</b> searched Profiles{" "}
        </span>
        <b> from SME Companies </b>
      </p>
    </div>
  );
};

/**
 * Handles download of selected leads for SME
 * @param selected - Array of uuid_
 * @param setSelected - State function for selected variable
 * @param pageLeads
 * @return {JSX.Element}
 * @constructor
 */
const SMEExportBar = ({ selected, setSelected, pageLeads }) => {
  const [downloading, setDownloading] = useState(false);

  const handleExport = () => {
    // FIXME: Convert this to React-query since now I (Yash Agarwal) know how to handle Infinite retries on error
    //  on blob type of response
    (async () => {
      setDownloading(true);
      const payload = { identifiers: selected };
      const data = await fetchSMEFile(payload);

      download(data, "SME-Leads-Leadzen-AI.xlsx");
      setDownloading(false);
    })();
  };

  const handlePageLeadsSelection = (e) => {
    if (!e.target.checked) {
      setSelected([]);
      return;
    }
    setSelected(pageLeads.map((lead) => lead["uuid_"]));
  };

  return (
    <div className="d-flex justify-content-between align-items-center my-3">
      <div className="d-flex justify-content-start align-items-center">
        <input
          style={{ margin: "0.75rem" }}
          type="checkbox"
          checked={Boolean(selected.length === pageLeads.length)}
          onChange={(e) => handlePageLeadsSelection(e)}
        />{" "}
        <span style={{ fontSize: "1rem" }}>
          Profiles selected: <strong>{selected.length}</strong>
        </span>
      </div>

      <button
        className="export-btn"
        onClick={handleExport}
        data-cy="export-btn"
      >
        {downloading ? <Spinner color={"black"} /> : "Download"}
      </button>
    </div>
  );
};

/**
 * @param pageLeads - Displays current leads
 * @return {JSX.Element}
 * @constructor
 */
const SMEPageRecords = ({ page, pageLeads }) => {
  const [selectedLeads, setSelectedLeads] = useState([]);
  return (
    <>
      {selectedLeads.length ? (
        <SMEExportBar
          selected={selectedLeads}
          setSelected={setSelectedLeads}
          pageLeads={pageLeads}
        />
      ) : null}
      {pageLeads ? (
        <div>
          {pageLeads.map((lead, index) => {
            <span>{index}</span>;
            return (
              <SmeLockedInfo
                page={page}
                key={lead["id"]}
                lead={lead}
                selected={selectedLeads}
                setSelected={setSelectedLeads}
                allSelected={selectedLeads.length === pageLeads.length}
              />
            );
          })}
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-2">
          <h5>No records found</h5>
        </div>
      )}
    </>
  );
};

/**
 * Manages and maintains the pagination for SME data
 * @param data - Array of records from fetch call
 * @param count - Count of records
 * @return {JSX.Element}
 * @constructor
 */
const SMEPaginatedData = ({ data, count }) => {
  // We start with an empty list of items.
  const [page, setPage] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  // following the API or data you're working with.

  const pageCount = Math.ceil(data?.length / itemsPerPage);

  useEffect(() => {
    const itemOffset = page * itemsPerPage;
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);

    setCurrentItems(data?.slice(itemOffset, endOffset));
  }, [data, page]);

  useEffect(() => {
    setPage(0);
  }, [data]);

  return (
    <>
      <SMETopBar
        offset={page * itemsPerPage + currentItems?.length}
        count={count}
      />
      <SMEPageRecords page={page} pageLeads={currentItems} />
      <Pagination
        onPageChange={({ selected }) => setPage(selected)}
        pageNumber={page}
        pageCount={pageCount}
      />
    </>
  );
};

const SmeListView = (props) => {
  const [pageRecords, setPageRecords] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  console.log(pageRecords)
  let payload;
  console.log(
    "props?.allCoordinates.length: ",
    !props?.requestType === "geolocator_sme"
  );
  if (props?.center) {
    payload = { city: props?.input, center: props?.center };
  }

  if (props?.allCoordinates?.length > 0) {
    payload = { allCoordinates: props?.allCoordinates, type: "Polygon" };
  }

  const { data, status } = useQuery(
    ["SME Data", payload],
    props?.input || props?.requestType === "geolocator_sme"
      ? () => fetchGeoSMERecords(payload)
      : fetchSMEData
  );
  const { enqueueSnackbar } = useSnackbar();

  function removeDuplicates(arr) {
    const uniqueObjects = {};
    const result = [];

    for (const obj of arr) {
      const key = obj.company_name + obj.contact_person;
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        result.push(obj);
      }
    }

    return result;
  }

  useEffect(() => {
    // Record fromHistory is converted to desired formatting
    const fromHistory = props?.location?.state?.details?.[0]?.search_results;
    if (fromHistory?.length > 0) {
      console.log("from history", fromHistory);
      const filteredData = fromHistory.filter((item) => {
        return (
          item.company_name.trim() !== "" && item.contact_person.trim() !== ""
        );
      });
      const uniqueArray = removeDuplicates(filteredData);
      setPageRecords(uniqueArray);
    } else {
      if (status === "loading") setLoading(true);
      if (status === "error") {
        enqueueSnackbar(
          "Please try again after sometime. There was an error connecting to Leadzen Servers!",
          { variant: "error" }
        );
        setLoading(false);
      }

      setPageRecords(data?.["sme"]);
      setCount(data?.["sme"].length < 10 ? data?.["sme"].length : data?.count );
      setLoading(false);
    }
  }, [
    data,
    enqueueSnackbar,
    props?.location?.state?.details?.search_results,
    status,
  ]);

  return (
    <>
      <div className="realtime">
        {/*{!props?.requestType === "geolocator_sme"}*/}
        {props?.input || props?.requestType === "geolocator_sme" ? (
          ""
        ) : (
          <SmeFilters
            setLoading={setLoading}
            setPageRecords={setPageRecords}
            setCount={setCount}
          />
        )}

        <div
          className="realtime__results-container"
          style={{
            width:
              props?.input || props?.requestType === "geolocator_sme"
                ? "100%"
                : "77%",
          }}
          data-cy="sme-list-container"
        >
          <div className="d-flex justify-content-center mt-2">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && pageRecords?.length > 0 ? (
            <SMEPaginatedData data={pageRecords} count={count} />
          ) : null}
          {!loading && pageRecords?.length <= 0 ? (
            <h5 className="my-5 text-center">No records found</h5>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SmeListView;
