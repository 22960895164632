import React, { useEffect } from "react";
import CommonFilterItem from "../CommonFilterItem";
import CommonSelectBoxItem from "../CommonSelectBoxItem";
import CommonDateFilter from "../CommonDateFilter";
import CommonBasicFilter from "../CommonBasicFilter";

const FilterRecentlyFundedCompanies = ({
  filterRequest,
  setFilterRequest,
  sheetName,
}) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);

  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_that_got_funded"
          label="Funded Company Name"
          icon="/assets/images/new_icons/company_name.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="industry_of_company_that_got_funded"
          label="Industry"
          icon="/assets/images/new_icons/Industry.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_that_funded"
          label="Funded By"
          icon="/assets/images/new_icons/Funded.png"
        />
        <CommonFilterItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="funding_round"
          label="Funding Round"
          icon="/assets/images/new_icons/Job Title.png"
          sheetName={sheetName}
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="location"
          label="Location"
          icon="/assets/images/new_icons/Location.png"
        />
        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="no_of_employees"
          label="Employee Size"
          tooltipText={"Select Employee Size"}
          icon="/assets/images/new_icons/Employee Count.png"
          collection={[
            "1-10",
            "11-50",
            "51-200",
            "201-500",
            "501-1000",
            "1001-5000",
            "5001-10000",
            "10001+",
          ]}
        />

        <CommonDateFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="date_of_funding"
          label="Date Of Funding"
          tooltipText={"Select Date Range"}
          icon="/assets/images/new_icons/date.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keywords"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};
export default FilterRecentlyFundedCompanies;
