import { configureStore } from '@reduxjs/toolkit';
import areaDetailReducer from '../_reducer/areaDetail';
import leadsDataReducer from '../_reducer/leads';
import sharedReducer from '../_reducer/shared';

export const store = configureStore({
  reducer: {
    areaDetail: areaDetailReducer,
    leads: leadsDataReducer,
    shared: sharedReducer,
  },
});
