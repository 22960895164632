import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { cleanWebsiteUrl } from "../../Utils/helper";

const CompanyIndividualDetail = ({ title, value, type }) => {
  return (
    <>
      {value && (
        <div
          style={{
            display: value ? "block" : "none",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          <p className="specific-page-title">{title}</p>
          <div
            style={{
              backgroundColor: "#fafafa",
              padding: "10px 0 10px 5px",
              marginTop: "-8px",
            }}
          >
            {type === "link" ? (
              <a
                href={value ? value : <p>Not Found</p>}
                target="_blank"
                rel="noreferrer"
              >
                {value ? value : <p>Not Found</p>}
              </a>
            ) : value ? (
              value
            ) : (
              <p>Not Found</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const SpecificCompanyDetails = ({
  data,
  index,
  currentPage,
  sheetName,
  setlocation,
}) => {
  const history = useHistory();
  console.log("SpecificCompanyDetails data: ", data);
  useEffect(() => {
    setlocation(data?.roc);
  }, []);

  async function handleProfile(index, data) {
    const profile = {
      id: uuid(),
      full_name: data?.full_name,
      location: data?.location,
      job: data?.position || data?.job,
      details_url: data?.details_url,
    };

    history.push({
      pathname: "/UnlockProfile", // BasicSearchResult
      state: { details: profile },
    });
  }
  console.log(data);
  return (
    <>
      {data && (data?.linkedin_url || data?.cin) ? (
        // seeLess ? null : (
        <div
          id={"collapseExample_" + index + currentPage}
          style={{ width: "100%", margin: "0 auto" }}
        >
          <section className="item-section mt-3">
            <div className="phone-child-div ">
              {(data?.tagline ||
                data?.founded_year ||
                data?.hq ||
                data?.industry ||
                data.cin ||
                data.company_address ||
                data?.auth_capital ||
                data?.paid_capital) && (
                <div className="px-3">
                  {data.tagline ? (
                    <div style={{ display: data?.tagline ? "block" : "none" }}>
                      <p className="specific-page-title">Tag Line</p>
                      <div>
                        {data?.tagline ? data?.tagline : <p>Not Found</p>}
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: data.cin ? "block" : "none" }}>
                      <p className="specific-page-title">Company CIN</p>
                      <div>{data?.cin ? data?.cin : <p>Not Found</p>}</div>
                    </div>
                  )}

                  {data?.founded_year ? (
                    <div
                      style={{
                        display: data?.founded_year ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Founded Year</p>
                      <div>
                        {data?.founded_year ? (
                          data?.founded_year
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: data.company_address ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Company Address</p>
                      <div>
                        {data.company_address ? (
                          data.company_address
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                  )}
                  {data?.hq ? (
                    <div style={{ display: data?.hq ? "block" : "none" }}>
                      <p className="specific-page-title">Company Headquarter</p>
                      <div>
                        {data?.hq ? (
                          <React.Fragment>
                            {data?.hq?.line_1 ? data?.hq?.line_1 + "," : null}{" "}
                            {data?.hq?.city ? data?.hq?.city + "," : null}{" "}
                            {data?.hq?.state ? data?.hq?.state + "," : null}{" "}
                            {data?.hq?.country ? data?.hq?.country : null}
                          </React.Fragment>
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: data?.auth_capital ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Authorized Capital</p>
                      <div>
                        {data?.auth_capital ? (
                          data?.auth_capital
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                  )}
                  {console.log(data?.paid_capital)}
                  {data?.industry ? (
                    <div style={{ display: data?.industry ? "block" : "none" }}>
                      <p className="specific-page-title">Industry</p>
                      <div>
                        {data?.industry ? data?.industry : <p>Not Found</p>}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: data?.paid_capital ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Paid Capital</p>
                      <div>
                        {data?.paid_capital ? (
                          data?.paid_capital?.[0] === -1 ? (
                            "none"
                          ) : (
                            data?.paid_capital
                          )
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="px-3">
                {data?.specialities && data?.specialities?.length > 0 ? (
                  <div
                    style={{
                      display: data?.specialities ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Specialities</p>
                    <div>
                      {data?.specialities ? (
                        data?.specialities?.join(", ")
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: data?.category ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Category</p>
                    <div>
                      {data?.category ? data?.category : <p>Not Found</p>}
                    </div>
                  </div>
                )}

                {data?.categories && data?.categories?.length > 0 ? (
                  <div style={{ display: data?.categories ? "block" : "none" }}>
                    <p className="specific-page-title">Categories</p>
                    <div>
                      {data?.categories ? (
                        data?.categories.join(", ")
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        display: data?.sub_category ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Sub Category</p>
                      <div>
                        {data?.sub_category ? (
                          data?.sub_category
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: data?.classes ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Class</p>
                      <div>
                        {data?.classes ? data?.classes : <p>Not Found</p>}
                      </div>
                    </div>
                    <div
                      style={{
                        display: data?.activity ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Activity</p>
                      <div>
                        {data?.activity ? data?.activity : <p>Not Found</p>}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="px-3">
                {data?.company_type ? (
                  <>
                    <div
                      style={{
                        display: data?.company_type ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Company Type</p>
                      <div>
                        {data?.company_type ? (
                          <>
                            {console.log(data?.company_type)}
                            {data?.company_type === "PUBLIC_COMPANY" ||
                            data?.company_type === "PRIVATELY_HELD" ? (
                              <p>Public Company</p>
                            ) : (
                              data?.company_type
                            )}
                          </>
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div style={{ display: data?.website ? "block" : "none" }}>
                      <p className="specific-page-title">Company Website</p>
                      <div>
                        <a
                          href={
                            data?.website ? (
                              cleanWebsiteUrl(data?.website)
                            ) : (
                              <p>Not Found</p>
                            )
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.website ? (
                            cleanWebsiteUrl(data?.website)
                          ) : (
                            <p>Not Found</p>
                          )}
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        display: data?.follower_count ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Company Followers</p>
                      <div>
                        {data?.follower_count ? (
                          data?.follower_count
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: data?.company_size_on_linkedin
                          ? "block"
                          : "none",
                      }}
                    >
                      <p className="specific-page-title">Employee count</p>
                      <div>
                        {data?.company_size_on_linkedin ? (
                          data?.company_size_on_linkedin
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: data?.no_of_employees ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Employee count</p>
                      <div>
                        {data?.no_of_employees ? (
                          data?.no_of_employees
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          data?.extra && data?.extra?.contact_email
                            ? "block"
                            : "none",
                      }}
                    >
                      <p className="specific-page-title">Contact Email</p>
                      <div>
                        {data?.extra && data?.extra?.contact_email ? (
                          data?.extra?.contact_email
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          data?.extra && data?.extra?.phone_number
                            ? "block"
                            : "none",
                      }}
                    >
                      <p className="specific-page-title">Contact Phone</p>
                      <div>
                        {data?.extra && data?.extra?.phone_number ? (
                          data?.extra?.phone_number
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: data?.acquisitions ? "block" : "none",
                      }}
                    >
                      <p className="specific-page-title">Acquisitions</p>
                      <div>
                        {data?.acquisitions ? (
                          data?.acquisitions
                        ) : (
                          <p>Not Found</p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ display: data?.roc ? "block" : "none" }}>
                    <p className="specific-page-title">ROC</p>
                    <div>{data?.roc ? data?.roc : <p>Not Found</p>}</div>
                  </div>
                )}
              </div>
            </div>
          </section>
          {data?.description && data?.description !== "Spam" ? (
            <section className="item-section">
              <div className="row">
                <div
                  style={{
                    display:
                      data?.description && data?.description !== "Spam"
                        ? "block"
                        : "none",
                    textAlign: "justify",
                  }}
                  className="col-md-6 pe-5"
                >
                  <p className="specific-page-title">Business Description</p>
                  {data?.description && data?.description !== "Spam" ? (
                    data?.description
                  ) : (
                    <p>Not Found</p>
                  )}
                </div>
                <div className="col-md-6">
                  <img
                    width={"50%"}
                    src={
                      data?.profile_pic_url ? (
                        data?.profile_pic_url
                      ) : (
                        <p>Not Found</p>
                      )
                    }
                    alt=""
                  />
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {data?.team_members && data?.team_members?.length > 0 ? (
            <section className="item-section">
              <p className="specific-page-title">Teams/Key members</p>
              <Swiper
                breakpoints={{
                  668: {
                    slidesPerView: 2,
                  },
                  800: {
                    slidesPerView: 3,
                  },
                  1080: {
                    slidesPerView: 4,
                  },
                }}
                slidesPerView={4}
                spaceBetween={5}
                // onSlideChange={(e) => console.log(e)}
                // onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
              >
                {data?.team_members?.length
                  ? data?.team_members?.map((profile, index) => (
                      <SwiperSlide key={index} className="my-3">
                        <div
                          className="d-flex flex-column align-items-center"
                          style={{ marginBottom: "1.5rem", minheight: "250px" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                            }}
                          >
                            <a
                              href={profile.details_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className=""
                                src="/assets/images/user-author-pic.png"
                                alt=""
                              />
                            </a>{" "}
                          </div>{" "}
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              height: "30px",
                            }}
                            className="d-block mt-3"
                          >
                            {" "}
                            {profile.full_name}{" "}
                          </p>
                          {"   "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "space-evenly",
                              marginRight: "1.2rem",
                              marginLeft: "1.2rem",
                              minHeight: "130px",
                              // border: '1px solid crimson'
                            }}
                          >
                            <p
                              style={{
                                wordBreak: "break-word",
                                width: "12rem",
                                textAlign: "center",
                              }}
                              className="d-block mt-1 mx-5"
                            >
                              {" "}
                              {profile.job?.length >= 120
                                ? profile.job.slice(0, 120) + " ..."
                                : profile.job}
                            </p>{" "}
                          </div>
                          <p className="search-view-btn mt-auto">
                            <button
                              className="btn button mb-3"
                              onClick={() => handleProfile(index, profile)}
                              style={
                                profile.meta_data?.["unlocked_profile"] && {
                                  backgroundColor: "#FF6852",
                                  color: "white",
                                  fontSize: "14px",
                                }
                              }
                            >
                              {profile.meta_data?.["unlocked_profile"]
                                ? "Unlock Profile"
                                : "View Profile"}
                            </button>
                          </p>
                        </div>
                      </SwiperSlide>
                    ))
                  : null}{" "}
              </Swiper>
            </section>
          ) : (
            ""
          )}
        </div>
      ) : (
        // )
        <div style={{ width: "100%", margin: "0 auto" }}>
          <section
            className="item-section w-100"
            style={{ textAlign: "center" }}
          >
            Data Not Found
          </section>
        </div>
      )}
    </>
  );
};

export default SpecificCompanyDetails;
