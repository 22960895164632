import { apiPersonalizedBaseUrl, apiWhatsappUrl } from "../config";

// Dialer for phone number selection
export const openDialer = (phoneNumber, messsage = "") => {
  console.log("phone number: " + phoneNumber);
  const cleanPhoneNumber = phoneNumber.replace(/[\s()+-]/g, "");
  console.log("clean phone number: " + cleanPhoneNumber);
  window.open(
    `${apiWhatsappUrl}=${cleanPhoneNumber}&text=${messsage}`,
    "_blank"
  );
};

// Open email app
export const mailOpener = (email, subject = "", body = "") => {
  if (typeof window !== "undefined" && window.open) {
    const subjectEncoded = encodeURIComponent(subject);
    const bodyEncoded = encodeURIComponent(body);
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subjectEncoded}&body=${bodyEncoded}`,
      "_blank",
      "noopener,noreferrer"
    );
  } 
};

export const fetchPost = async (endpoint, payload) => {
  console.log("payload:", payload);
  try {
    const response = await fetch(`${apiPersonalizedBaseUrl}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("analystt" + ":" + "hello123"),
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (response.status === 201 || response.status === 200) {
      return data;
    }
    return "error";
  } catch (error) {
    console.log("error", error);
  }
};

export const cleanWebsiteUrl = (inputText) => {
  try {
    console.log("Input",inputText)
    if (inputText.includes('External')) {
      const [cleanedText] = inputText.split(' External');
      return cleanedText.trim();
    } else {
      // If "External" is not present, return the same input text
      return inputText;
    }
  } catch (error) {
    console.error('Error processing text:', error.message);
    return inputText; // Return the original text in case of an error
  }
};