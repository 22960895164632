import React, { useEffect, useState } from "react";
import "./MapResultFromHistory.css";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { httpService } from "../Geolocator/geolocatorHttpServices/geolocatorHttpServices";
import MapResultItem from "./MapResultItem";
import CombinedSearchBar from "../SharedComponent/CombinedSearchBar";
import ResultsSearchBar from "../SharedComponent/Components/ResultsSearchBar";
import MapSelectAll from "./MapSelectAll";
import MapExport from "./MapExport";
import SmeListView from "../RealTime/SmeCompanies/SmeListView";
import { mapMetaData } from "./api";
import { Loader } from "../Utils/utils";
import {
  fetchSavedListsNames,
  getSaveListMetadata,
} from "../SavedList/SavedListQueries";
import Pagination from "../Utils/Pagination";

const itemsPerPage = 15;
const MapResultFromHistory = ({ data }) => {
  const [leads, setLeads] = useState([]);
  const [copyLeads, setCopyLeads] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [searchInputUrl, setSearchInputUrl] = useState(
    data?.details?.search_results[0]?.next
  );
  const [simpleSearchId, setSimpleSearchId] = useState(
    data?.details?.search_id
  );
  const [mapSelectedLeads, setMapSelectedLeads] = useState([]);
  const [saveListNames, setSaveListNames] = useState([]);
  const [saveListMetadata, setSaveListMetadata] = useState([]);
  const [loading, setLoading] = useState(() => true);
  const { enqueueSnackbar } = useSnackbar();

  console.log("data in mapresultfromhistoy", data);
  useEffect(() => {
    const getMapHistoryData = async () => {
      setLoading(true);
      await paginate(0);
    };
    getMapHistoryData();
  }, []);

  useEffect(() => {
    (async () => {
      // Existing records in saved_list
      let saveListNames = await fetchSavedListsNames();
      console.log("saveListNames", saveListNames);

      if (saveListNames?.["list_names"]) {
        setSaveListNames(saveListNames["list_names"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      console.log("pgdataa", leads);
      if (leads?.length === 0) return;
      setSaveListMetadata([]);
      const hashKeyInputs = leads?.map((lead) => lead?.id);
      const saveListMetadata = await getSaveListMetadata(hashKeyInputs);
      setSaveListMetadata(saveListMetadata);
      console.log("saveListMetadata", saveListMetadata);
    })();
  }, [leads]);

  const handleSearchLeads = (searchedLeads) => {
    // setCopyLeads(searchedLeads);
    setLeads(searchedLeads);
  };
  const paginate = async (pageNumber) => {
    let paginateLeads = null;
    let payload = null;
    if (data?.details?.search_term?.includes("people")) {
      paginateLeads =
        leads && Array.isArray(leads)
          ? allLeads?.slice(
              pageNumber * itemsPerPage - itemsPerPage,
              pageNumber * itemsPerPage
            )
          : [];
      const result = await mapMetaData(paginateLeads);
      setLeads(result);

      setLoading(false);
    } else {
      console.log("searchUrl: ", searchInputUrl);
      let searchUrl;

      searchUrl = searchInputUrl?.split("view")[1]?.split("/");
      const type = searchUrl?.length !== 7 ? "Polygon" : "Feature";
      setLoading(true);

      payload = {
        data_layer_type: type === "Polygon" ? searchUrl[2] : searchUrl[5],
        latitude_north_w: type === "Feature" ? searchUrl[1] : null,
        longitude_north_w: type === "Feature" ? searchUrl[2] : null,
        latitude_south_e: type === "Feature" ? searchUrl[3] : null,
        longitude_south_e: type === "Feature" ? searchUrl[4] : null,
        lat_lon_list:
          type === "Polygon"
            ? JSON.parse(searchUrl[1].replace(/'/g, '"'))
            : null,
        search_term: data?.details?.search_term,
        limit: itemsPerPage,
        type: type,
        offset: pageNumber * itemsPerPage,
      };
      setActivePage(pageNumber);
      httpService
        .getPaginateLeads(payload)
        .then(async (response) => {
          setActivePage(pageNumber);
          paginateLeads = response?.search_results?.search_result;
          const result = await mapMetaData(paginateLeads);
          setLeads(result);
          setCopyLeads(result);
          setAllLeads(result);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className="leads_mainContainer">
      {data?.details?.search_term?.includes("sme") ? (
        <SmeListView
          input={data?.details?.search_term}
          center={data?.details?.center}
        />
      ) : (
        <div className="result-container__results">
          <hr className="divider" />
          <div className="result-container__text-result">
            <CombinedSearchBar isResultPage={false} />

            <div className="user-widget-box  my-3">
              <div className="d-flex align-items-center justify-content-between py-3">
                <div className="d-flex align-items-center ">
                  <MapSelectAll
                    leads={leads}
                    setMapSelectedLeads={setMapSelectedLeads}
                    mapSelectedLeads={mapSelectedLeads}
                  />
                  <small className="">
                    <b>{leads?.length ? leads?.length : "0"}</b> of{" "}
                    <b>
                      {data?.details?.search_results
                        ? data?.details?.search_results?.[0]?.count
                        : 0}
                    </b>{" "}
                    Searched profiles{" "}
                  </small>

                  <small>
                    {"  "}[Total Profiles:{" "}
                    <b>
                      {data?.details?.search_results
                        ? data?.details?.search_results?.[0]?.count
                        : 0}
                    </b>
                    ]
                  </small>
                </div>
                {leads && (
                  <ResultsSearchBar
                    original={copyLeads}
                    setLeads={handleSearchLeads}
                  />
                )}
                <div className="d-flex align-items-center">
                  <MapExport
                    mapSelectedLeads={mapSelectedLeads}
                    searchTerm={data?.details?.search_term}
                  />
                </div>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : leads && leads?.length > 0 ? (
              leads?.map((item, i) => {
                return (
                  <div key={i}>
                    <MapResultItem
                      data={item}
                      searchTerm={data?.details?.search_term}
                      i={i}
                      setMapSelectedLeads={setMapSelectedLeads}
                      mapSelectedLeads={mapSelectedLeads}
                      activePage={activePage}
                      simpleSearchId={simpleSearchId}
                      saveListNames={saveListNames}
                      setSaveListNames={setSaveListNames}
                      saved={saveListMetadata?.[i]?.["saved"]}
                    />
                  </div>
                );
              })
            ) : (
              "Record Not Found"
            )}
          </div>
          <div className="d-flex justify-content-center">
            <Pagination
              onPageChange={({ selected }) => paginate(selected)}
              pageNumber={activePage}
              pageCount={Math.ceil(
                data?.details?.search_results[0]?.count / itemsPerPage
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MapResultFromHistory;
