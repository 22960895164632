import {getFetch, postFetch} from "../../Config/facade";
import {isObjectValuesEmpty} from "../Utils/utils";
import Emitter from "../Utils/emitter";

/**
 * Fetches people history paginated using simply page numbers
 * @param page
 * @param type - Either People/Company/Search History
 * @param child - Enter child if admin checking for child history
 * @return {Promise<*>}
 */
export const fetchHistory = async (page = 0, type = "people", child) => {
  const response = await getFetch(`/history/${type}/chunk`, {
    page: page,
    child: child ? encodeURIComponent(child) : null,
  });
  Emitter.emit("updateCredits", true);

  return response;
};

/**
 * Results of searchbar in paginated form
 * @param params
 * @return {Promise<*>}F
 */
export const fetchSearchedHistory = async (params) => {
  console.log("params: ", params);
  return await getFetch("/history/all/search", params);
};

/**
 * Defined a set fetchInterval of 5m
 * @param payload - query parameter
 * @return {Promise<*>}
 */
export const fetchQueueTaskStatus = async (payload) => {
  if (payload) return await getFetch(`/bulk_upload/queue/estimate/${payload}`);
};

/**
 * Defined a set fetchInterval of 5s
 * @param payload
 * @return {Promise<*>}
 */
export const fetchBulkTaskStatus = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/bulk_upload/progress", payload);
};

/**
 * Gets the blob of the file, and then we use download js package
 * @param url - file path to download from server
 * @return {Promise<*>}
 */
export const fetchFileFromUrl = async (url) => {
  return await getFetch(url);
};
