import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { generateRandomString } from "../SharedComponent/Header";

const ChatSupport = () => {
  const userToken = Cookies.get("user_id");
  useEffect(() => {
    if (userToken) {
      window.BrevoConversationsSetup = {
        visitorId: userToken,
      };
    } else {
      window.BrevoConversationsSetup = {
        visitorId: generateRandomString(20),
      };
    }

    window.BrevoConversationsID = "645397b5a426dd78423bfe98";
    window["BrevoConversations"] =
      window["BrevoConversations"] ||
      function () {
        (window["BrevoConversations"].q =
          window["BrevoConversations"].q || []).push(arguments);
      };
    let scriptCreated = document.createElement("script");
    scriptCreated.async = true;
    scriptCreated.src =
      "https://conversations-widget.brevo.com/brevo-conversations.js";
    if (document.head) document.head.appendChild(scriptCreated);
    window.BrevoConversationsSetup = {
      mode: "frame",
      injectTo: "conversations-wrapper",
    };
  }, [userToken]);
  return (
    <div>
      ChatSupport
      <div
        id="conversations-wrapper"
        style={{
          width: "100%",
          height: "500px",
        }}
      ></div>
    </div>
  );
};

export default ChatSupport;
