import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import extractDomain from "extract-domain";

const ExtractContacts = (data) => {
  const [socialMediaData, setSocialMediaData] = useState({
    url: null,
    type: "Likers",
  });

  const history = useHistory();

  const allowedDomains = ["linkedin.com"];

  const handleTypeChange = (e) => {
    setSocialMediaData({ ...socialMediaData, type: e.target.value });
  };

  const handleUrlChange = (e) => {
    setSocialMediaData({ ...socialMediaData, url: e.target.value });
  };

  const [socialChecked, setsocialChecked] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(socialMediaData);

    if (!socialMediaData.url) {
      console.log("No Social Media URL");
      setsocialChecked(true);
      return;
    }

    const urlDomain = extractDomain(socialMediaData.url);

    console.log(`urlDomain: ${urlDomain}`);

    if (!allowedDomains.includes(urlDomain)) {
      alert(
        `Only URLs from the following domains are supported at this time: \n\n${allowedDomains}`
      );
      return;
    }

    switch (socialMediaData.type) {
      case "Commenters":
        sendToHistory({
          data: {
            url: socialMediaData.url,
            type: socialMediaData.type,
          },
        });
        break;
      case "Likers":
      default:
        sendToHistory({
          data: {
            url: socialMediaData.url,
            type: socialMediaData.type,
          },
        });
        break;
    }

    function sendToHistory(state = null) {
      if (!state) {
        console.warn("Invalid state");
        return;
      }

      history.push({
        pathname: "/SocialMediaSearch",
        state: {
          searchInput: JSON.stringify({
            url: state.data.url,
            type: state.data.type,
          }),
        },
      });
    }
  };

  const closeModal = () => {
    document.getElementById("searchError").style.display = "none";
    setsocialChecked(false);
  };

  return (
    <div className="user-widget-box p-4 my-3 text-center">
      <h5 className="text-danger">Now Fetch Contacts</h5>
      <p className="text-dark mb-3">
        {/*of Followers, Likers, Commentors & Group Members & Job Seekers From*/}
        of Likers and Commenters From Supported Social Media Posts
      </p>
      <ul className="user-widget-social mt-3 mb-2 list-inline">
        {/*<li className="list-inline-item">*/}
        {/*  <a href="#">*/}
        {/*    <img src="assets/images/social-facebook.png" alt="title" />*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className="list-inline-item">*/}
        {/*  <a href="#">*/}
        {/*    <img src="assets/images/social-instagram.png" alt="title" />*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className="list-inline-item">*/}
        {/*  <a href="#">*/}
        {/*    <img src="assets/images/social-twitter.png" alt="title" />*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li className="list-inline-item">
          <a href="#">
            <img src="/assets/images/social-linkedin.png" alt="title" />
          </a>
        </li>
        {/*<li className="list-inline-item">*/}
        {/*  <a href="#">*/}
        {/*    <img src="assets/images/social-youtube.png" alt="title" />*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className="list-inline-item">*/}
        {/*  <a href="#">*/}
        {/*    <img src="assets/images/social-naukri-com.png" alt="title" />*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
      <form action="#" className="search-form-lg m-auto">
        <div className="input-group">
          <input
            id="social-media-url"
            type="url"
            className="form-control"
            onBlur={handleUrlChange}
            onInput={handleUrlChange}
            placeholder="Paste Social Media URL"
            disabled={data.data}
          />
          {/*<button*/}
          {/*  className="btn btn-danger"*/}
          {/*  // onClick={handleTypeSubmit}*/}
          {/*  type="submit"*/}
          {/*>*/}
          {/*  <img src="assets/images/social-search-past.png" alt="title" />*/}
          {/*</button>*/}
        </div>
      </form>

      <div className="radio-form-check d-block my-3">
        {/*<div className="form-check form-check-inline">*/}
        {/*  <input*/}
        {/*    className="form-check-input"*/}
        {/*    type="radio"*/}
        {/*    onChange={handleType}*/}
        {/*    name="inlineRadioOptions"*/}
        {/*    value="All"*/}
        {/*    id="inlineRadio1"*/}
        {/*  />*/}
        {/*  <label className="form-check-label" for="inlineRadio1">*/}
        {/*    All*/}
        {/*  </label>*/}
        {/*</div>*/}
        {/*<div className="form-check form-check-inline">*/}
        {/*  <input*/}
        {/*    className="form-check-input"*/}
        {/*    type="checkbox"*/}
        {/*    name="inlineRadioOptions"*/}
        {/*    id="inlineRadio2"*/}
        {/*    checked*/}
        {/*  />*/}
        {/*  <label className="form-check-label" for="inlineRadio2">*/}
        {/*    Followers*/}
        {/*  </label>*/}
        {/*</div>*/}
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="Likers"
            checked={socialMediaData.type.includes("Likers")}
            onChange={handleTypeChange}
            disabled={data.data}
          />
          <label className="form-check-label" for="inlineRadio3">
            Likers
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            name="inlineRadioOptions"
            id="inlineRadio4"
            value="Commenters"
            checked={socialMediaData.type.includes("Commenters")}
            onChange={handleTypeChange}
            disabled={data.data}
          />
          <label className="form-check-label" for="inlineRadio4">
            Commenters
          </label>
        </div>
        {/*<div className="form-check form-check-inline">*/}
        {/*  <input*/}
        {/*    className="form-check-input"*/}
        {/*    type="checkbox"*/}
        {/*    name="inlineRadioOptions"*/}
        {/*    id="inlineRadio5"*/}
        {/*  />*/}
        {/*  <label className="form-check-label" for="inlineRadio5">*/}
        {/*    Job Seekers*/}
        {/*  </label>*/}
        {/*</div>*/}
        {/*<div className="form-check form-check-inline">*/}
        {/*  <input*/}
        {/*    className="form-check-input"*/}
        {/*    type="checkbox"*/}
        {/*    name="inlineRadioOptions"*/}
        {/*    id="inlineRadio6"*/}
        {/*  />*/}
        {/*  <label className="form-check-label" for="inlineRadio6">*/}
        {/*    Group Members*/}
        {/*  </label>*/}
        {/*</div>*/}
      </div>

      <button
        className="btn og-red text-white global-hover"
        type="submit"
        onClick={handleSubmit}
        disabled={data.data}
      >
        <span
          className="pe-1 text-center d-flex align-items-center justify-content-between"
          style={{ width: "105%" }}
        >
          <img
            src="/assets/images/new_icons/search.png"
            alt="title"
            className="search-icon"
            style={{ width: "15px", height: "15px" }}
          />
          Search
        </span>{" "}
      </button>
      {socialChecked ? (
        <div
          class="modal"
          style={{ display: "block" }}
          id="searchError"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="d-flex justify-content-center">
                      <img
                        style={{ height: "130px", width: "70px" }}
                        src="assets/images/g10 (2).png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="text-center">
                      <p class="text-danger ">Oops</p>
                      <p>
                        {" "}
                        Looks like you haven't given me a linkedIn URL. Please
                        enter a link and try again
                      </p>
                      <button
                        style={{ background: "#fb3e3e" }}
                        class="btn text-white"
                        onClick={closeModal}
                      >
                        Retry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/*<p className="m-0 mt-2">*/}
      {/*  <a href={data.data ? "" : "/userGuide"} className="learn-link">*/}
      {/*    Learn More{" "}*/}
      {/*  </a>*/}
      {/*</p>*/}
    </div>
  );
};

export default ExtractContacts;
