import React, { useState } from "react";
import DatePicker from "react-datepicker";
import SelectBulkExportTypePopup from "../Utils/SelectBulkExportTypePopup";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { apiServer } from "../../config";

const CreditExport = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [exportType, setExportType] = useState("phone");
  const [exportState, setExportState] = useState("export");
  const { enqueueSnackbar } = useSnackbar();
  const handleStartDate = (e) => {
    setStartDate(e);
    setExportState("export");
    setExportType("phone");
  };
  const handleEndDate = (e) => {
    setEndDate(e);
    setExportState("export");
    setExportType("phone");
  };
  const checkErrors = async (e) => {
    e.preventDefault();

    if (startDate > endDate) {
      setExportType(null);
      console.error(`Error, Status Code: `);
      enqueueSnackbar("From date should be less than To date", {
        variant: "error",
      });
      return;
    }
    setExportType("phone");
  };
  const handleLeadSelectionExportExcel = async (e) => {
    e.preventDefault();
    console.log("in handleLeadSelectionExportExcel");

    await executeExport();
  };

  function handleError(response = null) {
    setExportState("export");
    console.error(`Error, Status Code: ${response?.status}`);
    enqueueSnackbar("Error Exporting File", { variant: "error" });
  }

  function handleNoRecordFound(response = null) {
    setExportState("export");
    enqueueSnackbar("No leads found for selected date range", {
      variant: "info",
    });
  }

  async function handleSuccess(response) {
    // Direct Download in case if only one selected lead.
    const data = await response.json();
    if (!data) {
      return handleError();
    }
    // if (!data?.details) {
    //   return handleNoRecordFound();
    // }
    const username = Cookies.get("username");
    enqueueSnackbar(
      <div>
        Hey {username}! We have started working on your file. <br />
        We will take some time to revert back. <br />
        After exporting, please ensure to refresh your credit for seamless
        service. Thank you!
      </div>,
      { variant: "info" }
    );
    let timeoutId = 1;
    const intervalId = setInterval(async () => {
      console.log("Checking For File state Every 3 Seconds");
      let fileName = data.output_filename;
      let url = `${apiServer}/${fileName.replace('"', "").replace('"', "")}`;
      try {
        const fetchPromise = await fetch(url);
        timeoutId++;
        console.log("timeoutid: ", timeoutId);
        switch (fetchPromise.status) {
          case 200:
            setExportState("export");
            if (fileName) {
              window.location.href = url;
            } else {
              console.error("Error in download link");
            }

            if (timeoutId === 120) clearTimeout(timeoutId);

            clearInterval(intervalId);
            break;
          case 400:
            console.log(fetchPromise);
            return;
          case 404:
            if (timeoutId === 120) {
              setExportState("failed");
              clearTimeout(timeoutId);
              clearInterval(intervalId);
              enqueueSnackbar("No leads found for selected date range.");
            }
            break;
          default:
            console.log(fetchPromise);
        }
      } catch (e) {
        console.error("Exception", e);
      }
    }, 12 * 1000);
  }

  function handleUnAuthorized() {
    setExportState("export");
    console.log("User is UnAuthorized");
    enqueueSnackbar("Please Logout and LogIn Again");
  }

  const executeExport = async () => {
    try {
      let start_date = new Date(
        startDate.setHours(0, 0, 0) - startDate.getTimezoneOffset() * 60000
      );
      let end_date = new Date(
        endDate.setHours(23, 59, 59) - endDate.getTimezoneOffset() * 60000
      );
      console.log(
        "startDate: endDate: ",
        new Date(start_date),
        new Date(end_date)
      );
      const inputData = {
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        phone_or_email: exportType,
      };

      const response = await fetch(
        apiServer + "/credit_transaction/export/excel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(inputData),
        }
      );

      setExportState("inProgress");
      switch (response.status) {
        case 200:
          return await handleSuccess(response);
        case 401:
          return handleUnAuthorized();
        default:
          return handleError(response);
      }
    } catch (err) {
      console.error("Error: ", err);
      handleError();
    }
  };

  function setExportStatus(exportState) {
    return exportState === "inProgress" ? "In Progress" : "Failed";
  }

  return (
    <div className="user-widget-box  my-3">
      <h5
        className="m-0"
        style={{
          display: "flex",
          justifyContent: "left",
          marginBottom: "0.5rem",
          marginLeft: "15px",
        }}
        className="d-flex"
      >
        {"    "} Export Credit Transaction
      </h5>
      <div className="d-flex justify-content-between global-shadow align-items-center py-3">
        <div>
          <label className="form-label">From:</label>
          <DatePicker
            selected={startDate}
            onChange={(e) => handleStartDate(e)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
          />
        </div>
        <div>
          <label className="form-label">To:</label>
          <DatePicker
            selected={endDate}
            onChange={(e) => handleEndDate(e)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
          />
        </div>
        {exportType === null ? (
          ""
        ) : (
          <SelectBulkExportTypePopup
            exportType={exportType}
            setExportType={setExportType}
            handleSubmit={handleLeadSelectionExportExcel}
            dataTarget="export-modal"
          />
        )}
        <div>
          <button
            data-toggle="modal"
            data-target="#export-modal"
            className="export-btn"
            data-cy="export-btn"
            disabled={
              startDate === null ||
              endDate === null ||
              exportState === "inProgress" ||
              exportState === "failed"
            }
            onClick={checkErrors}
          >
            {exportState === "export" ? (
              <>
                Export{" "}
                <img
                  className="ps-3"
                  src="/assets/images/export.png"
                  alt=""
                  style={{ width: "30px", height: "15px" }}
                />{" "}
              </>
            ) : (
              setExportStatus(exportState)
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditExport;
