import React, { useEffect, useState } from "react";
//REDUX
import {
  selectApartmentDetail,
  selectCurrentSavingDetail,
  selectPincodeDetail,
} from "../../../_reducer/areaDetail";
import { useSelector } from "react-redux";

import "./DisplaySearchArea.css";

function DisplaySearchArea({ setCategory }) {
  const [showDisplayArea, setDisplayArea] = useState(false);
  const apartmentDetail = useSelector(selectApartmentDetail);
  const currentSavingDetail = useSelector(selectCurrentSavingDetail);
  const pincodeDetail = useSelector(selectPincodeDetail);

  useEffect(() => {
    console.log("enter");
    setCategory(null);
    if (
      pincodeDetail?.sum_json &&
      apartmentDetail?.salary_max &&
      currentSavingDetail?.response_store
    )
      setDisplayArea(true);
    else setDisplayArea(false);
  }, [apartmentDetail, currentSavingDetail, pincodeDetail]);

  return (
    <div className="area-drop-down">
      <button
        className="area-drop-down__btn"
        data-cy="search-area-btn"
        onClick={() => setDisplayArea(!showDisplayArea)}
      >
        <span>Details Of Searched Area</span>
        <img
          className="dropdown_d"
          src="/assets/images/leadzen/chevron-down.png"
          alt="chevron-down"
        />
      </button>

      {showDisplayArea && (
        <>
          <div className="area-drop-down__child shadow">
            <div className="area-drop-down__sub-child">
              <h4 className="bold-text">Area Details</h4>
              <hr className="detail-divider" />
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Nearby Pincodes
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.pincode
                    .replaceAll("{", "")
                    .replaceAll("}", "")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Major States
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.stateme
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replaceAll("'", "")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Major District
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.districtme
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replaceAll("'", "")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Major Regions
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.regionme
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replaceAll("'", "")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Prime Cities
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.districtme
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replaceAll("'", "")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Monthly Salary (Low)
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {apartmentDetail?.salary_min &&
                    Math.ceil(apartmentDetail?.salary_min).toLocaleString("en")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Monthly Salary (High)
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {apartmentDetail?.salary_max &&
                    Math.ceil(apartmentDetail?.salary_max).toLocaleString("en")}
                </li>
              </ul>
            </div>
          </div>

          <div
            className="area-drop-down__child shadow active"
            id="apartment-drop-down"
          >
            {apartmentDetail?.price_min &&
            apartmentDetail?.price_max &&
            apartmentDetail?.price_avg &&
            apartmentDetail?.price_sqft_min &&
            apartmentDetail?.price_sqft_max &&
            apartmentDetail?.price_sqft_avg &&
            apartmentDetail?.area_sqft_max &&
            apartmentDetail?.area_sqft_min &&
            apartmentDetail?.rent_min &&
            apartmentDetail?.rent_max ? (
              <div className="area-drop-down__sub-child">
                <h4 className="bold-text">Area Real Estate</h4>
                <hr className="detail-divider" />
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Buy Price (Low)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.price_min &&
                      Math.ceil(apartmentDetail?.price_min).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Buy Price (High)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.price_max &&
                      Math.ceil(apartmentDetail?.price_max).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Buy Price (Average)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.price_avg &&
                      Math.ceil(apartmentDetail?.price_avg).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Buy Price Per Sq.ft (Low)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.price_sqft_min &&
                      Math.ceil(apartmentDetail?.price_sqft_min).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Buy Price Per Sq.ft (Low)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.price_sqft_max &&
                      Math.ceil(apartmentDetail?.price_sqft_max).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Buy Price Per Sq.ft (Average)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.price_sqft_avg &&
                      Math.ceil(apartmentDetail?.price_sqft_avg).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Flat Size(sq.ft) (Low)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.area_sqft_min &&
                      Math.ceil(apartmentDetail?.area_sqft_min).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Flat Size(sq.ft) (High)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.area_sqft_max &&
                      Math.ceil(apartmentDetail?.area_sqft_max).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Flat Size(sq.ft) (Average)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.area_sqft_min &&
                      Math.ceil(apartmentDetail?.area_sqft_min).toLocaleString(
                        "en"
                      )}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Monthly Rental (Low)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.rent_min &&
                      Math.ceil(apartmentDetail?.rent_min).toLocaleString("en")}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Monthly Rental (High)
                  </li>
                  <li className="area-drop-down__li area-drop-down--value">
                    {apartmentDetail?.rent_max &&
                      Math.ceil(apartmentDetail?.rent_max).toLocaleString("en")}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="area-drop-down__child shadow active"
            id="real-estate-drop-down"
          >
            <div className="area-drop-down__sub-child">
              <h4 className="bold-text">Area Demographics</h4>
              <hr className="detail-divider" />
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Total Population
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.population &&
                    (pincodeDetail?.sum_json?.population).toLocaleString("en")}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Male Population
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.male_population &&
                    (pincodeDetail?.sum_json?.male_population).toLocaleString(
                      "en"
                    )}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Male Percentage
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.male_population_percentage &&
                    parseFloat(
                      pincodeDetail?.sum_json?.male_population_percentage
                    ).toFixed(2)}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Female Population
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.female_population &&
                    (pincodeDetail?.sum_json?.female_population).toLocaleString(
                      "en"
                    )}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Female Percentage
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.female_population_percentage &&
                    parseFloat(
                      pincodeDetail?.sum_json?.female_population_percentage
                    ).toFixed(2)}
                </li>
              </ul>
              <ul className="area-drop-down__ul">
                <li className="area-drop-down__li area-drop-down--key">
                  Population Density
                </li>
                <li className="area-drop-down__li area-drop-down--value">
                  {pincodeDetail?.sum_json?.population_density &&
                    parseFloat(
                      pincodeDetail?.sum_json?.population_density
                    ).toFixed(2)}
                </li>
              </ul>
            </div>
          </div>

          <div id="current-savings">
            <div className="area-drop-down__child shadow active">
              <div
                className="area-drop-down__sub-child"
                id="current-savings-child"
              >
                {currentSavingDetail?.response_store && (
                  <p className="bold-text">Deposits (RBI)</p>
                )}
                <hr className="detail-divider" />
                <ul className="area-drop-down__ul">
                  <li
                    className="area-drop-down__li area-drop-down--key bold-text"
                    style={{ minWidth: "max-content" }}
                  >
                    District:{" "}
                    {currentSavingDetail?.response_store &&
                      currentSavingDetail?.response_store[0].district}
                  </li>
                  <li className="area-drop-down__li area-drop-down--value bold-text">
                    {currentSavingDetail?.response_store &&
                      currentSavingDetail?.response_store[0].pincode}
                  </li>
                </ul>
                <hr className="detail-divider" />
                {!currentSavingDetail?.response_store && (
                  <p id="time-period" className="bold-text">
                    Time Period
                  </p>
                )}
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key bold-text">
                    2021-22
                  </li>
                  <li className="area-drop-down__li area-drop-down--value bold-text">
                    Q3
                  </li>
                  <li className="area-drop-down__li area-drop-down--value bold-text">
                    Q2
                  </li>
                </ul>
                <hr className="detail-divider" />
                <ul className="area-drop-down__ul" id="current-details">
                  <li className="area-drop-down__li area-drop-down--key">
                    Current
                  </li>
                  {currentSavingDetail?.response_store && (
                    <li
                      className="area-drop-down__li area-drop-down--key"
                      style={{ minWidth: "max-content" }}
                    >
                      ({" "}
                      {(
                        (currentSavingDetail?.response_store[0].response[0]
                          .current /
                          currentSavingDetail?.response_store[0].response[1]
                            .current -
                          1) *
                        100
                      ).toFixed(2)}{" "}
                      %){" "}
                      {
                        currentSavingDetail?.response_store[0].response[0]
                          .current
                      }
                    </li>
                  )}
                  <li
                    className="area-drop-down__li area-drop-down--key"
                    style={{ textAlign: "center" }}
                  >
                    {currentSavingDetail?.response_store &&
                      currentSavingDetail?.response_store[0].response[1]
                        .current}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Savings
                  </li>
                  {currentSavingDetail?.response_store && (
                    <li
                      className="area-drop-down__li area-drop-down--key"
                      style={{ minWidth: "max-content" }}
                    >
                      ({" "}
                      {(
                        (currentSavingDetail?.response_store[0].response[0]
                          .savings /
                          currentSavingDetail?.response_store[0].response[1]
                            .savings -
                          1) *
                        100
                      ).toFixed(2)}{" "}
                      %){" "}
                      {
                        currentSavingDetail?.response_store[0].response[0]
                          .savings
                      }
                    </li>
                  )}
                  <li
                    className="area-drop-down__li area-drop-down--key"
                    style={{ textAlign: "center" }}
                  >
                    {currentSavingDetail?.response_store &&
                      currentSavingDetail?.response_store[0].response[1]
                        .savings}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Terms
                  </li>
                  {currentSavingDetail?.response_store && (
                    <li
                      className="area-drop-down__li area-drop-down--key"
                      style={{ minWidth: "max-content" }}
                    >
                      ({" "}
                      {(
                        (currentSavingDetail?.response_store[0].response[0]
                          .term /
                          currentSavingDetail?.response_store[0].response[1]
                            .term -
                          1) *
                        100
                      ).toFixed(2)}{" "}
                      %){" "}
                      {currentSavingDetail?.response_store[0].response[0].term}
                    </li>
                  )}
                  <li
                    className="area-drop-down__li area-drop-down--key"
                    style={{ textAlign: "center" }}
                  >
                    {currentSavingDetail?.response_store &&
                      currentSavingDetail?.response_store[0].response[1].term}
                  </li>
                </ul>
                <ul className="area-drop-down__ul">
                  <li className="area-drop-down__li area-drop-down--key">
                    Total
                  </li>
                  {currentSavingDetail?.response_store && (
                    <li
                      className="area-drop-down__li area-drop-down--key"
                      style={{ minWidth: "max-content" }}
                    >
                      ({" "}
                      {(
                        (currentSavingDetail?.response_store[0].response[0]
                          .total /
                          currentSavingDetail?.response_store[0].response[1]
                            .total -
                          1) *
                        100
                      ).toFixed(2)}{" "}
                      %){" "}
                      {currentSavingDetail?.response_store[0].response[0].total}
                    </li>
                  )}
                  <li
                    className="area-drop-down__li area-drop-down--key"
                    style={{ textAlign: "center" }}
                  >
                    {currentSavingDetail?.response_store &&
                      currentSavingDetail?.response_store[0].response[1].total}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DisplaySearchArea;
