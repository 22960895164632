import RealtimeItems from "./RealtimeItems";

const RealTimeItemsList = ({
  companyPageRecord,
  sheetName,
  index,
  currentPage,
  jobUrlList,
  setJobUrlList,
  pathName,
  realtimeSelectedLeads,
  setRealtimeSelectedLeads,
  customCount,
  setCustomCount,
}) => {
  return (
    <>
      <RealtimeItems
        companyPageRecord={companyPageRecord}
        sheetName={sheetName}
        index={index}
        currentPage={currentPage}
        jobUrlList={jobUrlList}
        setJobUrlList={setJobUrlList}
        pathName={pathName}
        realtimeSelectedLeads={realtimeSelectedLeads}
        setRealtimeSelectedLeads={setRealtimeSelectedLeads}
        customCount={customCount}
        setCustomCount={setCustomCount}
      />
    </>
  );
};
export default RealTimeItemsList;
