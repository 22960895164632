import React from "react";

export const MapLongitudeLatitude = (props) => {
  return (
    <div className="result-container__result-info">
      <span>
        {" "}
        {["[" + props.data?.latitude + ", " + props.data?.longitude + "]"]}{" "}
      </span>
    </div>
  );
};

export default MapLongitudeLatitude;
