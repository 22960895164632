import React, { useEffect } from "react";
import CommonBasicFilter from "../CommonBasicFilter";

const FilterListedCompanies = ({ filterRequest, setFilterRequest }) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);
  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company"
          label="Company Name"
          icon="/assets/images/new_icons/company_name.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="industry"
          label="Industry"
          icon="/assets/images/new_icons/Industry.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_city"
          label="City"
          icon="/assets/images/new_icons/Location.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_state"
          label="State"
          icon="/assets/images/new_icons/Location.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keyword"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};

export default FilterListedCompanies;
