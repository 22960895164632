const BulkModal = (errorMessage) => {
  console.log(errorMessage.errorMessage, "IN BULK");

  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      <div
        className="modal"
        style={{ backdropFilter: " blur(8px)", display: "block" }}
        id="searchError"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row d-flex align-items-center">
                <div class="col-4">
                  <div class="d-flex justify-content-center">
                    <img
                      src="/assets/images/g10 (2).png"
                      alt="#"
                      className="popup-img"
                    />
                  </div>
                </div>
                <div class="col-7">
                  <div class="text-center">
                    <p class="text-danger ">
                      <strong>Oops!</strong>
                    </p>
                    <p>{errorMessage.errorMessage}</p>

                    <button
                      onClick={refreshPage}
                      style={{ background: "#fb3e3e", border: "#fb3e3e" }}
                      className="btn btn-primary global-hover"
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkModal;
