import React, { useState } from "react";
import Fuse from "fuse.js";
import { useSnackbar } from "notistack";

const CommonBasicFilter = ({
  filterRequest,
  setFilterRequest,
  itemClass,
  label,
  icon,
}) => {
  const [searchEntryValue, setSearchEntryValue] = useState("");
  const disallowedSpecialChars = /[`!@#$%^&*()_+=[\]{};':"\\|,<>/?~]/;
  const { enqueueSnackbar } = useSnackbar();

  const knownSearchEntryNames = [];
  const fuseEntries = new Fuse(knownSearchEntryNames, {
    threshold: 0.5,
    keys: ["name"],
    includeScore: true,
  });

  const filteredFuseEntries = fuseEntries.search(searchEntryValue);
  const filteredSearchEntryNames = filteredFuseEntries?.map(
    (result) => result.item
  );

  const handleSearchEntryValueChanged = (e) => {
    let searchEntry = e.target.value;

    if (!searchEntry) {
      console.warn(`Invalid Search Entry. searchEntry: ${searchEntry}`);
      setSearchEntryValue("");
      return;
    }

    if (disallowedSpecialChars.test(searchEntry)) {
      console.warn("Search Entry Contains Special Chars");
      enqueueSnackbar("Special Chars are not allowed", {
        variant: "warning",
      });
      return;
    }

    setSearchEntryValue(searchEntry);
  };

  const handleSearchEntryValueUpdated = (e) => {
    const newQueryValue = e.target.value.toLowerCase();
    setSearchEntryValue("");
    if (newQueryValue[0] === "-") {
      console.warn("Invalid Search Entry");
      enqueueSnackbar("Invalid Search Entry", {
        variant: "warning",
      });
      return;
    }
    if (newQueryValue.length < 3) {
      console.warn("Search entry should contain at least 3 characters");
      enqueueSnackbar("Search entry should contain at least 3 characters", {
        variant: "warning",
      });
      return;
    }

    if (!filterRequest.hasOwnProperty(itemClass)) {
      filterRequest[itemClass] = [];
    }

    if (filterRequest[itemClass].indexOf(newQueryValue) < 0) {
      filterRequest[itemClass].push(newQueryValue);
    }

    setFilterRequest({ ...filterRequest });
  };

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed realtime__accordion-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${itemClass}`}
          >
            <img src={icon} alt="#" className="realtime__img" />
            <span className="px-2">{label}</span>
          </button>
        </h2>
        <div
          id={itemClass}
          className="collapse"
          data-bs-parent={"#" + itemClass}
        >
          <div className="accordion-body">
            <input
              id={`filter-search-input-${itemClass}`}
              className="customize-search w-100"
              name={`filter-search-input-name-${itemClass}`}
              value={searchEntryValue}
              onChange={handleSearchEntryValueChanged}
              type="text"
              data-cy={itemClass}
              minLength={3}
            />
          </div>
          <div
            className={
              searchEntryValue ? "industry-display show" : "industry-display"
            }
            data-cy={`cypress-a-${itemClass}`}
          >
            {knownSearchEntryNames && (
              <button
                className="industry-btn"
                value={searchEntryValue}
                style={{ cursor: "pointer" }}
                onClick={handleSearchEntryValueUpdated}
              >
                - {searchEntryValue}
              </button>
            )}
            {filteredSearchEntryNames?.map((item, index) => (
              <button
                key={`potential-matches-${index}-${itemClass}`}
                className="industry-btn"
                value={item.toString()}
                style={{ cursor: "pointer" }}
                onClick={handleSearchEntryValueUpdated}
              >
                {index + 1}. {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonBasicFilter;
