import React from "react";
import "./Style/style.css";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import { Link } from "react-router-dom";
import CompanyFilters from "../CompanySharedComponent/CompanyFilters";
import BulkSearch from "../SharedComponent/BulkSearch";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import Pagination from "../SharedComponent/Pagination";
import SpecificCompany from "./SpecificCompany";
import { bangloreCompanies, financeCompanies } from "../../DemoData";

const CompanyDemo = ({
  customSearch,
  today,
  currentPage,
  handleProfile,
  isOpen,
  viewHandleLoading,
  companyInfo,
  paginate,
  active,
}) => {
  let arr;

  if (active === "bangloreCompanies") {
    arr = bangloreCompanies;
  } else if (active === "financeCompanies") {
    arr = financeCompanies;
  }

  return (
    <div>
      <div>
        <div className="modal" id="UpgradeModal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="d-flex">
                <div className="pe-4">
                  <img
                    style={{ height: "125px", width: "100px" }}
                    src={"/assets/images/g10.png"}
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <p className="text-danger ">Oops</p>
                  <p>
                    looks like you have insufficient credit to access this
                    leads. upgrade your plan now.
                  </p>
                  <button
                    style={{ background: "#FB3E3E" }}
                    className="btn text-white"
                  >
                    {" "}
                    Upgrade Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="bulkmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-message">
              <p>
                <i className="text-danger">Format to follow:</i>
                Ensure that the first column has the unique values you’re
                searching for. Download the sample below for better
                understanding.
              </p>
              <Link>
                <i className="text-danger text-decoration-underline">
                  Click here to download csv format
                </i>
              </Link>
            </div>
            <div className="modal-content">
              <form action="/upload" id="mydrop" className="dropzone">
                <div className="dz-message needsclick">
                  <button type="button" className="dz-button">
                    Drag and Drop File
                  </button>
                  <br />
                  <button type="button" className="dz-button">
                    OR
                  </button>
                  <br />
                  <span className="note needsclick">
                    <input type="file" accept=".csv" />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="main-content-area pb-6 pt-2">
          <div className="main-wrapper container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <SpecificSearchBtn details={false} />
                <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                  <h6 className="text-danger mb-3">Customize your search</h6>
                  <CompanyFilters customSearch={customSearch} />
                </div>
                <BulkSearch data={true} />
                <SidebarExtractContact data={true} />
              </div>
              <div className="col-md-8 col-lg-9">
                <div className="user-search-wrapper mt-4">
                  <div
                    className="detailed-search"
                    style={{ paddingLeft: "40px" }}
                  >
                    <div>
                      <small>Last Updated: {today}</small>
                    </div>
                  </div>
                </div>
                <div className="user-widget-box" style={{ marginTop: "2.4em" }}>
                  <div className="d-flex align-items-center justify-content-between py-3">
                    <div className="d-flex align-items-center ">
                      <small className="mx-3">
                        <b>10</b> of <b>10</b> Searched profiles
                      </small>
                    </div>
                    <div className="d-flex">
                      <button className="export-btn" disabled={true}>
                        Export{" "}
                        <img
                          className="ps-3"
                          src="/assets/images/export.png"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="user-widget-box  my-3">
                  <div className="search-container mb-2">
                    {arr.map((data, index) => (
                      <div key={index}>
                        <div className="user-container py-2">
                          <div className="search-author text-danger d-flex align-items-center">
                            <img
                              onError={(e) => {
                                e.currentTarget.src =
                                  "/assets/images/Group 2367.png";
                              }}
                              style={{ borderRadius: "50%" }}
                              src={
                                data.logoUrl
                                  ? data.logoUrl
                                  : "/assets/images/Group 2367.png"
                              }
                              alt=""
                            />
                          </div>
                          <div className="search-user">
                            <div className="row">
                              <div className="col d-flex align-items-center">
                                <div>
                                  <p
                                    className="d-block"
                                    style={{ border: "2px solid cornflower" }}
                                  >
                                    {data.name}{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="col d-flex align-items-center">
                                <small>{data.description}</small>
                              </div>
                            </div>
                          </div>

                          <div className="search-view-btn d-flex align-items-center">
                            <a
                              key={index}
                              className="btn button"
                              data-toggle="collapse"
                              href={`#collapseExample_${currentPage}${index}`}
                              data-target={`#collapseExample_${currentPage}${index}`}
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              onClick={() => handleProfile(index, data)}
                            >
                              View Details
                            </a>
                          </div>
                        </div>
                        <div
                          style={{
                            background: "white",
                            borderRadius: "20px",
                            padding: "20px",
                          }}
                        >
                          <div
                            className="panel-collapse collapse in"
                            id={`collapseExample_${currentPage}${index}`}
                          >
                            {isOpen === `${currentPage}${index}` ? (
                              <span>
                                {!viewHandleLoading ? (
                                  <span>
                                    <SpecificCompany data={companyInfo} />
                                  </span>
                                ) : (
                                  <section
                                    className="item-section"
                                    style={{ textAlign: "center" }}
                                  >
                                    Please Wait..
                                  </section>
                                )}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  {
                    <Pagination
                      postsPerPage={10}
                      totalPosts={10}
                      paginate={paginate}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDemo;
