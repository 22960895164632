import React, { useEffect } from "react";
import CommonBasicFilter from "../CommonBasicFilter";
import CommonSelectBoxItem from "../CommonSelectBoxItem";

const FilterFinancialIntermediaries = ({ filterRequest, setFilterRequest }) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);
  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="person_name"
          label="Person Name"
          icon="/assets/images/new_icons/Person.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_name"
          label="Company Name"
          icon="/assets/images/new_icons/company_name.png"
        />
        {/*<CommonBasicFilter*/}
        {/*  setFilterRequest={setFilterRequest}*/}
        {/*  filterRequest={filterRequest}*/}
        {/*  itemClass="category"*/}
        {/*  label="Category"*/}
        {/*  icon="/assets/images/new_icons/Industry.png"*/}
        {/*/>*/}

        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="category"
          label="Category"
          tooltipText={"Select Category"}
          icon="/assets/images/new_icons/Industry.png"
          collection={[
            "Chartered Accountant",
            "Company Secretary",
            "Wealth Manager",
            "Stock Broker",
            "Sub Broker",
            "Independent Advisor",
            "Relationship Manager",
          ]}
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="location"
          label="Location"
          icon="/assets/images/new_icons/Location.png"
        />

        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="no_of_employees"
          label="Employee Size"
          tooltipText={"Select Employee Size"}
          icon="/assets/images/new_icons/Employee Count.png"
          collection={[
            "1-10",
            "11-50",
            "51-200",
            "201-500",
            "501-1000",
            "1001-5000",
            "5001-10000",
            "10001+",
          ]}
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keywords"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};

export default FilterFinancialIntermediaries;
