import React, { useEffect, useState } from "react";
import "./Styles/SavedList.css";
import Profile from "../SearchResult/Optimized/Profile";
import Cookies from "js-cookie";
import UnlockProfileEmail from "../SearchResult/Optimized/UnlockProfileEmail";
import SearchSpecificUser from "../DetailedInfo/SearchSpecificUser";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Pagination from "../Utils/Pagination";
import Emitter from "../Utils/emitter";
import { useSnackbar } from "notistack";
import { apiServer } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { selectViewDetail, saveDetailedLeadsId } from "../../_reducer/shared";
import { httpService } from "../Geolocator/geolocatorHttpServices/geolocatorHttpServices";
import { mapMetaData } from "../mapResultFromHistory/api";
import ViewDetails from "../Geolocator/viewDetails/ViewDetails";

import { Spinner } from "../Utils/utils";
import {
  fetchSavedListItems,
  removeItemFromList,
  searchSavedListItems,
} from "./SavedListQueries";
import MapName from "../mapResultFromHistory/MapName";
import MapLongitudeLatitude from "../mapResultFromHistory/MapLongitudeLatitude";
import MapCategory from "../mapResultFromHistory/MapCategory";
import MapViewProfile from "../mapResultFromHistory/MapViewProfile";
import MapSingleItem from "../mapResultFromHistory/MapSingleItem";

const GeolocatorSavedListView = ({ listName, state, dispatch }) => {
  const [savedLeads, setSavedLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    searchText,
    page,
    view,
    searchCache,
    totalSearchRecords,
    totalGeolocatorRecords,
    geolocatorResultsCache,
    itemsPerPage,
  } = state;

  console.log("savedLeads", savedLeads);

  const itemType = "geolocator";

  const apiHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  };
  const onViewProfileOrWorkEmail = async () => {
    console.log("before onviewprofileorworkemail metadata");
    const data = await mapMetaData(savedLeads);
    setSavedLeads(data);
    Emitter.emit("updateCredits", true); // Get user details
    Emitter.emit("fetchCredits", true); // Update credits
  };

  useEffect(() => {
    if (view === "search") {
      console.log("view is search");
      searchSavedLeads(false);
    } else {
      console.log("view is normal");
      fetchSavedLeads(false);
    }
  }, [page, view]);

  const fetchSavedLeads = async (forceFetch) => {
    try {
      if (!forceFetch && geolocatorResultsCache[page]) {
        setSavedLeads(geolocatorResultsCache[page]);
        const data = await mapMetaData(geolocatorResultsCache[page]);
        setSavedLeads(data);
      } else {
        setLoading(true);
        const pageNo = forceFetch ? 0 : page;
        const data = await fetchSavedListItems(pageNo, itemType, listName);
        const leads = await mapMetaData(data?.["save_list_items"]);
        console.log("leads", leads);
        setSavedLeads(leads);
        dispatch({
          type: "SET_TOTAL_GEOLOCATOR_RECORDS",
          payload: data?.["total_item_count"],
        });
        dispatch({
          type: "SET_GEOLOCATOR_RESULTS_CACHE",
          payload: {
            ...geolocatorResultsCache,
            [page]: data?.["save_list_items"],
          },
        });
      }
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("There was an error fetching data...", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
      console.log(err);
    }
  };

  const searchSavedLeads = async (forceFetch) => {
    try {
      if (!forceFetch && searchCache[page]) {
        setSavedLeads(searchCache[page]);
        const data = await mapMetaData(searchCache[page]);
        setSavedLeads(data);
      } else {
        setLoading(true);
        const pageNo = forceFetch ? 0 : page;
        const data = await searchSavedListItems(
          pageNo,
          itemType,
          listName,
          searchText
        );
        const leads = await mapMetaData(data?.["save_list_items"]);
        setSavedLeads(leads);
        dispatch({
          type: "SET_TOTAL_SEARCH_RECORDS",
          payload: data?.["total_item_count"],
        });
        dispatch({
          type: "SET_SEARCH_CACHE",
          payload: { ...searchCache, [page]: data?.["save_list_items"] },
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      enqueueSnackbar("There was an error fetching the data...", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const data = await removeItemFromList(listName, itemId);
      if (data?.["message"] === "success") {
        dispatch({ type: "SET_PAGE", payload: 0 });
        dispatch({ type: "SET_SEARCH_CACHE", payload: {} });
        dispatch({ type: "SET_GEOLOCATOR_RESULTS_CACHE", payload: {} });
        enqueueSnackbar("Item removed..", {
          variant: "success",
          autoHideDuration: 2000,
        });
        if (view === "search") {
          console.log("view is search");
          searchSavedLeads(true);
        } else {
          console.log("view is normal");
          fetchSavedLeads(true);
        }
      } else {
        enqueueSnackbar("Error removing item..", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Error removing item..", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <div
        className="lead-accordion accordion-body global-width"
        style={{ marginLeft: "5%" }}
        id="accordionExample2"
      >
        <h5 className="text-start mt-2">{`No. of records : ${
          view === "search" ? totalSearchRecords : totalGeolocatorRecords
        }`}</h5>
        <div className="accordion-item mb-3 ">
          <div
            id="collapseOne"
            className="accordion-collapse show collapse"
            data-bs-parent="#accordionExample2"
          >
            <div style={{ padding: "0rem" }} className="accordion-body">
              {loading ? (
                <Spinner />
              ) : savedLeads.length > 0 ? (
                savedLeads.map((data, index) => {
                  return (
                    <GeolocatorItem
                      key={index}
                      index={index}
                      data={data}
                      page={page}
                      handleDelete={handleDelete}
                      onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                    />
                  );
                })
              ) : (
                <h5 className="save_list_notFound">NO RESULTS FOUND</h5>
              )}
              <Pagination
                onPageChange={({ selected }) =>
                  dispatch({ type: "SET_PAGE", payload: selected })
                }
                pageNumber={page}
                pageCount={Math.ceil(
                  (view === "search"
                    ? totalSearchRecords
                    : totalGeolocatorRecords) / itemsPerPage
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const GeolocatorItem = ({
  index,
  data,
  page,
  handleDelete,
  onViewProfileOrWorkEmail,
}) => {
  const [viewDetailsResult, setViewDetailResult] = useState({});
  const [detailsLoader, setDetailsLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const geolocatorDispatch = useDispatch();

  const fetchProfileDetail = (simpleSearchId, detailedId) => {
    setDetailsLoader(true);
    if (simpleSearchId) {
      httpService
        .getDetailedRecord(detailedId, simpleSearchId, "")
        .then(async (response) => {
          let { id } = response;
          if (id) {
            geolocatorDispatch(saveDetailedLeadsId(id));
            const intervalId = setInterval(async () => {
              httpService.getDetailedLeads(id).then(async (data) => {
                const { search_result, status } = data;
                if (status === "success") {
                  clearInterval(intervalId);
                  setViewDetailResult(search_result);
                  setDetailsLoader(false);
                  onViewProfileOrWorkEmail();
                } else if (status === "running") {
                  console.log("Case: running ");
                } else {
                  clearInterval(intervalId);
                  setViewDetailResult({});
                  setDetailsLoader(false);
                }
              });
            }, 5 * 1000);
          } else {
            if (response.status === 402) {
              enqueueSnackbar(
                "You have insufficient credits. Buy Credits to get details.",
                { variant: "warning" }
              );
            } else {
              console.log(response);
            }
            setDetailsLoader(false);
            setIsOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div key={index}>
      <div
        className="container-style mt-3 global-shadow"
        style={{ padding: "10px 10px", width: "95%" }}
      >
        <div
          className="save-list-names-container"
          style={{ minHeight: "80px" }}
        >
          <MapName name={data["saved_item"]["name"]} />
          <MapLongitudeLatitude
            data={{
              latitude: data["saved_item"]["latitude"],
              longitude: data["saved_item"]["longitude"],
            }}
          />
          <MapSingleItem item={data["saved_item"].city} />
          <MapSingleItem item={data["saved_item"].pincode} />
          <MapCategory category={data["saved_item"]["category"]} />
          <MapViewProfile
            data={data["saved_item"]}
            setIsOpen={setIsOpen}
            activePage={page}
            i={index}
            isOpen={isOpen}
            fetchProfileDetail={fetchProfileDetail}
            simpleSearchId={data["saved_item"]["simpleSearchId"]}
            detailedId={data["saved_item"]["id"]}
            // viewDetailsResult={viewDetailsResult}
          />
          <RemoveItem data={data} handleDelete={handleDelete} />
        </div>
        {isOpen === data.saved_item?.id ? (
          <div
            style={{
              background: "white",
              borderRadius: "20px",
              padding: "20px",
              marginTop: "-12px",
            }}
          >
            <ViewDetails
              leadsId={data.saved_item?.id}
              simpleSearchId={data.saved_item?.simpleSearchId}
              onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
              searchTerm=""
              viewDetailsResult={viewDetailsResult}
              loader={detailsLoader}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const RemoveItem = ({ data, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ marginRight: "1.5rem" }}>
      {loading ? (
        <Spinner />
      ) : (
        <IconButton
          onClick={async () => {
            setLoading(true);
            await handleDelete(data["id_"]);
            setLoading(false);
          }}
          data-cy="save-list-remove-btn"
        >
          <CloseIcon color="primary" />
        </IconButton>
      )}
    </div>
  );
};

export default GeolocatorSavedListView;
