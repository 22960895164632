import LinkedInImage from "./LinkedInImage";
import React from "react";
import { fundingAmountFormatter, ValidateLinkedinUrl } from "../../Utils/utils";
import dayjs from "dayjs";

const RecentlyFundedCompanies = ({ companyPageRecord }) => {
  return (
    <>
      {/*<div className="realtime-result">*/}
      <div className="ml-1 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord?.company_that_got_funded === "object" &&
          companyPageRecord?.company_that_got_funded.length > 0
            ? companyPageRecord?.company_that_got_funded[0]
            : companyPageRecord?.company_that_got_funded
            ? companyPageRecord?.company_that_got_funded
            : "-"}
        </h6>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.linkedin_of_company_that_got_funded ===
            "object" &&
            companyPageRecord?.linkedin_of_company_that_got_funded.length > 0
            ? companyPageRecord?.linkedin_of_company_that_got_funded[0]
            : companyPageRecord?.linkedin_of_company_that_got_funded
            ? companyPageRecord?.linkedin_of_company_that_got_funded
            : null
        )}
      />

      <div className="realtime-result__child">
        <>
          Funding Amount
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.funding_amount === "object" &&
            companyPageRecord?.funding_amount[0].length > 0
              ? companyPageRecord?.funding_amount[0] < 0
                ? "Undisclosed"
                : companyPageRecord?.funding_amount[0]
              : companyPageRecord?.funding_amount
              ? companyPageRecord?.funding_amount < 0
                ? "Undisclosed"
                : fundingAmountFormatter(companyPageRecord?.funding_amount)
              : "Undisclosed"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Funded By
          <br />
          <span className="text-red">
            {typeof companyPageRecord.company_that_funded === "object" &&
            companyPageRecord.company_that_funded[0].length > 0
              ? companyPageRecord.company_that_funded[0]
              : companyPageRecord.company_that_funded
              ? companyPageRecord.company_that_funded
              : "-"}
          </span>
        </>
      </div>
      <div
        className="realtime-result__child"
        style={{ textTransform: "capitalize" }}
      >
        Date Of Funding <br /> {/*{companyPageRecord?.Date_of_funding ? (*/}
        <>
          <span className="text-danger">
            {typeof companyPageRecord?.date_of_funding === "object" &&
            companyPageRecord?.date_of_funding[0].length > 0
              ? dayjs(companyPageRecord?.date_of_funding[0]).format(
                  "DD MMM YYYY"
                )
              : "-"}{" "}
          </span>
        </>
        {/*) : null}*/}
      </div>
      <div className="realtime-result__child">
        Funded Company Contact
        <>
          <br />
          <span className="text-red" style={{ fontWeight: "normal" }}>
            {typeof companyPageRecord?.contact_name_of_the_company_that_got_funded ===
              "object" &&
            companyPageRecord?.contact_name_of_the_company_that_got_funded[0]
              .length > 0
              ? companyPageRecord
                  ?.contact_name_of_the_company_that_got_funded[0]
              : companyPageRecord?.contact_name_of_the_company_that_got_funded
              ? companyPageRecord?.contact_name_of_the_company_that_got_funded
              : "-"}
          </span>
        </>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.contact_linkedin_of_the_company_that_got_funded ===
            "object" &&
            companyPageRecord?.contact_linkedin_of_the_company_that_got_funded
              .length > 0
            ? companyPageRecord
                ?.contact_linkedin_of_the_company_that_got_funded[0]
            : companyPageRecord?.contact_linkedin_of_the_company_that_got_funded
            ? companyPageRecord?.contact_linkedin_of_the_company_that_got_funded
            : null
        )}
      />
    </>
  );
};
export default RecentlyFundedCompanies;
