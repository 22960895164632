import React, { useState, useRef, useEffect } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
//HTTP-SERVICE
import { httpService } from "../geolocatorHttpServices/geolocatorHttpServices";
// RXJS
import { forkJoin } from "rxjs";
//REDUX
import {
  savePincodeDetail,
  saveApartmentDetail,
  saveCurrentSavingDetail,
  saveMapBoxAutocomplete,
  selectMapBoxAutocomplete,
} from "../../../_reducer/areaDetail";
import {
  saveCategorySelected,
  saveCategorySelectedArray,
  saveClearSearchResult,
} from "../../../_reducer/shared";
import { useDispatch, useSelector } from "react-redux";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./MapboxAutocomplete.css";
import { saveLeadsData, saveLeadsForMapMarker } from "../../../_reducer/leads";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibXVzYWItbW9taW4iLCJhIjoiY2t5em01b3gxMHhnaTJucXZ1ZzJxbHlpNiJ9.xrVG6rwZ0kJyT8ciaAMVog";

function MapboxAutocomplete({ setCategory }) {
  let viewport = useRef(false);
  const [result, setResult] = useState({});
  const dispatch = useDispatch();
  const mapBoxAutocomplete = useSelector(selectMapBoxAutocomplete);

  useEffect(() => {
    setResult(mapBoxAutocomplete);
    if (Object.keys(mapBoxAutocomplete).length > 0) {
      let { bbox, place_name } = mapBoxAutocomplete;
      let els = document.getElementsByClassName(
        "mapboxgl-ctrl-geocoder--input"
      )[0];
      els.value = place_name;
      // Disabled : Api not in use 
      // forkJoin([
      //   httpService.getPincodearea(bbox),
      //   httpService.getApartmentdetail(bbox),
      //   httpService.getCurrentsavings(bbox),
      // ]).subscribe((data) => {
      //   dispatch(savePincodeDetail(data[0]));
      //   dispatch(saveApartmentDetail(data[1]));
      //   dispatch(saveCurrentSavingDetail(data[2]));
      // });
      dispatch(saveCategorySelected(""));
      dispatch(saveCategorySelectedArray([]));
      dispatch(saveLeadsData({}));
      dispatch(saveLeadsForMapMarker([]));
    } else {
      // Clear the geocoder input field if mapBoxAutocomplete is empty
      let els = document.getElementsByClassName(
        "mapboxgl-ctrl-geocoder--input"
      )[0];
      if (els) {
        els.value = "";
      }
    }
  }, [result]);

  useEffect(() => {
    if (Object.keys(mapBoxAutocomplete).length === 0) {
      // Clear the geocoder input field if mapBoxAutocomplete is empty
      let els = document.getElementsByClassName(
        "mapboxgl-ctrl-geocoder--input"
      )[0];
      if (els) {
        els.value = "";
      }
    }
  }, [mapBoxAutocomplete]);

  useEffect(() => {
    if (!viewport.current) {
      const geocoder = new MapboxGeocoder({
        accessToken: MAPBOX_TOKEN,
        types: "country,region,place,postcode,locality,neighborhood",
      });
      viewport.current = true;
      geocoder.addTo("#geocoder");

      geocoder.on("result", (e) => {
        setResult(e.result);
        dispatch(saveMapBoxAutocomplete(e.result));
      });

      // Clear results container when search is cleared.
      geocoder.on("clear", () => {
        setResult({});
        dispatch(saveMapBoxAutocomplete({}));
        dispatch(saveClearSearchResult(true));
        dispatch(saveCategorySelected(""));
        dispatch(saveCategorySelectedArray([]));
        dispatch(saveLeadsData({}));
        dispatch(saveLeadsForMapMarker([]));
        setCategory(null);
      });
    }
  }, []);

  return <div id="geocoder"></div>;
}

export default MapboxAutocomplete;
