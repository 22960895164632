import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useSnackbar } from "notistack";
import { apiPersonalizedUrl } from "../../config";
import Cookies from "js-cookie";
import { AppContext } from "../../Context";

const PersonalizedForm = ({
  title,
  open,
  setMessage,
  onClose,
  endPoint,
  setLoading,
  loginError,
  url,
}) => {
  const [receiverName, setReceiverName] = useState("");
  const [receiverCompany, setReceiverCompany] = useState("");
  const [senderCompany, setSenderCompany] = useState("");
  const [senderDesignation, setSenderDesignation] = useState("");
  const [problem, setProblem] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [solution, setSolution] = useState("");
  const [aboutSendersCompany, setAboutSendersCompany] = useState("");
  const [senderCompanyClients, setSenderCompanyClients] = useState([]);
  const { personalisedDetails, dispatch } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (personalisedDetails) {
      setSenderCompany(personalisedDetails.sendersCompany);
      setSenderDesignation(personalisedDetails.senderDesignation);
      setAboutSendersCompany(personalisedDetails.aboutSendersCompany);
      setSenderCompanyClients(personalisedDetails.senderCompanyClients);
    }
  }, []);

  const isEmpty =
    receiverName === "" ||
    receiverCompany === "" ||
    senderCompany === "" ||
    senderDesignation === "" ||
    problem === "" ||
    // linkedin === "" ||
    aboutSendersCompany === "" ||
    solution === "" ||
    senderCompanyClients === "";

  const handleGenerate = async () => {
    if (isEmpty) {
      return enqueueSnackbar(`All fields are required`, {
        variant: "error",
        style: {
          zIndex: "10000 !important",
        },
      });
    }
    if (loginError) {
      return enqueueSnackbar(
        `Please try again later. Error generating message`,
        {
          variant: "error",
        }
      );
    }
    const payload = {
      receiverName,
      receiversCompany: receiverCompany,
      sendersCompany: senderCompany,
      aboutSendersCompany,
      senderName: Cookies.get("username"),
      senderDesignation,
      senderCompanyClients,
      problem,
      solution,
      followUps: "3",
      linkedin_url: url
        ? "https://www.linkedin.com/in/sonakshi-pratap/"
        : linkedin,
    };
    dispatch({
      type: "setPersonalisedDetails",
      payload: {
        sendersCompany: senderCompany,
        aboutSendersCompany: aboutSendersCompany,
        senderDesignation: senderDesignation,
        senderCompanyClients: senderCompanyClients,
      },
    });
    try {
      setLoading(true);
      const response = await fetch(`${apiPersonalizedUrl}/${endPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("template_token")}`,
        },
        body: JSON.stringify(payload),
      });
      const { data } = await response.json();
      if (response.status === 200) {
        setLoading(false);
        setMessage(data?.personalised_message);
        setReceiverName("");
        setReceiverCompany("");
        setSenderCompany("");
        setSenderDesignation("");
        setAboutSendersCompany("");
        setSolution("");
        setProblem("");
        setSenderCompanyClients([]);
        setLinkedin("");
      } else if (response.status === 400) {
        enqueueSnackbar("Error,Please try again later.", {
          variant: "error",
        });
        setLoading(false);
      } else {
        enqueueSnackbar("Check your filled details!", { variant: "error" });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!open) {
      setMessage("");
      setReceiverName("");
      setReceiverCompany("");
      setSenderCompany("");
      setSenderDesignation("");
      setProblem("");
      setLinkedin("");
      setAboutSendersCompany("");
      setSolution("");
      setSenderCompanyClients([]);
    }
  }, [open]);
  return (
    <Box minWidth={"100%"}>
      <DialogTitle
        sx={{ mt: 2, p: 2, textDecoration: "underline" }}
        id="customized-dialog-title"
        textAlign={"center"}
      >
        Let’s Create a Personalized {title} message!
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          zIndex: "9999 !important",
          right: 8,
          top: 8,
          color: "#000",
          bgcolor: "transparent",
          "&:hover": {
            color: "#fff",
            bgcolor: "#414A4C",
          },
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack
          paddingX={"1rem"}
          paddingY={"2rem"}
          direction="column"
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            <TextField
              label="Receiver's name"
              variant="outlined"
              fullWidth
              value={receiverName}
              onChange={(e) => setReceiverName(e.target.value)}
            />
            <TextField
              label="Receiver's company name"
              variant="outlined"
              fullWidth
              value={receiverCompany}
              onChange={(e) => setReceiverCompany(e.target.value)}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Sender's company name"
              variant="outlined"
              fullWidth
              value={senderCompany}
              onChange={(e) => setSenderCompany(e.target.value)}
            />
            <TextField
              label="Sender's Designation"
              variant="outlined"
              fullWidth
              value={senderDesignation}
              onChange={(e) => setSenderDesignation(e.target.value)}
            />
          </Stack>
          <Box marginY={"20px"}>
            <TextField
              label="About Sender’s Company"
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
              value={aboutSendersCompany}
              onChange={(e) => setAboutSendersCompany(e.target.value)}
            />
          </Box>
          <Box marginY={"20px"}>
            <TextField
              label="What we offer"
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
            />
          </Box>
          <Box marginY={"20px"}>
            <TextField
              label="Solution to your problem"
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
              value={solution}
              onChange={(e) => setSolution(e.target.value)}
            />
          </Box>
          <Autocomplete
            multiple
            options={senderCompanyClients}
            freeSolo
            value={senderCompanyClients}
            onChange={(event, newValue) => {
              setSenderCompanyClients([...newValue]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Key Clients (After typing a client name press enter)"
                label="Key Clients"
              />
            )}
          />
          {!url && (
            <Box marginY={"20px"}>
              <TextField
                label="Client's linkedin url"
                variant="outlined"
                fullWidth
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </Box>
          )}
          <Button
            variant="contained"
            disableElevation
            onClick={handleGenerate}
            startIcon={
              title === "email" ? (
                <EmailIcon fontSize="large" />
              ) : title === "whatsapp" ? (
                <WhatsAppIcon fontSize="large" />
              ) : (
                <LinkedInIcon fontSize="large" />
              )
            }
            sx={{
              paddingY: "12px",
              fontSize: "16px",
            }}
          >
            Generate Message
          </Button>
        </Stack>
      </DialogContent>
    </Box>
  );
};

export default PersonalizedForm;
