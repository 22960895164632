import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    // minWidth: "450px",
    // maxWidth: "890px",
    background: "#ddd",
    // minHeight: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    animationName: "$loader",
    animationTimingFunction: "ease-in-out",
    animationDuration: "3s",
    animationIterationCount: "infinite",
    height: "60px",
    width: "60px",
    borderRadius: "100%",
    position: "absolute",
    border: "2px solid white",
  },
  dotFirst: {
    backgroundColor: "#fb3e3e",
    animationDelay: "0.5s",
  },
  dotSecond: {
    backgroundColor: "#fb3e3e",
    animationDelay: "0.4s",
  },
  dotThird: {
    backgroundColor: "#fb3e3e",
    animationDelay: "0.3s",
  },
  dotFourth: {
    backgroundColor: "#fb3e3e",
    animationDelay: "0.2s",
  },
  "@keyframes loader": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(0.1)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}));

const CustomLoader = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      // minHeight={750}
      // minWidth={890}
      // marginX={"auto"}
      sx={{
        minWidth: "500px",
        minHeight: "500px",
        marginX: "auto",
      }}
    >
      <div className={`${classes.dot} ${classes.dotFirst}`} />
      <div className={`${classes.dot} ${classes.dotSecond}`} />
      <div className={`${classes.dot} ${classes.dotThird}`} />
      <div className={`${classes.dot} ${classes.dotFourth}`} />
      <Box mt={12}>Generating message...</Box>
    </Box>
  );
};

export default CustomLoader;
