import React, { useEffect, useState } from "react";
import "./Styles/SavedList.css";
import Profile from "../SearchResult/Optimized/Profile";
import Cookies from "js-cookie";
import UnlockProfileEmail from "../SearchResult/Optimized/UnlockProfileEmail";
import SearchSpecificUser from "../DetailedInfo/SearchSpecificUser";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Pagination from "../Utils/Pagination";
import Emitter from "../Utils/emitter";
import { useSnackbar } from "notistack";
import { apiServer } from "../../config";
import { UnlockedProfileAndEmailDetails } from "../DetailedInfo/UnlockedProfileAndEmailDetails";
import {
  fetchWorkEmailFromElastic,
  fetchPhoneFromElastic,
} from "../SearchResult/SearchQueries";

import { Spinner } from "../Utils/utils";
import {
  fetchSavedListItems,
  removeItemFromList,
  searchSavedListItems,
} from "./SavedListQueries";

const PersonSavedListView = ({ listName, state, dispatch }) => {
  const [savedLeads, setSavedLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [isOpen, setIsOpen] = useState("");
  const [workEmailId, setWorkEmailId] = useState("");
  const [workEmailLoading, setWorkEmailLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [userUnlockedDetails, setUserUnlockedDetails] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const {
    searchText,
    page,
    view,
    searchCache,
    totalSearchRecords,
    personResultsCache,
    totalPersonRecords,
    itemsPerPage,
  } = state;

  console.log("savedLeads", savedLeads);

  const itemType = "person";

  const apiHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  };
  const onViewProfileOrWorkEmail = async () => {
    console.log("before onviewprofileorworkemail metadata");
    await getMetaData(savedLeads);
    Emitter.emit("updateCredits", true); // Get user details
    Emitter.emit("fetchCredits", true); // Update credits
  };

  const realtimeNonLinkedinProfileEmailUnlock = async (payload, type) => {
    if (type === "email") {
      try {
        setWorkEmailLoading(true);
        const emailUnlockResponse = await fetchWorkEmailFromElastic(payload);
        setWorkEmailId(true);
        setUserUnlockedDetails((prev) => {
          return {
            ...prev,
            workEmailDetails: {
              email: emailUnlockResponse.data?.email,
              verified: emailUnlockResponse.data?.verified,
            },
          };
        });
      } catch (err) {
        console.log("CHECK THIS LOG --->", err.message);
        enqueueSnackbar(err.message, { variant: "error" });
        setUserUnlockedDetails({});
        setSearchId({});
        setIsOpen("");
      } finally {
        setWorkEmailLoading(false);
        onViewProfileOrWorkEmail();
      }
    } else if (type === "phone") {
      try {
        setPhoneLoading(true);
        const fetchResponse = await fetchPhoneFromElastic(payload);
        // setSearchId(fetchResponse?.search_id);
        setUserUnlockedDetails((prev) => {
          return {
            ...prev,
            phones: fetchResponse?.phones,
          };
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        setUserUnlockedDetails({});
        setSearchId({});
        setIsOpen("");
      } finally {
        setPhoneLoading(false);
        onViewProfileOrWorkEmail();
      }
    }
  };

  const getMetaData = async (savedLeads) => {
    console.log("savedLeads: ", savedLeads);

    const urls = savedLeads?.map(
      (lead) =>
        lead?.["saved_item"]?.["details_url"] ?? lead?.["saved_item"]?.["id"]
    );
    console.log("urls ", urls);
    try {
      if (savedLeads?.length > 0) {
        await (async () => {
          let metadata = await fetch(apiServer + "/history/metadata", {
            method: "POST",
            headers: apiHeader,
            body: JSON.stringify({ ids: urls }),
          });

          if (!metadata.ok) {
            return;
          }
          metadata = await metadata.json();
          console.log("Meta_data: ", metadata);
          // If metadata found merge with current page records
          let currentLeads = savedLeads.map((record) => {
            if (record?.["saved_item"]?.["meta_data"]) {
              if (record?.["saved_item"]?.["meta_data"]?.["unlocked_email"]) {
                record["saved_item"]["meta_data"]["unlocked_email"] = false;
              }

              if (record?.["saved_item"]?.["meta_data"]?.["unlocked_profile"]) {
                record["saved_item"]["meta_data"]["unlocked_profile"] = false;
              }
            }
            return record;
          });
          console.log("currentLeads", currentLeads);
          if (metadata) {
            currentLeads = currentLeads.map((record, index) => {
              record["saved_item"]["meta_data"] = {
                ...record.saved_item.meta_data,
                user_unlocked: metadata?.user_metadata
                  ? { ...metadata.user_metadata[index] }
                  : null,
                team_unlocked: metadata?.unlocked_by_others
                  ? { ...metadata.unlocked_by_others[index] }
                  : null,
              };
              return record;
            });
          }
          setSavedLeads(currentLeads);
        })();
      } else {
        setSavedLeads([]);
      }
    } catch (e) {
      console.warn(e);
    }
    return savedLeads;
  };

  useEffect(() => {
    if (view === "search") {
      console.log("view is search");
      searchSavedLeads(false);
    } else {
      console.log("view is normal");
      fetchSavedLeads(false);
    }
  }, [page, view]);

  const fetchSavedLeads = async (forceFetch) => {
    try {
      if (!forceFetch && personResultsCache[page]) {
        setSavedLeads(personResultsCache[page]);
        await getMetaData(personResultsCache[page]);
      } else {
        setLoading(true);
        const pageNo = forceFetch ? 0 : page;
        const data = await fetchSavedListItems(pageNo, itemType, listName);
        await getMetaData(data?.["save_list_items"]);
        dispatch({
          type: "SET_TOTAL_PERSON_RECORDS",
          payload: data?.["total_item_count"],
        });
        dispatch({
          type: "SET_PERSON_RESULTS_CACHE",
          payload: {
            ...personResultsCache,
            [page]: data?.["save_list_items"],
          },
        });
      }
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("There was an error fetching data...", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
      console.log(err);
    }
  };

  const searchSavedLeads = async (forceFetch) => {
    try {
      if (!forceFetch && searchCache[page]) {
        setSavedLeads(searchCache[page]);
        await getMetaData(searchCache[page]);
      } else {
        setLoading(true);
        const pageNo = forceFetch ? 0 : page;
        const data = await searchSavedListItems(
          pageNo,
          itemType,
          listName,
          searchText
        );
        await getMetaData(data?.["save_list_items"]);
        dispatch({
          type: "SET_TOTAL_SEARCH_RECORDS",
          payload: data?.["total_item_count"],
        });
        dispatch({
          type: "SET_SEARCH_CACHE",
          payload: { ...searchCache, [page]: data?.["save_list_items"] },
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      enqueueSnackbar("There was an error fetching the data...", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const data = await removeItemFromList(listName, itemId);
      if (data?.["message"] === "success") {
        dispatch({ type: "SET_PAGE", payload: 0 });
        dispatch({ type: "SET_SEARCH_CACHE", payload: {} });
        dispatch({ type: "SET_PERSON_RESULTS_CACHE", payload: {} });
        enqueueSnackbar("Item removed..", {
          variant: "success",
          autoHideDuration: 2000,
        });
        if (view === "search") {
          console.log("view is search");
          searchSavedLeads(true);
        } else {
          console.log("view is normal");
          fetchSavedLeads(true);
        }
      } else {
        enqueueSnackbar("Error removing item..", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Error removing item..", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <div
        className="lead-accordion accordion-body global-width"
        style={{ marginLeft: "5%" }}
        id="accordionExample2"
      >
        <h5 className="text-start mt-2">{`No. of records : ${
          view === "search" ? totalSearchRecords : totalPersonRecords
        }`}</h5>
        <div className="accordion-item mb-3 ">
          <div
            id="collapseOne"
            className="accordion-collapse show collapse"
            data-bs-parent="#accordionExample2"
          >
            <div style={{ padding: "0rem" }} className="accordion-body">
              {loading ? (
                <Spinner />
              ) : savedLeads.length > 0 ? (
                savedLeads.map((data, index) => {
                  return (
                    <div key={index}>
                      <div
                        className="container-style mt-3 global-shadow"
                        style={{ padding: "10px 10px", width: "95%" }}
                      >
                        <div className="save-list-names-container">
                          <Profile
                            data={data["saved_item"]}
                            flexBasis="40%"
                            savedList={true}
                          />
                          <UnlockProfileEmail
                            index={index}
                            data={data["saved_item"]}
                            // hash_key={hash_key}
                            currentPage={page}
                            simpleSearchId={data["saved_item"]["id"]}
                            realtimeNonLinkedinProfileEmailUnlock={
                              realtimeNonLinkedinProfileEmailUnlock
                            }
                            setSearchId={setSearchId}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            workEmailId={workEmailId}
                            setWorkEmailId={setWorkEmailId}
                          />
                          <RemoveItem data={data} handleDelete={handleDelete} />
                        </div>
                        {isOpen === data.saved_item.details_url ? (
                          <div
                            style={{
                              background: "white",
                              borderRadius: "20px",
                              padding: "20px",
                            }}
                            id={`collapseExample_${data.id}`}
                          >
                            <SearchSpecificUser
                              id={data.saved_item.id}
                              searchId={searchId}
                              setSearchId={setSearchId}
                              isSavedLead={true}
                              setIsOpen={setIsOpen}
                              index={index}
                              data={data.saved_item}
                              currentPage={page}
                              workEmailId={workEmailId}
                              setWorkEmailId={setWorkEmailId}
                              // realTimeSearchResults={realTimeSearchResults}
                              onViewProfileOrWorkEmail={
                                onViewProfileOrWorkEmail
                              }
                              // currentLeads={currentLeads}
                              // setCurrentLeads={setCurrentLeads}
                            />
                          </div>
                        ) : isOpen === data.saved_item.id ? (
                          <UnlockedProfileAndEmailDetails
                            userDetails={userUnlockedDetails}
                            onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                            workEmailId={workEmailId}
                            workEmailLoading={workEmailLoading}
                            phoneLoading={phoneLoading}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                })
              ) : (
                <h5 className="save_list_notFound">NO RESULTS FOUND</h5>
              )}
              <Pagination
                onPageChange={({ selected }) =>
                  dispatch({ type: "SET_PAGE", payload: selected })
                }
                pageNumber={page}
                pageCount={Math.ceil(
                  (view === "search"
                    ? totalSearchRecords
                    : totalPersonRecords) / itemsPerPage
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RemoveItem = ({ data, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ marginRight: "1.5rem" }}>
      {loading ? (
        <Spinner />
      ) : (
        <IconButton
          onClick={async () => {
            setLoading(true);
            await handleDelete(data["id_"]);
            setLoading(false);
          }}
          data-cy="save-list-remove-btn"
        >
          <CloseIcon color="primary" />
        </IconButton>
      )}
    </div>
  );
};

export default PersonSavedListView;
