import { createSlice } from "@reduxjs/toolkit";

export const sharedSlice = createSlice({
  name: "shared",
  initialState: {
    loader: false,
    categorySelectedArray: [],
    categorySelected: "",
    clearSearchResult: false,
    leadsId: "",
    viewDetail: {
      view: false,
      id: null,
    },
  },
  reducers: {
    saveLoaderState: (state, action) => {
      state.loader = action.payload;
    },
    saveCategorySelected: (state, action) => {
      state.categorySelected = action.payload;
    },
    saveClearSearchResult: (state, action) => {
      state.clearSearchResult = action.payload;
    },
    saveViewDetail: (state, action) => {
      state.viewDetail = action.payload;
    },
    saveLeadsId: (state, action) => {
      state.leadsId = action.payload;
    },
    saveDetailedLeadsId: (state, action) => {
      state.leadsId = action.payload;
    },
    saveCategorySelectedArray: (state, action) => {
      state.categorySelectedArray = action.payload;
    },
  },
});

export const {
  saveLoaderState,
  saveCategorySelected,
  saveClearSearchResult,
  saveViewDetail,
  saveCategorySelectedArray,
  saveLeadsId,
  saveDetailedLeadsId,
} = sharedSlice.actions;

export const selectLoaderState = (state) => state.shared.loader;
export const selectCategorySelected = (state) => state.shared.categorySelected;
export const selectClearSearchResult = (state) =>
  state.shared.clearSearchResult;
export const selectViewDetail = (state) => state.shared.viewDetail;
export const selectCategorySelectedArray = (state) =>
  state.shared.categorySelectedArray;
export const selectLeadsId = (state) => state.shared.leadsId;

export default sharedSlice.reducer;
