import React from "react";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { apiServer } from "../../config";

const MapExport = ({ mapSelectedLeads, searchTerm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const apiHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  };

  async function handleSuccess(fetchResponse) {
    console.log("In success export map", fetchResponse);
    const data = await fetchResponse.json();
    if (!data) {
      return handleError();
    }
    const username = Cookies.get("username");
    enqueueSnackbar(
      <div>
        Hey {username}! We have started working on your file. <br />
        We will take some time to revert back. <br />
        Be rest assured, You'll get an email once it is ready.
        <br />
        After exporting, please ensure to refresh your credit for seamless
        service. Thank you!
      </div>,
      { variant: "info" }
    );

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      console.log("Stopped checking for file, timeout cleared");
    }, 3 * 60 * 1000);

    console.log("data from response ", data);

    const intervalId = setInterval(async () => {
      console.log("Checking For File state Every 5 Seconds");

      let fileName = data?.output_filename;

      let url = `${apiServer}/${fileName.replace('"', "").replace('"', "")}`;
      try {
        const fetchPromise = await fetch(url);
        switch (fetchPromise.status) {
          case 200:
            if (fileName) {
              window.location.href = url;
            } else {
              console.error("Error in download link");
            }
            if (timeoutId) clearTimeout(timeoutId);
            clearInterval(intervalId);
            break;
          case 400:
            console.log(fetchPromise);
            return;
          default:
            console.log(fetchPromise);
        }
      } catch (e) {
        console.error("Exception", e);
      }
    }, 5 * 1000);
  }

  function handleUnAuthorized() {
    console.log("In unauthorize export map");
    enqueueSnackbar("Please Logout and LogIn Again");
  }
  function handleInsufficientBalance(response) {
    console.error("Insufficient credits...", response);
    enqueueSnackbar(
      "You have insufficient profile credits. Buy Credits to get details.",
      { variant: "warning" }
    );
  }
  function handleError(fetchResponse) {
    console.log("In error export map", fetchResponse);
    enqueueSnackbar("Please Try Again");
  }

  async function handleLeadSelectionExportExcel() {
    console.log("In MapExport..", mapSelectedLeads);
    try {
      const fetchResponse = await fetch(
        apiServer + `/search/person/map/export/excel`,
        {
          method: "POST",
          headers: apiHeader,
          body: JSON.stringify({
            profile_ids: mapSelectedLeads,
            search_type: searchTerm?.includes("people") ? "map.people" : null,
            no_queue: false,
          }),
        }
      );

      switch (fetchResponse.status) {
        case 200:
          return await handleSuccess(fetchResponse);
        case 401:
          return handleUnAuthorized();
        case 402:
          return handleInsufficientBalance(fetchResponse);
        default:
          return handleError(fetchResponse);
      }
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <div className="d-flex align-items-center">
      <button
        onClick={handleLeadSelectionExportExcel}
        className="export-btn"
        data-cy="export-btn"
        disabled={mapSelectedLeads.length === 0}
      >
        Export ({mapSelectedLeads?.length})
        <img
          className="ps-3"
          src="/assets/images/export.png"
          alt=""
          style={{ width: "30px", height: "15px" }}
        />
      </button>
    </div>
  );
};
export default MapExport;
