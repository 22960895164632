import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  connectInfiniteHits,
  Highlight,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-dom";
import { IsNull, Map } from "react-lodash";
import { Button, Col, Row } from "react-bootstrap";
import MultiDomainPopup from "./MultiDomainPopup";
import { useSnackbar } from "notistack";

const ProspectFilterAutoSuggest = (props) => {
  const [show, setShow] = useState(false);
  const [showExclude, setShowExclude] = useState(false);
  //constants
  const identifier = uuidv4();
  const { enqueueSnackbar } = useSnackbar();
  //derived constants
  const accordionId = `filter-search-accordion-${identifier}`;

  const Hit = ({ hit }) => (
    <Highlight attribute={props.indexAttribute} hit={hit} />
  );

  const InfiniteHits = ({ hits, onHandleClick }) => {
    const limitedHits = hits.slice(0, 10);
    return (
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list" data-cy="search-query-list">
          <Map
            collection={limitedHits}
            iteratee={(hit) => {
              return (
                <li
                  key={hit.objectID}
                  className="ais-InfiniteHits-item"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => onHandleClick(hit[props.indexAttribute])}
                >
                  <Hit hit={hit} />
                </li>
              );
            }}
          />
        </ul>
      </div>
    );
  };

  const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

  function handleSearchEntryValueUpdated(newQueryValue) {
    props?.onTargetValueSet(newQueryValue?.trim());
  }
  function handleExcludeEntryValueUpdated(newQueryValue) {
    props?.onExcludeValueSet(newQueryValue?.trim());
  }

  const handleSubmit = () => {
    console.log("multiDomain", props.multipleDomain);
    if (!props.multipleDomain) {
      console.warn(
        `Invalid Data, Not Sending multiple domain: ${props.multipleDomain}`
      );
      enqueueSnackbar(`Enter some domain to search`, { variant: "warning" });
      return;
    }
    props?.onAddMultipleDomains(props.multipleDomain?.trim());
    props.setMultipleDomain("");
    setShow(false);
  };
  const handleExcludeToggle = () => {
    setShowExclude((prev) => !prev);
  };
  return (
    <div className="accordion-item" style={{ position: "relative" }}>
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionId}`}
          disabled={props?.disabled}
        >
          <img
            src={props.iconUrl || "/assets/images/new_icons/Frame_2888.png"}
            alt="Prospecting Search Item"
          />
          {props?.disabled ? (
            <span style={{ color: "grey" }}>{props.displayName}</span>
          ) : (
            props?.displayName
          )}

          <IsNull
            value={props.tooltipText}
            no={() => {
              return (
                <span
                  className="tool"
                  tooltip-data={
                    props.tooltipText ||
                    "Enter the first letter and choose upto five from the list"
                  }
                >
                  <img
                    src="/assets/images/new_icons/i_button.png"
                    alt="#"
                    className="mx-2 tool"
                    style={{
                      width: "15px",
                      height: "15px",
                      marginTop: "-.1em",
                    }}
                  />
                </span>
              );
            }}
          />
        </button>
      </h2>
      <div
        id={accordionId}
        className="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <InstantSearch
            indexName={props.indexName}
            searchClient={props.searchClient}
          >
            <SearchBox
              showLoadingIndicator={true}
              autoFocus={true}
              translations={{
                placeholder: props.inputPlaceholder,
              }}
            />
            <CustomInfiniteHits
              hitComponent={Hit}
              onHandleClick={handleSearchEntryValueUpdated}
            />
          </InstantSearch>
          {props.displayName === "Exclude Company Domain" && (
            <Row>
              <Col>
                <Button
                  variant="link"
                  onClick={() => setShow(!show)}
                  data-cy="domain"
                >
                  Add Multiple Domains
                </Button>
              </Col>
              <Col></Col>
            </Row>
          )}
          {show && (
            <MultiDomainPopup
              show={show}
              setShow={setShow}
              multiValue={props.multipleDomain}
              setMultipleDomain={props.setMultipleDomain}
              handleSubmit={handleSubmit}
            />
          )}
          {props?.showExcludeSearch && (
            <div className="mt-3 flex-row align-items-center gap-4">
              <input
                type="checkbox"
                className="position-static rounded-circle mt-0 ml-0 form-check-input"
                id={`exclude-checkbox-${identifier}`}
                onChange={handleExcludeToggle}
                style={{
                  height: "16px",
                  width: "16px",
                }}
              />
              <label
                className="form-check-label ml-2"
                htmlFor={`exclude-checkbox-${identifier}`}
              >
                Exclude:
              </label>
            </div>
          )}

          <div className={""}>
            {showExclude && props?.showExcludeSearch && (
              <InstantSearch
                indexName={props.indexName}
                searchClient={props.searchClient}
              >
                <SearchBox
                  showLoadingIndicator={true}
                  autoFocus={true}
                  translations={{
                    placeholder: props.inputPlaceholder,
                  }}
                />
                <CustomInfiniteHits
                  hitComponent={Hit}
                  onHandleClick={handleExcludeEntryValueUpdated}
                />
              </InstantSearch>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProspectFilterAutoSuggest;
