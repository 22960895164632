import EngageCard from "./EngageCard";
import { engageCards } from "./EngageCards";
import "../RealTime/Style/style.css";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";

const EngagePage = () => {
  return (
    <div>
      <motion.div {...fadeInFadeOut}>
        <div className="container">
          <div className="map-section">
            <div className="row">
              <div className="col-md-6">
                <img src="/assets/images/realtime (9).png" alt="" />
              </div>
              <div className="col-md-6 ">
                <div className="trending-lead global-shadow">
                  <img src="/assets/images/realtime (5).png" alt="" />
                  <h4>Engage</h4>
                  <p>
                    Engage with the generated leads via Whatsapp, Email or SMS
                  </p>
                  <p>Grab your Leads Now !</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="trends container mt-0 mb-6 global-shadow"
          data-cy="realtime-leads"
        >
          <div className="marketing-trend">
            <div className="mb-2 head-btn-style">
              <div className="row">
                <div className="col-8 px-4 ">
                  <div className="">
                    <h5 className="m-0">Engage</h5>
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-end"></div>
              </div>
            </div>
            <div className="container" id="collapseOne">
              <div className="row">
                {engageCards.map((card, index) => (
                  <div className="col-md-4 py-3">
                    <EngageCard
                      key={index}
                      id={index}
                      page_name={card.page_name}
                      data_cy={card.data_cy}
                      url={card.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
export default EngagePage;
