import React from "react";
import InvestorsWhoFundedRecently from "./RealTimeIndividualItems/InvestorsWhoFundedRecently";
import RecentlyFundedCompanies from "./RealTimeIndividualItems/RecentlyFundedCompanies";
import RecentlyAcquiredCompanies from "./RealTimeIndividualItems/RecentlyAcquiredCompanies";
import CompaniesAquiringRecently from "./RealTimeIndividualItems/CompaniesAquiringRecently";
import UnlockCompanyProfile from "./RealTimeIndividualItems/UnlockCompanyProfile";
import CompaniesHiringRecently from "./RealTimeIndividualItems/CompaniesHiringRecently";
import NewlyRegisteredSubsidiariesOfForeignCompanies from "./RealTimeIndividualItems/NewlyRegisteredSubsidiariesOfForeignCompanies";
import NewlyRegisteredCompaniesOnMCA from "./RealTimeIndividualItems/NewlyRegisteredCompaniesOnMCA";
import RealtimeSelectItem from "./RealTimeIndividualItems/RealtimeSelectItem";
import {
  setCompanyName,
  setContactPersonLinkedin,
  setLinkedIn,
  setContactPersonName,
} from "./RealTimeIndividualItems/util";
import PublicListedCompanies from "./RealTimeIndividualItems/PublicListedCompanies";
import FinancialIntermediaries from "./RealTimeIndividualItems/FinancialIntermediaries";
import UnfundedCompanies from "./RealTimeIndividualItems/UnfundedCompanies";

const RealtimeItem = ({
  companyPageRecord,
  sheetName,
  index,
  currentPage,
  open,
  setOpen,
  openId,
  setOpenId,
  setResult,
  jobUrlList,
  setJobUrlList,
  setLoading,
  pathName,
  realtimeSelectedLeads,
  setRealtimeSelectedLeads,
  customCount,
  setCustomCount,
  location,
}) => {
  const companyLinkedIn = setLinkedIn(sheetName, companyPageRecord);
  const companyName = setCompanyName(sheetName, companyPageRecord);
  const companyContactLinkedIn = setContactPersonLinkedin(
    sheetName,
    companyPageRecord
  );
  const companyContactName = setContactPersonName(sheetName, companyPageRecord);
  return (
    <div className="realtime-result" id={index} data-cy="realtime-result">
      <RealtimeSelectItem
        realtimeSelectedLeads={realtimeSelectedLeads}
        setRealtimeSelectedLeads={setRealtimeSelectedLeads}
        companyLinkedIn={companyLinkedIn}
        companyName={companyName}
        companyContactLinkedIn={companyContactLinkedIn}
        companyContactName={companyContactName}
        index={index}
        sheetName={sheetName}
        pathName={pathName}
        customCount={customCount}
        setCustomCount={setCustomCount}
        companyPageRecord={companyPageRecord}
      />
      {
        {
          "realtime-investors_who_funded_recently": (
            <InvestorsWhoFundedRecently companyPageRecord={companyPageRecord} />
          ),
          "realtime-recently_funded_company": (
            <RecentlyFundedCompanies companyPageRecord={companyPageRecord} />
          ),
          "realtime-recently_acquired_companies": (
            <RecentlyAcquiredCompanies companyPageRecord={companyPageRecord} />
          ),
          "realtime-companies_acquiring_recently": (
            <CompaniesAquiringRecently companyPageRecord={companyPageRecord} />
          ),
          "realtime-companies_hiring_recently": (
            <CompaniesHiringRecently
              companyPageRecord={companyPageRecord}
              jobUrlList={jobUrlList}
              setJobUrlList={setJobUrlList}
              pathName={pathName}
            />
          ),
          "realtime-newly_registered_subsidiaries_of_foreign_companies": (
            <NewlyRegisteredSubsidiariesOfForeignCompanies
              companyPageRecord={companyPageRecord}
            />
          ),
          "realtime-newly_registered_companies_on_mca": (
            <NewlyRegisteredCompaniesOnMCA
              companyPageRecord={companyPageRecord}
              location={location}
            />
          ),
          "realtime-public_listed_companies": (
            <PublicListedCompanies companyPageRecord={companyPageRecord} />
          ),
          "realtime-financial_intermediaries": (
            <FinancialIntermediaries companyPageRecord={companyPageRecord} />
          ),
          "realtime-unfunded_companies": (
            <UnfundedCompanies companyPageRecord={companyPageRecord} />
          ),
        }[sheetName]
      }
      <div className="realtime-result__name" id={index}>
        <UnlockCompanyProfile
          index={index}
          currentPage={currentPage}
          sheetName={sheetName}
          setResult={setResult}
          open={open}
          setOpen={setOpen}
          openId={openId}
          setOpenId={setOpenId}
          companyPageRecord={companyPageRecord}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};
export default RealtimeItem;
