import React, { useEffect, useState } from "react";
import CompanySearchResultItem from "./CompanySearchResultItem";
import { Loader } from "../Utils/utils";
import Pagination from "../Utils/Pagination";
import {
  fetchSavedListsNames,
  getSaveListMetadata,
} from "../SavedList/SavedListQueries";
import "./style.css";

const CompanyFilterSearch = ({ data, loading }) => {
  const [currentUnlockButtonId, setCurrentUnlockButtonId] = useState("");
  const [buttonOpenState, setButtonOpenState] = useState(false);
  const [saveListMetadata, setSaveListMetadata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [saveListNames, setSaveListNames] = useState([]);

  const itemsPerPage = 10;
  const pageCount = Math.ceil(data?.length / itemsPerPage);

  useEffect(() => {
    const itemOffset = pageNumber * itemsPerPage;
    const endOffset = itemOffset + itemsPerPage;
    console.log("itemOffset: ", itemOffset, "endOffset: ", endOffset);
    console.log("data: ", data);
    setCurrentItems(data?.slice(itemOffset, endOffset));
  }, [data, pageNumber]);

  useEffect(() => {
    setPageNumber(0);
  }, [data]);
  useEffect(() => {
    setCurrentUnlockButtonId("");
  }, [loading]);

  useEffect(() => {
    (async () => {
      let saveListNames = await fetchSavedListsNames();
      console.log("saveListNames", saveListNames);

      if (saveListNames?.["list_names"]) {
        setSaveListNames(saveListNames["list_names"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      console.log("pgdataa", currentItems);
      if (currentItems?.length === 0) return;
      const hashKeyInputs = currentItems?.map((lead) => `${lead?.social_url}/`);
      const saveListMetadata = await getSaveListMetadata(hashKeyInputs);
      setSaveListMetadata(saveListMetadata);
      console.log("saveListMetadata", saveListMetadata);
    })();
  }, [currentItems]);

  useEffect(() => {
    if (saveListMetadata) setSaveListMetadata([]);
  }, [pageNumber]);

  return (
    <div className="leads_mainContainer">
      <div className="result-container__results">
        <hr className="divider" />
        <div className="result-container__text-result">
          {loading ? (
            <Loader />
          ) : currentItems && currentItems?.length > 0 ? (
            currentItems?.map((item, index) => {
              return (
                <CompanySearchResultItem
                  data={item}
                  key={index}
                  itemIndex={index}
                  currentUnlockButtonId={currentUnlockButtonId}
                  setCurrentUnlockButtonId={setCurrentUnlockButtonId}
                  buttonOpenState={buttonOpenState}
                  setButtonOpenState={setButtonOpenState}
                  saveListNames={saveListNames}
                  setSaveListNames={setSaveListNames}
                  saved={saveListMetadata?.[index]?.["saved"]}
                  pageNumber={pageNumber}
                />
              );
            })
          ) : (
            <div className="company-record-not-found my-3">
              Record Not Found
            </div>
          )}
          {!loading && (
            <Pagination
              onPageChange={({ selected }) => setPageNumber(selected)}
              pageNumber={pageNumber}
              pageCount={pageCount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyFilterSearch;
