import React, { useEffect } from "react";
import dayjs from "dayjs";

const CommonSelectedItemInFilter = ({
  filterRequest,
  setFilterRequest,
  itemName,
  image,
}) => {
  useEffect(() => {}, [filterRequest]);

  function clearFilter(key, inputKey) {
    let index = filterRequest[inputKey].indexOf(key);
    if (index !== -1) {
      filterRequest[inputKey].splice(index, 1);
    }
    setFilterRequest(() => {
      return { ...filterRequest };
    });
  }

  return (
    <>
      {filterRequest &&
        filterRequest[itemName]?.map((key, index) => {
          if (key) {
            return (
              <div className="realtime__query-container" key={index}>
                <span className="realtime__query">
                  <img src={image} alt="#" className="realtime__img" />
                  {typeof key === "string" && key
                    ? key
                    : Object.keys(key).map((item, id) => {
                        console.log("in map: ", item, key);
                        if (item === "start_date") {
                          const selected_date = key[item];
                          return `${dayjs(selected_date).format(
                            "YYYY-MM-DD"
                          )} `;
                        }
                        if (item === "end_date") {
                          const selected_date = key[item];
                          return `To ${dayjs(selected_date).format(
                            "YYYY-MM-DD"
                          )} `;
                        }
                        return `${key[item]} `;
                      })}
                </span>
                <img
                  width={"10px"}
                  src="/assets/images/new_icons/close.svg"
                  alt="#"
                  onClick={() => {
                    clearFilter(key, itemName);
                  }}
                  data-cy="remove"
                />
              </div>
            );
          }
          return null;
        })}
    </>
  );
};
export default CommonSelectedItemInFilter;
