import React from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButton } from "../../History/MUICustomized";

const PlanHeader = ({
  setPaymentData,
  monthlyPlans,
  annualyPlans,
  toggleButton,
  handleToggle,
  planName,
  currency,
  setCurrency,
}) => {
  console.log("planName: ", planName);
  console.log("handleToggle: ", handleToggle);
  console.log("monthlyPlans: ", monthlyPlans);
  console.log("toggleButton: ", toggleButton);
  return (
    <>
      <div className="row">
        <div className="col-lg-12 p-2 text-center">
          <h2 className="py-4">Choose {planName} plan</h2>
          <ToggleButtonGroup
            color="primary"
            value={toggleButton}
            exclusive
            onChange={handleToggle}
            aria-label="payment buttons"
            sx={{
              margin: "0",
              marginBottom: "0.5rem",
              backgroundColor: "#ffffff",
              border: "2px solid #fb3e3e",
              borderRadius: "25px",
              height: "3.25rem",
              marginTop: "1rem",
            }}
          >
            <ToggleButton
              value="monthly"
              aria-label="monthly"
              onClick={() => setPaymentData(monthlyPlans)}
            >
              Monthly
            </ToggleButton>
            <ToggleButton
              value="annually"
              aria-label="annually"
              onClick={() => setPaymentData(annualyPlans)}
            >
              Annually
            </ToggleButton>
          </ToggleButtonGroup>
          {toggleButton === "annualy" && (
            <h6 className="py-3">
              {" "}
              Get 30% OFF on yearly Standard, Starter & Growth plan
            </h6>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 text-center mt-1">
          <div
            className="d-flex justify-content-between"
            style={{
              width: "280px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <span
              className={`badge badge-pill ${
                currency === "USD" ? "badge-danger" : "badge-dark"
              } py-2 px-3`}
              style={{
                fontSize: "16px",
                borderRadius: "13px",
                cursor: "pointer",
              }}
              // disabled
              onClick={() => setCurrency("USD")}
            >
              $ USD
            </span>
            <span
              className={`badge badge-pill ${
                currency === "GBP" ? "badge-danger" : "badge-dark"
              } py-2 px-3`}
              style={{
                fontSize: "16px",
                borderRadius: "13px",
                cursor: "pointer",
              }}
              onClick={() => setCurrency("GBP")}
            >
              £ GBP
            </span>
            <span
              className={`badge badge-pill ${
                currency === "INR" ? "badge-danger" : "badge-dark"
              } py-2 px-3`}
              style={{
                fontSize: "16px",
                borderRadius: "13px",
                cursor: "pointer",
              }}
              onClick={() => setCurrency("INR")}
            >
              ₹ INR
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanHeader;
