import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../Context";

const AskJarvis = () => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/app.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="user-widget-box text-center p-4 my-3"
      style={{ width: "100%" }}
    >
      <div className="user-promote-logo">
        <img src="/assets/images/Group 2117 (1).png" alt="title" />
      </div>
      <div className="user-promote-slider">
        <div className="item">
          <div className="user-promote-item">
            <p className="">Need a list of people working at Amazon</p>
            <div
              classNameName="px-3 pb-4"
              style={{ position: "absolute", bottom: "15px", content: "" }}
            >
              <Link
                to={"/advanceSearch"}
                className="small m-0"
                onClick={() => {
                  dispatch({ type: "setDemo", payload: "company" });
                }}
              >
                Try This
              </Link>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="user-promote-item">
            <p className="">
              Need a list of companies in Finance with 200 employees in India
            </p>
            <div
              classNameName="px-3 pb-4"
              style={{ position: "absolute", bottom: "15px", content: "" }}
            >
              <Link
                to={"/searchResultCompany"}
                className="small m-0"
                onClick={() => {
                  dispatch({ type: "setDemo", payload: "financeCompanies" });
                }}
              >
                Try This
              </Link>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="user-promote-item">
            <p className="">Need a list of IT companies in Bangalore</p>
            <div
              classNameName="px-3 pb-4"
              style={{ position: "absolute", bottom: "15px", content: "" }}
            >
              <Link
                to={"/searchResultCompany"}
                className="small m-0"
                onClick={() => {
                  dispatch({ type: "setDemo", payload: "bangloreCompanies" });
                }}
              >
                Try This
              </Link>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="user-promote-item">
            <p className="">Need a list of content writer from pune</p>
            <div
              classNameName="px-3 pb-4"
              style={{ position: "absolute", bottom: "15px", content: "" }}
            >
              <Link
                to={"/advanceSearch"}
                className="small m-0"
                onClick={() => {
                  dispatch({ type: "setDemo", payload: "contentWriter" });
                }}
              >
                Try This
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskJarvis;
