import React from "react";

const MapViewProfile = ({
  data,
  setIsOpen,
  activePage,
  i,
  isOpen,
  fetchProfileDetail,
  simpleSearchId,
  detailedId,
  open,
  setOpen,
  viewDetailsResult,
}) => {
  const isUserUnlocked = data.meta_data?.user_unlocked?.unlocked_profile;
  const isTeamUnlocked = data.meta_data?.team_unlocked?.unlocked_profile;
  return (
    <div className="result-container__result-btn">
      <btn
        id={`proBtn_${activePage}${i}`}
        className="btn button v-btn"
        data-toggle="collapse"
        // href={`#collapseExample_${activePage}${i}`}
        // data-target={`#collapseExample_${activePage}${i}`}
        onClick={(e) => {
          if (isOpen === data?.id) {
            setIsOpen("");
            return;
          }
          setIsOpen(data?.id);
          if (simpleSearchId && detailedId) {
            fetchProfileDetail(simpleSearchId, detailedId);
          } else {
            fetchProfileDetail();
          }
        }}
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
        style={
          isUserUnlocked ||
          isTeamUnlocked ||
          (viewDetailsResult?.phone_number?.[0].number &&
            viewDetailsResult?.latitude === data?.latitude &&
            viewDetailsResult?.longitude === data?.longitude &&
            viewDetailsResult?.name === data?.name)
            ? {
                backgroundColor: "#FF6852",
                color: "white",
              }
            : {}
        }
      >
        {isOpen === data?.id ? (
          "Hide Profile"
        ) : isUserUnlocked ||
          isTeamUnlocked ||
          (viewDetailsResult?.latitude === data?.latitude &&
            viewDetailsResult?.longitude === data?.longitude &&
            viewDetailsResult?.name === data?.name) ? (
          <span
            className="search-tool"
            tooltip-data={
              isTeamUnlocked
                ? "Information unlocked by team"
                : "You have unlocked this information before"
            }
          >
            View Profile {isTeamUnlocked ? "*" : ""}
          </span>
        ) : (
          "Unlock Profile"
        )}
      </btn>
    </div>
  );
};
export default MapViewProfile;
