import React from "react";
import img from "../../images/failed.png";
import "./PaymentSuccessAndFailure.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PaymentFailed() {
  const history = useHistory();
  return (
    <div className="payment">
      <div className="content">
        <div className="text">
          <div className="heading mb-4">Oh No!</div>
          <div className="failed mb-4">Payment Failed</div>
          <div className=" transaction-failed mb-5">
            Your payment transaction was unsuccessful. Please try again or
            contact our support team for assistance.
          </div>
          <div className="fail-buttons">
            {/*<button className="button">*/}
            {/*  <span*/}
            {/*    className="button-text"*/}
            {/*    onClick={() => {*/}
            {/*      history.push({*/}
            {/*        pathname: "/payment/platform",*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Try Again*/}
            {/*  </span>*/}
            {/*</button>*/}
            <button
              className="home"
              onClick={() => {
                history.push({
                  pathname: "/dashboard",
                });
              }}
            >
              Home Page
            </button>
          </div>
        </div>
        <img src={img} alt="failed" className="failure"></img>
      </div>
    </div>
  );
}
