import React, { useEffect, useState } from "react";
import "./Style/style.css";
import Cookies from "js-cookie";
import Modal from "../SharedComponent/Modal";
import { apiServer } from "../../config";
import { mailOpener, openDialer } from "../../Utils/helper";
import { useSnackbar } from "notistack";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MessageDialog from "../PersonalizedMessages/MessageDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const SpecificUser = (props) => {
  const [listPhones, setListPhones] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  console.log("props...", props.proxyData);
  console.log("props_details,,,", props.details);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState("");

  const handleClickOpen = async (email) => {
    setData(email);
    setOpen(true);
    setMessage("email");
  };
  const handleWhatsapp = (phone) => {
    setData(phone);
    setOpen(true);
    setMessage("whatsapp");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showless = props.details === "Record Not Found" ? true : false;

  let tempList = [];

  function handleError(response = null) {
    console.error(`Error, Status Code: ${response?.status}`);
  }

  function handleUnAuthorized(response = null) {
    console.log("User is UnAuthorized in specficUser");
    setPopupVisible(true);
  }

  const phoneNumberVerification = async (phone) => {
    try {
      // TODO: remove call to phone verification
      const response = await fetch(`${apiServer}/phone/phone_verification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify({ phones: phone }),
      });

      async function handleSuccess(response) {
        const json = await response.json();
        if (!json) {
          return handleError();
        }
        return json;
      }

      switch (response.status) {
        case 200:
          return await handleSuccess(response);
        case 401:
          return handleUnAuthorized(response);
        default:
          return handleError(response);
      }
    } catch (e) {
      console.error("Error: ", e);
      handleError();
    }
  };

  useEffect(() => {
    (async () => {
      if (props.details.phones) {
        console.log("props specific>>>>>", props.details.phones);
        props.details.phones.map((number) => tempList.push(number.number));
        console.log("tempList", tempList);
        const response = await phoneNumberVerification(tempList);
        if (response) {
          setListPhones(response);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (props.details && props.details.phones) {
        console.log("props specific>>>>>", props.details.phones);
        props.details.phones.map((number) => tempList.push(number.number));
        console.log("tempList", tempList);
        const response = await phoneNumberVerification(tempList);
        if (response) {
          setListPhones(response);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/app.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const showLess = () => {
    const clsBtn = document.getElementById(
      `closeBtn_${props.currentPage}${props.index}`
    );
    clsBtn.innerHTML = "Unlock Profile";
  };

  // const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElArray, setAnchorElArray] = useState(new Array(30).fill(null));
  const openPopup = Boolean(anchorElArray);
  const handleClick = (event, index) => {
    console.log("event", event);
    console.log("index", index);
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = newAnchorElArray[index]
      ? null
      : event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleClosePopup = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };
  return (
    <>
      {popupVisible ? <Modal /> : null}
      <div>
        {" "}
        {props.details === "Record Not Found" ||
        props.details === "Item not found" ? (
          <>
            <div>
              {" "}
              <section className="item-section" style={{ textAlign: "center" }}>
                Record Not found{" "}
              </section>
            </div>
            <div>
              {!props.demo && (
                <section style={{ textAlign: "center" }}>
                  {/* <button className="btn button" onClick = { (e)=>{ showLess(e) } }>Show Less</button> */}
                  <a
                    className="btn button"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    href={`#collapseExample_${props.currentPage}${props.index}`}
                    onClick={showLess}
                  >
                    show less
                  </a>
                </section>
              )}
            </div>
          </>
        ) : (
          <div>
            <section className="item-section">
              <div className="phone-child-div">
                <div className="">
                  {" "}
                  {Object.keys(listPhones).length > 0 ? (
                    <h6> Associated Phone Numbers </h6>
                  ) : null}{" "}
                  {Object.keys(listPhones).length > 0
                    ? listPhones.map((item, index) => (
                        <div className="ms-2 d-flex align-items-center gap-3 mb-3">
                          <div className="d-flex align-items-center">
                            <WhatsAppIcon
                              sx={{
                                fontSize: 18,
                                color: "#4EC57E",
                              }}
                            />
                            <Tooltip title="Tap for more actions" arrow>
                              <Button
                                variant="outlined"
                                data-cy="linkedin-link"
                                aria-controls={open ? item?.phone : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, index)}
                                sx={{
                                  color: "black",
                                  border: "1px solid black",
                                  outline: "none",
                                  borderRadius: "50px",
                                }}
                              >
                                {" "}
                                {item.phone}{" "}
                              </Button>{" "}
                            </Tooltip>
                            <Menu
                              id={item?.number}
                              anchorEl={anchorElArray[index]}
                              open={Boolean(anchorElArray[index])}
                              onClose={() => handleClose(index)}
                              MenuListProps={{
                                "aria-labelledby": item.phone,
                              }}
                              sx={{
                                borderRadius: "50px",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  gap: "4px",
                                  "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                  },
                                }}
                                onClick={() => openDialer(item?.phone)}
                              >
                                <OpenInNewIcon style={{ color: "#7B7A80" }} />
                                Open Whatsapp
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClose(index);
                                  handleWhatsapp(item?.phone);
                                }}
                                sx={{
                                  display: "flex",
                                  gap: "4px",
                                  "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                  },
                                }}
                              >
                                <WhatsAppIcon style={{ color: "#7B7A80" }} />
                                Send Personalized Message
                              </MenuItem>
                            </Menu>
                          </div>{" "}
                          <div className="d-flex align-items-center">
                            <a
                              className="me-2 ms-2"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(item.number);
                                alert("Phone Number Copied!");
                              }}
                            >
                              <img
                                style={{ height: "10px" }}
                                src="/assets/images/Union.png"
                                alt=""
                              />
                            </a>{" "}
                          </div>{" "}
                          {item.status === "Success" ? (
                            <div
                              style={{
                                wordBreak: "break-word",
                                marginRight: "3px",
                                paddingRight: "3px",
                              }}
                              className="d-flex align-items-center"
                            >
                              <img
                                className="ms-2"
                                src="/assets/images/Vector.png"
                                alt=""
                              />
                            </div>
                          ) : null}{" "}
                        </div>
                      ))
                    : null}{" "}
                </div>{" "}
                <div>
                  {" "}
                  {props.details.emails !== undefined &&
                  props.details.emails?.length !== 0 ? (
                    <h6> Associated Email Addresses </h6>
                  ) : null}{" "}
                  {props.details.emails
                    ? props.details.emails.map((email) => (
                        <div
                          className="ms-2 d-flex align-items-center mb-3"
                          align="left"
                        >
                          <div className="d-flex align-items-center">
                            <Tooltip title="Tap for more actions" arrow>
                              <Button
                                data-cy="linkedin-link"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                                variant="outlined"
                                sx={{
                                  color: "black",
                                  border: "1px solid black !important",
                                  outline: "none",
                                  borderRadius: "50px",
                                  minWidth: "min-content",
                                }}
                              >
                                {email.address}
                              </Button>
                            </Tooltip>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorElArray}
                              open={open}
                              onClose={handleClosePopup}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{
                                borderRadius: "50px",
                              }}
                            >
                              <MenuItem
                                onClick={() => mailOpener(email.address)}
                                sx={{
                                  display: "flex",
                                  gap: "4px",
                                  "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                  },
                                }}
                              >
                                <SendIcon style={{ color: "#7B7A80" }} />
                                Open Email
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleClickOpen(email.address);
                                }}
                                sx={{
                                  display: "flex",
                                  gap: "4px",
                                  "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                  },
                                }}
                              >
                                <EmailOutlinedIcon
                                  style={{ color: "#7B7A80" }}
                                />
                                Send Personalized Email
                              </MenuItem>
                            </Menu>
                            <img
                              className="ms-2"
                              style={{ height: "10px" }}
                              src="/assets/images/Union.png"
                              alt=""
                            />
                          </div>{" "}
                          {email.valid === "valid" ? (
                            <div
                              style={{
                                wordBreak: "break-word",
                                marginRight: "3px",
                                paddingRight: "3px",
                              }}
                              className="d-flex align-items-center"
                            >
                              <img
                                className="ms-2"
                                src="/assets/images/Vector.png"
                                alt=""
                              />
                            </div>
                          ) : null}{" "}
                        </div>
                      ))
                    : null}
                  {props.details.usernames !== undefined &&
                  props.details.usernames?.length !== 0 ? (
                    <h6> Associated Usernames </h6>
                  ) : null}{" "}
                  {props.details.usernames
                    ? props.details.usernames.map((data) => (
                        <div
                          className="ms-2 d-flex align-items-center mb-3"
                          align="left"
                        >
                          <div className="d-flex align-items-center">
                            <small className="ms-2"> {data.content} </small>{" "}
                            <small className="me-2 ms-2">
                              {" "}
                              {data.valid_since ? (
                                <span> Since {data.valid_since} </span>
                              ) : null}{" "}
                            </small>{" "}
                          </div>{" "}
                        </div>
                      ))
                    : null}
                  {props.details.urls !== undefined &&
                  props.details.urls?.length !== 0 ? (
                    <h6> Probable URLs Associated </h6>
                  ) : null}{" "}
                  {props.details.urls
                    ? props.details.urls.map((url) => (
                        <div
                          className="ms-2 d-flex align-items-center mb-3"
                          style={{ maxWidth: "250px" }}
                        >
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="d-flex align-items-center"
                          >
                            <small className="ms-2">
                              {" "}
                              {url.url.slice(0, 50)}{" "}
                            </small>{" "}
                            <a href={url.url} target="_blank" rel="noreferrer">
                              <img
                                className="ms-2"
                                style={{ height: "10px" }}
                                src="/assets/images/Union (1).png"
                                alt=""
                              />
                            </a>{" "}
                          </div>{" "}
                        </div>
                      ))
                    : null}{" "}
                </div>{" "}
                <div style={{ margin: "0 1em" }}>
                  {" "}
                  {props.details.gender ? <h6> Gender </h6> : null}{" "}
                  <div className="ms-2 d-flex align-items-center mb-3">
                    <small>
                      {" "}
                      {props.details.gender
                        ? props.details.gender.content
                        : null}{" "}
                    </small>{" "}
                  </div>{" "}
                  {props.details.dob ? <h6> Age </h6> : null}{" "}
                  <div className="ms-2 d-flex align-items-center mb-3">
                    <small>
                      {" "}
                      {props.details.dob ? (
                        <span> {props.details.dob.display} </span>
                      ) : null}{" "}
                    </small>{" "}
                  </div>{" "}
                  {props.details.languages !== undefined &&
                  props.details.languages?.length !== 0 ? (
                    <h6> Languages Known </h6>
                  ) : null}{" "}
                  {props.details.languages
                    ? props.details.languages.map((language) => (
                        <div className="ms-2 d-flex align-items-center mb-3">
                          <small> {language._display} </small>{" "}
                        </div>
                      ))
                    : null}
                  <h6> Location </h6>{" "}
                  <div className="ms-2 mb-3">
                    <div className="d-flex">
                      <div className="col">
                        <div className="d-flex">
                          <div className="me-2">
                            <div className="ms-2 d-flex align-items-center mb-3">
                              <small>
                                {" "}
                                {props.proxyData && props.proxyData.city
                                  ? props.proxyData.city + ","
                                  : null}{" "}
                                {props.proxyData && props.proxyData.state
                                  ? props.proxyData.state + ","
                                  : null}{" "}
                                {props.proxyData &&
                                props.proxyData.country_full_name
                                  ? props.proxyData.country_full_name
                                  : null}{" "}
                              </small>{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </section>{" "}
            <section className="item-section">
              <div style={{ width: "900px" }}>
                <h4> Associated Jobs and Companies </h4>{" "}
                {props.proxyData && props.proxyData.experiences
                  ? props.proxyData.experiences.map((comp) => (
                      <div className="table-alignment container-fluid">
                        <td> {comp.company} </td>
                        <td> {} </td>{" "}
                        <td>
                          {" "}
                          {comp.starts_at
                            ? `${comp.starts_at.day}-${comp.starts_at.month}-${comp.starts_at.year}`
                            : null}{" "}
                          {comp.ends_at
                            ? ` to ${comp.ends_at.day}-${comp.ends_at.month}-${comp.ends_at.year}`
                            : null}{" "}
                        </td>{" "}
                        <td>
                          <div className="d-flex justify-content-between">
                            {" "}
                            {/*<p>{comp.url}</p>*/} {/*<a href={comp.url}>*/}{" "}
                            {/*  <img*/} {/*    className="ms-2"*/}{" "}
                            {/*    style={{ height: "10px" }}*/}{" "}
                            {/*    src="assets/images/Union (1).png"*/}{" "}
                            {/*    alt=""*/} {/*  />*/} {/*</a>*/}{" "}
                          </div>{" "}
                        </td>{" "}
                      </div>
                    ))
                  : "Companies not found"}{" "}
              </div>{" "}
            </section>{" "}
            <section className="item-section">
              <div style={{ width: "900px" }}>
                <h4> Probable Education Associated </h4>{" "}
                {props.proxyData && props.proxyData.education
                  ? props.proxyData.education.map((edu) => (
                      <div className="table-alignment container-fluid">
                        <td> {edu.degree_name || "--"} </td>{" "}
                        <td> {edu.school} </td>{" "}
                      </div>
                    ))
                  : null}{" "}
              </div>{" "}
            </section>{" "}
            <section className="item-section">
              <div className="row">
                <div className="col-md-6">
                  <h4> Associated Locations </h4> {/*<div>*/} {/*  <Map*/}{" "}
                  {/*    google={props.google}*/} {/*    zoom={15}*/}{" "}
                  {/*    style={{ width: "80%", height: "80%" }}*/}{" "}
                  {/*    initialCenter={{ lat: 9.761927, lng: 79.95244 }}*/}{" "}
                  {/*  />*/} {/*</div>*/}{" "}
                </div>{" "}
                <div className="col-md-6">
                  <h4> List of Locations </h4>{" "}
                  {props.details.addresses
                    ? props.details.addresses.map((location) => (
                        <div>
                          <p> {location._display} </p>{" "}
                        </div>
                      ))
                    : null}{" "}
                </div>{" "}
              </div>{" "}
            </section>{" "}
            <section>
              <div className="user-widget-box text-center p-4 my-3">
                <h4 className="text-left"> Probable People Associated </h4>{" "}
                <div className="user-promote-slider">
                  {" "}
                  {props.proxyData && props.proxyData.people_also_viewed
                    ? props.proxyData.people_also_viewed.map((profile) => (
                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a href={profile.link}>
                              <img
                                className=""
                                src="/assets/images/user-author-pic.png"
                                alt=""
                              />
                            </a>{" "}
                          </div>{" "}
                          <p
                            style={{ textAlign: "center" }}
                            className="d-block mt-3"
                          >
                            {" "}
                            {profile.name}{" "}
                          </p>{" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              marginRight: "1.2rem",
                              marginLeft: "1.2rem",
                            }}
                          >
                            <p
                              style={{
                                wordBreak: "break-word",
                                margin: "0.5rem",
                                width: "15rem",
                                textAlign: "center",
                              }}
                              className="d-block mt-3"
                            >
                              {" "}
                              {profile.summary} at {profile.location}{" "}
                            </p>{" "}
                          </div>
                        </div>
                      ))
                    : null}{" "}
                </div>{" "}
              </div>{" "}
              {showless && (
                <div>
                  <section style={{ textAlign: "center" }}>
                    <a
                      className="btn button"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      href={`#collapseExample_${props.currentPage}${props.index}`}
                      onClick={showLess}
                    >
                      show less
                    </a>
                  </section>
                </div>
              )}
            </section>{" "}
          </div>
        )}{" "}
      </div>
      <MessageDialog
        open={open}
        data={data}
        title={message}
        onClose={handleClose}
      />
    </>
  );
};
export default SpecificUser;
// export default GoogleApiWrapper({
//   apiKey: "API_KEY",
// })(SpecificUser);
