import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import CompanyFilters from "../CompanySharedComponent/CompanyFilters";
import BulkSearch from "../SharedComponent/BulkSearch";
import AskJarvis from "../SharedComponent/AskJarvis";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import { AppContext } from "../../Context";
import SpecificCompany from "../CompanyInfo/SpecificCompany";
import backIcon from "../../images/back-union.png";
import locationIcon from "../../images/cil_location-pin.png";
import building from "../../images/Group 2367.png";
import CombinedSearchBar from "../SharedComponent/CombinedSearchBar";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import History from "../History/History";
import { useState } from "react";

const DetailInfoCompany = () => {
  const { customCompanySearch, customCompanyInfo, companyLoading } =
    useContext(AppContext);
  const [toggleCheck, setToggleCheck] = useState(false);
  let history = useHistory();

  const handleChange = async (e, event) => {
    if (event) {
      setToggleCheck(true);
    } else {
      setToggleCheck(false);
    }
  };

  return (
    <>
      <div className="modal" id="bulkmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="modal-dialog">
          <div className="modal-message">
            <p>
              <i className="text-danger">Format to follow:</i> Ensure that the
              first column has the unique values you’re searching for. Download
              the sample below for better understanding.{" "}
            </p>
            <Link>
              <i className="text-danger text-decoration-underline">
                Click here to download csv format
              </i>
            </Link>
          </div>
          <div className="modal-content">
            <form action="/upload" id="mydrop" className="dropzone">
              <div className="dz-message needsclick">
                <button type="button" className="dz-button">
                  Drag and Drop File
                </button>
                <br />
                <button type="button" className="dz-button">
                  OR{" "}
                </button>
                <br />
                <span className="note needsclick">
                  <input type="file" accept=".csv" />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="main-content-area pb-6 pt-2">
        <div className="main-wrapper container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <BulkSearch />
              <div className="mt-3">
                <div className="btnn btn-1">
                  <input
                    type="checkbox"
                    name="switch"
                    id="switch"
                    defaultChecked={toggleCheck}
                    onClick={(e) => handleChange(e, e.target?.["checked"])}
                    data-cy="switch"
                  />
                  <label htmlFor="switch" />
                </div>
              </div>
              <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                <h6 className="text-danger mb-3">
                  {toggleCheck ? "People Search" : "Company Search"}
                </h6>
                <CompanyFilters customSearch={customCompanySearch} />
                {/* <ProspectSearch companyFilter={true} /> */}
              </div>

              {/* <SidebarExtractContact data={true} /> */}
            </div>
            <div className="col-md-8 col-lg-9">
              <div
                className="container-fluid my-3 d-flex align-content-center"
                style={{
                  height: "52px",
                  background: "#fff",
                  borderRadius: "5px",
                }}
              >
                <button
                  className="back-btn my-2"
                  onClick={() => {
                    history.goBack();
                  }}
                  style={{ background: "transparent", outline: "none" }}
                >
                  <img
                    src={backIcon}
                    alt="#"
                    style={{ width: "10px", height: "10px" }}
                  />
                  Back
                </button>
              </div>
              <CombinedSearchBar isResultPage={true} />
              <div
                style={{ background: "white", borderRadius: "20px" }}
                className="global-shadow"
              >
                <History onRepeatedPage={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailInfoCompany;
