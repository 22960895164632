import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";

export const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButtonGroup-grouped": {
    border: "0",
    color: "#232323",
    minWidth: "8rem",
  },
  "&.Mui-selected": {
    color: "#ffffff !important",
    backgroundColor: "#fb3e3e",
    borderRadius: "25px",
    border: "0",
  },
  "&.Mui-selected:hover": {
    background: "#fb3e33",
  },
});
