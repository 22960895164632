import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";
import { Button, ListGroup } from "react-bootstrap";
import { Map } from "react-lodash";

const ProspectFilterList = (props) => {
  //constants
  const identifier = uuidv4();
  //derived constants
  const accordionId = `filter-search-accordion-${identifier}`;
  const slugifiedDisplayName = slugify(props.displayName.toLowerCase());

  //states
  const [searchEntryValue, setSearchEntryValue] = useState("");

  const handleSearchEntryValueUpdated = (value) => {
    const newQueryValue = value;
    setSearchEntryValue(newQueryValue);
    props?.onTargetValueSet(newQueryValue?.trim());
    setSearchEntryValue("");
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionId}`}
          disabled={props?.disabled}
        >
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={props.iconUrl || "/assets/images/new_icons/Frame_2888.png"}
            alt="Prospecting Search Item Image"
          />
          {props?.disabled ? (
            <span style={{ color: "grey" }}>{props.displayName}</span>
          ) : (
            props?.displayName
          )}
          {props.tooltipText ? (
            <span className="tool" tooltip-data={props.tooltipText}>
              <img
                src="/assets/images/new_icons/i_button.png"
                alt="#"
                className="mx-2 tool"
                style={{
                  width: "15px",
                  height: "15px",
                  marginTop: "-.1em",
                }}
              />
            </span>
          ) : null}
        </button>
      </h2>
      <div
        id={accordionId}
        className="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div
          className="accordion-body"
          data-cy={`cypress-${slugifiedDisplayName}`}
        >
          <ListGroup>
            <Map
              collection={props?.collection}
              iteratee={(x, index) => {
                return (
                  <ListGroup.Item key={index}>
                    <div className="d-grid gap-0">
                      <Button
                        variant={
                          props.queryState?.[`${props.stateName}`]?.some(
                            (item) => item === x
                          )
                            ? "dark"
                            : "outline-dark"
                        }
                        onClick={() => handleSearchEntryValueUpdated(x)}
                        data-cy="filter-btn"
                      >
                        {x}
                      </Button>
                    </div>
                  </ListGroup.Item>
                );
              }}
            />
          </ListGroup>
        </div>
      </div>
    </div>
  );
};
export default ProspectFilterList;
