import React, { useEffect, useState } from "react";
import FilterRecentlyFundedCompanies from "./IndexItems/FilterRecentlyFundedCompanies";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import ItemSelectedRecentlyFundedCompany from "./SelectedFilterItems/ItemSelectedRecentlyFundedCompany";
import FilterInvestorWhoFundedRecently from "./IndexItems/FilterInvestorWhoFundedRecently";
import ItemSelectedInvestorsWhoFundedRecently from "./SelectedFilterItems/ItemSelectedInvestorsWhoFundedRecently";
import FilterRecentlyAcquiredCompanies from "./IndexItems/FilterRecentlyAcquiredCompanies";
import ItemSelectedRecentlyAcquiredCompanies from "./SelectedFilterItems/ItemSelectedRecentlyAcquiredCompanies";
import ItemSelectedCompaniesAcquiringRecently from "./SelectedFilterItems/ItemSelectedCompaniesAcquiringRecently";
import FilterCompaniesAcquiringRecently from "./IndexItems/FilterCompaniesAcquiringRecently";
import ItemSelectedNewlyRegisteredCompaniesOnMCA from "./SelectedFilterItems/ItemSelectedNewlyRegisteredCompaniesOnMCA";
import FilterNewlyRegisteredCompaniesOnMCA from "./IndexItems/FilterNewlyRegisteredCompaniesOnMCA";
import ItemSelectedCompaniesHiringRecently from "./SelectedFilterItems/ItemSelectedCompaniesHiringRecently";
import FilterCompaniesHiringRecently from "./IndexItems/FilterCompaniesHiringRecently";
import ItemSelectedNewlyRegisteredSubsidiariesOfForeignCompanies from "./SelectedFilterItems/ItemSelectedNewlyRegisteredSubsidiariesOfForeignCompanies";
import FilterNewlyRegisteredSubsidiariesOfForeignCompanies from "./IndexItems/FilterNewlyRegisteredSubsidiariesOfForeignCompanies";
import ItemSelectedFamilyOfficesInIndia from "./SelectedFilterItems/ItemSelectedFamilyOfficesInIndia";
import FilterFamilyOfficesInIndia from "./IndexItems/FilterFamilyOfficesInIndia";
import ItemSelectedActiveAngelInvestorsInIndia from "./SelectedFilterItems/ItemSelectedActiveAngelInvestorsInIndia";
import FilterActiveAngelInvestorsInIndia from "./IndexItems/FilterActiveAngelInvestorsInIndia";
import FilterUnfundedCompany from "./IndexItems/FilterUnfundedCompany";
import ItemSelectedUnfundedStartups from "./SelectedFilterItems/ItemSelectedUnfundedStartups";
import ItemSelectedListedCompanies from "./SelectedFilterItems/ItemSelectedListedCompanies";
import FilterListedCompanies from "./IndexItems/FilterListedCompanies";
import FilterPublicListedCompanies from "./IndexItems/FilterPublicListedCompanies";
import ItemSelectedPublicListedCompanies from "./SelectedFilterItems/ItemSelectedPublicListedCompanies";
import FilterFinancialIntermediaries from "./IndexItems/FilterFinancialIntermediaries";
import ItemSelectedFinancialIntermediaries from "./SelectedFilterItems/ItemSelectedFinancialIntermediaries";
import ItemSelectedUnfundedCompanies from "./SelectedFilterItems/ItemSelectedUnfundedCompanies";
import FilterUnfundedCompanies from "./IndexItems/FilterUnfundedCompanies";
const RealtimeFilters = ({
  sheetName,
  setRealtimeSelectedLeads,
  setCurrentPage,
  setCustomCount,
}) => {
  const [filterRequest, setFilterRequest] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    console.log(" In RealtimeFilters filterRequest:###", filterRequest);
  }, [filterRequest]);
  async function handleFilter() {
    console.log("RealtimeFilters filter request: ---", filterRequest);
    if (
      filterRequest &&
      Object.keys(filterRequest).length === 0 &&
      Object.getPrototypeOf(filterRequest) === Object.prototype
    ) {
      enqueueSnackbar("Select one of the filter ", {
        variant: "error",
      });
      return;
    }
    try {
      setFilterRequest({});
      console.log("in filter push: ", sheetName);
      if (
        sheetName === "realtime-family_offices_in_india" ||
        sheetName === "realtime-active_angel_investors_in_india" ||
        sheetName === "realtime-financial_intermediaries"
      ) {
        history.push({
          pathname: "/people/realtime/filter", // BasicSearchResult
          state: {
            sheetName: sheetName,
            payload: filterRequest,
          },
        });
      } else {
        setCurrentPage(0);
        setCustomCount(0);
        setRealtimeSelectedLeads([]);
        history.push({
          pathname: "/realtime/filter", // BasicSearchResult
          state: {
            sheetName: sheetName,
            payload: filterRequest,
          },
        });
      }
    } catch (e) {}
  }
  return (
    <>
      {" "}
      <div className="realtime__filter" data-cy="filter-search">
        <h3 className="realtime__heading">Filter Companies</h3>
        <div className="realtime__filter-query" data-cy="query-container">
          {
            {
              "realtime-recently_funded_company": (
                <>
                  <ItemSelectedRecentlyFundedCompany
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterRecentlyFundedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName="realtime-recently_funded_companies"
                  />
                </>
              ),
              "realtime-investors_who_funded_recently": (
                <>
                  <ItemSelectedInvestorsWhoFundedRecently
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterInvestorWhoFundedRecently
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-recently_acquired_companies": (
                <>
                  <ItemSelectedRecentlyAcquiredCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterRecentlyAcquiredCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-companies_acquiring_recently": (
                <>
                  <ItemSelectedCompaniesAcquiringRecently
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterCompaniesAcquiringRecently
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-newly_registered_companies_on_mca": (
                <>
                  <ItemSelectedNewlyRegisteredCompaniesOnMCA
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterNewlyRegisteredCompaniesOnMCA
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-companies_hiring_recently": (
                <>
                  <ItemSelectedCompaniesHiringRecently
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterCompaniesHiringRecently
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-newly_registered_subsidiaries_of_foreign_companies": (
                <>
                  <ItemSelectedNewlyRegisteredSubsidiariesOfForeignCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterNewlyRegisteredSubsidiariesOfForeignCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-family_offices_in_india": (
                <>
                  <ItemSelectedFamilyOfficesInIndia
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterFamilyOfficesInIndia
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-active_angel_investors_in_india": (
                <>
                  <ItemSelectedActiveAngelInvestorsInIndia
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterActiveAngelInvestorsInIndia
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-unfunded_startups": (
                <>
                  <ItemSelectedUnfundedStartups
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterUnfundedCompany
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-listed_companies_india": (
                <>
                  <ItemSelectedListedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterListedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-public_listed_companies": (
                <>
                  <ItemSelectedPublicListedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterPublicListedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-unfunded_companies": (
                <>
                  <ItemSelectedUnfundedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterUnfundedCompanies
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
              "realtime-financial_intermediaries": (
                <>
                  <ItemSelectedFinancialIntermediaries
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                  />
                  <FilterFinancialIntermediaries
                    filterRequest={filterRequest}
                    setFilterRequest={setFilterRequest}
                    sheetName={sheetName}
                  />
                </>
              ),
            }[sheetName]
          }
        </div>
        <div className="realtime__search-box">
          <button
            type="button"
            className="realtime__search-btn"
            onClick={() => {
              handleFilter();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};
export default RealtimeFilters;
