import LinkedInImage from "./LinkedInImage";
import React from "react";
import { ValidateLinkedinUrl } from "../../Utils/utils";
import dayjs from "dayjs";

const NewlyRegisteredCompaniesOnMCA = ({ companyPageRecord, location }) => {
  return (
    <>
      {/*<div className="realtime-result">*/}
      <div className="ml-2 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord?.newly_registered_company_name ===
            "object" &&
          companyPageRecord?.newly_registered_company_name.length > 0
            ? companyPageRecord?.newly_registered_company_name[0]
            : companyPageRecord?.newly_registered_company_name
            ? companyPageRecord?.newly_registered_company_name
            : "-"}
        </h6>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.newly_registered_company_linkedin ===
            "object" &&
            companyPageRecord?.newly_registered_company_linkedin.length > 0
            ? companyPageRecord?.newly_registered_company_linkedin[0]
            : companyPageRecord?.newly_registered_company_linkedin
            ? companyPageRecord?.newly_registered_company_linkedin
            : null
        )}
      />

      <div className="realtime-result__child">
        <>
          Director Name
          <br />
          <span className="text-red">
            {typeof companyPageRecord[
              "newly_registered_company_founder_or_director_name"
            ] === "object" &&
            companyPageRecord[
              "newly_registered_company_founder_or_director_name"
            ][0].length > 0
              ? companyPageRecord[
                  "newly_registered_company_founder_or_director_name"
                ][0]
              : companyPageRecord[
                  "newly_registered_company_founder_or_director_name"
                ]
              ? companyPageRecord[
                  "newly_registered_company_founder_or_director_name"
                ]
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Location
          <br />
          <span className="text-red">
            {(typeof companyPageRecord?.city === "object" &&
            companyPageRecord?.city[0].length > 0
              ? companyPageRecord?.city[0]
              : companyPageRecord?.city
              ? companyPageRecord?.city
              : "-") ||
            (typeof companyPageRecord?.area === "object" &&
            companyPageRecord?.area[0].length > 0
              ? companyPageRecord?.area[0]
              : companyPageRecord?.area
              ? companyPageRecord?.area
              : "-") ||
            (typeof companyPageRecord?.state === "object" &&
            companyPageRecord?.state[0].length > 0
              ? companyPageRecord?.state[0]
              : companyPageRecord?.state
              ? companyPageRecord?.state
              : "-") ||
            (typeof companyPageRecord?.country === "object" &&
            companyPageRecord?.country[0].length > 0
              ? companyPageRecord?.country[0]
              : companyPageRecord?.country
              ? companyPageRecord?.country
              : "-") ||
            companyPageRecord?.roc?.length > 0
              ? companyPageRecord?.roc?.[0]?.replace("ROC ", "")
              : "-"}
          </span>
        </>
      </div>
      <div
        className="realtime-result__child"
        style={{ textTransform: "capitalize" }}
      >
        Date Of Incorporation <br />{" "}
        {/*{companyPageRecord?.Date_of_funding ? (*/}
        <>
          <span className="text-danger">
            {typeof companyPageRecord?.date_of_incorporation === "object" &&
            companyPageRecord?.date_of_incorporation[0].length > 0
              ? dayjs(companyPageRecord?.date_of_incorporation[0]).format(
                  "DD MMM YYYY"
                )
              : "-"}{" "}
          </span>
        </>
      </div>
    </>
  );
};
export default NewlyRegisteredCompaniesOnMCA;
