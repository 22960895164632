import React, { useEffect } from "react";
import CommonSelectedItemInFilter from "../CommonSelectedItemInFilter";

const ItemSelectedActiveAngelInvestorsInIndia = ({
  filterRequest,
  setFilterRequest,
}) => {
  useEffect(() => {}, [filterRequest]);

  return (
    <>
      <div className="query-parent">
        <div
          className="query-container"
          id="query-container"
          data-cy="query-container"
        >
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="company_name"
            image="/assets/images/new_icons/company_name.png"
          />
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="industry"
            image="/assets/images/new_icons/Industry.png"
          />

          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="city"
            image="/assets/images/new_icons/Location.png"
          />

          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="keywords"
            image="/assets/images/new_icons/keywords.png"
          />
        </div>
      </div>
    </>
  );
};

export default ItemSelectedActiveAngelInvestorsInIndia;
