import ResultsSearchBar from "../../SharedComponent/Components/ResultsSearchBar";
import React, { useState, useEffect } from "react";
import CombinedSearchBar from "../../SharedComponent/CombinedSearchBar";
import SelectAllLeads from "./SelectAllLeads";
import ExportResult from "./ExportResult";
import { useSnackbar } from "notistack";

const SearchAndExportBar = (props) => {
  const [customCount, setCustomCount] = useState(props?.currentLeads?.length);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setCustomCount(props?.selectedLeads?.length);
  }, [props?.selectedLeads]);

  const handleSearch = async (e) => {
    setCustomCount(e.target.value);
    if ((customCount <= 0) | (customCount > props?.currentLeads?.length)) {
      enqueueSnackbar(
        "Value should be in between 1 - " + props?.currentLeads?.length + " !",
        { variant: "error" }
      );
      setCustomCount(props?.currentLeads?.length);
      props?.setSelectedLeads([]);
      return;
    }
    props?.setSelectedLeads(props?.currentLeads.slice(0, customCount));
  };

  // useEffect(() => {
  //   if (customCount == props?.pageLeadsData?.length){
  //     props?.setIsCheckAll(true)}
  // }, [customCount]);
  {console.log("location->",props.props.props?.location?.pathname === "/geolocator_history")}
  return (
    <>
      <div
        className={
          props?.isExtensionResults
            ? "result-parent extension-page-margin"
            : "result-parent"
        }
      >
        {" "}
        {props?.isRealtime ? "" : <CombinedSearchBar isResultPage={false} />}
      </div>
      <div className="user-widget-box  my-3">
        <div
          className="d-flex align-items-center justify-content-between py-3"
          data-cy="search-and-export-bar"
          style={{ pointerEvents: props?.selected ? "none" : "" }}
        >
          <div className="d-flex align-items-center">
            {props?.hideExportBar ? (
              ""
            ) : (
              <>
                <SelectAllLeads
                  props={props}
                  isExport={props?.isExport}
                  isCheckAll={props?.isCheckAll}
                  currentLeads={props?.currentLeads}
                  setIsCheckAll={props?.setIsCheckAll}
                  setSelectedLeads={props?.setSelectedLeads}
                  setCustomCount={setCustomCount}
                />
                <small className="">
                  <input
                    type="number"
                    value={customCount}
                    size="0"
                    onChange={(e) => setCustomCount(e.target.value)}
                    onInput={(e) => setCustomCount(e.target.value)}
                    onBlur={handleSearch}
                    disabled={!props?.isCheckAll}
                    min="1"
                    max={props?.currentLeads?.length}
                  />{" "}
                  of{" "}
                  {props.props.props?.location?.pathname === "/geolocator_history" ? (
                    <>
                      {/* <b>
                        {props.props.props?.location?.state?.details?.search_results ? props.props.props?.location?.state?.details.search_results?.[0].count : 0}
                      </b>{" "} */}
                      hello
                      Searched profiles{" "}
                    </>
                  ) 
                  : props?.selectedLeads?.length ===
                  props?.currentLeads?.length ? (
                    <>
                      <b>{props?.currentLeads?.length}</b> Searched profiles
                    </>
                  ) : (
                    <>
                      <b>
                        {props.pageLeadsData ? props.pageLeadsData.length : 0}
                      </b>{" "}
                      Searched profiles{" "}
                    </>
                  )}
                </small>

                <small>
                  {"  "} [Total Profiles: <b>{props?.currentLeads?.length}</b>]
                </small>
              </>
            )}
          </div>{" "}
          {props?.isRealtime
            ? ""
            : props?.currentLeads && (
                <ResultsSearchBar
                  props={props.props.props}
                  hideExportBar={props?.hideExportBar}
                  original={props?.leadsCopy}
                  setLeads={props?.setCurrentLeads}
                />
              )}
          <div className="d-flex align-items-center">
            {props?.hideExportBar ? (
              ""
            ) : (
              <ExportResult
                props={props}
                selected={props?.selected}
                selectedLeads={props.selectedLeads}
                customCount={customCount}
                setCustomCount={setCustomCount}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchAndExportBar;
