import axios from "axios";
import Cookies from "js-cookie";
//let url = 'https://leadzenmap.analystt.ai/';
//let url = `${process.env.NODE_ENV === 'development'?process.env.REACT_APP_LOCAL_URL:process.env.REACT_APP_LIVE_URL}`
//'http://127.0.0.1:8000/';

let apiServerUrl = process.env.REACT_APP_MAP_URL;
let fastApiURL = process.env.REACT_APP_CONFIG_API_SERVER; //process.env.REACT_APP_CONFIG_API_SERVER

export const httpService = {
  getPincodearea,
  getApartmentdetail,
  getCurrentsavings,
  getLeads,
  get50Leads,
  getSpecificDetails,
  saveToHistory,
  getDetailedRecord,
  getDetailedLeads,
  getPaginateLeads,
};

function getPincodearea(bbox) {
  return axios({
    method: "get",
    url: `${apiServerUrl}pincodearea/${bbox[1]}/${bbox[0]}/${bbox[3]}/${bbox[2]}/`,
    headers: { accept: "application/json" },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getApartmentdetail(bbox) {
  return axios({
    method: "get",
    url: `${apiServerUrl}apartmentdetail/${bbox[1]}/${bbox[0]}/${bbox[3]}/${bbox[2]}/`,
    headers: { accept: "application/json" },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getCurrentsavings(bbox) {
  return axios({
    method: "get",
    url: `${apiServerUrl}currentsavings/${bbox[1]}/${bbox[0]}/${bbox[3]}/${bbox[2]}/`,
    headers: { accept: "application/json" },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

/* function getLeads(bbox,category,offset) {
    return axios({
        method: 'get',
        url: `${apiServerUrl}view/${bbox[3]}/${bbox[0]}/${bbox[1]}/${bbox[2]}/${category}/?limit=15&offset=${offset}`,
    }).then(async function (response) {
        if (response.status === 200) {
            return response.data
        } else {
            return response.status
        }
    }).catch(function (error) {
        return error
    });
} */

function get50Leads(bbox, category, offset) {
  return axios({
    method: "get",
    url: `${apiServerUrl}view/${bbox[3]}/${bbox[0]}/${bbox[1]}/${bbox[2]}/${category}/?limit=50&offset=1`,
    headers: { accept: "application/json" },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getSpecificDetails(id) {
  return axios({
    method: "get",
    url: `${apiServerUrl}viewdetails/${id}/`,
    headers: { accept: "application/json" },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getDetailedRecord(id, searchId, searchType) {
  console.log("id,searchId: ", id, "...", searchId);
  return axios({
    method: "post",
    url: `${fastApiURL}/search/person/map/detailed`,

    data: {
      detailed_id: id,
      simple_search_id: searchId,
      search_type: searchType,
    },
    headers: {
      Authorization: `Bearer ${Cookies.get("user_token")}`,
      accept: "application/json",
    },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      console.log("Error in get detail record", error);
      return error.response;
    });
}

function saveToHistory(data) {
  return axios({
    method: "post",
    url: `${fastApiURL}/search/person/map/simple`,
    data: data,
    headers: {
      Authorization: `Bearer ${Cookies.get("user_token")}`,
      accept: "application/json",
    },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getLeads(id) {
  return axios({
    method: "get",
    url: `${fastApiURL}/search/person/map/simple/result/${id}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("user_token")}`,
      accept: "application/json",
    },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getPaginateLeads(data) {
  return axios({
    method: "post",
    url: `${fastApiURL}/search/person/map/simple/paginate`,
    data: data,
    headers: {
      Authorization: `Bearer ${Cookies.get("user_token")}`,
      accept: "application/json",
    },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}

function getDetailedLeads(id) {
  return axios({
    method: "get",
    url: `${fastApiURL}/search/person/map/detailed/result/${id}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("user_token")}`,
      accept: "application/json",
    },
  })
    .then(async function (response) {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.status;
      }
    })
    .catch(function (error) {
      return error;
    });
}
