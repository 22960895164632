import React from "react";

export const MapCategory = (data) => {
  // console.log("category: ", data.category);
  return (
    <div className="result-container__result-info overflow-hidden">
      <span>
        {data.category
          ? data.category
              .replaceAll("{", "")
              .replaceAll("}", "")
              .replaceAll("'", "")
          : "Not Found"}
      </span>
    </div>
  );
};

export default MapCategory;
