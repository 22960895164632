import React, { useEffect } from "react";
import CommonSelectedItemInFilter from "../CommonSelectedItemInFilter";

const ItemSelectedRecentlyAcquiredCompanies = ({
  filterRequest,
  setFilterRequest,
}) => {
  useEffect(() => {}, [filterRequest]);

  return (
    <>
      <div className="query-parent">
        <div
          className="query-container"
          id="query-container"
          data-cy="query-container"
        >
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="company_that_got_acquired"
            image="/assets/images/new_icons/company_name.png"
          />

          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="company_that_acquired"
            image="/assets/images/new_icons/Funded.png"
          />

          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="date_of_acquisition"
            image="/assets/images/new_icons/date.png"
          />
          {/*<CommonSelectedItemInFilter*/}
          {/*  filterRequest={filterRequest}*/}
          {/*  setFilterRequest={setFilterRequest}*/}
          {/*  itemName="funding_amount"*/}
          {/*  image="/assets/images/new_icons/revenue.png"*/}
          {/*/>*/}
          <CommonSelectedItemInFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            itemName="keywords"
            image="/assets/images/new_icons/keywords.png"
          />
        </div>
      </div>
    </>
  );
};
export default ItemSelectedRecentlyAcquiredCompanies;
