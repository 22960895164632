import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import { setLinkedIn } from "./util";
import {
  cachedGetResultsByPolling,
  cachedPostForExecutionAndGetId,
} from "../../SharedComponent/api";
import Cookies from "js-cookie";
import { apiServer } from "../../../config";

const UnlockCompanyProfile = ({
  index,
  currentPage,
  sheetName,
  setResult,
  openId,
  setOpenId,
  open,
  setOpen,
  companyPageRecord,

  setLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  let linkedin = null;

  const onSuccess = (json, searchIdExe) => {
    console.log("json: ", json);
    if (!json) {
      onError();
    }
    setResult(json?.["search_result"]);
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
    // enqueueSnackbar("Error in Search", { variant: "error" });
  };

  const onNotFound = () => {
    setLoading(false);
    enqueueSnackbar("Record not found", { variant: "error" });
  };

  const apiProcess = useCallback(
    async (
      requestBody,
      apiEndpoint = "/company/detailed/",
      apiEndpointResult = "/company/detailed/result/",
      onSuccess,
      onError,
      onNotFound
    ) => {
      console.log("apiProcess: ", apiEndpoint, "requestBody: ", requestBody);
      setLoading(true);
      const executionId = await cachedPostForExecutionAndGetId(
        apiEndpoint,
        JSON.stringify(requestBody)
      );
      console.log("New executionId: ", executionId);
      if (!executionId) {
        console.error("Invalid Execution Data", executionId);
        onError();
        return;
      }
      cachedGetResultsByPolling(
        `${apiEndpointResult}${executionId}`,
        (result) => onSuccess(result, executionId),
        () => onError(),
        () => onNotFound()
      );
    },
    []
  );

  async function getLinkedInUrl() {
    try {
      const json = {
        company_name: `${companyPageRecord.company_name} company linkedin`,
      };
      const fetchResponse = await fetch(apiServer + "/valueserp/get_linkedin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(json),
      });

      const response = await fetchResponse.json();
      console.log("response: ", response);
      if (!json) {
        console.log("in error");
        return null;
      }
      return response.linked_in_url;
    } catch (err) {}
  }

  async function handleProfile(companyPageRecord, sheetName, open) {
    console.log("companyPageRecord in detailed: ", companyPageRecord);
    setOpenId(`${currentPage}${index}`);
    try {
      linkedin = setLinkedIn(sheetName, companyPageRecord);
      if (sheetName === "realtime-companies_hiring_recently" && !linkedin) {
        linkedin = await getLinkedInUrl();
        console.log("result by serp: ", linkedin);
      }
      console.log("linkedin: ", linkedin);

      if (
        !open &&
        (sheetName === "realtime-investors_who_funded_recently" ||
          sheetName === "realtime-recently_funded_company" ||
          sheetName === "realtime-recently_acquired_companies" ||
          sheetName === "realtime-companies_hiring_recently" ||
          sheetName === "realtime-financial_intermediaries" ||
          sheetName === "realtime-public_listed_companies" ||
          sheetName === "realtime-unfunded_companies" ||
          sheetName === "realtime-companies_acquiring_recently")
      ) {
        let searchInputBody = {
          identifier: linkedin,
          use_thirdparty_api: true,
          // sheet_name: sheetName,
        };

        if (!searchInputBody) {
          console.warn("BasicSearchResults: Search Bar: No Search Input Body");
          return;
        }
        apiProcess(
          searchInputBody,
          "/company/detailed/",
          "/company/detailed/result/",
          onSuccess,
          onError,
          onNotFound
        ).then(() => {});
      } else if (
        sheetName ===
          "realtime-newly_registered_subsidiaries_of_foreign_companies" ||
        sheetName === "realtime-newly_registered_companies_on_mca"
      ) {
        console.log(sheetName, companyPageRecord);
        setResult(companyPageRecord);
        setLoading(true);

        setLoading(false);
      }
    } catch (err) {
      onError();
    }
  }

  return (
    <>
      <button
        className="btn button v-btn"
        data-toggle="collapse"
        type="button"
        aria-expanded="false"
        aria-controls={"collapseExample_" + index}
        // id={record[rowValues.linkedin_link_1]}
        // style={
        //   record.meta_data?.["unlocked_profile"] && {
        //     backgroundColor: "#FF6852",
        //     color: "white",
        //     fontSize: "14px",
        //   }

        onClick={(e) => {
          handleProfile(companyPageRecord, sheetName, open);
          // props.setNumber(props.index);
          setOpen((open) => !open);
        }}
      >
        {open ? "Hide Profile" : "View Profile"}
        {/*{open ? (*/}
        {/*  "Hide Profile"*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    {record.meta_data?.["unlocked_profile"] ? (*/}
        {/*      <span*/}
        {/*        className="search-tool"*/}
        {/*        tooltip-data="You have unlocked this information before"*/}
        {/*      >*/}

        {/*    </span>*/}
        {/*  ) : (*/}
        {/*    "Unlock Profile"*/}
        {/*  )}*/}
        {/*</>*/}
        {/*)}*/}
      </button>
    </>
  );
};
export default UnlockCompanyProfile;
