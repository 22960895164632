import Emitter from "../../Utils/emitter";
import {isObjectValuesEmpty} from "../../Utils/utils";
import {getFetch, postFetch} from "../../../Config/facade";

/**
 * Fetches initial displayed records for SME Companies
 * @return {Promise<any>}
 */
export const fetchSMEData = async () => {
    return await getFetch("/sme_companies/read_data");
};

/**
 * Fetches filtered data as given inputs on Filters
 * @param payload - follows the Request model of the below API Endpoint
 * @return {Promise<any>}
 */
export const fetchSMEFilteredRecords = async (payload) => {
    if (!isObjectValuesEmpty(payload))
        return await postFetch("/sme_companies/query_data", payload);
};

/**
 * Fetches record data where uuid_ is passed, this handles credit deduction
 * @param payload - is the uuid_ of the SME record object
 * @return {Promise<any>}
 */
export const fetchSMEUnlockedRecord = async (payload, search_id_payload) => {
    console.log("payload,search_id_payload ", payload, search_id_payload)
    if (payload) {
        const response = await getFetch(`/sme_companies/unlock/${payload}/${search_id_payload}`);

        Emitter.emit("updateCredits", true);
        Emitter.emit("fetchCredits", true);
        return response;
    }
};

/**
 *
 * @param payload
 * @return {Promise<any>}
 */
export const fetchGeoSMERecords = async (payload) => {
    if (!isObjectValuesEmpty(payload))
        return await postFetch("/sme_companies/geolocator_data", payload);
};

/**
 * Fetches an Excel file response that is downloaded instantly
 * @param payload - is array of uuid_ selected by the user
 * @return {Promise<Blob>}
 */
export const fetchSMEFile = async (payload) => {
    if (payload) {
        const response = await postFetch("/sme_companies/export/excel", payload);

        Emitter.emit("updateCredits", true);
        return response;
    }
};
