import { useSnackbar } from "notistack";
import { isObjectValuesEmpty, Spinner } from "../Utils/utils";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { mailOpener, openDialer } from "../../Utils/helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MessageDialog from "../PersonalizedMessages/MessageDialog";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const initialAnchorElArray = Array.from({ length: 30 }, () => null);

export const AssociatedPhones = ({ phones, handleWhatsapp, onlyEmail, isRealTime }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [popupOpen, setPopupOpen] = useState(false);
  const [anchorElArray, setAnchorElArray] = useState(initialAnchorElArray);

  const open = Boolean(anchorElArray?.length);
  const handleClick = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = newAnchorElArray[index]
      ? null
      : event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  return (
    <section className="pr-5">
      <h5> Associated Phone Numbers </h5>

      {onlyEmail ? (
        <p>
          Please click on <strong>Unlock Profile</strong>
        </p>
      ) : !phones?.[0]?.number ? (
        <p className="m-0">Not Found </p>
      ) : (
        phones?.map((phone, index) => (
          <div
            key={`phone-${index}`}
            className="d-flex align-items-center mb-3 max-w-content"
          >
            <WhatsAppIcon
              sx={{
                fontSize: 20,
                color: "#4EC57E",
                marginRight: "1rem",
              }}
            />
            <Tooltip title="Tap for more actions" arrow>
              <Button
                variant="outlined"
                data-cy="linkedin-link"
                aria-controls={open ? phone?.number : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleClick(e, index)}
                sx={{
                  flex: 2,
                  color: "black",
                  border: "1px solid black",
                  outline: "none",
                  borderRadius: "50px",
                  margin: "0px",
                  marginRight: "1rem",
                }}
              >
                {phone?.number}
              </Button>
            </Tooltip>
            <Menu
              id={phone?.number}
              anchorEl={anchorElArray[index]}
              open={Boolean(anchorElArray[index])}
              onClose={() => handleClose(index)}
              MenuListProps={{
                "aria-labelledby": phone?.number,
              }}
              sx={{
                borderRadius: "50px",
              }}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  gap: "4px",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
                onClick={() => openDialer(phone?.number)}
              >
                <OpenInNewIcon style={{ color: "#7B7A80" }} />
                Open Whatsapp
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose(index);
                  setPopupOpen(true);
                  handleWhatsapp(phone?.number);
                }}
                sx={{
                  display: "flex",
                  gap: "4px",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <WhatsAppIcon style={{ color: "#7B7A80" }} />
                Send Personalized Message
              </MenuItem>
            </Menu>
            <div
              style={{
                flex: 1,
              }}
            >
              <img
                className="me-2 ms-2"
                s
                style={{ height: "1rem", cursor: "pointer" }}
                src="/assets/images/new_icons/Copy.png"
                alt="copy-button"
                onClick={async () => {
                  await navigator.clipboard.writeText(phone?.number);
                  enqueueSnackbar("Phone Number Copied!");
                }}
              />
              <img
                className="ms-2"
                style={{ width: "0.8rem", height: "0.8rem" }}
                src="/assets/images/new_icons/Verified.png"
                alt="Verified Contact Number"
              />
            </div>
            {isRealTime && <MessageDialog
              data={phone?.number}
              open={popupOpen}
              title="whatsapp"
              onClose={handlePopupClose}
            />}
          </div>
        ))
      )}
    </section>
  );
};

const EmailInfo = ({ index, email, verified, handleClickOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      key={`email-${index}`}
      className="d-flex align-items-center gap-5 max-w-content mb-3"
    >
      <Tooltip title="Tap for more actions" arrow>
        <Button
          data-cy="linkedin-link"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="outlined"
          sx={{
            flex: 2,
            color: "black",
            border: "1px solid black !important",
            outline: "none",
            borderRadius: "50px",
            minWidth: "min-content",
          }}
        >
          {email}
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          borderRadius: "50px",
        }}
      >
        <MenuItem
          onClick={() => mailOpener(email)}
          sx={{
            display: "flex",
            gap: "4px",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <SendIcon style={{ color: "#7B7A80" }} />
          Open Email
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleClickOpen(email);
          }}
          sx={{
            display: "flex",
            gap: "4px",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <EmailOutlinedIcon style={{ color: "#7B7A80" }} />
          Send Personalized Email
        </MenuItem>
      </Menu>
      <div
        style={{
          flex: 1,
        }}
      >
        <img
          className="me-2 ms-2"
          style={{ height: "1rem", cursor: "pointer" }}
          src="/assets/images/new_icons/Copy.png"
          alt="copy-button"
          onClick={async () => {
            await navigator.clipboard.writeText(email);
            enqueueSnackbar("Email Address Copied!");
          }}
        />
        {verified && (
          <img
            className="ms-2"
            style={{ height: "0.75rem", width: "0.75rem" }}
            src="/assets/images/new_icons/Verified.png"
            alt="Verified Email Address"
          />
        )}
      </div>
    </div>
  );
};

export const AssociatedWorkEmail = ({
  workEmailLoading,
  workEmailInfo,
  onlyProfile,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const handleClickOpen = async (email) => {
    setData(email);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <h5>Associated Work Email</h5>
      {onlyProfile ? (
        <p>
          Please click on <strong>Unlock Work Email</strong>
        </p>
      ) : (
        <>
          {workEmailLoading ? (
            <div className="m-3">
              <Spinner />
            </div>
          ) : (
            <div className="mb-3">
              {!isObjectValuesEmpty(workEmailInfo) ? (
                <EmailInfo
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  index="main-email"
                  email={workEmailInfo?.email}
                  verified={workEmailInfo?.verified}
                />
              ) : (
                "Not Found"
              )}
            </div>
          )}
        </>
      )}
      <MessageDialog
        data={data}
        open={open}
        title="email"
        onClose={handleClose}
      />
    </div>
  );
};

export const AssociatedEmails = ({ emails }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const handleClickOpen = async (email) => {
    setData(email);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className="pr-5">
      {emails ? (
        <div>
          <h5> Associated Email Addresses </h5>
          {!emails?.length && <p className="m-0">Not Found </p>}

          {emails?.map((email, index) => (
            <EmailInfo
              handleClickOpen={handleClickOpen}
              key={index}
              index={index}
              email={email?.email}
              verified={email?.verified}
            />
          ))}
        </div>
      ) : null}
      <MessageDialog
        data={data}
        open={open}
        title="email"
        onClose={handleClose}
      />
    </section>
  );
};

export const MiscInfo = ({ gender, dob, languages, usernames }) => {
  return (
    <div>
      {gender ? (
        <>
          <h6> Gender </h6>
          <div className="ms-2 d-flex align-items-center mb-3">
            <small>{gender ? gender : null}</small>
          </div>
        </>
      ) : null}
      {dob ? (
        <>
          <h6> Age </h6>
          <div className="ms-2 d-flex align-items-center mb-3">
            <small>{dob ? <span> {dob} </span> : null}</small>
          </div>
        </>
      ) : null}
      {languages?.length ? <h6> Languages Known </h6> : null}
      {languages?.length
        ? languages?.map((language, index) => (
            <div
              key={`language-${index}`}
              className="ms-2 d-flex align-items-center mb-3"
            >
              <small> {language} </small>
            </div>
          ))
        : null}
      {usernames?.length ? <h6> Associated Usernames </h6> : null}
      {usernames?.length
        ? usernames?.map((username, index) => (
            <div
              key={`username-${index}`}
              className="ms-2 d-flex align-items-center mb-3"
              align="left"
            >
              <div className="d-flex align-items-center">
                <small className="ms-2"> {username} </small>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export const AssociatedJobs = ({ jobs }) => {
  console.log(jobs)
  const removeDuplicates = (jobs) => {
    const seen = new Set();
    const uniqueJobs = [];

    for (const job of jobs) {
        const name = job?.job_name;
        const end_date = job?.end_date;
        const start_date = job?.start_date;
        const company_name = job?.company_name;
        const combinedKey = `${name}-${end_date}-${start_date}}-${company_name}`;

        if (!seen.has(combinedKey)) {
            seen.add(combinedKey);
            uniqueJobs.push(job);
        }
    }

    return uniqueJobs;
};
const uniqueJobs = removeDuplicates(jobs || [])
  return (
    <section className="item-section">
      <h4>Associated Jobs and Companies</h4>
      <table>
        <thead>
          <tr className="table-alignment-three container-fluid">
            <th>Company</th>
            <th>Role</th>
            <th>Period</th>
          </tr>
        </thead>

        <tbody>
          {uniqueJobs?.map((job, index) => (
            <tr
              key={`job-${index}`}
              className="table-alignment-three container-fluid"
            >
              <td>{job["company_name"]}</td>
              <td> {job["job_name"]} </td>
              <td>
                {job["start_date"]}{" "}
                {job["end_date"] ? `to ${job["end_date"]}` : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export const ProbableEducation = ({ educations }) => {
  return (
    <section className="item-section">
      <h4> Probable Education Associated </h4>
      <table>
        <thead>
          <tr className="table-alignment-two container-fluid">
            <th>Degree</th>
            <th>School</th>
          </tr>
        </thead>

        <tbody>
          {educations?.map(({ degree, school }, index) => (
            <tr
              key={`education-${index}`}
              className="table-alignment-two container-fluid"
            >
              <td> {degree || "--"} </td>
              <td> {school} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export const SocialMedia = ({ urls, locations }) => {
  return (
    <section className="item-section">
      <div className="row">
        <div className="col-md-6" style={{ overflowWrap: "break-word" }}>
          {urls?.length ? <h4> Associated urls </h4> : null}

          <small className="ms-2">
            {urls?.map((url, index) => (
              <div key={`url-${index}`} style={{ wordBreak: "break-word" }}>
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </div>
            ))}{" "}
          </small>
        </div>

        <div className="col-md-6">
          {locations?.length ? <h4> List of Locations </h4> : null}

          {locations?.map((location, index) => (
            <p key={`location-${index}`}>
              {location.replace("Contact Info", "")}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};
