export const initialState = {
  searchText: "",
  toggleButton: "people",
  searchCache: {},
  totalSearchRecords: 0,
  page: 0,
  view: "normal",
  totalPersonRecords: 0,
  totalCompanyRecords: 0,
  totalGeolocatorRecords: 0,
  personResultsCache: {},
  companyResultsCache: {},
  geolocatorResultsCache: {},
  itemsPerPage: 10,
};

export const saveListReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_TEXT":
      return { ...state, searchText: action.payload };
    case "SET_TOGGLE_BUTTON":
      return { ...state, toggleButton: action.payload };
    case "SET_SEARCH_CACHE":
      return { ...state, searchCache: action.payload };
    case "SET_TOTAL_SEARCH_RECORDS":
      return { ...state, totalSearchRecords: action.payload };
    case "SET_PAGE":
      return { ...state, page: action.payload };
    case "SET_VIEW":
      return { ...state, view: action.payload };
    case "SET_TOTAL_PERSON_RECORDS":
      return { ...state, totalPersonRecords: action.payload };
    case "SET_TOTAL_COMPANY_RECORDS":
      return { ...state, totalCompanyRecords: action.payload };
    case "SET_TOTAL_GEOLOCATOR_RECORDS":
      return { ...state, totalGeolocatorRecords: action.payload };
    case "SET_PERSON_RESULTS_CACHE":
      return { ...state, personResultsCache: action.payload };
    case "SET_COMPANY_RESULTS_CACHE":
      return { ...state, companyResultsCache: action.payload };
    case "SET_GEOLOCATOR_RESULTS_CACHE":
      return { ...state, geolocatorResultsCache: action.payload };
    default:
      return state;
  }
};
