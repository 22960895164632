import React from "react";
import img from "../../images/successful.png";
import "./PaymentSuccessAndFailure.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PaymentSuccess = () => {
  const history = useHistory();
  return (
    <div className="payment">
      <div className="content">
        <div className="text ">
          <div className="heading mb-4">Thank You!</div>
          <div className="success mb-4">The payment was successful</div>
          <div className=" transaction mb-5">
            Payment is successfully done. Your credits will be refilled shortly
          </div>
          <button
            className="button mt-4"
            style={{
              backgroundColor: "#fb3e3e",
              color: "#fff",
            }}
            onClick={() => {
              history.push({
                pathname: "/dashboard",
              });
            }}
          >
            <span className="button-text">Go to Home Page</span>
          </button>
        </div>
        <img src={img} alt="successful" className="successful"></img>
      </div>
    </div>
  );
};

export default PaymentSuccess;
