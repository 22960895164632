import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButton } from "./MUICustomized";

const HistoryButtons = ({ toggle, setToggle }) => {
  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={toggle}
      exclusive
      onChange={handleToggle}
      aria-label="history buttons"
      sx={{
        margin: "0",
        marginBottom: "0.5rem",
        backgroundColor: "#ffffff",
        border: "2px solid #fb3e3e",
        borderRadius: "25px",
        height: "3.25rem",
      }}
    >
      <ToggleButton value="people" aria-label="people">
        People
      </ToggleButton>
      <ToggleButton value="company" aria-label="company">
        Company
      </ToggleButton>
      <ToggleButton value="bulk_downloads" aria-label="bulk downloads">
        Bulk/Export
      </ToggleButton>
      <ToggleButton value="custom_downloads" aria-label="custom downloads">
        Custom Delivered
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default HistoryButtons;
