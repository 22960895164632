import React, { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Pagination from "../../SharedComponent/Pagination";
import Emitter from "../../Utils/emitter";
import SearchResultItems from "./SearchResultItems";
import SearchAndExportBar from "./SearchAndExportBar";
import InputOrHistorySearchText from "./InputOrHistorySearchText";
import { motion } from "framer-motion";
import {
  cachedGetResultsByPolling,
  cachedPostForExecutionAndGetId,
} from "../../SharedComponent/api";
import MapResultFromHistory from "../../mapResultFromHistory/MapResultFromHistory";
import { fadeInFadeOut } from "../../../Config/motions";
import RealtimeFilters from "../../RealTime/filters/RealtimeFilters";
import CompanyFilterSearch from "../../CompanyFilter/CompanySearchResultItems";
import { Loader } from "../../Utils/utils";
import { callSaveSimpleHistoryOnly } from "../utils";
import { apiServer } from "../../../config";
import { Box, Tab, Tabs } from "@mui/material";
import { current } from "immer";

const SearchResultList = (props) => {
  const [companySearch, setCompanySearch] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [prospectData, setProspectData] = useState({});
  const [currentLeads, setCurrentLeads] = useState([]);
  const [searchedLeads, setSearchedLeads] = useState([]);
  const [leadsCopy, setLeadsCopy] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [pageLeadsData, setPageLeadsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const RECORDS_PER_SHEET = 10;

  const [simpleSearchId, setSimpleSearchId] = useState(() => "");
  const [loading, setLoading] = useState(() => true);
  const [isExport, setExport] = useState(() => true);
  const [isCheckAll, setIsCheckAll] = useState(() => false);
  const [isRealTime, setIsRealTime] = useState(() => false);
  const [isRealTimeHistory, setIsRealTimeHistory] = useState(false);
  const [isMap, setIsMap] = useState(() => false);
  const [isExtensionResults, setIsExtensionResults] = useState(() => false);
  const [tabValue, setTabValue] = useState("total_results");

  const { enqueueSnackbar } = useSnackbar();

  console.log("currentLeads", currentLeads);

  const urlId = useParams();
  const history = useHistory();

  const apiHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  };
  const searchedTerm = props?.props?.location?.state?.payload;

  console.log("props in filter history : ", props);
  useEffect(() => {
    (async () => {
      setCurrentLeads([]);

      async function handleCompanyAndNameSearch() {
        let searchInputBody = {};
        searchInputBody.search_type = "company_and_name";
        searchInputBody.search_text = props.props.location?.state?.searchInput;
        searchInputBody.cookie = Cookies.get("user_linkedin_cookie");
        apiProcess(
          searchInputBody,
          "/search/person/simple/",
          "/search/person/simple/result/",
          onSuccess,
          onError,
          onNotFound
        ).then(() => {});
      }

      async function handleSearchBarSearch() {
        let searchInputBody = {};
        searchInputBody.search_type = "search_bar";
        searchInputBody = props.props.location.state.searchInput;
        searchInputBody.cookie = Cookies.get("user_linkedin_cookie");
        if (!searchInputBody) {
          console.warn("BasicSearchResults: Search Bar: No Search Input Body");
          return;
        }
        apiProcess(
          searchInputBody,
          "/search/person/simple/",
          "/search/person/simple/result/",
          onSuccess,
          onError,
          onNotFound
        ).then(() => {});
      }

      async function handleUnlockProfile() {
        try {
          setLoading(true);
          const response = await callSaveSimpleHistoryOnly(
            props?.props?.location?.state?.details
          );

          const handleSuccess = async () => {
            setLoading(false);
            const data = await response.json();
            setSimpleSearchId(data.id);
            // setCurrentLeads(data);
            // todo fix from get proper results from api
            setProspectData({
              total_results: [props?.props?.location?.state?.details],
              net_new_results: [props?.props?.location?.state?.details],
              unlocked_results: [],
            });
            setLeadsCopy(data);
            setTotalCount(1);
          };

          switch (response.status) {
            case 404:
              setLoading(false);
              setCurrentLeads([]);
              enqueueSnackbar("You didn't search anything yet!");
              break;
            case 200:
              await handleSuccess();
              break;
            default:
              setLoading(false);
              enqueueSnackbar(
                "Something went wrong. Please try again after sometime",
                { variant: "error" }
              );
          }
        } catch (error) {
          setLoading(false);
          console.error(`Error while fetching extension search data ${error}`);
        }
      }

      async function handleProspectSearch() {
        setLoading(true);
        if (!props.props.location.state.searchInput) {
          console.error(
            "BasicSearchResults: Prospect Search: No Search Input Body"
          );
          return;
        }

        console.table(props.props.location.state.searchInput);

        apiProcess(
          JSON.parse(props.props.location.state.searchInput),
          "/prospect/match/",
          "/prospect/match/result/",
          onSuccess,
          onError,
          onNotFound
        ).then(() => {});
      }

      async function handleProspectSearchAlternate() {
        if (!props.props.location.state.searchInput) {
          console.error(
            "BasicSearchResults: Prospect Search Alternate: No Search Input Body"
          );
          return;
        }

        console.table(props.props.location.state.searchInput);

        apiProcess(
          JSON.parse(props.props.location.state.searchInput),
          "/prospect/match/alt",
          "/prospect/match/alt/result/",
          onSuccess,
          onError,
          onNotFound
        ).then(() => {});
      }

      async function handleHistory(hash_key) {
        try {
          const fetchResponse = await fetch(
            apiServer + `/history/simple/${hash_key}`,
            {
              headers: apiHeader,
            }
          );

          switch (fetchResponse.status) {
            case 200:
              return await handleSuccess(fetchResponse);
            case 401:
              return handleUnAuthorized();
            default:
              return handleError(fetchResponse);
          }
        } catch (err) {
          handleError(err);
        }
      }

      async function handleSuccess(fetchResponse) {
        let json = await fetchResponse.json();
        console.log("json in history success: ", json);
        if (!json) {
          console.warn(`Invalid Data`);
          return handleError();
        }
        if (
          json.total_results[0].details_url.includes("www.linkedin.com/sales/")
        )
          setExport(false);
        setSimpleSearchId(json.total_results[0].id);
        setLoading(false);
        setProspectData(json);
        setLeadsCopy(json);
        console.log(
          "in history isRealTimeHistory: ",
          isRealTimeHistory,
          json.length
        );
      }

      async function handleExtensionSearch() {
        try {
          setLoading(true);
          const response = await fetch(
            `${apiServer}/search/person/extension/${urlId.id}`,
            {
              method: "GET",
              headers: apiHeader,
            }
          );

          const handleSuccess = async () => {
            setLoading(false);
            const data = await response.json();
            console.log("data: ", data);
            // setCurrentLeads(data);
            // todo fix from get proper results from api
            setProspectData({
              total_results: data,
              net_new_results: data,
              unlocked_results: [],
            });
            setLeadsCopy(data);
          };

          switch (response.status) {
            case 404:
              setLoading(false);
              setCurrentLeads([]);
              enqueueSnackbar("You didn't search anything yet!");
              break;
            case 200:
              await handleSuccess();
              break;
            default:
              setLoading(false);
              enqueueSnackbar(
                "Something went wrong. Please try again after sometime",
                { variant: "error" }
              );
          }
        } catch (error) {
          setLoading(false);
          console.error(`Error while fetching extension search data ${error}`);
        }
      }

      switch (props.props.location.pathname) {
        case "/SearchBarSearch": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          await handleSearchBarSearch();
          break;
        }

        case "/UnlockProfile": {
          const leads = [props?.props?.location?.state?.details];
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setLoading(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          setCurrentLeads(leads);
          setLeadsCopy(leads);

          await handleUnlockProfile();
          break;
        }

        case "/ProspectSearch": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          await handleProspectSearch();
          break;
        }

        case "/ProspectSearchAlternate": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          await handleProspectSearchAlternate();
          break;
        }

        case "/simple_history_all": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          await handleHistory(props?.props?.location?.state?.details.hash_key);
          break;
        }

        case "/CompanyAndNameSearch": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          await handleCompanyAndNameSearch();
          break;
        }

        case "/geolocator_history": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsRealTime(false);
          setIsRealTimeHistory(false);
          setIsMap(true);
          break;
        }

        case "/RealtimePeopleSearch": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(true);
          setIsRealTimeHistory(false);
          await handleRealtimePeopleSearch(1);
          break;
        }

        case "/people/realtime/filter": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(true);
          setIsRealTimeHistory(false);
          await handleRealtimePeopleFilterSearch(1);
          break;
        }

        case "/people/realtime/history": {
          setIsExtensionResults(false);
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsMap(false);
          setIsRealTime(false);
          // setIsRealTimeHistory(true);
          // await handleHistory(props?.props?.location?.state?.details.hash_key);
          break;
        }

        case "/extensionCompany": {
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsRealTimeHistory(false);
          setIsExtensionResults(true);
          setSimpleSearchId(props?.props?.location?.state?.details?.id);
          setProspectData({
            total_results:
              props?.props?.location?.state?.details?.search_results,
            net_new_results: [],
            unlocked_results: [],
          });
          setLeadsCopy(props?.props?.location?.state?.details?.search_results);
          setLoading(false);
          break;
        }
        default:
          setSelectedLeads([]);
          setIsCheckAll(false);
          setIsRealTimeHistory(false);
          setIsExtensionResults(true);
          setSimpleSearchId(urlId.id);
          await handleExtensionSearch();
      }
    })();
  }, [props?.props?.location?.state]);

  // useEffect(() => {
  //   paginate(1);
  // }, [currentLeads]);

  useEffect(() => {
    if (selectedLeads.length === 0) setIsCheckAll(false);
  }, [selectedLeads]);

  async function handleRealtimePeopleSearch(page) {
    let searchInputBody = {
      sheet_name: props?.props?.location?.state?.searchInput?.index,
      // records_per_page: 10,
      page: page,
    };

    if (!searchInputBody) {
      console.warn("BasicSearchResults: Search Bar: No Search Input Body");
      return;
    }

    apiProcess(
      searchInputBody,
      "/realtime/records",
      "/realtime/records/result/",
      onSuccessRealtime,
      onError,
      onNotFound
    ).then(() => {});
  }

  const handleRealtimePeopleFilterSearch = async (page) => {
    setIsRealTime(true);
    setCurrentLeads([]);
    setLeadsCopy([]);
    setTotalCount([]);
    setCurrentPage(1);
    let searchInputBody = {
      sheet_name: props?.props?.location?.state?.sheetName
        ? props?.props?.location?.state?.sheetName
        : "",
      payload: props?.props?.location?.state?.payload
        ? props?.props?.location?.state?.payload
        : {},
      // records_per_page: 10,
      page: page,
    };

    if (
      !searchInputBody ||
      searchInputBody.sheet_name === "" ||
      searchInputBody.payload === null
    ) {
      console.warn("Realtime Filter: No Search Input Body");
      return;
    }
    console.log(
      "### handleRealtimePeopleFilterSearch searchInputBody: ",
      searchInputBody
    );
    apiProcess(
      searchInputBody,
      "/realtime/filters",
      "/realtime/filters/result/",
      onSuccessRealtime,
      onError,
      onNotFound
    ).then(() => {});
  };

  const onSuccessRealtime = async (json, searchIdExe) => {
    console.log("in onSuccessRealtime");
    setSimpleSearchId(searchIdExe);
    let filteredCurrentLeads = json?.search_results;
    setCurrentLeads(filteredCurrentLeads);
    setLeadsCopy(filteredCurrentLeads);
    setTotalCount(filteredCurrentLeads?.length);
    setTabValue("realtime_results");
    setPageLeadsData(
      filteredCurrentLeads.slice(
        currentPage * RECORDS_PER_SHEET - RECORDS_PER_SHEET,
        currentPage * RECORDS_PER_SHEET
      )
    );
    setLoading(false);
  };

  const onSuccess = async (json, searchIdExe) => {
    setSimpleSearchId(searchIdExe);
    setProspectData(json);
    setTabValue("total_results");
    setLoading(false);
  };

  const onError = () => {
    setCurrentLeads([]);
    setProspectData({});
    setLoading(false);
  };

  const onNotFound = () => {
    setCurrentLeads([]);
    setProspectData({});
    setLoading(false);
  };

  const apiProcess = useCallback(
    async (
      requestBody,
      apiEndpoint = "/search/person/simple/",
      apiEndpointResult = "/search/person/simple/result/",
      onSuccess,
      onError,
      onNotFound
    ) => {
      console.log("apiProcess: ", apiEndpoint, "requestBody: ", requestBody);

      setLoading(true);

      const executionId = await cachedPostForExecutionAndGetId(
        apiEndpoint,
        JSON.stringify(requestBody)
      );

      console.log("New executionId: ", executionId);

      if (!executionId) {
        console.error("Invalid Execution Data", executionId);
        onError();
        return;
      }

      cachedGetResultsByPolling(
        `${apiEndpointResult}${executionId}`,
        (result) => onSuccess(result, executionId),
        () => onError(),
        () => onNotFound()
      );
    },
    []
  );

  const getMetaData = useCallback(
    (pageNumber, pageLeadsData, abortController) => {
      // const pageLeads = [...pageLeadsData];
      const urls = pageLeadsData?.map((lead) =>
        lead.details_url ? lead.details_url : lead.id
      );

      console.log("urls ", urls);
      try {
        if (pageLeadsData?.length > 0) {
          setTimeout(async () => {
            try {
              const res = await fetch(apiServer + "/history/metadata", {
                method: "POST",
                headers: apiHeader,
                body: JSON.stringify({ ids: urls }),
                signal: abortController.signal,
              });

              if (!res.ok) {
                return;
              }
              const metadata = await res.json();

              if (!metadata) {
                console.log("No Metadata Found");
                return;
              }
              console.log("Meta_data: ", metadata);

              setPageLeadsData((prevPageLeadsData) => {
                return prevPageLeadsData.map((record, index) => {
                  return {
                    ...record,
                    meta_data: {
                      ...record.meta_data,
                      user_unlocked: metadata?.user_metadata
                        ? { ...metadata.user_metadata[index] }
                        : null,
                      team_unlocked: metadata?.unlocked_by_others
                        ? { ...metadata.unlocked_by_others[index] }
                        : null,
                    },
                  };
                });
              });
            } catch (error) {
              if (error.name === "AbortError") {
                console.warn("getMetaData aborted");
              }
              console.warn(error);
            }
          }, 10);
        }
      } catch (e) {
        console.warn(e);
      }
      // return pageLeadsData;
    },
    [pageLeadsData]
  ); // Add dependencies if needed

  const onViewProfileOrWorkEmail = async () => {
    const abortController = new AbortController();
    console.log("before onviewprofileorworkemail metadata");
    getMetaData(currentPage, pageLeadsData, abortController);
    Emitter.emit("updateCredits", true); // Get user details
    Emitter.emit("fetchCredits", true); // Update credits
  };

  function handleUnAuthorized() {
    console.log("User is UnAuthorized");
    setLoading(false);
    enqueueSnackbar("Please Logout and LogIn Again");
  }

  function handleError(response = null) {
    if (response && response.status) {
      console.error(`Error, Status Code: ${response.status}`);
    }
    setLoading(false);
    enqueueSnackbar("Error in Search", { variant: "error" });
  }

  const paginate = async (pageNumber) => {
    console.log("in paginate: ", pageNumber, props?.props?.location?.pathname);
    setCurrentPage(pageNumber);
    // await getMetaData(pageNumber, pageLeadsData);
    // if (isRealTime) {
    //   if (props?.props?.location?.pathname === "/people/realtime/filter") {
    //     console.log("in filter paginate");
    //     await handleRealtimePeopleFilterSearch(pageNumber);
    //   } else {
    //     console.log("in normal paginate");
    //     await handleRealtimePeopleSearch(pageNumber);
    //   }
    // } else {
    // await getMetaData(pageNumber, currentLeads);
    // }
  };

  // useEffect(() => {
  //   setPageLeadsData([]);
  //   setValue("total_results");
  // }, [props]);

  // todo use redux / reducer to handle tab change
  useEffect(() => {
    let pageLeads = [];
    // Fix race condition: abort the currect metadata req on tab change
    const abortController = new AbortController();

    switch (tabValue) {
      case "total_results": {
        if (prospectData?.total_results) {
          pageLeads = prospectData.total_results.slice(
            currentPage * 10 - 10,
            currentPage * 10
          );
          setCurrentLeads(prospectData.total_results);
          setLeadsCopy(prospectData.total_results);
          setTotalCount(prospectData.total_results?.length);
        } else {
          setPageLeadsData([]);
          setCurrentLeads([]);
          setLeadsCopy([]);
          setTotalCount(0);
        }
        break;
      }
      case "net_new_results": {
        if (prospectData?.net_new_results) {
          pageLeads = prospectData.net_new_results.slice(
            currentPage * RECORDS_PER_SHEET - RECORDS_PER_SHEET,
            currentPage * RECORDS_PER_SHEET
          );
          setCurrentLeads(prospectData.net_new_results);
          setLeadsCopy(prospectData.net_new_results);
          setTotalCount(prospectData.net_new_results?.length);
        } else {
          setPageLeadsData([]);
          setCurrentLeads([]);
          setLeadsCopy([]);
          setTotalCount(0);
        }
        break;
      }
      case "unlocked_results": {
        if (prospectData?.unlocked_results) {
          pageLeads = prospectData.unlocked_results.slice(
            currentPage * RECORDS_PER_SHEET - RECORDS_PER_SHEET,
            currentPage * RECORDS_PER_SHEET
          );
          setCurrentLeads(prospectData.unlocked_results);
          setLeadsCopy(prospectData.unlocked_results);
          setTotalCount(
            prospectData.unlocked_results
              ? prospectData.unlocked_results.length
              : 0
          );
        } else {
          setPageLeadsData([]);
          setCurrentLeads([]);
          setLeadsCopy([]);
          setTotalCount(0);
        }
        break;
      }
      case "search_within_results": {
        if (searchedLeads) {
          pageLeads = searchedLeads.slice(
            currentPage * RECORDS_PER_SHEET - RECORDS_PER_SHEET,
            currentPage * RECORDS_PER_SHEET
          );
          setCurrentLeads(searchedLeads);
          setTotalCount(searchedLeads ? searchedLeads.length : 0);
        } else {
          // setPageLeadsData([]);
          // setCurrentLeads([]);
          // setLeadsCopy([]);
          // setTotalCount(0);
          setSearchedLeads([]);
          setTabValue("total_results");
        }
        break;
      }
      default: {
        console.log("running default case for tab");
        pageLeads = currentLeads.slice(
          currentPage * RECORDS_PER_SHEET - RECORDS_PER_SHEET,
          currentPage * RECORDS_PER_SHEET
        );
      }
    }
    console.log(
      "pageLeadsData in useeffect",
      pageLeadsData,
      "pageleads",
      pageLeads
    );
    setPageLeadsData(pageLeads);

    getMetaData(currentPage, pageLeads, abortController);

    return () => {
      abortController.abort();
    };
  }, [
    searchedLeads,
    currentPage,
    prospectData.net_new_results,
    prospectData.total_results,
    prospectData.unlocked_results,
    tabValue,
  ]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCurrentPage(1);
  };

  const handleSearchWithinResults = (leads) => {
    console.log(
      "search within results leads",
      leads.length,
      prospectData.total_results.length
    );
    if (prospectData.total_results.length === leads.length) {
      setSearchedLeads([]);
      setTabValue("total_results");
      setCurrentPage(1);
      return;
    }
    setSearchedLeads(leads);
    setTabValue("search_within_results");
  };

  useEffect(() => {
    setSearchedLeads([]);
  }, [props]);

  return (
    <>
      {isRealTime || isRealTimeHistory ? (
        <motion.div className="realtime" {...fadeInFadeOut}>
          <RealtimeFilters
            sheetName={
              props?.props?.location?.state?.searchInput?.index
                ? props?.props?.location?.state?.searchInput?.index
                : props?.props?.location?.state?.sheetName
            }
          />
          <div className="realtime__results-container">
            {searchedTerm && (
              <InputOrHistorySearchText
                payload={props?.props?.location?.state}
              />
            )}
            <div className="sheetRecords__total-records">
              <button
                className="sheetRecords__back-btn ml-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                <img src="/assets/images/new_icons/Back.png" alt="#" />
                <span className="pl-2">Back</span>
              </button>
              <p className="mr-3">
                <span>
                  <b>{pageLeadsData.length}</b> of <b>{totalCount}</b> searched
                  Profiles{" "}
                </span>
                <b>
                  {" "}
                  from{" "}
                  {props?.props?.location?.state?.searchInput?.index
                    ? props?.props?.location?.state?.searchInput?.index
                    : props?.props?.location?.state?.sheetName}{" "}
                </b>
              </p>
            </div>
            <SearchAndExportBar
              props={props}
              isExtensionResults={isExtensionResults}
              isExport={isExport}
              isCheckAll={isCheckAll}
              currentLeads={currentLeads}
              setIsCheckAll={setIsCheckAll}
              setSelectedLeads={setSelectedLeads}
              setCurrentLeads={setCurrentLeads}
              selectedLeads={selectedLeads}
              pageLeadsData={pageLeadsData}
              leadsCopy={leadsCopy}
              isRealtime={isRealTime || isRealTimeHistory}
            />
            {loading ? (
              <Loader />
            ) : (
              <SearchResultItems
                pageLeadsData={pageLeadsData}
                isExport={isExport}
                selectedLeads={selectedLeads}
                setSelectedLeads={setSelectedLeads}
                simpleSearchId={simpleSearchId}
                onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                currentPage={currentPage}
                hash_key={props?.location?.state?.details?.hash_key}
                currentLeads={currentLeads}
                setCurrentLeads={setCurrentLeads}
                isRealTime={isRealTime}
              />
            )}
            <div className="d-flex justify-content-center">
              {currentLeads.length <= 10 ? null : (
                <Pagination
                  props={props}
                  loading={loading}
                  postsPerPage={10}
                  totalPosts={totalCount}
                  paginate={paginate}
                  render={props?.location?.state?.searchInput}
                />
              )}
            </div>{" "}
          </div>
        </motion.div>
      ) : (
        <div className="col-md-8 col-lg-9">
          {props?.props?.location?.pathname === "/UnlockProfile" ? (
            ""
          ) : (
            <InputOrHistorySearchText payload={props.props?.location?.state} />
          )}
          {companySearch ? (
            <>
              <SearchAndExportBar
                props={props}
                isExtensionResults={isExtensionResults}
                isExport={isExport}
                isCheckAll={isCheckAll}
                currentLeads={currentLeads}
                setIsCheckAll={setIsCheckAll}
                setSelectedLeads={setSelectedLeads}
                setCurrentLeads={setCurrentLeads}
                selectedLeads={selectedLeads}
                pageLeadsData={pageLeadsData}
                leadsCopy={leadsCopy}
              />
              <CompanyFilterSearch
                data={companyData}
                loading={loading}
                setLoading={setLoading}
              />
            </>
          ) : (
            !isMap && (
              <>
                {/* <InputOrHistorySearchText payload={props} /> */}
                <SearchAndExportBar
                  props={props}
                  isExtensionResults={isExtensionResults}
                  isExport={isExport}
                  isCheckAll={isCheckAll}
                  currentLeads={currentLeads}
                  setIsCheckAll={setIsCheckAll}
                  setSelectedLeads={setSelectedLeads}
                  setCurrentLeads={handleSearchWithinResults}
                  selectedLeads={selectedLeads}
                  pageLeadsData={pageLeadsData}
                  leadsCopy={prospectData?.total_results}
                  isRealtime={isRealTime || isRealTimeHistory}
                />
                {!isMap &&
                props.props.location.pathname !== "/UnlockProfile" &&
                props.props.location.pathname !== "/extensionCompany" &&
                !props.props.location.pathname.includes(
                  "extensionSearchResult"
                ) ? (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label={
                          prospectData?.total_results && !loading
                            ? `All (${prospectData.total_results.length})`
                            : "All"
                        }
                        value={"total_results"}
                      />
                      <Tab
                        label={
                          prospectData?.net_new_results && !loading
                            ? `Net New (${prospectData.net_new_results.length})`
                            : "Net New"
                        }
                        value={"net_new_results"}
                      />
                      <Tab
                        label={
                          prospectData?.unlocked_results && !loading
                            ? `Unlocked (${prospectData.unlocked_results.length})`
                            : "Unlocked"
                        }
                        value={"unlocked_results"}
                      />
                      {searchedLeads.length ? (
                        <Tab
                          label={
                            searchedLeads && !loading
                              ? `Searched Leads (${searchedLeads.length})`
                              : "Searched Leads"
                          }
                          value={"search_within_results"}
                        />
                      ) : null}
                    </Tabs>
                  </Box>
                ) : null}

                {loading && !isMap ? (
                  <Loader />
                ) : (
                  !isMap && (
                    <>
                      <SearchResultItems
                        pageLeadsData={pageLeadsData}
                        isExport={isExport}
                        selectedLeads={selectedLeads}
                        setSelectedLeads={setSelectedLeads}
                        simpleSearchId={simpleSearchId}
                        onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                        currentPage={currentPage}
                        currentLeads={currentLeads}
                        setCurrentLeads={setCurrentLeads}
                      />
                    </>
                  )
                )}

                <div className="d-flex justify-content-center">
                  {currentLeads.length <= 10 ? null : (
                    <Pagination
                      props={props}
                      loading={loading}
                      postsPerPage={10}
                      totalPosts={totalCount}
                      paginate={paginate}
                      render={tabValue}
                    />
                  )}
                </div>
              </>
            )
          )}
          {isMap && (
            <>
              {/* <InputOrHistorySearchText
                          payload={props.props?.location?.state}
                        /> */}
              <MapResultFromHistory data={props?.props?.location?.state} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SearchResultList;
