import React, { useEffect } from "react";
import CommonDateFilter from "../CommonDateFilter";
import CommonBasicFilter from "../CommonBasicFilter";

const FilterRecentlyAcquiredCompanies = ({
  filterRequest,
  setFilterRequest,
  sheetName,
}) => {
  console.log(
    "FilterRecentlyAcquiredCompanies: ",
    sheetName,
    filterRequest,
    setFilterRequest
  );
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);

  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_that_got_acquired"
          label="Acquired Company Name"
          icon="/assets/images/new_icons/company_name.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_that_acquired"
          label="Acquired By"
          icon="/assets/images/new_icons/Funded.png"
        />

        <CommonDateFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="date_of_acquisition"
          label="Date Of Acquisition"
          tooltipText={"Select Date Range"}
          icon="/assets/images/new_icons/date.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keywords"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};
export default FilterRecentlyAcquiredCompanies;
