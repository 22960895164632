import React from "react";
import { motion } from "framer-motion";
import { slideInSlideOut } from "../../Config/motions";
import { isObjectValuesEmpty, Spinner } from "../Utils/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { useHistory } from "react-router-dom";
import { cleanWebsiteUrl } from "../../Utils/helper";
import { v4 as uuid } from "uuid";

const CompanyViewProfile = ({ data, loading, itemIndex }) => {
  const {
    address,
    address_2,
    about_us,
    affiliated_companies,
    categories,
    company_name,
    company_size,
    country_code,
    cover_image_url,
    employees,
    employees_num,
    fields,
    founded,
    funding,
    crunchbase_url,
    headquarters,
    image_url,
    industry,
    location,
    locations,
    position,
    primary,
    similar_companies,
    social_followers,
    slogan,
    specialties,
    type,
    website,
  } = data;
  const history = useHistory();

  console.log("companyDatasheet=", data);

  const handleProfileButton = (data) => {
    const profile = {
      id: uuid(),
      full_name: data?.full_name,
      location: data?.location,
      job: data?.position,
      details_url: data?.social_url,
      isRealtime: false,
    };

    history.push({
      pathname: "/UnlockProfile",
      state: { details: profile },
    });
  };

  const handleCompanyButton = (data) => {
    // todo make it company
    const profile = {
      id: data?.id,
      industry: data?.industry,
      details_url: data?.social_url,
      isRealtime: false,
    };

    history.push({
      pathname: "/UnlockCompany",
      state: { details: profile },
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <motion.div {...slideInSlideOut}>
          {isObjectValuesEmpty(data) ? (
            <div
              className="company-record-not-found"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Record not found
            </div>
          ) : (
            <motion.div
              {...slideInSlideOut}
              data-cy="profile-company-data-container"
            >
              <section className="item-section">
                <div className="phone-child-div text-left gap-2">
                  <CompanyInfo title={"Company Name"} value={company_name} />
                  <CompanyInfo title={"Company Size"} value={company_size} />
                  <CompanyInfo title={"Company Type"} value={type} />
                  <CompanyInfo
                    title={"Company Headquarters"}
                    value={headquarters}
                  />
                  <CompanyInfo title={"Company Tag Line"} value={slogan} />
                  <CompanyInfo
                    title={"Company Followers"}
                    value={social_followers}
                  />
                  <CompanyInfo title={" Founded"} value={founded} />
                  <CompanyInfoLink title={"Company website"} link={website} />
                  <CompanyInfoLink title={"Crunchbase"} link={crunchbase_url} />
                </div>
              </section>
              <section
                className="item-section"
                style={{
                  display:
                    specialties?.length || categories?.length
                      ? "block"
                      : "none",
                }}
              >
                <div className="row">
                  {specialties?.length > 0 ? (
                    <section className="col-md-6 text-start">
                      <h5> Company Specialities </h5>
                      {specialties.map((speciality, index) => (
                        <p className="mb-3 text-start" key={index}>
                          {speciality}
                        </p>
                      ))}
                    </section>
                  ) : null}
                  {categories?.length > 0 ? (
                    <section className="col-md-6 text-start">
                      <h5 className=""> Company Categories </h5>
                      {categories?.map((category, index) => (
                        <p className="mb-3" key={index}>
                          {category}
                        </p>
                      ))}
                    </section>
                  ) : null}
                </div>
              </section>
              <section
                className="item-section"
                style={{
                  display: funding ? "block" : "none",
                }}
              >
                <p className="specific-page-title mb-4">Funding</p>
                <div className={"text-justify phone-child-div"}>
                  <CompanyInfo title={"Funding Price"} value={funding?.price} />
                  <CompanyInfo
                    title={"Last Round Date"}
                    value={funding?.last_round_date}
                  />

                  <CompanyInfo
                    title={"Last Round Stage"}
                    value={funding?.last_round_stage}
                  />
                  <CompanyInfo
                    title={"Number of rounds"}
                    value={funding?.number_of_rounds}
                  />
                </div>
                <div className={"text-left my-4"}>
                  {funding?.investors.length ? (
                    <>
                      <h6 className={"text-center"}>Investors</h6>
                      {funding.investors.map((investor) => (
                        <div>
                          <CompanyInfo
                            title={"Investor name"}
                            value={investor?.investor_name}
                          />
                          <CompanyInfoLink
                            title={"Investor crunchbase"}
                            link={investor?.investor_url}
                          />
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </section>
              <section
                className="item-section"
                style={{
                  display: about_us ? "block" : "none",
                }}
              >
                <p className="specific-page-title">Company Description</p>
                <div className={"text-justify"}>
                  <p>{about_us || "Not found"}</p>
                  {data?.image_url ? (
                    <div className="">
                      <img
                        src={data?.image_url || ""}
                        alt={`${company_name}`}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section
                className="item-section"
                style={{
                  display: employees.length ? "block" : "none",
                }}
              >
                <p className="specific-page-title">Associated Team Members</p>
                <Swiper
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                  slidesPerView={3}
                  spaceBetween={50}
                  onSlideChange={(e) => console.log(e)}
                  onSwiper={(swiper) => console.log(swiper)}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  {employees?.length
                    ? employees?.map((profile, index) => (
                        <SwiperSlide key={index} className="my-3">
                          <div
                            className="d-flex flex-column align-items-center"
                            style={{ marginBottom: "1.5rem", height: "300px" }}
                          >
                            <div className="swiper-container">
                              <a
                                href={profile.social_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className=""
                                  src={
                                    profile?.image_url ||
                                    "/assets/images/user-author-pic.png"
                                  }
                                  alt=""
                                />
                              </a>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                              className="d-block mt-3"
                            >
                              {profile?.full_name}
                            </p>
                            <div className="swiper-job">
                              <p className="d-block mt-1">{profile.position}</p>
                            </div>
                            <p
                              // style={{ position: "fixed", bottom: "0" }}
                              className="search-view-btn "
                            >
                              <button
                                className="btn button mb-2"
                                onClick={() => handleProfileButton(profile)}
                              >
                                View Profile
                              </button>
                            </p>
                          </div>
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
              </section>
              <section
                className="item-section"
                style={{
                  display: affiliated_companies.length ? "block" : "none",
                }}
              >
                <p className="specific-page-title">Affiliated Companies</p>
                <Swiper
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                  slidesPerView={3}
                  spaceBetween={50}
                  onSlideChange={(e) => console.log(e)}
                  onSwiper={(swiper) => console.log(swiper)}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  {affiliated_companies?.length
                    ? affiliated_companies?.map((company, index) => (
                        <SwiperSlide key={index} className="my-3">
                          <div
                            className="d-flex flex-column align-items-center"
                            style={{ marginBottom: "1.5rem" }}
                          >
                            <div className="swiper-container">
                              <a
                                href={company.social_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className=""
                                  src={
                                    company?.image_url
                                      ? company?.image_url
                                      : "/assets/images/user-author-pic.png"
                                  }
                                  alt=""
                                />
                              </a>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                              className="d-block mt-3"
                            >
                              {company?.company_name}
                            </p>
                            <div className="py-2 mb-3">
                              <p className="d-block mt-2">{company.industry}</p>
                            </div>

                            {/*Disabled as no route available  i.e /UnlockCompany */}

                            {/*<p*/}
                            {/*  style={{ position: "fixed", bottom: "0" }}*/}
                            {/*  className="search-view-btn mt-2"*/}
                            {/*>*/}
                            {/*  <button*/}
                            {/*    className="btn button"*/}
                            {/*    onClick={() => handleCompanyButton(company)}*/}
                            {/*  >*/}
                            {/*    View Profile*/}
                            {/*  </button>*/}
                            {/*</p>*/}
                          </div>
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
              </section>
              <section
                className="item-section"
                style={{
                  display: similar_companies.length ? "block" : "none",
                }}
              >
                <p className="specific-page-title">Similar Companies</p>
                <Swiper
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                  slidesPerView={3}
                  spaceBetween={50}
                  onSlideChange={(e) => console.log(e)}
                  onSwiper={(swiper) => console.log(swiper)}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  {similar_companies?.length
                    ? similar_companies?.map((company, index) => (
                        <SwiperSlide key={index} className="my-3">
                          <div
                            className="d-flex flex-column align-items-center"
                            style={{ marginBottom: "1.5rem" }}
                          >
                            <div className="swiper-container">
                              <a
                                href={company.social_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className=""
                                  src={
                                    company?.image_url
                                      ? company.image_url
                                      : "/assets/images/user-author-pic.png"
                                  }
                                  alt=""
                                />
                              </a>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                              className="d-block mt-3"
                            >
                              {company?.company_name}
                            </p>
                            <div className="py-2 mb-3">
                              <p className="d-block mt-2">{company.industry}</p>
                            </div>

                            {/*Disabled as no route available  i.e /UnlockCompany */}

                            {/*<p*/}
                            {/*  style={{ position: "fixed", bottom: "0" }}*/}
                            {/*  className="search-view-btn mt-2"*/}
                            {/*>*/}
                            {/*  <button*/}
                            {/*    className="btn button"*/}
                            {/*    onClick={() => handleCompanyButton(company)}*/}
                            {/*  >*/}
                            {/*    View Profile*/}
                            {/*  </button>*/}
                            {/*</p>*/}
                          </div>
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
              </section>{" "}
            </motion.div>
          )}
        </motion.div>
      )}
    </>
  );
};

export default CompanyViewProfile;

export const CompanyInfo = ({ title, value }) => {
  return value ? (
    <div>
      <h6>{title} </h6>
      <div className="ms-2 d-flex align-items-center mb-3">
        <small>
          <span> {value}</span>
        </small>
      </div>
    </div>
  ) : null;
};

const CompanyInfoLink = ({ title, link }) => {
  return link ? (
    <div>
      <h6> {title} </h6>
      <div className="ms-2 d-flex align-items-center mb-3" align="left">
        <div className="d-flex align-items-center">
          <small>
            <a href={cleanWebsiteUrl(link)} target="_blank" rel="noreferrer">
              {cleanWebsiteUrl(link)}
            </a>
          </small>
        </div>
      </div>
    </div>
  ) : null;
};
