import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CommonDateFilter = ({
  filterRequest,
  setFilterRequest,
  label,
  icon,
  attribute,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleStartDate = (e) => {
    setStartDate(e);

    if (!filterRequest.hasOwnProperty("start_date")) {
      filterRequest[attribute] = [];
    }
    if (filterRequest[attribute].hasOwnProperty("start_date")) {
      filterRequest[attribute].start_date = e;
    } else {
      filterRequest[attribute].push({
        start_date: e,
        end_date: endDate,
      });
    }
    setFilterRequest(() => {
      return { ...filterRequest };
    });
  };

  const handleEndDate = (e) => {
    setEndDate(e);

    if (!filterRequest.hasOwnProperty("end_date")) {
      filterRequest[attribute] = [];
    }
    if (filterRequest[attribute].hasOwnProperty("end_date")) {
      filterRequest[attribute].end_date = e;
    } else {
      filterRequest[attribute].push({
        start_date: startDate,
        end_date: e,
      });
    }
    setFilterRequest(() => {
      return { ...filterRequest };
    });
  };

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed realtime__accordion-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + attribute}
          >
            <img src={icon} alt="#" className="realtime__img" />

            <span className="px-2">{label}</span>
          </button>
        </h2>
        <div
          id={attribute}
          className="collapse"
          data-bs-parent={`#${attribute}`}
          data-cy={attribute}
        >
          <div className={"accordion-body"}>
            <div className="row align-content-baseline">
              <label className="form-label col-3">From:</label>
              <div className="col-9">
                <DatePicker
                  className="w-100"
                  selected={startDate}
                  onChange={(e) => handleStartDate(e)}
                  selectsStart
                  startDate={startDate}
                  maxDate={new Date()} // not allow future dates
                  endDate={endDate}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className="row align-content-baseline mt-2">
              <label className="form-label col-3">To:</label>
              <div className="col-9">
                <DatePicker
                  className="w-100"
                  selected={endDate}
                  onChange={(e) => handleEndDate(e)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonDateFilter;
