import React, { useEffect, useState } from "react";

const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  setToggle,
  render,
  loading,
  props
}) => {
  console.log("in pagination.jsx");

  const pageNumbers = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [maxNumberLimit, setMaxNumberLimit] = useState(10);
  const [minNumberLimit, setMinNumberLimit] = useState(0);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const pagePrev = () => {
    paginate(currentPage - 1);
    setCurrentPage(currentPage - 1);
    if (currentPage <= minNumberLimit + 1) {
      setMaxNumberLimit(maxNumberLimit - 10);
      setMinNumberLimit(minNumberLimit - 10);
    }
  };
  const nextTen = () => {
    setMaxNumberLimit(maxNumberLimit + 10);
    setMinNumberLimit(minNumberLimit + 10);
    setCurrentPage(minNumberLimit + 11);
    paginate(minNumberLimit + 11);
  };

  const pageNext = () => {
    paginate(currentPage + 1);
    setCurrentPage(currentPage + 1);
    if (currentPage >= maxNumberLimit) {
      setMaxNumberLimit(maxNumberLimit + 10);
      setMinNumberLimit(minNumberLimit + 10);
    }
  };

  const changePage = (number) => {
    paginate(number);
    if (setToggle) setToggle({ ok: false, index: 0 });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [render]);

  useEffect(() => {
    setCurrentPage(1);
    paginate(1);
  }, [props.props.location.state]);
  
  return (
    <>
      {!loading && (
        <nav>
          <ul className="pagination" data-cy="pagination">
            {currentPage === 1 ? (
              <div className="mr-2 mt-1">{"<"}</div>
            ) : (
              <li className="page-item">
                <a onClick={pagePrev} className="page-link">
                  {"<"}
                </a>
              </li>
            )}

            {pageNumbers.map((number) => {
              if (number <= maxNumberLimit && number > minNumberLimit) {
                console.log(minNumberLimit + ":" + maxNumberLimit);
                return (
                  <li
                    key={number}
                    className={
                      (currentPage === number ? "active" : "") + " page-item"
                    }
                    onClick={() => setCurrentPage(number)}
                  >
                    <a
                      onClick={() => {
                        changePage(number);
                      }}
                      className="page-link"
                      data-cy="page-number"
                    >
                      {number}
                    </a>
                  </li>
                );
              }
              return null;
            })}

            {pageNumbers.length === currentPage ? (
              <div className="ml-2 mt-1">{">"}</div>
            ) : (
              <li className="page-item">
                {pageNumbers.length > maxNumberLimit ? (
                  <>
                    <small
                      className="ml-2"
                      style={{ cursor: "pointer" }}
                      onClick={nextTen}
                    >
                      {" "}
                      &hellip;
                    </small>
                    <a onClick={pageNext} className="page-link">
                      {" >"}
                    </a>
                  </>
                ) : (
                  <a onClick={pageNext} className="page-link">
                    {">"}
                  </a>
                )}
              </li>
            )}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Pagination;
