import React, { useEffect, useState } from "react";
import { Loader } from "../Utils/utils";
import RealtimeItem from "./RealtimeItem";
import SpecificCompanyDetails from "./SpecificCompanyDetails";
import { useLeadsContext } from "../../Context/useLeadsContext";

const RealtimeItems = ({
  companyPageRecord,
  sheetName,
  index,
  currentPage,
  jobUrlList,
  setJobUrlList,
  pathName,
  realtimeSelectedLeads,
  setRealtimeSelectedLeads,
  customCount,
  setCustomCount,
}) => {
  const { leads } = useLeadsContext();
  const [openId, setOpenId] = useState("");
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(() => true);
  const [location, setlocation] = useState();
  useEffect(() => {
    setOpen(false)
  }, [currentPage])
  useEffect(() => {
    setOpen(false)
    setOpenId("")
  }, [leads])
  

  return (
    <div className="realtime-search-user-container py-2 ">
      {/*  Add item as per sheetname*/}
      <RealtimeItem
        companyPageRecord={companyPageRecord}
        sheetName={sheetName}
        index={index}
        currentPage={currentPage}
        open={open}
        setOpen={setOpen}
        openId={openId}
        setOpenId={setOpenId}
        setResult={setResult}
        setLoading={setLoading}
        jobUrlList={jobUrlList}
        setJobUrlList={setJobUrlList}
        pathName={pathName}
        realtimeSelectedLeads={realtimeSelectedLeads}
        setRealtimeSelectedLeads={setRealtimeSelectedLeads}
        customCount={customCount}
        setCustomCount={setCustomCount}
        location={location}
      />
      {open && openId === `${currentPage}${index}` ? (
        loading ? (
          <Loader />
        ) : (
          <SpecificCompanyDetails
            data={result}
            index={index}
            currentPage={currentPage}
            sheetName={sheetName}
            setlocation={setlocation}
            // loading={loading}
          />
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default RealtimeItems;
