import { useEffect, useState } from "react";
import ModalPopup from "../SharedComponent/Modal";
import { addToSavedList, removeFromSavedList } from "./SavedListQueries";
import { Tooltip, Box } from "@mui/material";
import SaveListModal from "./SavedListModal.jsx";
import { IoAddCircleOutline, IoAddCircle } from "react-icons/io5";
const SavedListButton = ({
  saved,
  data,
  saveListNames,
  setSaveListNames,
  itemType,
  simpleSearchId,
  pageNumber,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [savedRealtime, setSavedRealtime] = useState(false);

  useEffect(() => {
    if (savedRealtime) setSavedRealtime(false);
  }, [pageNumber]);

  return (
    <>
      <div className="box">
        <button
          style={{ background: "none", border: "none" }}
          type="button"
          onClick={() => {
            setOpenModal(true);
            console.log("modal clicked....");
          }}
          data-cy="saved-list-btn"
        >
          {saved || savedRealtime ? (
            <Tooltip title="Saved Item. Click to add to more lists.">
              <div>
                <IoAddCircle color="#4BB543" size={30} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Save this item">
              <div>
                <IoAddCircleOutline color="#fb3e3e" size={30} />
              </div>
            </Tooltip>
          )}
        </button>
      </div>
      {openModal && (
        <SaveListModal
          setSavedRealtime={setSavedRealtime}
          openModal={openModal}
          setOpenModal={setOpenModal}
          saveListNames={saveListNames}
          saveListItem={data}
          setSaveListNames={setSaveListNames}
          itemType={itemType}
          simpleSearchId={simpleSearchId}
        />
      )}
    </>
  );
};

export default SavedListButton;
