import { useSnackbar } from "notistack";
import {
  fetchPersonDetailed,
  fetchPersonDetailedWorkEmail,
  fetchRealtimeWithoutLinkedInURL,
  fetchWorkEmail,
} from "../SearchQueries";
import { useLocation } from "react-router-dom";
import { getSearchCategory } from "../../Utils/categoryUtils";

const realTimeProfileUrl = `${process.env.REACT_APP_CONFIG_REALTIME_URL}`;

const UnlockProfileEmail = ({
  index,
  data,
  hash_key,
  currentPage,
  simpleSearchId,
  setSearchId,
  realtimeNonLinkedinProfileEmailUnlock,
  isOpen,
  setIsOpen,
  workEmailId,
  setWorkEmailId,
}) => {
  console.log("unlockDatahehe", data);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  // details var used when unlock profile from hisotry page result
  const details = location.state?.details ?? "";
  console.log("details from email", details);
  // details var used when unlock profile from hisotry page result

  const payload = {
    details_url: data.details_url,
    search_id: details ? details?.search_id : simpleSearchId,
    phones: data.phones,
    emails: data.emails,
    meta_data: data.meta_data,
    full_name: data.full_name,
    title: data.job,
    company_name: data.company_name,
    product_name: "platform",
    category: data.category ?? getSearchCategory(location.pathname),
  };

  const UnlockWorkEmail = () => {
    const isUserUnlocked = data?.meta_data?.user_unlocked?.unlocked_email;
    const isTeamUnlocked = data?.meta_data?.team_unlocked?.unlocked_email;

    const handleUnlockEmail = async (e) => {
      if (!window.navigator.onLine) {
        enqueueSnackbar("No Internet Connection", { variant: "error" });
        return;
      }
      if (e.target.innerText.includes("Hide Work Email")) {
        setWorkEmailId(false);
        setIsOpen(false);
        return;
      }

      console.log(
        "handleUnlockEmail: e.target.id: ",
        e.target.id,
        data.details_url
      );

      if (data?.meta_data?.not_linkedin_url) {
        setIsOpen(data.id);
        let payload = {
          id: data.id,
          url: data.details_url,
          search_id: simpleSearchId,
          product_name: "platform",
          meta_data: data.meta_data,
          category: data.category ?? getSearchCategory(location.pathname),
          use_cache: false,
          use_history: false,
        };
        realtimeNonLinkedinProfileEmailUnlock(payload, "email");
      } else {
        try {
          let person_detailed;
          if (data?.meta_data?.["unlocked_profile"]) {
            person_detailed = await fetchPersonDetailed(payload);
          } else {
            person_detailed = await fetchPersonDetailedWorkEmail(payload);
          }

          console.log("person_detailed", person_detailed, payload);

          setSearchId(person_detailed?.id);
          setIsOpen(data.details_url);
        } catch (err) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
        try {
          let emailVerificationRequest = {
            id: data.id,
            url: data.details_url,
            search_id: simpleSearchId,
            product_name: "platform",
            meta_data: data.meta_data,
            category: getSearchCategory(location.pathname),
          };
          const emailVerificationResponse = await fetchWorkEmail(
            emailVerificationRequest
          );
          setWorkEmailId(emailVerificationResponse?.id);
        } catch (err) {
          console.log("CHECK THIS LOG --->", err.message);
          enqueueSnackbar(err.message, { variant: "error" });
        }
      }
    };

    return (
      <div className="unlock-email">
        <button
          id={`emailBtn_${currentPage}${index}`}
          className="btn button v-btn"
          onClick={async (e) => {
            await handleUnlockEmail(e);
          }}
          style={
            isUserUnlocked || isTeamUnlocked
              ? {
                  backgroundColor: "#FF6852",
                  color: "white",
                }
              : null
          }
        >
          {(isOpen === data?.details_url || isOpen === data?.id) &&
          workEmailId ? (
            "Hide Work Email"
          ) : isUserUnlocked || isTeamUnlocked ? (
            <span
              className="search-tool"
              tooltip-data={
                isTeamUnlocked
                  ? "Information unlocked by team"
                  : "You have unlocked this information before"
              }
            >
              View Work Email {isTeamUnlocked ? "*" : ""}
            </span>
          ) : (
            "Unlock Work Email"
          )}
        </button>
      </div>
    );
  };

  const UnlockProfile = () => {
    const isUserUnlocked = data.meta_data?.user_unlocked?.unlocked_profile;
    const isTeamUnlocked = data.meta_data?.team_unlocked?.unlocked_profile;
    const handleProfile = async (e) => {
      if (!window.navigator.onLine) {
        enqueueSnackbar("No Internet Connection", { variant: "error" });
        return;
      }
      setWorkEmailId(false);

      if (e.target.innerText.includes("Hide Profile")) {
        setIsOpen(false);
        return;
      }
      if (data?.meta_data?.not_linkedin_url) {
        try {
          setIsOpen(data.id);
          let payload = {
            id: data.id,
            search_id: simpleSearchId,
            product_name: "platform",
            meta_data: data.meta_data,
            category: data.category ?? getSearchCategory(location.pathname),
          };
          realtimeNonLinkedinProfileEmailUnlock(payload, "phone");
        } catch (err) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
      } else {
        if (data.details_url === realTimeProfileUrl) {
          try {
            const fetchResponse = await fetchRealtimeWithoutLinkedInURL(
              hash_key
            );

            setSearchId(fetchResponse?.id);
            setIsOpen(data.details_url);
          } catch (err) {
            enqueueSnackbar(err.message, { variant: "error" });
          }
        } else {
          try {
            const fetchResponse = await fetchPersonDetailed(payload);

            setSearchId(fetchResponse?.id);
            setIsOpen(data.details_url);
          } catch (err) {
            enqueueSnackbar(err.message, { variant: "error" });
          }
        }
      }
    };

    return (
      <div className="unlock-profile">
        <button
          id={`proBtn_${currentPage}${index}`}
          className="btn button v-btn"
          onClick={async (e) => {
            await handleProfile(e);
          }}
          style={
            isUserUnlocked || isTeamUnlocked
              ? {
                  backgroundColor: "#FF6852",
                  color: "white",
                }
              : null
          }
        >
          {(isOpen === data.details_url || isOpen === data.id) &&
          !workEmailId ? (
            "Hide Profile"
          ) : isUserUnlocked || isTeamUnlocked ? (
            <span
              className="search-tool"
              tooltip-data={
                isTeamUnlocked
                  ? "Information unlocked by team"
                  : "You have unlocked this information before"
              }
            >
              View Profile {isTeamUnlocked ? "*" : ""}
            </span>
          ) : (
            "Unlock Profile"
          )}
        </button>
      </div>
    );
  };

  return (
    <>
      <UnlockWorkEmail />
      <UnlockProfile />
    </>
  );
};

export default UnlockProfileEmail;
