import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const LeadCard = ({ id, page_name, data_cy, disable, index }) => {
  const [selectedButtonId, setSelectedButtonID] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const identifier = `rt-${id}`;

  const handleCompany = async (e) => {
    console.log("buttonDisabled: ", buttonDisabled);
    console.log("In LeadCard sheetname e: ", e);
    if (buttonDisabled) {
      enqueueSnackbar("Feature is temporary unavailable !");
      return;
    }
    disable(true);
    const { id } = e.target;
    console.log("id", id);
    setSelectedButtonID(id);

    if (id === "rt-smeCompanies") {
      history.push("smeCompanies");
    } else {
      try {
        let realTimeDataSource = {
          sheet_name: e.target.parentNode.children[0].innerText,
        };
        console.log("In LeadCard sheetname: ", realTimeDataSource);
        if (
          realTimeDataSource.sheet_name === "Recently Funded Companies" ||
          realTimeDataSource.sheet_name === "Investors Who Funded Recently" ||
          realTimeDataSource.sheet_name === "Recently Acquired Companies" ||
          realTimeDataSource.sheet_name === "Companies Acquiring Recently" ||
          realTimeDataSource.sheet_name === "Companies Hiring Recently" ||
          realTimeDataSource.sheet_name ===
            "Newly Registered Subsidiaries Of Foreign Companies" ||
          realTimeDataSource.sheet_name ===
            "Newly Registered Companies On MCA" ||
          realTimeDataSource.sheet_name === "Unfunded Companies In India" ||
          realTimeDataSource.sheet_name === "Listed Company In India" ||
          realTimeDataSource.sheet_name === "Public Listed Companies In India"
        ) {
          history.replace({
            pathname: "/realTimeResults",
            state: {
              sheetName: realTimeDataSource.sheet_name,
              index: index,
            },
          });
        }
        if (
          realTimeDataSource.sheet_name === "Family Offices In India" ||
          realTimeDataSource.sheet_name === "Active Angel Investors In India" ||
          realTimeDataSource.sheet_name === "Financial Intermediaries In India"
        ) {
          console.log("in Person if");
          history.push({
            state: {
              searchInput: {
                sheet_name: realTimeDataSource.sheet_name,
                index: index,
              },
            },
            pathname: "/RealtimePeopleSearch",
          });
        } else {
          setButtonDisabled(true);
          // setSelectedButtonID("");
        }
        // if (

        // ) {
        //   history.push({
        //     pathname: "realtimeCompany",
        //     state: {
        //       sheetName: realTimeDataSource.sheet_name,
        //       index: index,
        //     },
        //   });
        // }
      } catch (error) {
        console.error("In handle company>>", error);
        enqueueSnackbar(
          "Error in fetching data, Please try again after sometime!",
          {
            variant: "error",
          }
        );
        disable(false);
        setSelectedButtonID("");
      }
    }
  };

  return (
    <div className="lead-card">
      <div className="text-center">
        <p style={{ fontSize: "1.1rem" }}>{page_name}</p>

        {selectedButtonId === identifier ? (
          <div
            style={{ color: "red" }}
            className="spinner-border"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <button
            id={identifier}
            type="button"
            className="trends-btn global-hover"
            onClick={handleCompany}
            data-cy={data_cy}
            disabled={buttonDisabled}
          >
            Grab Leads Now
          </button>
        )}
      </div>
    </div>
  );
};

export default LeadCard;
