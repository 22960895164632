import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { apiServer } from "../../../config";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import validator from "validator";

const apiHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${Cookies.get("user_token")}`,
};
const AddTeammatesPopUp = ({ dataTarget, setTeamMembers }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    return validator.isEmail(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const listAllChildren = async () => {
    try {
      const fetchResponse = await fetch(apiServer + `/user/list_children`, {
        headers: apiHeader,
      });
      const data = await fetchResponse.json();
      console.log("data: ", data);
      setTeamMembers(data);
    } catch (err) {
      console.log("");
    }
  };

  const handleAddTeammates = async (e) => {
    e.preventDefault();
    console.log("In handle teammates");
    if (!isValidEmail) {
      enqueueSnackbar("Enter Valid Email.");
      setEmail("");
      setIsValidEmail(false);
      return;
    }
    const payload = {
      children: email,
    };
    try {
      const response = await fetch(apiServer + "/user/add_children", {
        method: "POST",
        headers: apiHeader,
        body: JSON.stringify(payload),
      });
      switch (response.status) {
        case 200:
          enqueueSnackbar("Teammate Added Successfully.");
          await listAllChildren();
          setEmail("");
          break;
        case 401:
          enqueueSnackbar("Please Logout and Login again.");
          setEmail("");
          break;
        case 404:
          enqueueSnackbar("Entered Email Not Found. Please Enter Valid Email.");
          setEmail("");
          break;
        case 405:
          enqueueSnackbar("Entered User Should Be Platform User.");
          setEmail("");
          break;
        case 406:
          enqueueSnackbar("You Cannot Add Yourself as Teammate.");
          setEmail("");
          break;
        case 409:
          enqueueSnackbar(
            "User is Already Teammate of Organization or Already Parent."
          );
          setEmail("");
          break;
        default:
          enqueueSnackbar("Something Wrong. Try After Sometime");
          setEmail("");
          break;
      }
      setEmail("");
      setIsValidEmail(false);
    } catch (err) {
      console.error("Error: ", err);
    }
  };
  return (
    <div
      id={dataTarget}
      className="modal fade mt-7"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTeammateModalLabel"
      aria-hidden="false"
      data-cy="add-teammate-type-popup"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="text-danger tool"
              tooltip-data="Please ensure that the entered Email Id should be registered with Leadzen.AI."
            >
              <h4 className="modal-title">
                Add Teammate{" "}
                <img
                  src="/assets/images/new_icons/i_button.png"
                  alt="#"
                  className="mx-2"
                  style={{ width: "15px", height: "15px", marginTop: "-.1em" }}
                />
              </h4>
            </h5>
          </div>
          <div className="modal-body">
            <form className="pl-3">
              {/*<Box display={"flex"} gap={3} alignItems="center" fullWidth>*/}
              {/*  <Typography>*/}
              {/*    Note: Please validate thst entered Email Id should be*/}
              {/*    registered with Leadzen.AI. You can add multiple Email IDs*/}
              {/*    separated by comma*/}
              {/*  </Typography>*/}
              {/*</Box>*/}
              <Box display={"flex"} gap={3} alignItems="center" fullWidth>
                <Typography>Enter Email Id </Typography>
                <TextField
                  value={email}
                  onChange={handleEmailChange}
                  required
                  name="email"
                  size="small"
                  sx={{ label: { color: "grey" }, height: "60px !important" }}
                  fullWidth
                  // onChange={handleInput}
                  // onInput={handleInput}
                />
                {email ? (
                  isValidEmail ? (
                    <p style={{ color: "green" }}>Valid Email Address</p>
                  ) : (
                    <p style={{ color: "red" }}>Invalid Email Address</p>
                  )
                ) : null}
              </Box>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  data-cy="dismiss-btn"
                  onClick={() => setEmail("")}
                >
                  Discard
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleAddTeammates}
                  data-dismiss="modal"
                  data-cy="export-btn"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeammatesPopUp;
