import React, { useContext, useState } from "react";
import linkIcon from "../../images/link.png";
import connectedIcon from "../../images/varient.png";
import userRobotIcon from "../../images/user-robot-icon.png";
import { AppContext } from "../../Context";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const CompanyAndNameSearch = (props) => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const [companyName, setCompanyName] = useState("");
  const [userName, setUserName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const pattern = /^[A-Za-z0-9\s.]+$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!window.navigator.onLine){
      props.setpopup(true)
      return;
    }

    if (userName) {
      if (userName.length < 3) {
         
        return enqueueSnackbar("Name should be at least 2 characters long!", {
          variant: "error",
        });
      }
      else if (userName.length > 80) {
        return enqueueSnackbar("Name cannot exceed 100 characters!", {
          variant: "error",
        });
      }
    }
    if (companyName) {
      if (companyName.length < 2) {
         
        return enqueueSnackbar("Company Name should be at least 2 characters long!", {
          variant: "error",
        });
      }
      else if (companyName.length > 100) {
        return enqueueSnackbar("Company Name cannot exceed 100 characters!", {
          variant: "error",
        });
      }
    }
    if (jobTitle) {
      if (jobTitle.length < 2) {
         
        return enqueueSnackbar("Job should be at least 2 characters long!", {
          variant: "error",
        });
      }
      else if (jobTitle.length > 80) {
        return enqueueSnackbar("Job cannot exceed 100 characters!", {
          variant: "error",
        });
      }
    }
    const inputs = {Company:companyName, "Person Name":userName, "Job Title":jobTitle};
    const validInputs = Object.entries(inputs || {})
    ?.filter((input) => pattern.test(input[1]));
    const unValidInputs = Object.entries(inputs || {})
    ?.filter((input) => !pattern.test(input[1]) && (input[1]!== ""));
    console.log("validInputs",validInputs)
    console.log("unValidInputs",unValidInputs)
    if (validInputs.length < 1) {
      if (unValidInputs.length === 0) {
        return enqueueSnackbar(`Please fill valid details`, {
          variant: "error",
        });
      }
        else{
          return enqueueSnackbar(`Please fill valid details for ${unValidInputs?.[0]?.[0]}`, {
            variant: "error",
          });
        }
    }

    dispatch({ type: "upsetHistorySearchTerm" });
    if (companyName || userName || jobTitle) {
      console.log("CompanyAndNameSearch: companyName=" + companyName);
      console.log("CompanyAndNameSearch: userName=" + userName);
      history.push({
        state: {
          searchInput: JSON.stringify({
            full_name: userName,
            company_name: companyName,
            job_title: jobTitle,
          }),
        },
        pathname: "/CompanyAndNameSearch",
      });
      setCompanyName("");
      setUserName("");
      setJobTitle("");
    } else {
      enqueueSnackbar("Please fill atleast two of the fields", {
        variant: "error",
      });
      console.error("CompanyAndNameSearch: companyName or userName is empty");
    }
  };

  return (
    <div className="common-type-search">
      <div className="title">
        <div className="title-img">
          <img src={userRobotIcon} alt="#" />
        </div>
        <div className="title-content">
          <h2>Get LinkedIn Profile</h2>
          <h3>of Person from Company</h3>
        </div>
      </div>
      <div className="prsn-search-container">
        <form onSubmit={handleSubmit} className="type-search-frm">
          <input
            type="text"
            className="mr-1"
            placeholder="Person Name"
            onChange={(e) => setUserName(e.target.value)}
            onInput={(e) => setUserName(e.target.value)}
            value={userName}
            data-cy="person"
          />
          <img
            src={linkIcon}
            alt="#"
            style={{ width: "15px", height: "15px" }}
          />
          <input
            type="text"
            className="ml-1 mr-1"
            placeholder="Company Name"
            onChange={(e) => setCompanyName(e.target.value)}
            onInput={(e) => setCompanyName(e.target.value)}
            value={companyName}
            data-cy="company"
          />
          <img
            src={linkIcon}
            alt="#"
            style={{ width: "15px", height: "15px" }}
          />
          <input
            type="text"
            className="ml-1"
            placeholder="Job Title"
            onChange={(e) => setJobTitle(e.target.value)}
            onInput={(e) => setJobTitle(e.target.value)}
            value={jobTitle}
          />
          <button
            type="submit"
            className="type-search-frm-button mr-2 ml-2 global-hover"
            data-cy="search"
          >
            <img
              src={connectedIcon}
              alt="#"
              style={{
                width: "15px",
                height: "15px",
                paddingRight: ".2em",
              }}
              className="mr-2"
            />
            Get URL
          </button>
        </form>
        <p className="text-dark pt-3 fst-italic ml-2">
          Please fill at least one of the inputs to search for{" "}
          <span className="text-danger">Profiles from their Companies**</span>
        </p>
      </div>
    </div>
  );
};

export default CompanyAndNameSearch;
