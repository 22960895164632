import React, { useEffect } from "react";
import AutoSuggestInputForFilter from "./AutoSuggestInputForFilter";

const CommonFilterItem = ({
  filterRequest,
  setFilterRequest,
  itemClass,
  label,
  icon,
  sheetName,
}) => {
  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed realtime__accordion-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + itemClass}
          >
            <img src={icon} alt="#" className="realtime__img" />

            <span className="px-2">{label}</span>
          </button>
        </h2>
        <div
          id={itemClass}
          className="collapse"
          data-bs-parent={`#${itemClass}`}
        >
          <AutoSuggestInputForFilter
            filterRequest={filterRequest}
            setFilterRequest={setFilterRequest}
            sheetName={sheetName}
            attribute={itemClass}
          />
        </div>
      </div>
    </>
  );
};
export default CommonFilterItem;
