import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { mailOpener, openDialer } from "../../Utils/helper";

const MessageBox = ({ message, setMessage, onClose, title, data }) => {
  const [copy, setCopy] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const handleCopy = () => {
    setCopy(true);
    navigator.clipboard.writeText(message);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const extractSubject = (data) => {
    const regex = /Subject: (.*)\n/;
    const match = data.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return "No subject found";
    }
  };

  const extractBody = (data) => {
    const regex = /Subject: (.*)\n\n([\s\S]*)/;
    const match = data.match(regex);
    if (match && match[2]) {
      return match[2];
    } else {
      return "No body found";
    }
  };
  const handleClose = () => {
    onClose();
    setMessage("");
  };

  const handleMessage = () => {
    if (title === "whatsapp") {
      handleClose();
      return openDialer(data, message);
    }
    if (title === "email") {
      const subject = extractSubject(message);
      const body = extractBody(message);
      setSuccessMessage(true);
      return mailOpener(data, subject, body);
    }
  };

  return (
    <Box>
      <Typography
        my={2}
        textAlign={"center"}
        fontWeight={600}
        typography={"p"}
        color={"#fb3e3e"}
        sx={{
          textDecoration: `${successMessage ? "none" : "underline"}`,
          textUnderlineOffset: "5px",
          fontSize: `${successMessage && "20px"}`,
        }}
      >
        {successMessage
          ? "Email sent successfully"
          : `Personalized ${title} message`}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={() => {
          console.log("onclick close");
          setMessage("");
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#000",
          bgcolor: "transparent",
          "&:hover": {
            color: "#fff",
            bgcolor: "#414A4C",
          },
          "&:focus": {
            outline: "none",
          },
          zIndex: 20,
        }}
      >
        <CloseIcon />
      </IconButton>
      {message && (
        <>
          {!successMessage && (
            <Stack
              marginX={"1rem"}
              maxHeight={"350px"}
              overflow={"auto"}
              marginY={"1rem"}
              direction="column"
              spacing={2}
              border={"1px solid #ddd"}
              p={2}
            >
              <Box> {message}</Box>
            </Stack>
          )}
          <Box ml={"90%"} mt={-2} position={"relative"}>
            {!successMessage && (
              <Tooltip title="Click to copy" arrow>
                <IconButton
                  aria-label="copy content"
                  size="large"
                  disableElevation
                  onClick={handleCopy}
                  sx={{
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  {copy ? (
                    <CheckCircleIcon fontSize="inherit" color="primary" />
                  ) : (
                    <ContentCopyIcon fontSize="inherit" color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={5}
            maxWidth={"90%"}
            pl={2}
          >
            {title !== "linkedin" && (
              <Button
                onClick={successMessage ? handleClose : handleMessage}
                variant="contained"
                disableElevation
                sx={{
                  paddingY: `${successMessage ? "6px" : "12px"}`,
                  fontSize: "16px",
                  maxWidth: "200px",
                  marginX: `${successMessage && "auto"}`,
                  marginTop: `${successMessage && "20px"}`,
                  paddingX: `${successMessage && "13px"}`,
                }}
              >
                {successMessage ? (
                  "Ok"
                ) : title === "whatsapp" ? (
                  <Box flex gap={1}>
                    <WhatsAppIcon fontSize="medium" /> Send message
                  </Box>
                ) : (
                  <Box flex gap={2}>
                    <EmailIcon fontSize="medium" /> Send email
                  </Box>
                )}
              </Button>
            )}
          </Stack>
        </>
      )}
      {/* {successMessage && <h3>Success</h3>} */}
    </Box>
  );
};

export default MessageBox;
