import React, { useEffect, useState } from "react";
import "./Style/style.css";
import { Link, Redirect, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import validator from "validator";
import axios from "axios";
import eyeOff from "../../images/eye-off.png";
import eyeOn from "../../images/eye-on.png";
import Popup from "../Utils/Popup";
import { setUser } from "@sentry/react";
import { apiServer } from "../../config";
import * as amplitude from "@amplitude/analytics-browser";

const LogIn = (props) => {
  const [historyState, setHistoryState] = useState("");
  const [userSession, setUserSession] = useState(false);
  const [popup, closePopup] = useState(false);

  const [response, setResponse] = useState({ ok: null, message: null });
  const [showPass, setShowPass] = useState(false);
  const [userVerifiedStatus, setUserVerifiedStatus] = useState(true);
  const [loggedOut, setLoggedOut] = useState(false);
  const [message, setmessage] = useState(
    "Hey buddy, looks like you've typed in the wrong information. It happens to the best of us. Take a minute and try again"
  );

  const history = useHistory();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const emailVerified = url.searchParams.get("emailVerified");
  const email = url.searchParams.get("email");

  const [userLogin, setUserLogin] = useState({
    email: email || "",
    password: "",
    error: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserLogin({ ...userLogin, [name]: value });
  };

  const Robot = () => {
    if (response.message) {
      return (
        <div className="col-md-6 robot-container order-md-12">
          <div className="sign-up-robot text-center ps-4 pe-7 pt-2 pb-7 mb-4">
            <img
              style={{ width: "20px" }}
              src="/assets/images/Group 2221.png"
              alt=""
            />
            {!userLogin.error ? (
              <p className="fw-bold">
                Hey Buddy, Time to take the lead.
                <br /> {!userLogin.error ? response.message : userLogin.error}
                <br />{" "}
                <Link to="/signUp" className="text-danger text-decoration-none">
                  Sign up
                </Link>{" "}
                to begin.{" "}
              </p>
            ) : (
              <p className="fw-bold">
                Hey Buddy, Time to take the lead.
                <br /> {userLogin.error} <br />{" "}
              </p>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="col-md-6 robot-container order-md-12">
        <div className="sign-up-robot ps-4 pe-7 pt-2 pb-7 mb-4">
          <h3>Hi</h3>
          <p className="fw-bold">
            I am <span className="text-danger">Jarv!</span> <br />
            Are you ready to unlock opportunities with the{" "}
            <span className="text-danger">
              most intelligent AI based platform?{" "}
            </span>
          </p>
        </div>
      </div>
    );
  };

  function handleError(status) {
    console.error(`Got HTTP Error ${status}`);
    closePopup(true);
  }

  const formData = new FormData();
  formData.set("username", userLogin.email);
  formData.set("password", userLogin.password);

  const fetchData = async () => {
    try {
      const fetchResponse = await axios.post(
        apiServer + "/auth/jwt/login",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      async function handleSuccess(fetchResponse) {
        let json_res = await fetchResponse.data;
        try {
          const userStatusResponse = await axios.get(apiServer + "/users/me", {
            headers: {
              Authorization: `Bearer ${json_res.access_token}`,
            },
          });

          async function handleUserSuccess() {
            const userStatus = await userStatusResponse;

            console.log("userStatus>>>>>>>>", userStatus);

            setResponse({ ...response, ok: true });

            if (userStatus.data.is_verified === false) {
              setUserVerifiedStatus(false);
            } else {
              Cookies.set("user_email", userLogin.email, { expires: 30 });
              Cookies.set("user_id", userStatus.data.id, { expires: 30 });
              Cookies.set("user_token", json_res.access_token, {
                expires: 30,
              });
              Cookies.set("username", userStatus.data.username, {
                expires: 30,
              });
              console.log("User Logged In and is Verified");
              setUser({
                email: userLogin.email,
                username: userStatus.data.username,
                userId: userStatus.data.id,
              });
              // TODO: Add this after onboarded implementation
              // if (userStatus.data.onboarded === false) {
              //   console.log("in user render");
              //   history.push({
              //     pathname: "/firstTimeUser",
              //   });
              // } else {
              const redirectUrl = localStorage.getItem("redirectUrl");
              if (redirectUrl && !userStatus.data.parent) {
                localStorage.removeItem("redirectUrl");
                history.push({
                  pathname: "/extensionPayment",
                });
              } else {
                history.push({
                  pathname: "/dashboard",
                });
              }

              // }
            }
          }

          switch (userStatusResponse.status) {
            case 200:
              return await handleUserSuccess(userStatusResponse);
            default:
              return handleError(userStatusResponse);
          }
        } catch (err) {
          handleError(err);
        }
      }

      switch (fetchResponse.status) {
        case 200:
          return await handleSuccess(fetchResponse);
        default:
          return handleError(fetchResponse);
      }
    } catch (err) {
      handleError(err);
      setResponse({ ...response, message: "user not found" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userLogin.email || !userLogin.password) {
      closePopup(true);
      return;
    }
    if (!validator.isEmail(userLogin.email)) {
      setUserLogin({ ...userLogin, error: "Invalid Email" });
    }
    if (
      !validator.isStrongPassword(userLogin.password, {
        minLength: 8,
        minLowercase: 1,
        maxlength: 50,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setUserLogin({ ...userLogin, error: "Invalid Password" });
    }

    fetchData();
  };

  useEffect(() => {
    if (historyState !== "") {
      alert(historyState + " Created successfully please login.");
    }
  }, [historyState]);

  useEffect(() => {
    const state = props?.location?.state;

    if (state !== undefined) {
      if (state.userRegistrationEmail) {
        setHistoryState(state.userRegistrationEmail);
      }
      if (state.loggedOut) {
        setLoggedOut(true);
      }
      if (state.userSession) {
        setUserSession(true);
      }
    }

    const userLoggedIn = Cookies.get("user_token");
    if (userLoggedIn) {
      amplitude.logEvent("Login", {
        role: "Existing User",
        userName: userLogin.email,
      });
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!window.navigator.onLine) {
      setmessage(
        "Hey buddy, looks like there is problem connecting to internet. Please check your internet connection and try again!"
      );
    } else
      setmessage(
        "Hey buddy, looks like you've typed in the wrong information. It happens to the best of us. Take a minute and try again"
      );
  }, [window.navigator.onLine]);

  return (
    <div>
      {popup && <Popup message={message} closePopup={closePopup} />}

      {loggedOut && (
        <Popup
          message="Logged Out Successfully!"
          btnText="Close"
          closePopup={setLoggedOut}
        />
      )}

      {emailVerified ? (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>{email}</strong> You have verified successfully.
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}

      {userSession && (
        <Popup
          message="Your session expired. Please Login again."
          btnText="Login Again"
          closePopup={closePopup}
        />
      )}

      <div className="main-content-area overflow-hidden">
        <div className="main-wrapper">
          <div className="container-fluid">
            <div className="form-container">
              <div className="signup-wrapper py-4 px-md-6">
                <div className="row align-items-center">
                  {!userVerifiedStatus ? <Redirect to="/unverified" /> : null}
                  <div className="col-md-6 order-md-1">
                    <div className="sign-up-form">
                      <div className="text-center">
                        <h3 className="fw-bolder">Welcome to LeadZen.ai</h3>
                        <h5 className="text-danger mb-4">
                          Most Intelligent Lead Generation Platform
                        </h5>
                      </div>
                      <form className="sign-up-form" onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <input
                            type="email"
                            className="w-100"
                            autoComplete="off"
                            value={userLogin.email}
                            onChange={handleInput}
                            onInput={handleInput}
                            name="email"
                            placeholder="Enter your email"
                            id="email"
                            required
                            data-cy="email"
                          />
                        </div>
                        <div className="mb-3 password-input">
                          <input
                            type={showPass ? "text" : "password"}
                            className="w-100"
                            autoComplete="off"
                            value={userLogin.password}
                            onChange={handleInput}
                            onInput={handleInput}
                            name="password"
                            placeholder="Enter your password"
                            id="password"
                            required
                            data-cy="password"
                          />
                          <img
                            src={showPass ? eyeOn : eyeOff}
                            alt="visibility button"
                            style={{
                              position: "absolute",
                              top: "13px",
                              right: "10px",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onMouseEnter={() => setShowPass(true)}
                            onMouseLeave={() => setShowPass(false)}
                            id="visibility-img"
                            data-cy="password-visibility-button"
                          />
                        </div>
                        <div className="mb-1 d-block d-md-flex justify-content-end">
                          <p>
                            <Link
                              to="/forgotpassword"
                              className="small text-secondary"
                              data-cy="forgot-btn"
                            >
                              Forgot your password?
                            </Link>
                          </p>
                        </div>
                        <div className="ripple-effect">
                          <button
                            type="submit"
                            className="text-white w-100 og-btn ripple"
                            data-cy="signin"
                          >
                            Sign In
                          </button>
                        </div>
                        <div className="text-center">
                          <p className="text-secondary m-0">
                            Don't have an account?{" "}
                            <Link
                              to="/signUp"
                              className="text-danger"
                              data-cy="signup-btn"
                            >
                              Sign Up
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <Robot />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie px-5 mt-2">
          <div className="cookie-message">
            <p className="text-center mt-3">
              We use cookies to improve your browsing experience. By accepting
              this, you agree to our privacy policy data policy and cookie
              policy.{" "}
              <a
                href="https://leadzen.ai/cookie-policy/"
                className="text-danger"
                style={{ borderBottom: "1px solid #FB3E3E" }}
              >
                {" "}
                Terms and Conditions{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
