import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { apiServer } from "../../../config";
import Emitter from "../../Utils/emitter";

const RevertCreditsPopUp = ({
  dataTarget,
  childEmail,
  childCredits,
  setIsCreditUpdated,
  isCreditUpdated,
}) => {
  const [profileCredits, setProfileCredits] = useState(0);
  const [emailCredits, setEmailCredits] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    (async () => {
      setProfileCredits(0);
      setEmailCredits(0);
      // await getCredits();
    })();
  }, []);
  console.log("childCredit: ", childCredits);

  async function handleRevertCredits() {
    try {
      if (profileCredits < 0 || emailCredits < 0) {
        enqueueSnackbar("Negative credits are not allowed");
        return;
      }
      if (
        childCredits?.profile_credits < 0 ||
        childCredits?.email_credits < 0
      ) {
        enqueueSnackbar("Child does not have sufficient credits to revert");
        return;
      }
      if (profileCredits === 0 && emailCredits === 0) {
        enqueueSnackbar(
          "Both Profile Credit and Work Email Credit Should Not Be 0"
        );
        return;
      }
      if (profileCredits > childCredits?.profile_credits) {
        enqueueSnackbar(
          "Profile Credit Value should be less than " +
            childCredits?.profile_credits
        );
        return;
      }
      if (emailCredits > childCredits?.email_credits) {
        enqueueSnackbar(
          "Work Email Credit Value should be less than " +
            childCredits?.email_credits
        );
        return;
      }
      const inputData = {
        child_email: childEmail,
        profile_credit: profileCredits,
        email_credit: emailCredits,
      };
      const credit_res = await fetch(apiServer + "/user/revert_credits", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(inputData),
      });
      switch (credit_res.status) {
        case 200:
          enqueueSnackbar("Credits Reverted Successfully.");
          // await getCredits();
          break;
        case 401:
          enqueueSnackbar("Please Logout and Login again.");
          break;
        case 404:
          enqueueSnackbar("Entered Email Not Found. Please Enter Valid Email.");
          break;
        case 406:
          enqueueSnackbar("Enter Email Id is not associated with you.");
          break;
        default:
          enqueueSnackbar("Something Wrong. Try After Sometime");
          break;
      }
      const response = await credit_res.json();
      console.log("allocate credits response: ", response);
      setProfileCredits(0);
      setEmailCredits(0);
      setIsCreditUpdated(!isCreditUpdated);
      Emitter.emit("fetchCredits", true);
      // await getCredits();
    } catch (err) {
      console.log("Exception: ", err);
    }
  }

  return (
    <div
      id={dataTarget}
      className="modal fade mt-7"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="allocateCreditModalLabel"
      aria-hidden="false"
      data-cy="allocate-credits-type-popup"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/*<h5*/}
            {/*  className="text-danger tool"*/}
            {/*  tooltip-data="Please ensure that the entered Email Id should be registered with Leadzen.AI."*/}
            {/*>*/}
            <h4 className="modal-title">
              Revert Credits of {childEmail}
              {/*<img*/}
              {/*  src="/assets/images/new_icons/i_button.png"*/}
              {/*  alt="#"*/}
              {/*  className="mx-2"*/}
              {/*  style={{ width: "15px", height: "15px", marginTop: "-.1em" }}*/}
              {/*/>*/}
            </h4>
            {/*</h5>*/}
          </div>
          <div className="modal-body">
            <form className="pl-3">
              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                paddingTop={"20px"}
                justifyContent={"space-between"}
              >
                <Typography>
                  Enter Profile Credits ({" "}
                  <Tooltip title="Child's Total Profile Credits">
                    {childCredits?.profile_credits}{" "}
                  </Tooltip>
                  ):
                </Typography>
                <input
                  type="number"
                  value={profileCredits}
                  size="0"
                  onChange={(e) => setProfileCredits(e.target.value)}
                  onInput={(e) => setProfileCredits(e.target.value)}
                  // onBlur={handleSearch}
                  // disabled={!props?.isCheckAll}
                  min="1"
                  max={childCredits?.profile_credits}
                />{" "}
              </Box>
              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                paddingTop={"20px"}
                justifyContent={"space-between"}
              >
                <Typography>
                  Enter Email Credits ({" "}
                  <Tooltip title="Child's Total Work Email Credits">
                    {childCredits?.email_credits}{" "}
                  </Tooltip>
                  ) :{" "}
                </Typography>
                <input
                  type="number"
                  value={emailCredits}
                  size="0"
                  onChange={(e) => setEmailCredits(e.target.value)}
                  onInput={(e) => setEmailCredits(e.target.value)}
                  // onBlur={handleSearch}
                  // disabled={!props?.isCheckAll}
                  min="1"
                  max={childCredits?.email_credits}
                />{" "}
              </Box>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  data-cy="dismiss-btn"
                  // onClick={() => setEmail("")}
                >
                  Discard
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleRevertCredits}
                  data-dismiss="modal"
                  data-cy="export-btn"
                >
                  Revert Credits
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevertCreditsPopUp;
