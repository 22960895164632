import React, { useContext, useEffect, useState } from "react";
import "./Style/style.css";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import { Link, useLocation } from "react-router-dom";
import CompanyFilters from "../CompanySharedComponent/CompanyFilters";
import BulkSearch from "../SharedComponent/BulkSearch";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import Pagination from "../SharedComponent/Pagination";
import UserSearch from "../CompanySharedComponent/UserSearch";
import SpecificCompany from "./SpecificCompany";
import { AppContext } from "../../Context";
import CompanyDemo from "./CompanyDemo";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";
import { apiServer } from "../../config";

let appRender = 0;

const SearchResultCompany = (props) => {
  const { historySearchTerm, dispatch } = useContext(AppContext);

  console.log(`appRender = ${appRender++}`);

  const { isDemo, resultBy } = useContext(AppContext);
  if (isDemo) {
    props.location.state = {};
  }

  const [customSearch, setCustomSearch] = useState({
    name: null,
    location: null,
    industry: null,
    employeeCount: null,
    keywords: null,
  });
  const [isOpen, setIsOpen] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewHandleLoading, setViewHandleLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLeads, setCurrentLeads] = useState([]);
  const [myLeads, setMyLeads] = useState([]);
  const [companyInfo, setCompanyInfo] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [specificUserDetails, setSpecificUserDetails] = useState([
    { index: null, details: null },
  ]);
  const { enqueueSnackbar } = useSnackbar();

  // These variables aren't being updated dynamically states aren't needed
  const searchText = "";
  const tempCookie = Cookies.get("user_linkedin_cookie");
  console.log("props>>", props);

  // for query parameters if its a redirect
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let company_name = query.get("company_name");
  let cookie = query.get("cookie");

  let today = new Date();

  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + "/" + mm + "/" + yyyy;
  const realTimeCompanyUrl = "https://www.linkedin.com/company/realTimeCompany";
  const paginate = (pageNumber) => {
    setCurrentLeads([]);
    setCurrentPage(pageNumber);
    setCurrentLeads(
      searchedList && Array.isArray(searchedList)
        ? searchedList.slice(pageNumber * 10 - 10, pageNumber * 10)
        : 0
    );
  };

  useEffect(() => {
    paginate(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedList]);

  useEffect(() => {
    (async () => {
      let requestForTexAu = {};
      if (props?.location?.state?.customSearch) {
        setCustomSearch(props.location.state.customSearch);
        //setting custom search items in global context
        dispatch({
          type: "setCustomCompanySearch",
          payload: props.location.state.customSearch,
        });
        console.log(
          "from advance.customSearch filters .....",
          props.location.state.customSearch
        );
        setLoading(true);
        let employeeCount = null;
        if (props.location.state.customSearch.employeeCount) {
          const tmp = props.location.state.customSearch.employeeCount;
          employeeCount =
            tmp === "1-10 Employees"
              ? "B"
              : tmp === "11-50 Employees"
              ? "C"
              : tmp === "51-200 Employees"
              ? "D"
              : tmp === "201-500 Employees"
              ? "E"
              : tmp === "501-1000 Employees"
              ? "F"
              : tmp === "1001-5000 Employees"
              ? "G"
              : tmp === "5001-10000 Employees"
              ? "H"
              : "I";
        }
        requestForTexAu = {
          name: props.location.state.customSearch.name
            ? props.location.state.customSearch.name
            : "",

          industry: props.location.state.customSearch.industry
            ? [props.location.state.customSearch.industry]
            : [],
          location: props.location.state.customSearch.location
            ? [props.location.state.customSearch.location]
            : [],
          employeeCount: props.location.state.customSearch.employeeCount
            ? [employeeCount]
            : [],
          keywords: props.location.state.customSearch.keywords
            ? [props.location.state.customSearch.keywords]
            : [],
        };
        const endpoint = "/texau/linkedin/matching_profiles_for_company_url";
        console.log("this is the result", requestForTexAu, props);
        const inputData = requestForTexAu;
        inputData.cookie = tempCookie;
        await sendForExecution(endpoint, inputData);
      }
      if (props.location.pathname.includes("/company_result_by_name")) {
        console.log("......from name .....", props.location.state.requestTexAu);
        setLoading(true);
        requestForTexAu = {
          name: props.location.state.requestTexAu.name,
          industry: [],
          location: [],
          employeeCount: [],
          keywords: [],
        };
        const endpoint = "/texau/linkedin/matching_profiles_for_company_url";
        const inputData = requestForTexAu;
        inputData.cookie = tempCookie;
        await sendForExecution(endpoint, inputData);
      }
      if (props.location.pathname.includes("/result_by_history_type3")) {
        try {
          const response = await fetch(
            apiServer + `/history/id/${props.location.state.details.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("user_token")}`,
              },
            }
          );

          let json_res = await response.json();
          if (!json_res || !json_res.search_results) {
            console.warn(`Invalid Data`);
            return;
          }
          console.log("Data>>>>>>>>>>>loading..", json_res, loading);
          setLoading(false);
          setMyLeads(json_res.search_results);
        } catch (err) {
          enqueueSnackbar("Something went wrong. Please try again");
          console.error("Error: ", err);
        }
      }
      if (props.location.pathname.includes("/result_by_history_type4")) {
        try {
          const response = await fetch(
            apiServer + `/history/id/${props.location.state.details.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("user_token")}`,
              },
            }
          );

          let json_res = await response.json();
          if (!json_res || !json_res.search_results) {
            console.warn(`Invalid Data`);
            return;
          }
          console.log("Loading realtime data>..", json_res, loading);
          setLoading(false);
          setMyLeads(json_res.search_results);
        } catch (err) {
          enqueueSnackbar("Something went wrong. Please try again");
          console.error("Error: ", err);
        }
      }
      if (company_name) {
        setCustomSearch({
          name: company_name,
          location: null,
          industry: null,
          employeeCount: null,
          keywords: null,
        });
        let employeeCount = null;
        //setting custom search items in global context
        dispatch({
          type: "setCustomCompanySearch",
          payload: customSearch,
        });
        setLoading(true);

        requestForTexAu = {
          name: company_name ? company_name : "",

          industry: customSearch.industry ? [customSearch.industry] : [],
          location: customSearch.location ? [customSearch.location] : [],
          employeeCount: customSearch.employeeCount ? [employeeCount] : [],
          keywords: customSearch.keywords ? [customSearch.keywords] : [],
        };
        const endpoint = "/texau/linkedin/matching_profiles_for_company_url";
        const inputData = requestForTexAu;
        inputData.cookie = cookie;
        await sendForExecution(endpoint, inputData);
      }
    })();
  }, [
    props?.location?.state?.customSearch,
    props?.location?.state?.requestTexAu,
    company_name,
  ]);

  const sendForExecution = async (endpoint, inputData) => {
    function handleError(status) {
      setLoading(false);
      setMyLeads([]);
      console.error(`Got HTTP Error ${status}`);
    }

    console.log(`endpoint: ${endpoint}, inputData: ${inputData}`);
    console.log(inputData);

    try {
      const response = await fetch(apiServer + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(inputData),
      });

      function handleUnAuthorized(response = null) {
        console.log("User is UnAuthorized in searchResultCompany");
        setLoading(false);
        setMyLeads([]);
      }

      async function handleSuccess(response) {
        let json = await response.json();
        if (!json || !json.execution_id) {
          handleError(response);
        }

        console.log(`Got Response ${json}`);
        checkExecutionStatus(json.execution_id, "");
      }

      switch (response.status) {
        case 200:
          return await handleSuccess(response);
        case 401:
          return handleUnAuthorized(response);
        default:
          return handleError(response);
      }
    } catch (err) {
      console.error(`Exception Getting Data from ${endpoint}`);
      console.error(err);
      handleError(500);
    }
  };

  const checkExecutionStatus = (executionId, handleFrom) => {
    // const [executionId,handleFrom] = args
    if (!executionId) {
      console.log("executionId is Null");
      return;
    }

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 5 * 60 * 1000);

    const intervalId = setInterval(async () => {
      console.log("In interval.....");
      try {
        const response = await fetch(
          apiServer + `/texau/result/${executionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
          }
        );

        function handleError() {
          if (timeoutId) clearTimeout(timeoutId);
          clearInterval(intervalId);

          setLoading(false);
          setMyLeads("");
        }

        async function handleSuccess(response) {
          if (props.location.pathname.includes("/result_by_history_type4")) {
            console.log("Yes it is in real time company");
            const json = await response.json();
            setMyLeads(json.data);
          }
          const json = await response.json();
          if (!json || !json.data) {
            console.warn(`Invalid Data`);
            return handleError();
          }

          console.log(json);

          setLoading(false);
          if (timeoutId) clearTimeout(timeoutId);
          clearInterval(intervalId);
          setMyLeads(json.data);
          await saveSearchedRecord(json.data, "texAuCompany");
        }

        function handleUnAuthorized(response = null) {
          console.log("User is UnAuthorized in searchResultCompany");
          handleError();
        }

        function handleCookieError() {
          // got cookie error - no need to check again, results will not change
          console.log("Response cookie error", response.statusText);
          handleError();
        }

        function handleNotFound() {
          console.log("Not Found Yet, Waiting...");
        }

        switch (response.status) {
          case 200:
            return handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 403:
            return handleCookieError();
          case 404:
            return handleNotFound();
          default:
            return handleError();
        }
      } catch (e) {
        console.error("Exception>>", e);
      }
    }, 15 * 1000);

    timeoutId = setTimeout(function () {
      console.error("record not found within 5 Min");
      clearInterval(intervalId);
      // TODO: show appropriate ui actions like stop spinners and show error message etc
    }, 5 * 60 * 1000);
  };

  const saveSearchedRecord = async (response, searchType) => {
    // console.log("In saveSearchedRecord...searchTerm", searchTerm);
    let search_term = "";
    if (props.location.pathname.includes("/searchResultCompany")) {
      let values = Object.values(props.location.state.customSearch);

      search_term = values.filter(Boolean).toString();

      console.log("Values Only.....>>>>", search_term);
    }
    let requestForSaveSearch = {
      search_id: uuidv4(),
      search_type: searchType,
      search_term: JSON.stringify(search_term),
      search_results: response,
    };
    console.log("In saveSearchedRecord...", requestForSaveSearch);
    try {
      const response = await fetch(apiServer + "/history/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestForSaveSearch),
      });
    } catch (e) {
      console.error("Exception>>", e);
    }
  };

  function handleError(status) {
    setLoading(false);
    setMyLeads([]);
    console.error(`Got HTTP Error ${status}`);
  }

  function handleCookieError(response) {
    console.log("Response cookie error", response.statusText);
    handleError();
  }

  function handleUnAuthorized(response = null) {
    console.log("User is UnAuthorized in searchResultCompany");
    handleError();
  }

  const handleProfile = async (index, data) => {
    setIsOpen(`${currentPage}${index}`);
    if (data.companyUrl === realTimeCompanyUrl) {
      try {
        const response = await fetch(
          apiServer + `/history/id/${props.location.state.details.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
          }
        );

        async function handleSuccess(response) {
          let json_res = await response.json();
          if (!json_res || !json_res.search_results) {
            console.warn(`Invalid Data`);
            return;
          }
          setCompanyInfo(json_res);
          setViewHandleLoading((prevState) => false);
          console.log("viewHandleLoading", viewHandleLoading);

          // checkExecutionStatus(json_res.execution_id, "handleProfile");
          setSpecificUserDetails((prev) => [
            ...prev,
            { index: `${currentPage}${index}`, details: json_res },
          ]);
        }

        function handleNotFound() {
          console.log("Not Found");
          setViewHandleLoading(false);
          setCompanyInfo("");
        }

        switch (response.status) {
          case 200:
            return handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 403:
            return handleCookieError();
          case 404:
            return handleNotFound();
          default:
            return handleError();
        }
      } catch (err) {
        enqueueSnackbar("Something went wrong. Please try again");
        setSpecificUserDetails((prev) => [
          ...prev,
          { index: `${currentPage}${index}`, details: "Record Not Found" },
        ]);
        console.error("Error: ", err);
      }
    } else {
      console.log(
        "%c This is company URL",
        "background: #121212; color: crimson"
      );

      let reqJsonPipl = {
        url: data.companyUrl,
      };
      console.log(reqJsonPipl);
      console.log("in Handle profile...", `${currentPage}${index}`, data);
      setCompanyInfo("");
      setViewHandleLoading((prevState) => true);
      // setSecondLoader(true);

      try {
        let isDuplicate = false;

        specificUserDetails.forEach((spec) => {
          console.log("spec>>>", spec.index);
          if (spec.index === `${currentPage}${index}`) {
            isDuplicate = false;
          }
        });

        console.log("isDuplicate>>>>", isDuplicate);
        if (isDuplicate === false) {
          console.log("In Fetch......");

          // FIXME: Undefined endpoint, remove this code or update /company/detailed/ process
          const response = await fetch(
            apiServer + "/proxycurl/company_search",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("user_token")}`,
              },
              body: JSON.stringify(reqJsonPipl),
            }
          );

          async function handleSuccess(response) {
            let json_res = await response.json();
            console.log("Data proxyCurl..>>>>>>>>>>>", json_res);
            setCompanyInfo(json_res);
            setViewHandleLoading((prevState) => false);
            console.log("viewHandleLoading", viewHandleLoading);

            // checkExecutionStatus(json_res.execution_id, "handleProfile");
            setSpecificUserDetails((prev) => [
              ...prev,
              { index: `${currentPage}${index}`, details: json_res },
            ]);

            // setSecondLoader(false);
          }

          function handleNotFound() {
            console.log("Not Found Yet, Waiting...");
            setViewHandleLoading(false);
            setCompanyInfo("");
          }

          function handleError(status) {
            console.error(`Got HTTP Error ${status}`);
            setViewHandleLoading(false);
            setCompanyInfo("");
          }

          switch (response.status) {
            case 200:
              return handleSuccess(response);
            case 401:
              return handleUnAuthorized(response);
            case 403:
              return handleCookieError();
            case 404:
              return handleNotFound();
            default:
              return handleError();
          }
        } else {
          setViewHandleLoading((prevState) => false);
        }
      } catch (err) {
        console.error("Error in catch: ", err);
        setSpecificUserDetails((prev) => [
          ...prev,
          { index: `${currentPage}${index}`, details: "Record Not Found" },
        ]);
        console.log("dataa", data);
      }
    }
  };

  console.log("companyInfo", companyInfo);

  useEffect(() => {
    if (searchText !== "") {
      setSearchedList(
        myLeads.filter((data) => {
          return (
            data.name.toLowerCase().includes(searchText.toLowerCase()) ||
            data.description.toLowerCase().includes(searchText.toLowerCase())
          );
        })
      );
    } else {
      setSearchedList(myLeads);
    }
  }, [searchText, myLeads]);
  console.log("isOpen", isOpen);
  console.count();

  const closeModal = () => {
    document.getElementById("searchError").style.display = "none";
  };

  const Popup = () => {
    return (
      <>
        <div
          class="modal"
          style={{ display: "block" }}
          id="searchError"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="d-flex justify-content-center">
                      <img
                        style={{ height: "130px", width: "70px" }}
                        src="/assets/images/g10 (2).png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="text-center">
                      <p class="text-danger ">Oops</p>
                      <p>
                        Hey! Looks like the company you searched for is quite
                        hard to find. I checked over 500+ sources for contact
                        data but to no avail Let's try another company
                      </p>
                      <button
                        style={{ background: "#fb3e3e" }}
                        class="btn text-white"
                        onClick={closeModal}
                      >
                        Retry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  if (isDemo) {
    for (let key in resultBy) {
      if (resultBy[key] === true) {
        return (
          <CompanyDemo
            customSearch={customSearch}
            today={today}
            currentPage={currentPage}
            handleProfile={handleProfile}
            isOpen={isOpen}
            viewHandleLoading={viewHandleLoading}
            companyInfo={companyInfo}
            paginate={paginate}
            active={
              key === "bangloreCompanies"
                ? "bangloreCompanies"
                : "financeCompanies"
            }
          />
        );
      }
    }
  }

  //for adding commas between search queries
  let total = 0,
    count = 0;
  if (props?.location?.state?.customSearch) {
    Object.keys(props?.location?.state?.customSearch).forEach((item) => {
      if (props?.location?.state?.customSearch[item]) total++;
    });
  }

  return (
    <div>
      <div>
        <div className="modal" id="UpgradeModal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="d-flex">
                <div className="pe-4">
                  <img
                    style={{ height: "125px", width: "100px" }}
                    src={"/assets/images/g10.png"}
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <p className="text-danger ">Oops</p>
                  <p>
                    looks like you have insufficient credit to access this
                    leads. upgrade your plan now.
                  </p>
                  <button
                    style={{ background: "#FB3E3E" }}
                    className="btn text-white"
                  >
                    {" "}
                    Upgrade Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="bulkmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-message">
              <p>
                <i className="text-danger">Format to follow:</i>
                Ensure that the first column has the unique values you’re
                searching for. Download the sample below for better
                understanding.
              </p>
              <Link>
                <i className="text-danger text-decoration-underline">
                  Click here to download csv format
                </i>
              </Link>
            </div>
            <div className="modal-content">
              <form action="/upload" id="mydrop" className="dropzone">
                <div className="dz-message needsclick">
                  <button type="button" className="dz-button">
                    Drag and Drop File
                  </button>
                  <br />
                  <button type="button" className="dz-button">
                    OR
                  </button>
                  <br />
                  <span className="note needsclick">
                    <input type="file" accept=".csv" />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        <motion.div className="main-content-area pb-6 pt-2" {...fadeInFadeOut}>
          <div className="main-wrapper container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <SpecificSearchBtn details={false} />
                <div
                  className="sidebar-search-for sidebar-widget pt-4 my-3"
                  style={
                    loading ? { opacity: "0.4", pointerEvents: "none" } : {}
                  }
                >
                  <h6
                    className="text-danger mb-3"
                    style={{ pointerEvents: "none", opacity: "0.4" }}
                  >
                    Customize your search
                  </h6>
                  {/*<div className="px-4">*/}
                  {/*  <CustomizeButton />*/}
                  {/*</div>*/}
                  <CompanyFilters customSearch={customSearch} />
                </div>
                <BulkSearch data={true} />
                <SidebarExtractContact data={true} />
              </div>

              <div className="col-md-8 col-lg-9">
                {
                  <div
                    className="user-result mt-3 d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <label
                      htmlFor="results-container"
                      style={{ minWidth: "120px" }}
                    >
                      Fetch Result for:
                    </label>
                    <div className="results-container mb-1">
                      {historySearchTerm ? (
                        <p>{historySearchTerm}</p>
                      ) : company_name ? (
                        <p>{company_name}</p>
                      ) : props?.location?.state?.requestTexAu ? (
                        <p>{props?.location?.state?.requestTexAu?.name}</p>
                      ) : (
                        <p>
                          {props?.location?.state?.customSearch &&
                            Object.keys(
                              props?.location?.state?.customSearch
                            ).map((item) => {
                              if (props?.location?.state?.customSearch[item]) {
                                count++;
                                if (total === count)
                                  return (
                                    props?.location?.state?.customSearch[item] +
                                    ""
                                  );
                                else
                                  return (
                                    props?.location?.state?.customSearch[item] +
                                    ", "
                                  );
                              }
                              return null;
                            })}
                        </p>
                      )}
                    </div>
                    <div
                      className="detailed-search mb-2"
                      style={{ position: "absolute", right: "0" }}
                    >
                      <div>
                        <small>Last Updated: {today}</small>
                      </div>
                    </div>
                  </div>
                }

                <div className="user-search-wrapper"></div>
                {loading === false ? (
                  <div style={{ marginTop: "1.5em" }}>
                    <div>
                      <UserSearch onResultPage={true} />
                    </div>
                  </div>
                ) : null}

                <div className="user-widget-box  my-3">
                  <div className="d-flex align-items-center justify-content-between py-3">
                    <div className="d-flex align-items-center ">
                      {/*<input*/}
                      {/*  className="ms-3 me-3"*/}
                      {/*  type="checkbox"*/}
                      {/*  id="selectAll"*/}
                      {/*  name="selectAll"*/}
                      {/*  // onChange={handleLeadSelectAll}*/}
                      {/*  // checked={isCheckAll}*/}
                      {/*/>*/}
                      <small className="mx-3">
                        <b>{currentLeads?.length}</b> of{" "}
                        <b>{myLeads ? myLeads?.length : 0}</b> Searched profiles
                      </small>
                    </div>
                    <div className="d-flex">
                      {/* <small className="unlock-btn">
                        Unlock Profile{" "}
                        <img
                            className="ps-3"
                            src={"assets/images/Group 1617.png"}
                            alt=""
                        />
                      </small>
                      <small className="unlock-btn">
                      Unlock Work Email{" "}
                        <img
                          className="ps-3"
                          src="assets/images/Group 1617.png"
                          alt=""
                        />
                      </small> */}
                      <button
                        // onClick={handleLeadSelectionExportExcel}
                        className="export-btn"
                        data-cy="export-btn"
                        disabled={true}
                      >
                        Export{" "}
                        <img
                          className="ps-3"
                          src="/assets/images/export.png"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="user-widget-box  my-3">
                  {loading === false ? (
                    <div
                      className="search-container mb-2"
                      data-cy="search-results"
                    >
                      {currentLeads && currentLeads?.length === 0 ? (
                        <div>
                          <Popup />
                        </div>
                      ) : currentLeads ? (
                        currentLeads.map((data, index) => (
                          <div key={index}>
                            <div className="user-container py-2">
                              {/*<input*/}
                              {/*  className="box ms-3 me-3"*/}
                              {/*  type="checkbox"*/}
                              {/*  id="checkbox"*/}
                              {/*/>*/}
                              <div className="search-author text-danger d-flex align-items-center">
                                <img
                                  onError={(e) => {
                                    e.currentTarget.src =
                                      "/assets/images/new_icons/ProfilePic.png";
                                  }}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    data.logoUrl
                                      ? data.logoUrl
                                      : "/assets/images/Group 2367.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="search-user">
                                <div className="d-flex">
                                  <div
                                    className="col d-flex align-items-center"
                                    style={{ maxWidth: "50%" }}
                                  >
                                    <div
                                      style={{
                                        maxWidth: "100%",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <p className="d-block">{data.name} </p>
                                      <small className="d-block" />
                                    </div>
                                  </div>
                                  <div className="col d-flex align-items-center">
                                    <small>{data.description}</small>
                                  </div>
                                </div>
                              </div>
                              <div className="search-linkedin text-danger d-flex align-items-center">
                                <span className=" ml-6 col d-flex align-items-center">
                                  <a
                                    href={
                                      data.companyUrl
                                        ? data.companyUrl !== realTimeCompanyUrl
                                          ? data.companyUrl
                                          : null
                                        : null
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="mr-4"
                                      src="/assets/images/linkedin1.png"
                                      alt=""
                                    />
                                  </a>
                                </span>
                              </div>
                              <div className="search-view-btn d-flex align-items-center">
                                <a
                                  key={index}
                                  className="btn button"
                                  data-toggle="collapse"
                                  href={
                                    "#collapseExample_" +
                                    `${currentPage}${index}`
                                  }
                                  data-target={
                                    "#collapseExample_" +
                                    `${currentPage}${index}`
                                  }
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapseExample"
                                  onClick={() => handleProfile(index, data)}
                                >
                                  View Details
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                background: "white",
                                borderRadius: "20px",
                                padding: "20px",
                              }}
                            >
                              <div
                                className="panel-collapse collapse in"
                                id={`collapseExample_${currentPage}${index}`}
                              >
                                {isOpen === `${currentPage}${index}` ? (
                                  <span>
                                    {!viewHandleLoading ? (
                                      <span>
                                        <SpecificCompany
                                          data={companyInfo}
                                          page={currentPage}
                                          index={index}
                                        />
                                      </span>
                                    ) : (
                                      <section
                                        className="item-section"
                                        style={{ textAlign: "center" }}
                                      >
                                        Please Wait..
                                      </section>
                                    )}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <Popup />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <div role="status" style={{ height: "400px" }}>
                        {/*<Lottie options={Loader} />*/}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  {loading === false ? (
                    <Pagination
                      postsPerPage={10}
                      totalPosts={searchedList ? searchedList?.length : 1}
                      paginate={paginate}
                      render={props.location.state.customSearch}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SearchResultCompany;
