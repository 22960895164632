import React, { useReducer } from "react";
import { reducer } from "./reducer";

const AppContext = React.createContext();

const initial = {
  isDemo: false,
  resultBy: {
    linkedUrl: false,
    name: false,
    tags: false,
    company: false,
    bangloreCompanies: false,
    financeCompanies: false,
    contentWriter: false,
  },
  customCompanySearch: null,
  customCompanyInfo: null,
  companyLoading: true,
  customPersonInfo: null,
  personLoading: true,
  tmpData: null,
  historySearchTerm: "",
  microIntraction: { name: "", active: "" },
  personalisedDetails : null,
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initial);
  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
