import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fb3e3e",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif`,
    button: {
      textTransform: "none",
    },
  },
});
