import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AskJarvis from "../SharedComponent/AskJarvis";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import ExtractContacts from "../SharedComponent/ExtractContacts";
import BulkSearch from "../SharedComponent/BulkSearch";
import CombinedSearchBar from "../SharedComponent/CombinedSearchBar";
import Modalpop from "../SharedComponent/Modal";
import Cookies from "js-cookie";
import { apiServer } from "../../config";

const FirstTimeUser = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  function handleError(status) {
    console.error(`Got HTTP Error ${status}`);
  }

  function handleUnAuthorized() {
    setPopupVisible(true);
    console.log("User is UnAuthorized in firstTimeUser");
  }

  const UpdateUser = async () => {
    try {
      console.log("in update user");

      const fetchResponse = await fetch(apiServer + "/users/me", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify({
          email: Cookies.get("user_email").toLowerCase(),
          onboarded: true,
        }),
      });

      async function handleSuccess(fetchResponse) {
        let json_res = await fetchResponse.data;
        console.log("json_res for update user", json_res);
      }

      switch (fetchResponse.status) {
        case 200:
          return await handleSuccess(fetchResponse);
        case 401:
          return handleUnAuthorized(fetchResponse);
        default:
          return handleError(fetchResponse);
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    (async () => {
      const script = document.createElement("script");
      script.src = "assets/js/app.js";
      script.async = true;
      await UpdateUser();
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCSVFile() {}

  return (
    <>
      {popupVisible ? <Modalpop /> : null}
      <div>
        <div className="modal" id="bulkmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-message">
              <p>
                <i className="text-danger">Format to follow:</i> Ensure that the
                first column has the unique values you’re searching for.
                Download the sample below for better understanding.{" "}
              </p>
              <Link>
                <i className="text-danger text-decoration-underline">
                  Click here to download csv format
                </i>
              </Link>
            </div>
            <div className="modal-content">
              <form action="/upload" id="mydrop" className="dropzone">
                <div className="dz-message needsclick">
                  <button type="button" className="dz-button">
                    Drag and Drop File
                  </button>
                  <br />
                  <button type="button" className="dz-button">
                    OR{" "}
                  </button>
                  <br />
                  <span className="note needsclick">
                    <input type="file" accept=".csv" onChange={handleCSVFile} />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="main-content-area pb-6 pt-2">
          <div className="main-wrapper container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                {/*<SpecificSearchBtn details={true} />*/}
                <BulkSearch />
                <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                  <ProspectSearch />
                </div>
                <SidebarExtractContact />
              </div>
              <div className="col-md-8 col-lg-9">
                <CombinedSearchBar isResultPage={true} />
                <ExtractContacts />
                <div>
                  <AskJarvis />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstTimeUser;
