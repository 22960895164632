import React, { useEffect } from "react";
import CommonBasicFilter from "../CommonBasicFilter";

const FilterCompaniesHiringRecently = ({
  filterRequest,
  setFilterRequest,
  sheetName,
}) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);

  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_name"
          label="Company Name"
          icon="/assets/images/new_icons/company_name.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="title"
          label="Title"
          icon="/assets/images/new_icons/Industry.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="location"
          label="Location"
          icon="/assets/images/new_icons/Location.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keywords"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};

export default FilterCompaniesHiringRecently;
