import React, { useState } from "react";
import CompanyFilters from "../CompanySharedComponent/CompanyFilters";
import "./Style/style.css";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import UserSearch from "../CompanySharedComponent/UserSearch";
import ExtractContacts from "../SharedComponent/ExtractContacts";
import { Link } from "react-router-dom";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import BulkSearch from "../SharedComponent/BulkSearch";

const FirstTimeUserCompany = () => {
  const [customSearch, setCustomSearch] = useState({
    location: null,
    industry: null,
    job_title: null,
    education: null,
    company_name: null,
    keywords: null,
    csv_file: null,
  });

  const handleCSVFile = (e) => {
    setCustomSearch({ ...customSearch, csv_file: e.target.files[0] });
  };

  return (
    <div>
      <div className="modal" id="bulkmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-dialog">
          <div className="modal-message">
            <p>
              <i className="text-danger">Format to follow:</i> Ensure that the
              first column has the unique values you’re searching for. Download
              the sample below for better understanding.{" "}
            </p>
            <Link>
              <i className="text-danger text-decoration-underline">
                Click here to download csv format
              </i>
            </Link>
          </div>
          <div className="modal-content">
            <form action="/upload" id="mydrop" className="dropzone">
              <div className="dz-message needsclick">
                <button type="button" className="dz-button">
                  Drag and Drop File
                </button>
                <br />
                <button type="button" className="dz-button">
                  OR{" "}
                </button>
                <br />
                <span className="note needsclick">
                  <input type="file" accept=".csv" onChange={handleCSVFile} />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="main-content-area pb-6 pt-2"
        style={{ pointerEvents: "none", opacity: "0.4" }}
      >
        <div className="main-wrapper container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <SpecificSearchBtn details={false} />
              <div
                className="sidebar-search-for sidebar-widget pt-4 my-3"
                data-cy="company-filters"
              >
                <h6
                  className="text-danger mb-3"
                  style={{ pointerEvents: "none", opacity: "0.4" }}
                >
                  Customize your search
                </h6>
                <CompanyFilters />
              </div>
              <BulkSearch data={true} />
              <SidebarExtractContact data={true} />
            </div>
            <div className="col-md-8 col-lg-9">
              <UserSearch />
              <ExtractContacts data={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeUserCompany;
