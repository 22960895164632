const pageVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "backInOut",
  duration: 0.4,
};

const containerHeightVariants = {
  hidden: {
    height: 0,
  },
  visible: {
    height: "auto",
    transition: {
      when: "beforeChildren",
    },
  },
  exit: {
    height: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

export const fadeInFadeOut = {
  variants: pageVariants,
  initial: "hidden",
  animate: "visible",
  exit: "exit",
  transition: pageTransition,
};

export const slideInSlideOut = {
  variants: containerHeightVariants,
  initial: "hidden",
  animate: "visible",
  exit: "exit",
};
