import LinkedInImage from "./LinkedInImage";
import React from "react";
import { fundingAmountFormatter, ValidateLinkedinUrl } from "../../Utils/utils";
import dayjs from "dayjs";

const CompaniesAquiringRecently = ({ companyPageRecord }) => {
  return (
    <>
      {/*<div className="realtime-result">*/}
      <div className="ml-2 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord.company_that_acquired === "object" &&
          companyPageRecord.company_that_acquired[0].length > 0
            ? companyPageRecord.company_that_acquired?.[0]?.replace("?","")
            : companyPageRecord.company_that_acquired?.replace("?","")
            ? companyPageRecord.company_that_acquired?.replace("?","")
            : "-"}
        </h6>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.linkedin_of_company_that_acquired ===
            "object" &&
            companyPageRecord?.linkedin_of_company_that_acquired.length > 0
            ? companyPageRecord?.linkedin_of_company_that_acquired[0]
            : companyPageRecord?.linkedin_of_company_that_acquired
            ? companyPageRecord?.linkedin_of_company_that_acquired
            : null
        )}
      />

      <div className="realtime-result__child">
        <>
          Deal Amount
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.acquired_amount === "object" &&
            companyPageRecord?.acquired_amount[0].length > 0
              ? companyPageRecord?.acquired_amount[0] < 0
                ? "Undisclosed"
                : companyPageRecord?.acquired_amount[0]
              : companyPageRecord?.acquired_amount
              ? companyPageRecord?.acquired_amount < 0
                ? "Undisclosed"
                : fundingAmountFormatter(companyPageRecord?.acquired_amount)
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Acquiring Company
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.company_that_got_acquired === "object" &&
            companyPageRecord?.company_that_got_acquired.length > 0
              ? companyPageRecord?.company_that_got_acquired[0]
              : companyPageRecord?.company_that_got_acquired
              ? companyPageRecord?.company_that_got_acquired
              : "-"}
          </span>
        </>
      </div>
      <div
        className="realtime-result__child"
        style={{ textTransform: "capitalize" }}
      >
        Date Of Acquisition <br /> {/*{companyPageRecord?.Date_of_funding ? (*/}
        <>
          <span className="text-danger">
            {typeof companyPageRecord?.date_of_acquisition === "object" &&
            companyPageRecord?.date_of_acquisition[0].length > 0
              ? dayjs(companyPageRecord?.date_of_acquisition[0]).format(
                  "DD MMM YYYY"
                )
              : "-"}{" "}
          </span>
        </>
        {/*) : null}*/}
      </div>
      <div className="realtime-result__child">
        Acquired Company Contact
        <>
          <br />
          <span style={{ fontWeight: "normal" }}>
            {typeof companyPageRecord?.contact_name_of_company_that_acquired ===
              "object" &&
            companyPageRecord?.contact_name_of_company_that_acquired[0].length >
              0
              ? companyPageRecord?.contact_name_of_company_that_acquired[0]
              : companyPageRecord?.contact_name_of_company_that_acquired
              ? companyPageRecord?.contact_name_of_company_that_acquired
              : "-"}
          </span>
        </>
      </div>

      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.contact_url_of_company_that_acquired ===
            "object" &&
            companyPageRecord?.contact_url_of_company_that_acquired.length > 0
            ? companyPageRecord?.contact_url_of_company_that_acquired[0]
            : companyPageRecord?.contact_url_of_company_that_acquired
            ? companyPageRecord?.contact_url_of_company_that_acquired
            : null
        )}
      />
    </>
  );
};
export default CompaniesAquiringRecently;
