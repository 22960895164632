import React, { useEffect } from "react";
import CommonBasicFilter from "../CommonBasicFilter";
import CommonSelectBoxItem from "../CommonSelectBoxItem";

const FilterPublicListedCompanies = ({ filterRequest, setFilterRequest }) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);
  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_name"
          label="Company Name"
          icon="/assets/images/new_icons/company_name.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="industry"
          label="Industry"
          icon="/assets/images/new_icons/Industry.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="location"
          label="Location"
          icon="/assets/images/new_icons/Location.png"
        />
        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="no_of_employees"
          label="Employee Size"
          tooltipText={"Select Employee Size"}
          icon="/assets/images/new_icons/Employee Count.png"
          collection={[
            "1-10",
            "11-50",
            "51-200",
            "201-500",
            "501-1000",
            "1001-5000",
            "5001-10000",
            "10001+",
          ]}
        />
        <CommonSelectBoxItem
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="estimated_revenue"
          label="Revenue"
          tooltipText={"Select estimated revenue"}
          icon="/assets/images/trending-up.png"
          collection={[
            "Upto Rs. 50 Lakh",
            "More than Rs. 500 Crore",
            "Rs. 50 Lakh - 1 Crore",
            "Rs. 1 - 2 Crore",
            "Rs. 2 - 5 Crore",
            "Rs. 5 - 10 Crore",
            "Rs. 10 - 25 Crore",
            "Rs. 25 - 50 Crore",
            "Rs. 50 - 100 Crore",
            "Rs. 100 - 500 Crore",
            "Rs. 500 - 1000 Crore",
            "Rs. 1000 - 5000 Crore",
          ]}
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="founded_year"
          label="Founded Year"
          icon="/assets/images/bx_bx-calendar.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keyword"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};

export default FilterPublicListedCompanies;
