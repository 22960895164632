import React from "react";
import {
  setCompanyName,
  setContactPersonLinkedin,
  setLinkedIn,
  setContactPersonName,
} from "./util";

const RealtimeSelectAllExport = ({
  leads,
  setRealtimeSelectedLeads,
  setIsRealtimeCheckAll,
  setCustomCount,
  isRealtimeCheckAll,
  sheetName,
  pathName,
  companyPageRecords,
  allCompanyPageRecords,
}) => {
  const handleLeadSelectAll = async (e) => {
    const { checked } = e.target;
    if (checked) {
      try {
        console.log("updated sheet name: ", sheetName, pathName);
        let updatedArray = null;
        if (
          sheetName === "realtime-companies_hiring_recently"
        ) {
          updatedArray = leads?.map((item) => ({
            company_name: item.company_name,
            location: item.location,
            title: item.title,
            jobURLs: item.apply_options,
          }));
        } else {
          if (
            sheetName === "realtime-recently_funded_company" ||
            sheetName === "realtime-investors_who_funded_recently"
          ){
            updatedArray = allCompanyPageRecords?.map((item) => ({
                company_linkedin_url: setLinkedIn(sheetName, item),
                company_name: setCompanyName(sheetName, item),
                contact_person_name: setContactPersonName(sheetName, item),
                contact_person_linkedin: setContactPersonLinkedin(sheetName, item),
                company_that_funded: item.company_that_funded?.[0],
                funding_amount: item.funding_amount?.[0],
            }));  
          }
          else{
            updatedArray = allCompanyPageRecords?.map((item) => ({
              company_linkedin_url: setLinkedIn(sheetName, item),
              company_name: setCompanyName(sheetName, item),
              contact_person_linkedin: setContactPersonLinkedin(sheetName, item),
              contact_person_name: setContactPersonName(sheetName, item),
            }));
          }
        }
        console.log("updatedArray: ", updatedArray);
        setRealtimeSelectedLeads(updatedArray);
        setIsRealtimeCheckAll(true);
        setCustomCount(updatedArray.length);
      } catch (error) {
        console.error("In handle company>>", error);
      }
    } else {
      setRealtimeSelectedLeads([]);
      setIsRealtimeCheckAll(false);
      setCustomCount(0);
    }
  };
  return (
    <>
      <input
        className="ms-3 me-3"
        type="checkbox"
        data-cy="selectAll-checkbox"
        id="selectAll"
        name="selectAll"
        onChange={(e) => handleLeadSelectAll(e)}
        checked={isRealtimeCheckAll}
      />
    </>
  );
};
export default RealtimeSelectAllExport;
