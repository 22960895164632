import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LeadCard from "../../../RealTime/LeadCard";
import { Map } from "react-lodash";

const ProspectFilterLinks = (props) => {
  //constants
  const identifier = uuidv4();

  //derived constants
  const accordionId = `filter-search-accordion-${identifier}`;

  const leadCards = [
    {
      page_name: "Recently Funded Companies",
      data_cy: "got-funded",
      index: "realtime-recently_funded_company",
    },
    {
      page_name: "Companies Hiring Recently",
      data_cy: "hiring",
      index: "realtime-companies_hiring_recently",
    },
  ];
  const [disabled, setDisabled] = useState(false);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionId}`}
        >
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={props.iconUrl || "/assets/images/new_icons/Frame_2888.png"}
            alt="Prospecting Search Item Image"
          />
          {props.displayName}
          {props.tooltipText ? (
            <span className="tool" tooltip-data={props.tooltipText}>
              <img
                src="/assets/images/new_icons/i_button.png"
                alt="#"
                className="mx-2 tool"
                style={{
                  width: "15px",
                  height: "15px",
                  marginTop: "-.1em",
                }}
              />
            </span>
          ) : null}
        </button>
      </h2>
      <div
        id={accordionId}
        className="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="d-grid gap-2">
            <Map
              collection={leadCards}
              iteratee={(value, index) => {
                return (
                  <LeadCard
                    key={index}
                    id={index}
                    page_name={value.page_name}
                    data_cy={value.data_cy}
                    disable={setDisabled}
                    index={value.index}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProspectFilterLinks;
