import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButton } from "../History/MUICustomized";

const SavedListButtons = ({ state, dispatch }) => {
  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      dispatch({ type: "SET_TOGGLE_BUTTON", payload: newToggle });
      dispatch({ type: "SET_SEARCH_CACHE", payload: {} });
      dispatch({ type: "SET_TOTAL_SEARCH_RECORDS", payload: 0 });
      dispatch({ type: "SET_VIEW", payload: "normal" });
      dispatch({ type: "SET_PAGE", payload: 0 });
      dispatch({ type: "SET_SEARCH_TEXT", payload: "" });
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={state.toggleButton}
      exclusive
      onChange={handleToggle}
      aria-label="save-list buttons"
      sx={{
        margin: "0",
        marginBottom: "0.5rem",
        backgroundColor: "#ffffff",
        border: "2px solid #fb3e3e",
        borderRadius: "25px",
        height: "3.25rem",
      }}
    >
      <ToggleButton value="people" aria-label="people">
        People
      </ToggleButton>
      <ToggleButton value="company" aria-label="company">
        Company
      </ToggleButton>
      <ToggleButton value="geolocator" aria-label="geolocator">
        Geolocator
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SavedListButtons;
