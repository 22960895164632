import React, { useEffect, useState } from "react";

const MapSelectAll = ({
  leads,
  setMapSelectedLeads,
  setIsCheckAll,
  mapSelectedLeads,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (mapSelectedLeads.length === 15) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [mapSelectedLeads]);
  const handleLeadSelectAll = async (e) => {
    const { checked } = e.target;
    if (!checked) {
      setMapSelectedLeads([]);
      setIsCheckAll(false);
      setIsChecked(false);
    } else {
      setMapSelectedLeads(leads.map((li) => li.id));
      setIsCheckAll(true);
      setIsChecked(true);
    }
  };
  return (
    <input
      className="ms-3 me-3"
      type="checkbox"
      id="selectAll"
      name="selectAll"
      // disabled={!props.isExport}
      onChange={(e) => handleLeadSelectAll(e)}
      checked={isChecked}
    />
  );
};
export default MapSelectAll;
