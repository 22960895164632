import CommonDateFilter from "../CommonDateFilter";
import CommonBasicFilter from "../CommonBasicFilter";
import React, { useEffect } from "react";

const FilterInvestorWhoFundedRecently = ({
  filterRequest,
  setFilterRequest,
  sheetName,
}) => {
  useEffect(() => {
    setFilterRequest((filterRequest) => filterRequest);
  }, []);

  return (
    <>
      <div className="sidebar-accordion accordion" style={{ width: "100%" }}>
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="investor_that_funded"
          label="Funded By"
          icon="/assets/images/new_icons/company_name.png"
        />
        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="company_that_got_funded"
          label="Funded Company Name"
          icon="/assets/images/new_icons/Industry.png"
        />

        <CommonDateFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          attribute="date_of_funding"
          label="Date Of Funding"
          tooltipText={"Select Date Range"}
          icon="/assets/images/new_icons/date.png"
        />

        <CommonBasicFilter
          setFilterRequest={setFilterRequest}
          filterRequest={filterRequest}
          itemClass="keywords"
          label="Keywords"
          icon="/assets/images/new_icons/keywords.png"
        />
      </div>
    </>
  );
};

export default FilterInvestorWhoFundedRecently;
