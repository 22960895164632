import React from "react";
import LinkedInImage from "./LinkedInImage";
import {ValidateLinkedinUrl} from "../../Utils/utils";

const UnfundedCompanies = ({companyPageRecord}) => {
    return (
        <>
            <div className="ml-2 realtime-result__name">
                <h6 style={{fontWeight: "normal"}}>
                    {typeof companyPageRecord?.company_name === "object" &&
                    companyPageRecord?.company_name?.length > 0
                        ? companyPageRecord?.company_name[0]
                        : companyPageRecord?.company_name
                            ? companyPageRecord?.company_name
                            : "-"}
                </h6>
            </div>
            <LinkedInImage
                link={ValidateLinkedinUrl(
                    typeof companyPageRecord?.company_linkedin_url === "object" &&
                    companyPageRecord?.company_linkedin_url?.length > 0
                        ? companyPageRecord?.company_linkedin_url[0]
                        : companyPageRecord?.company_linkedin_url
                            ? companyPageRecord?.company_linkedin_url
                            : null
                )}
            />
            <div className="realtime-result__child text-capitalize">
                <>
                    Industry
                    <br/>
                    <span className="text-red ">
            {typeof companyPageRecord?.industry === "object" &&
            companyPageRecord?.industry[0]?.length > 0
                ? companyPageRecord?.industry[0]
                : companyPageRecord?.industry
                    ? companyPageRecord?.industry
                    : "-"}
          </span>
                </>
            </div>

            <div className="realtime-result__child">
                <>
                    Location
                    <br/>
                    <span className="text-red">
            {typeof companyPageRecord?.city === "object" &&
            companyPageRecord?.city[0]?.length > 0
                ? companyPageRecord?.city[0]
                : companyPageRecord?.city
                    ? companyPageRecord?.city
                    : "-"}
          </span>
                </>
            </div>
        </>
    );
};

export default UnfundedCompanies;
