import React from "react";
import { isJSON } from "../../Utils/utils";

const InputOrHistorySearchText = (props) => {
  console.log("Props in InputOrHistorySearchText", props);
  let total = 0,
    count = 0,
    searchTerm;

  if (props?.payload?.details?.search_term) {
    searchTerm = isJSON(props?.payload?.details?.search_term);
  } else {
    searchTerm = props?.payload?.details?.search_term;
  }
  return (
    <div>
      <div className="user-result mt-3 d-flex align-items-center">
        <label htmlFor="results-container" style={{ minWidth: "120px" }}>
          Fetch Result for:
        </label>
        <div className="results-container mb-1">
          {props?.companySearchTerm && (
            <p>
              {Object.entries(props?.companySearchTerm)
                .filter(([key, value]) => value.length > 0)
                .map(([key, value]) => {
                  return key.includes("exclude")
                    ? `exclude: ${value.join(", ")}`
                    : value.join(", ");
                })
                .join(", ")}
            </p>
          )}

          {props?.companyHistory && <p>{props?.companyHistory}</p>}

          {props?.payload?.searchInput && (
            <>
              {props?.payload?.searchInput?.search_text ? (
                <p> {props?.payload?.searchInput.search_text} </p>
              ) : props?.payload?.searchInput &&
                !props?.payload?.searchInput?.sheet_name ? (
                <p>
                  {Object.keys(JSON.parse(props?.payload?.searchInput)).map(
                    (filter) => {
                      const searchingFor = JSON.parse(
                        props?.payload?.searchInput
                      );
                      if (searchingFor[filter]) {
                        count++;
                        if (total === count) return `${searchingFor[filter]}`;
                        else return `${searchingFor[filter]},  `;
                      }
                      return null;
                    }
                  )}
                </p>
              ) : null}
            </>
          )}
          {props?.payload?.payload && (
            <div className="d-flex">
              <p>
                {Object.values(props?.payload?.payload)?.map((item) =>
                  typeof item === "object" ? (
                    item?.map((res, index) => <span key={index}>{res},</span>)
                  ) : (
                    <span> {item}</span>
                  )
                )}
              </p>
            </div>
          )}

          {props?.payload?.details?.search_term &&
            typeof props?.payload?.details?.search_term === "string" && (
              <p>
                {searchTerm === "object" ? (
                  <>
                    {Object.keys(JSON.parse(searchTerm)).map((filter) => {
                      const searchingFor = JSON.parse(searchTerm);
                      if (searchingFor[filter]) {
                        count++;
                        if (total === count) return `${searchingFor[filter]}`;
                        else return `${searchingFor[filter]},  `;
                      }
                      return null;
                    })}
                  </>
                ) : (
                  <>{searchTerm}</>
                )}
              </p>
            )}
        </div>
      </div>
    </div>
  );
};

export default InputOrHistorySearchText;
