import ReactPaginate from "react-paginate";

const Pagination = ({ onPageChange, pageNumber, pageCount }) => {
  console.log(pageCount)
  return (
    <ReactPaginate
      className="pagination-bar mt-3 mb-5"
      previousLabel="< "
      nextLabel=" >"
      onPageChange={onPageChange}
      forcePage={pageNumber}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};

export default Pagination;
