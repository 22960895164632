import React from "react";
import { Box, Button, Typography } from "@mui/material";

const UserProfileItems = ({ setItemSelected, userDetails, itemSelected }) => {
  return (
    <>
      <div
        className="user-widget-box mt-3 text-center shadow-lg"
        style={{ textAlign: "center", width: "100%" }}
        data-cy="user_profile_item"
      >
        <Typography
          variant="h6"
          sx={{
            borderBottom: "1px solid rgba(0,0,0,0.2)",
          }}
          p={2}
        >
          My Profile
        </Typography>

        <Box p="7% 10%">
          <Typography
            sx={{
              color: "#fb3e3e",
            }}
            pb={2}
            variant="h6"
          >
            Personal Information
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            sx={{
              borderBottom: "3px solid #fb3e3e",
              paddingBottom: "10px",
            }}
          >
            <Button
              sx={{
                "&:hover": {
                  outline: "none",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
              onClick={() => setItemSelected("profile")}
            >
              <Typography
                variant={itemSelected === "profile" ? "subtitle1" : "subtitle2"}
                sx={{
                  color:
                    itemSelected === "profile" ? "#000)" : "rgba(0,0,0,0.7)",
                }}
              >
                Profile Settings
              </Typography>
            </Button>

            {userDetails?.signup_source === "platform" &&
            (!userDetails?.children || userDetails?.children?.length > 0) &&
            !userDetails?.parent ? (
              <Button
                sx={{
                  "&:hover": {
                    outline: "none",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                }}
                onClick={() => setItemSelected("admin")}
              >
                <Typography
                  variant={itemSelected === "admin" ? "subtitle1" : "subtitle2"}
                  sx={{
                    color:
                      itemSelected === "admin" ? "#000)" : "rgba(0,0,0,0.7)",
                  }}
                >
                  Admin Settings
                </Typography>
              </Button>
            ) : null}
          </Box>

          {/*<Box>*/}
          {/*  <Typography*/}
          {/*    variant="body1"*/}
          {/*    sx={{*/}
          {/*      color: "rgba(0,0,0,0.8)"*/}
          {/*    }}*/}
          {/*    pt={2}*/}
          {/*    px={2}*/}
          {/*  >*/}
          {/*    Current Plan Information*/}
          {/*  </Typography>*/}
          {/*  <Typography*/}
          {/*    variant="body1"*/}
          {/*    sx={{*/}
          {/*      color: "rgba(0,0,0,0.8)"*/}
          {/*    }}*/}
          {/*    py={2}*/}
          {/*  >*/}
          {/*    Transaction History*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Box>
      </div>
    </>
  );
};
export default UserProfileItems;
