import React from "react";
import LinkedInImage from "./LinkedInImage";
import { fundingAmountFormatter, ValidateLinkedinUrl } from "../../Utils/utils";
import dayjs from "dayjs";
const InvestorsWhoFundedRecently = ({ companyPageRecord }) => {
  return (
    <>
      <div className="ml-2 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord.investor_that_funded === "object" &&
          companyPageRecord.investor_that_funded[0]?.length > 0
            ? companyPageRecord.investor_that_funded[0]
            : companyPageRecord.investor_that_funded
            ? companyPageRecord.investor_that_funded
            : "-"}
        </h6>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.investor_linkedin === "object" &&
            companyPageRecord?.investor_linkedin.length > 0
            ? companyPageRecord?.investor_linkedin[0]
            : companyPageRecord?.investor_linkedin
            ? companyPageRecord?.investor_linkedin
            : null
        )}
      />

      <div className="realtime-result__child">
        <>
          Funding Amount
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.funding_amount === "object" &&
            companyPageRecord?.funding_amount[0].length > 0
              ? companyPageRecord?.funding_amount[0] < 0
                ? "Undisclosed"
                : companyPageRecord?.funding_amount[0]
              : companyPageRecord?.funding_amount
              ? companyPageRecord?.funding_amount < 0
                ? "Undisclosed"
                : fundingAmountFormatter(companyPageRecord?.funding_amount)
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Funded Company
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.company_that_got_funded === "object" &&
            companyPageRecord?.company_that_got_funded?.length > 0
              ? companyPageRecord?.company_that_got_funded[0]
              : companyPageRecord?.company_that_got_funded
              ? companyPageRecord?.company_that_got_funded
              : "-"}
          </span>
        </>
      </div>
      <div
        className="realtime-result__child"
        style={{ textTransform: "capitalize" }}
      >
        Date Of Funding <br /> {/*{companyPageRecord?.Date_of_funding ? (*/}
        <>
          <span className="text-danger">
            {typeof companyPageRecord?.date_of_funding === "object" &&
            companyPageRecord?.date_of_funding[0]?.length > 0
              ? dayjs(companyPageRecord?.date_of_funding[0]).format(
                  "DD MMM YYYY"
                )
              : "-"}{" "}
          </span>
        </>
        {/*) : null}*/}
      </div>
      <div className="realtime-result__child">
        Investor Contact
        <>
          <br />
          <span style={{ fontWeight: "normal" }}>
            {typeof companyPageRecord?.investor_contact === "object" &&
            companyPageRecord?.investor_contact[0]?.length > 0
              ? companyPageRecord?.investor_contact[0]
              : companyPageRecord?.investor_contact
              ? companyPageRecord?.investor_contact
              : "-"}
          </span>
        </>
      </div>
      <LinkedInImage
        link={ValidateLinkedinUrl(
          typeof companyPageRecord?.investor_contact_linkedin_url ===
            "object" &&
            companyPageRecord?.investor_contact_linkedin_url.length > 0
            ? companyPageRecord?.investor_contact_linkedin_url[0]
            : companyPageRecord?.investor_contact_linkedin_url
            ? companyPageRecord?.investor_contact_linkedin_url
            : null
        )}
      />
    </>
  );
};

export default InvestorsWhoFundedRecently;
