import { Route, Switch, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { AnimatePresence } from "framer-motion";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import LogIn from "./Components/LogIn/LogIn";
import SignUp from "./Components/SignUp/SignUp";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import UserGuide from "./Components/UserInfo/UserGuide";
import HowToUse from "./Components/HowToUse/HowToUse";
import FirstTimeUser from "./Components/UserInfo/FirstTimeUser";
import History from "./Components/History/History";
import Dashboard from "./Components/UserInfo/Dashboard";
import SearchResult from "./Components/SearchResult/SearchResult";
import SearchResultTexAu from "./Components/SearchResult/SearchResultTexAu";
import RealTimePage from "./Components/RealTime/RealTimePage";
import RealTimeListView from "./Components/RealTime/RealTimeListView";
import FirstTimeUserCompany from "./Components/CompanyInfo/FirstTimeUserCompany";
import SearchResultCompany from "./Components/CompanyInfo/SearchResultCompany";
import Geolocator from "./Components/Geolocator/Geolocator";
import SpecificCompany from "./Components/CompanyInfo/SpecificCompany";
import DetailInfoCompany from "./Components/DetailedInfo/DetailInfoCompany";
import CombinedSearchBar from "./Components/SharedComponent/CombinedSearchBar";
import BasicSearchResult from "./Components/SearchResult/BasicSearchResult";
import SpecificUserInfo from "./Components/DetailedInfo/SpecificUserInfo";
import Faq from "./Components/FAQ/Faq";
import SmeListView from "./Components/RealTime/SmeCompanies/SmeListView";
import Header from "./Components/SharedComponent/Header";
import NotFound from "./Components/NotFound/NotFound";
import CreditLog from "./Components/CreditsLog/CreditLog";
import EngagePage from "./Components/EngagePage/engage";
import RealTimeResults from "./Components/RealTime/RealTimeResults";
import CompanySearch from "./Components/CompanyFilter/CompanySearch";
import PaymentSuccess from "./Components/Payment/PaymentSuccess";
import ChatSupport from "./Components/ChatSupport/ChatSupport";
import UserProfile from "./Components/UserProfile/UserProfile";
import PaymentFailed from "./Components/Payment/PaymentFailed";

import StripeCreditPlanContainer from "./Components/Payment/stripe/StripeCreditPlanContainer";
import SavedListNames from "./Components/SavedList/SavedListNames";
import SavedList from "./Components/SavedList/SavedList";


const App = () => {
    const location = useLocation();

    const useStyles = makeStyles(() => ({
        success: { backgroundColor: "#16AE26" },
        error: { backgroundColor: "#EC343D" },
        warning: { backgroundColor: "#EA8600" },
        info: { backgroundColor: "#0E65D0" },
    }));

    // Create styles class as hook using MUI
    const classes = useStyles();

    return (
        <div style={{ position: "relative" }}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                preventDuplicate={true}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                }}
            >
                <Header />
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route path="/profile" component={UserProfile} />
                        <Route path="/support" component={ChatSupport} />
                        <Route path="/search_bar" component={CombinedSearchBar} />
                        <Route
                            path="/payment/extension"
                            component={StripeCreditPlanContainer}
                        />
                        <Route
                            path="/payment/platform"
                            component={StripeCreditPlanContainer}
                        />
                        <Route
                            path="/payment/outreach"
                            component={StripeCreditPlanContainer}
                        />
                        {/*<Route path="/payment/extension" component={CreditPlanContainer} />*/}
                        {/*<Route path="/payment/platform" component={CreditPlanContainer} />*/}
                        {/*<Route path="/payment/outreach" component={CreditPlanContainer} />*/}
                        <Route path="/SearchBarSearch" component={BasicSearchResult} />
                        <Route path="/SalesNavigatorSearch" component={BasicSearchResult} />
                        <Route path="/CompanyAndNameSearch" component={BasicSearchResult} />
                        <Route path="/UnlockProfile" component={BasicSearchResult} />
                        <Route path="/ProspectSearch" component={BasicSearchResult} />
                        <Route path="/CompanySearch" component={CompanySearch} />
                        <Route path="/CompanySearchHistory" component={CompanySearch} />
                        <Route
                            path="/ProspectSearchAlternate"
                            component={BasicSearchResult}
                        />
                        <Route path="/payment/success" component={PaymentSuccess} />
                        <Route path="/paymentfail" component={PaymentFailed} />
                        <Route path="/SocialMediaSearch" component={BasicSearchResult} />
                        <Route path="/simple_history_all" component={BasicSearchResult} />
                        <Route path="/geolocator_history" component={BasicSearchResult} />
                        <Route path="/RealtimePeopleSearch" component={BasicSearchResult} />
                        <Route
                            path="/people/realtime/filter"
                            component={BasicSearchResult}
                        />

                        <Route
                            path="/people/realtime/history"
                            component={BasicSearchResult}
                        />
                        <Route path="/realTimeResults" component={RealTimeResults} />
                        <Route
                            path="/extensionSearchResult/:id"
                            component={BasicSearchResult}
                        />
                        <Route path="/extensionCompany" component={BasicSearchResult} />
                        <Route path="/realTimePage" component={RealTimePage} />

                        <Route path="/realTimeListView" component={RealTimeListView} />

                        <Route path="/realtime/filter" component={RealTimeResults} exact />
                        <Route path="/realtime_history" component={RealTimeResults} exact />

                        <Route path="/smeCompanies" component={SmeListView} />

                        <Route path="/geolocator" component={Geolocator} />

                        <Route path="/history" component={History} />
                        <Route path="/savedLists/:name" component={SavedList} />
                        <Route path="/savedLists" component={SavedListNames} />
                        <Route path="/creditLog" component={CreditLog} />
                        <Route path="/engage" component={EngagePage} />

                        <Route path="/detailInfoCompany" component={DetailInfoCompany} />
                        <Route path="/specificCompany" component={SpecificCompany} />
                        <Route path="/specificUserInfo" component={SpecificUserInfo} />

                        <Route path="/searchResult" component={SearchResult} />
                        <Route path="/search_by_history_type2" component={SearchResult} />

                        <Route
                            path="/searchResultCompany"
                            component={SearchResultCompany}
                        />
                        <Route
                            path="/company_result_by_name"
                            component={SearchResultCompany}
                        />
                        <Route
                            path="/result_by_history_type3"
                            component={SearchResultCompany}
                        />
                        <Route
                            path="/result_by_history_type4"
                            component={SearchResultCompany}
                        />

                        <Route path="/social_url_search" component={SearchResultTexAu} />
                        <Route
                            path="/result_by_history_type1"
                            component={SearchResultTexAu}
                        />

                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/login" component={LogIn} />
                        <Route path="/forgotPassword" component={ForgotPassword} />
                        <Route path="/resetPassword/:token" component={ResetPassword} />
                        <Route path="/signUp" component={SignUp} />

                        <Route path="/firstTimeUser" component={FirstTimeUser} />
                        <Route path="/companyPage" component={FirstTimeUserCompany} />

                        <Route path="/userGuide" component={UserGuide} />
                        <Route path="/howToUse" component={HowToUse} />
                        <Route path="/faq" component={Faq} />

                        <Route path="/" component={LogIn} />
                        <Route component={NotFound} />
                    </Switch>
                </AnimatePresence>
            </SnackbarProvider>
        </div>
    );
};

export default App;
