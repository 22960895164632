import React from "react";
import "./plan.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { apiServer } from "../../../config";
import { useSnackbar } from "notistack";

const StripePlanDetails = ({
  currentPlanIds,
  loading,
  data,
  currency,
  currentPlan,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  console.log("data: ", data, currency);
  console.log("currentPlanIds: ", currentPlanIds, currentPlan);
  const sortedData = data?.sort(
    (a, b) => a?.metadata?.rs_plan_amount - b?.metadata?.rs_plan_amount
  );
  const path = window.location.pathname;
  console.log("path: " + path);
  const isPlatformPlanExist = currentPlan?.filter(
    (plan) => plan?.plan_type === "platform"
  );
  const isExtensionPlanExist = currentPlan?.filter(
    (plan) => plan?.plan_type === "extension"
  );
  console.log("window?.location?.pathname: ", path === "payment/extension");
  console.log("isPlatformPlanExist: ", isPlatformPlanExist?.length);
  const disabledExtensionPlan =
    path.includes("payment/extension") && isPlatformPlanExist?.length > 0;
  console.log("disabled", disabledExtensionPlan);
  const stripeSession = async (event, data) => {
    console.log("in stripe Payment click: ", data);
    event.preventDefault();
    if (isExtensionPlanExist && data?.metadata?.plan_type) {
      alert(
        "Please note: When upgrading from the extension plan to the platform, any unused extension credits will NOT be carried forward. Ensure you've utilized all your credits before proceeding."
      );
    }
    console.log("currency: ", currency);
    const response = await fetch(
      apiServer + "/payment/stripe/create-checkout-session",
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
          "Access-Control-Allow-Origin": "*",
        },

        body: JSON.stringify({
          price_id:
            currency === "INR"
              ? data?.metadata?.rs_plan_id
              : currency === "USD"
              ? data?.metadata?.usd_plan_id
              : data?.metadata?.gbp_plan_id,
          customer_email: Cookies.get("user_email"),
          currency: currency,
        }),
      }
    );

    console.log("response: ", response);
    const body = await response.json();
    window.location.href = body.url;
  };
  return (
    <div className="container">
      <div className="row mx-lg-2 d-flex flex-wrap">
        {loading ? (
          <div className="text-center">
            <div
              className="spinner-grow"
              role="status"
              style={{
                width: "3rem",
                height: "3rem",
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            {sortedData?.map((item, index) => {
              const currentPlan = currentPlanIds.includes(item?.id);
              return (
                <div
                  key={index}
                  className={`col-md-6 col-lg-3 mb-8 plan-box px-0`}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <div className="pricing text-center">
                    <div
                      className="pricing-header bg-black border-bottom border-medium-thick px-3 py-4"
                      style={{
                        borderLeft:
                          item?.item?.name === "Extension Silver" &&
                          "3px solid #fb3e3e",
                        borderRight:
                          item?.item?.name === "Extension Silver" &&
                          "3px solid #fb3e3e",
                        borderTop:
                          item?.item?.name === "Extension Silver" &&
                          "3px solid #fb3e3e",
                        borderTopLeftRadius:
                          item?.item?.name === "Extension Silver" && "10px",
                        borderTopRightRadius:
                          item?.item?.name === "Extension Silver" && "10px",
                      }}
                    >
                      {currentPlan && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            backgroundColor: "#fb3e3e",
                            color: "#fff",
                            padding: "4px 6px",
                          }}
                        >
                          Current Plan
                        </div>
                      )}
                      <span className="d-block font-alt letter-spacing-2 text-uppercase font-medium">
                        {item?.name}
                      </span>
                    </div>

                    <div className="pricing-body">
                      <span className="bg-gray d-block py-4 text-large">
                        <span
                          title={
                            currentPlan === item?.name
                              ? "Your current plan"
                              : ""
                          }
                        >
                          <button
                            className="btn btn-danger px-6 py-2"
                            onClick={(event) => stripeSession(event, item)}
                            disabled={currentPlan || disabledExtensionPlan}
                          >
                            Buy Now
                          </button>
                        </span>
                      </span>
                      <div
                        className="bg-white text-center pb-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          borderLeft:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          borderRight:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#fb3e3e",
                          }}
                        >
                          {item?.metadata?.email_credits &&
                          item?.metadata?.profile_credits
                            ? Number(item?.metadata?.email_credits) +
                              Number(item?.metadata?.profile_credits)
                            : item?.metadata?.email_credits
                            ? Number(item?.metadata?.email_credits) +
                              Number(item?.metadata?.profile_credits)
                            : "Custom"}{" "}
                          credits
                        </p>
                        {item?.notes?.plan_type !== "outreach" ? (
                          <span
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            {item?.metadata?.email_credits ||
                              item?.metadata?.email_credits}{" "}
                            Email credits
                          </span>
                        ) : null}
                        <span
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {item?.metadata?.profile_credits} Profile credits
                        </span>
                      </div>
                      <div
                        className=" py-4 text-center text-large d-flex items-center justify-content-center"
                        style={{
                          borderLeft:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          borderRight:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          backgroundColor: "#FFE3E3",
                        }}
                      >
                        <span className="mr-3"> Dedicated Support</span>{" "}
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#fb3e3e"
                          size="2x"
                        />
                      </div>
                      <div
                        className="bg-white py-4 text-center text-large d-flex items-center justify-content-center"
                        style={{
                          borderLeft:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          borderRight:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                        }}
                      >
                        <span className="mr-3"> Excel Data Export</span>{" "}
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#fb3e3e"
                          size="2x"
                        />
                      </div>
                      <div
                        className="bg-black my-3 text-center text-large d-flex items-center justify-content-center"
                        style={{
                          borderLeft:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          borderRight:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          borderBottom:
                            item?.item?.name === "Extension Silver" &&
                            "3px solid #fb3e3e",
                          borderBottomLeftRadius:
                            item?.item?.name === "Extension Silver" && "10px",
                          borderBottomRightRadius:
                            item?.item?.name === "Extension Silver" && "10px",
                        }}
                      >
                        <button
                          className="btn btn-light text-danger px-5 py-2 my-2"
                          style={{
                            backgroundColor: "white",
                            border: "1px solid rgba(0,0,0,0.1)",
                            boxShadow: "inset 0px 5px 5px rgba(0, 0, 0, 0.1)",
                            pointerEvents: "none",
                          }}
                        >
                          {" "}
                          {currency === "INR"
                            ? "₹ " + item?.metadata?.rs_plan_amount
                            : currency === "USD"
                            ? "$ " + item?.metadata?.usd_plan_amount
                            : "£ " + item?.metadata?.gbp_plan_amount}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="col-md-6 col-lg-3 mb-8 plan-enterprise p-0">
              <div className="pricing text-center">
                <div className="pricing-header bg-white border-bottom border-medium-thick px-3 py-4">
                  <span className="d-block letter-spacing-2 text-uppercase">
                    Enterprise
                  </span>
                </div>

                <div>
                  <button
                    className="btn btn-danger my-4"
                    style={{
                      width: "100%",
                      borderRadius: "0",
                      padding: "10px 15px",
                    }}
                    onClick={() => {
                      window.open(
                        "https://tidycal.com/sonakshi",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    Get a Quote
                  </button>
                  <div
                    className="bg-white text-center pb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fb3e3e",
                      }}
                    >
                      Custom credits
                    </p>
                    <span
                      style={{
                        height: "30px",
                      }}
                    ></span>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#FFE3E3",
                    }}
                    className=" py-4 text-center text-large d-flex items-center justify-content-center"
                  >
                    <span className="mr-3"> Dedicated Support</span>{" "}
                    <FontAwesomeIcon icon={faCheck} color="#fb3e3e" size="2x" />
                  </div>
                  <div className="bg-white py-4 text-center text-large d-flex items-center justify-content-center">
                    <span className="mr-3"> Excel Data Export</span>{" "}
                    <FontAwesomeIcon icon={faCheck} color="#fb3e3e" size="2x" />
                  </div>
                  <div className="bg-black text-center text-large d-flex items-center justify-content-center">
                    <button
                      className="btn btn-light text-danger px-5 py-2 my-2"
                      style={{
                        backgroundColor: "white",
                        border: "1px solid rgba(0,0,0,0.1)",
                        boxShadow: "inset 0px 5px 5px rgba(0, 0, 0, 0.1)",
                        pointerEvents: "none",
                      }}
                    >
                      {" "}
                      Customize
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <h3 className="text-center my-2">No Plans found!</h3>
        )}
      </div>
    </div>
  );
};

export default StripePlanDetails;
