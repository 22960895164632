import React, { useState } from "react";
import MapCheckBox from "./MapCheckBox";
import MapName from "./MapName";
import MapLongitudeLatitude from "./MapLongitudeLatitude";
import MapCategory from "./MapCategory";
import MapViewProfile from "./MapViewProfile";
import MapSingleItem from "./MapSingleItem";
import ViewDetails from "../Geolocator/viewDetails/ViewDetails";
import { saveDetailedLeadsId, selectViewDetail } from "../../_reducer/shared";
import { useDispatch, useSelector } from "react-redux";
import { httpService } from "../Geolocator/geolocatorHttpServices/geolocatorHttpServices";
import Emitter from "../Utils/emitter";
import SavedListButton from "../SavedList/SavedListButton";
import { useSnackbar } from "notistack";

const MapResultItem = ({
  data,
  searchTerm,
  i,
  setMapSelectedLeads,
  mapSelectedLeads,
  activePage,
  simpleSearchId,
  onViewProfileOrWorkEmail,
  saveListNames,
  setSaveListNames,
  saved,
}) => {
  const viewDetail = useSelector(selectViewDetail);
  const [viewDetailsResult, setViewDetailResult] = useState({});
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  console.log("saveListNames-->",saved)
  const fetchProfileDetail = () => {
    setLoader(true);
    if (simpleSearchId) {
      httpService
        .getDetailedRecord(
          viewDetail?.id ? viewDetail?.id : data?.id,
          simpleSearchId,
          searchTerm?.includes("people") ? "map.people" : ""
        )
        .then(async (response) => {
          let { id } = response;
          if (id) {
            dispatch(saveDetailedLeadsId(id));
            const intervalId = setInterval(async () => {
              httpService.getDetailedLeads(id).then(async (data) => {
                const { search_result, status } = data;
                if (status === "success") {
                  clearInterval(intervalId);
                  setViewDetailResult(search_result);
                  setLoader(false);
                  Emitter.emit("fetchCredits", true);
                } else if (status === "running") {
                  console.log("Case: running ");
                } else {
                  clearInterval(intervalId);
                  setViewDetailResult({});
                  setLoader(false);
                }
              });
            }, 5 * 1000);
          } else {
            if (response.status === 402) {
              enqueueSnackbar(
                "You have insufficient credits. Buy Credits to get details.",
                { variant: "warning" }
              );
            } else {
              console.log(response);
            }
            setLoader(false);
            setOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <div className="result-container__result">
        <MapCheckBox
          data={data}
          setMapSelectedLeads={setMapSelectedLeads}
          mapSelectedLeads={mapSelectedLeads}
        />
        <MapName name={data?.name} />
        {/*Old data*/}
        {/*{searchTerm?.includes("people") ? (*/}
        {/*  data?.address ? (*/}
        {/*    <MapSingleItem item={data?.address} />*/}
        {/*  ) : "-" || data?.city ? (*/}
        {/*    <MapSingleItem item={data?.city} />*/}
        {/*  ) : (*/}
        {/*    "-"*/}
        {/*  )*/}
        {/*) : (*/}
        <MapLongitudeLatitude data={data} />
        {/*)}*/}

        <MapSingleItem item={data?.city} />
        {/*hide : linkedin unavailable for geolocator data*/}
        {/*{data?.linkedin ? <MapSingleItem item={data?.linkedin} /> : "-"}*/}

        {/*Old data*/}
        {/*{searchTerm?.includes("people") ? (*/}
        {/*  data?.pincode ? (*/}
        {/*    <MapSingleItem item={data?.pincode} />*/}
        {/*  ) : (*/}
        {/*    "-"*/}
        {/*  )*/}
        {/*) : (*/}
        {/*  <MapCategory category={data?.category} />*/}
        {/*)}*/}
        <MapSingleItem item={data?.pincode} />
        <MapCategory category={data?.category} />
        <MapViewProfile
          data={data}
          setIsOpen={setIsOpen}
          activePage={activePage}
          i={i}
          fetchProfileDetail={fetchProfileDetail}
          isOpen={isOpen}
          open={open}
          setOpen={setOpen}
          viewDetailsResult={viewDetailsResult}
        />

        <SavedListButton
          pageNumber={activePage}
          saved={saved}
          data={data}
          itemType="geolocator"
          saveListNames={saveListNames}
          setSaveListNames={setSaveListNames}
          simpleSearchId={simpleSearchId}
        />
      </div>

      {isOpen === data?.id ? (
        <div
          style={{
            background: "white",
            borderRadius: "20px",
            padding: "20px",
            marginTop: "-12px",
          }}
        >
          <ViewDetails
            isGeolocator={true}
            leadsId={data?.id}
            simpleSearchId={simpleSearchId}
            onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
            searchTerm={searchTerm}
            viewDetailsResult={viewDetailsResult}
            loader={loader}
          />
        </div>
      ) : null}
    </>
  );
};
export default MapResultItem;
