import React from "react";

const RealtimeSelectItem = ({
  realtimeSelectedLeads,
  setRealtimeSelectedLeads,
  companyLinkedIn,
  companyName,
  companyContactLinkedIn,
  companyContactName,
  index,
  sheetName,
  pathName,
  setCustomCount,
  customCount,
  companyPageRecord,
}) => {

  const isCompanySelected = () => {
    
    
      console.log(realtimeSelectedLeads?.some(
        (member) =>
          member?.company_name === companyName &&
        member.location === companyPageRecord.location &&
        member.title === companyPageRecord.title
      ));
      console.log(realtimeSelectedLeads,companyPageRecord)
    if (
      sheetName === "realtime-investors_who_funded_recently"
    ) {
      companyName = companyPageRecord.investor_that_funded?.[0];
      const funding_amount = companyPageRecord.funding_amount?.[0];
      const investor_linkedin =
        companyPageRecord.investor_linkedin?.[0];
      const investor_contact = companyPageRecord.investor_contact?.[0];
      if (companyPageRecord.investor_contact?.[0]){
        return realtimeSelectedLeads?.some(
          (member) =>
            member?.company_name === companyName &&
            member.funding_amount === funding_amount &&
            member.company_linkedin_url === investor_linkedin &&
            member.contact_person_name === investor_contact
        );
      }
      else {
        return realtimeSelectedLeads?.some(
          (member) =>
            member?.company_name === companyName &&
            member.funding_amount === funding_amount &&
            member.company_linkedin_url === investor_linkedin
        );
      }
    }
    if (
      sheetName === "realtime-companies_hiring_recently" 
    ) {
      companyName = companyPageRecord.company_name;
      const companyLocation = companyPageRecord.location;
      const jobTitle = companyPageRecord.title;
      return realtimeSelectedLeads?.some(
        (member) =>
          member?.company_name === companyName &&
          member.location === companyLocation &&
          member.title === jobTitle
      );
    } else if (
      sheetName === "realtime-recently_funded_company"
    ) {
      companyName = companyPageRecord.company_that_got_funded?.[0];
      const company_linkedin_url =
        companyPageRecord.linkedin_of_company_that_got_funded?.[0];
      const contact_name =
        companyPageRecord.contact_name_of_the_company_that_got_funded?.[0];
      const company_that_funded = companyPageRecord.company_that_funded?.[0]  
      const funding_amount = companyPageRecord?.funding_amount?.[0];
      if (companyPageRecord.contact_name_of_the_company_that_got_funded?.[0]){
        return realtimeSelectedLeads?.some(
          (member) =>
            member?.company_name === companyName &&
            member.company_linkedin_url === company_linkedin_url &&
            member.contact_person_name === contact_name &&
            member.company_that_funded === company_that_funded &&
            member.funding_amount === funding_amount
        );
      } else {
        return realtimeSelectedLeads?.some(
          (member) =>
            member?.company_name === companyName &&
            member.company_linkedin_url === company_linkedin_url &&
            member.company_that_funded === company_that_funded &&
            member.funding_amount === funding_amount
        );
      }
    } else {
      if (sheetName === "public_listed_companies") {
        return realtimeSelectedLeads?.some(
          (member) => member?.company_name === companyName
        );
      } else {
        return realtimeSelectedLeads?.some(
          (member) =>
            member?.company_linkedin_url === companyLinkedIn &&
            member?.company_name === companyName &&
            member?.contact_person_linkedin === companyContactLinkedIn &&
            member?.contact_person_name === companyContactName
        );
      }
    }
  };

  const handleLeadSelectionChange = (e) => {
    const { checked } = e.target;
    if (!checked) {
      if (
        sheetName === "realtime-companies_hiring_recently"
      ) {
        companyName = companyPageRecord.company_name;
        const companyLocation = companyPageRecord.location;
        const jobTitle = companyPageRecord.title;
        setRealtimeSelectedLeads(
          realtimeSelectedLeads?.filter(
            (item) =>
              item.location !== companyLocation || item.title !== jobTitle
          )
        );
      } else {
        if (sheetName === "realtime-public_listed_companies") {
          setRealtimeSelectedLeads(
            realtimeSelectedLeads?.filter(
              (item) => item.company_name !== companyName
            )
          );
        } else if (sheetName === "realtime-recently_funded_company") {
          const contact_name =
            companyPageRecord.contact_name_of_the_company_that_got_funded?.[0];
          const company_that_funded =
            companyPageRecord?.company_that_funded?.[0];
          const funding_amount = companyPageRecord?.funding_amount?.[0];
          if(contact_name){
            setRealtimeSelectedLeads(
              realtimeSelectedLeads?.filter(
                (member) =>
            member.contact_person_name !== contact_name ||
            member.company_that_funded !== company_that_funded ||
            member.funding_amount !== funding_amount
              )
            );
          }else {
            setRealtimeSelectedLeads(
              realtimeSelectedLeads?.filter(
                (member) =>
            member.company_linkedin_url !== companyPageRecord.linkedin_of_company_that_got_funded?.[0] ||
            member.company_that_funded !== company_that_funded ||
            member.funding_amount !== funding_amount
              )
            );
          }
        } else if (sheetName === "realtime-investors_who_funded_recently") {
          const contact_name = companyPageRecord.investor_contact?.[0];
          const investor_that_funded =
            companyPageRecord?.investor_that_funded?.[0];
          const funding_amount = companyPageRecord?.funding_amount?.[0];
          setRealtimeSelectedLeads(
            realtimeSelectedLeads?.filter(
              (member) =>
                member.investor_contact !== contact_name &&
                member.company_that_funded !== investor_that_funded &&
                member.funding_amount !== funding_amount
            )
          );
        } else {
          setRealtimeSelectedLeads(
            realtimeSelectedLeads?.filter(
              (item) =>
                item.company_linkedin_url !== companyLinkedIn ||
                item.company_name !== companyName ||
                item.contact_person_name !== companyContactName
            )
          );
        }
      }
      setCustomCount(parseInt(customCount) - 1);
    } else {
      if (
        sheetName === "realtime-companies_hiring_recently"
      ) {
        if (!isCompanySelected()) {
          companyName = companyPageRecord.company_name;
          const companyLocation = companyPageRecord.location;
          const jobTitle = companyPageRecord.title;
          setRealtimeSelectedLeads([
            ...realtimeSelectedLeads,
            {
              company_name: companyName,
              company_linkedin_url: companyLinkedIn,
              location: companyLocation,
              title: jobTitle,
              jobURLs: companyPageRecord.apply_options,
            },
          ]);
          setCustomCount(parseInt(customCount) + 1);
        }
      } else {
        if (!isCompanySelected()) {
          if (
            (sheetName === "realtime-recently_funded_company" ||
              sheetName === "realtime-investors_who_funded_recently")
          ) {
            const company_that_funded =
              companyPageRecord?.[
                sheetName === "realtime-investors_who_funded_recently"
                  ? "company_that_got_funded"
                  : "company_that_funded"
              ]?.[0];
            const funding_amount = companyPageRecord?.funding_amount?.[0];
            setRealtimeSelectedLeads([
              ...realtimeSelectedLeads,
              {
                company_linkedin_url: companyLinkedIn,
                company_name: companyName,
                contact_person_name: companyContactName,
                contact_person_linkedin: companyContactLinkedIn,
                company_that_funded: company_that_funded,
                funding_amount: funding_amount,
              },
            ]);
          }
           else {
            if (sheetName === "realtime-recently_funded_company"){
              const funding_amount = companyPageRecord?.funding_amount?.[0];
              setRealtimeSelectedLeads([
                ...realtimeSelectedLeads,
                {
                  company_linkedin_url: companyLinkedIn,
                  company_name: companyName,
                  contact_person_linkedin: companyContactLinkedIn,
                  contact_person_name: companyContactName,
                  funding_amount: funding_amount,
                },
              ]);
            }
            else{
              setRealtimeSelectedLeads([
                ...realtimeSelectedLeads,
                {
                  company_linkedin_url: companyLinkedIn,
                  company_name: companyName,
                  contact_person_linkedin: companyContactLinkedIn,
                  contact_person_name: companyContactName,
                },
              ]);
            }
          }
          setCustomCount(parseInt(customCount) + 1);
        }
      }
    }
  };

  return (
    <>
      <input
        className="box"
        id={index}
        type="checkbox"
        name="name"
        checked={isCompanySelected()}
        onChange={(e) => handleLeadSelectionChange(e)}
      />
    </>
  );
};

export default RealtimeSelectItem;
