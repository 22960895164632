import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";
import { useSnackbar } from "notistack";
import Fuse from "fuse.js";

const ProspectFilterBasic = (props) => {
  const identifier = uuidv4();
  const { enqueueSnackbar } = useSnackbar();
  const disallowedSpecialChars = /[`!@#$%^&*()_+=[\]{};':"\\|,<>/?~]/;

  const accordionId = `filter-search-accordion-${identifier}`;
  const slugifiedDisplayName = slugify(props.displayName.toLowerCase());

  const [searchEntryValue, setSearchEntryValue] = useState("");
  const [excludeActive, setExcludeActive] = useState(false);
  const [excludeValue, setExcludeValue] = useState("");
  const [filteredExcludeSearchEntryNames, setFilteredExcludeSearchEntryNames] =
    useState([]);

  const fuseEntries = new Fuse(props?.knownValues || [], {
    threshold: 0.5,
    keys: ["name"],
    includeScore: true,
  });
  const filteredFuseEntries = fuseEntries.search(searchEntryValue);
  const filteredSearchEntryNames = filteredFuseEntries?.map(
    (result) => result.item
  );

  const handleSearchEntryValueChanged = (e) => {
    let searchEntry = e.target.value;

    if (!searchEntry) {
      console.warn(`Invalid Search Entry. searchEntry: ${searchEntry}`);
      setSearchEntryValue("");
      return;
    }

    if (disallowedSpecialChars.test(searchEntry)) {
      console.warn(
        `Search Entry Contains Special Chars, searchEntry=${searchEntry}`
      );
      enqueueSnackbar("Special Chars are not allowed", {
        variant: "warning",
      });
      return;
    }

    setSearchEntryValue(searchEntry);
  };

  const handleSearchEntryValueUpdated = () => {
    props?.onTargetValueSet(searchEntryValue?.trim());
    setSearchEntryValue("");
  };

  const handleExcludeToggle = () => {
    setExcludeActive(!excludeActive);
    setExcludeValue(""); // Reset exclude value when switching
    setFilteredExcludeSearchEntryNames([]); // Reset filtered exclude values
  };

  const handleExcludeValueChanged = (e) => {
    let excludeEntry = e.target.value;

    if (!excludeEntry) {
      console.warn(`Invalid Exclude Entry. excludeEntry: ${excludeEntry}`);
      setExcludeValue("");
      setFilteredExcludeSearchEntryNames([]);
      return;
    }

    if (disallowedSpecialChars.test(excludeEntry)) {
      console.warn(
        `Exclude Entry Contains Special Chars, excludeEntry=${excludeEntry}`
      );
      enqueueSnackbar("Special Chars are not allowed", {
        variant: "warning",
      });
      return;
    }

    setExcludeValue(excludeEntry);

    // Filter exclude values similar to search values
    const filteredExcludeEntries = fuseEntries.search(excludeEntry);
    setFilteredExcludeSearchEntryNames(
      filteredExcludeEntries?.map((result) => result.item) || []
    );
  };

  const handleExcludeValueUpdated = () => {
    props?.onExcludeTargetValueSet(excludeValue?.trim());
    setExcludeValue("");
    setFilteredExcludeSearchEntryNames([]);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionId}`}
        >
          <img
            src={props.iconUrl || "/assets/images/new_icons/Frame_2888.png"}
            alt="Prospecting Search Item Image"
          />
          {props.displayName}
          {props.tooltipText && (
            <span className="tool" tooltip-data={props.tooltipText}>
              <img
                src="/assets/images/new_icons/i_button.png"
                alt="#"
                className="mx-2 tool"
                style={{
                  width: "15px",
                  height: "15px",
                  marginTop: "-.1em",
                }}
              />
            </span>
          )}
        </button>
      </h2>
      <div
        id={accordionId}
        className="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <input
            className="customize-search w-100"
            name={`filter-search-input-name-${identifier}`}
            value={searchEntryValue}
            onInput={handleSearchEntryValueChanged}
            type="text"
            placeholder={props.inputPlaceholder || "Add a Placeholder"}
            list={`filter-search-list-${slugifiedDisplayName}`}
            data-cy={`cypress-${slugifiedDisplayName}`}
          />
          {props.showExcludeSearch && (
            <div className="mt-3 flex-row align-items-center gap-4">
              <input
                type="checkbox"
                className="position-static rounded-circle mt-0 ml-0 form-check-input"
                id={`exclude-checkbox-${identifier}`}
                onChange={handleExcludeToggle}
                style={{
                  height: "16px",
                  width: "16px",
                }}
              />
              <label
                className="form-check-label ml-2"
                htmlFor={`exclude-checkbox-${identifier}`}
              >
                Exclude:
              </label>
            </div>
          )}
          {excludeActive && props.showExcludeSearch && (
            <div>
              <input
                className="customize-search w-100 mt-2"
                name={`filter-exclude-input-name-${identifier}`}
                value={excludeValue}
                onInput={handleExcludeValueChanged}
                type="text"
                placeholder={props.inputPlaceholder || "Exclude from Search"}
              />
            </div>
          )}
        </div>
        <div
          className={
            searchEntryValue || excludeValue
              ? "industry-display show"
              : "industry-display"
          }
          data-cy={`cypress-a-${slugifiedDisplayName}`}
        >
          {searchEntryValue && (
            <button
              className="industry-btn"
              value={searchEntryValue}
              style={{ cursor: "pointer" }}
              onClick={handleSearchEntryValueUpdated}
            >
              - {searchEntryValue}
            </button>
          )}
          {filteredSearchEntryNames?.map((item, index) => (
            <button
              key={`potential-matches-${index}-${identifier}`}
              className="industry-btn"
              value={item.toString()}
              style={{ cursor: "pointer" }}
              onClick={handleSearchEntryValueUpdated}
            >
              {index + 1}. {item}
            </button>
          ))}
          {excludeValue && (
            <button
              className="industry-btn mt-2"
              style={{ cursor: "pointer" }}
              onClick={handleExcludeValueUpdated}
            >
              - {excludeValue}
            </button>
          )}
          {filteredExcludeSearchEntryNames.map((item, index) => (
            <button
              key={`exclude-potential-matches-${index}-${identifier}`}
              className="industry-btn mt-2"
              value={item.toString()}
              style={{ cursor: "pointer" }}
              onClick={handleExcludeValueUpdated}
            >
              {index + 1}. {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProspectFilterBasic;
