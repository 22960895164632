const CheckBoxInput = ({ checkBoxName, type, setType }) => {
  return (
    <div className="d-flex">
      <input
        type="checkbox"
        id={checkBoxName}
        name="bulk-unlock-type"
        value={checkBoxName}
        checked={type === checkBoxName || type === "both"}
        onChange={() => {
          setType(checkBoxName);
        }}
        style={{ zoom: "1.5" }}
        data-cy={`checkbox-${checkBoxName}`}
      />
      <span style={{ fontSize: "1.25rem", paddingLeft: "1rem" }}>
        {checkBoxName === "phone"
          ? "Phone Numbers"
          : checkBoxName === "email"
          ? "Email Address"
          : "Both"}
      </span>
    </div>
  );
};

/**
 * Popup that handles the selection of emails or phones that needs to be unlocked in bulk process
 * @param exportType
 * @param setExportType
 * @param handleSubmit
 * @param dataTarget
 * @return {JSX.Element}
 * @constructor
 */
const SelectBulkExportTypePopup = ({
  exportType,
  setExportType,
  handleSubmit,
  dataTarget,
}) => {
  return (
    <div
      id={dataTarget}
      className="modal fade mt-7"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exportModalLabel"
      aria-hidden="true"
      data-cy="bulk-export-type-popup"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Select Export With -</h4>
          </div>
          <div className="modal-body">
            <form className="pl-3">
              <CheckBoxInput
                checkBoxName="phone"
                type={exportType}
                setType={setExportType}
              />
              <CheckBoxInput
                checkBoxName="email"
                type={exportType}
                setType={setExportType}
              />
              <CheckBoxInput
                checkBoxName="both"
                type={exportType}
                setType={setExportType}
              />

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  data-cy="dismiss-btn"
                >
                  Discard
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  data-dismiss="modal"
                  data-cy="export-btn"
                >
                  Export
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectBulkExportTypePopup;
