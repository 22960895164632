import {useSnackbar} from "notistack";
import {useRef, useState} from "react";
import {Link} from "react-router-dom";
import "./Style/style.css";
import {apiServer} from "../../config";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [successMssg, setSuccessMssg] = useState(false);
    const formContainer = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    function handleError(status) {
        console.error(`Got HTTP Error ${status}`);
        enqueueSnackbar(
            "Connection to our server has been lost. Please try after some time",
            {variant: "error"}
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let email_input = {email: email.trim()};
        try {
            const response = await fetch(`${apiServer}/user/search_user_by_email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({email: email}),
            });
            const user = await response.json();
            if (user.details === "User not found") {
                return enqueueSnackbar(
                    "Wrong email address. Please try with a different email address",
                    {variant: "error"}
                );
            }
            if (user.email) {
                await fetch(apiServer + "/auth/forgot-password", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify(email_input),
                });
                formContainer.current.classList.remove("active");
                setSuccessMssg(true);
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <>
            <div className="fg-pass">
                <div className="fg-pass__img-container">
                    <img
                        className="fg-pass__img"
                        src="/assets/images/cartoon.webp"
                        srcSet="/assets/images/cartoon.png"
                        alt="#"
                    />
                </div>
                <h4 className="text-danger my-2"> Hey Buddy! </h4>
                <div className="fg-pass__form-container active" ref={formContainer}>
                    <p className="fg-pass__mssg">
                        Please enter your email ID. The verification link will be sent.
                    </p>
                    <form className="fg-pass__frm" onSubmit={handleSubmit}>
                        <div className="fg-pass__frm-row">
                            <input
                                type="email"
                                placeholder="Enter your email ID"
                                value={email}
                                onChange={(eve) => {
                                    setEmail(eve.target.value);
                                }}
                                required
                                data-cy="email"
                            />
                        </div>
                        <div className="fg-pass__frm-row">
                            <button
                                type="submit"
                                className="fg-pass__btn global-hover"
                                data-cy="submit"
                            >
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
                {successMssg && (
                    <div className="fg-pass__mssg-container">
                        <p className="fg-pass__mssg">
                            We have mailed you the reset link at {email}. Please check your
                            email.
                        </p>
                    </div>
                )}

                <div className="my-3">
                    <Link to="/login" className="text-danger">
                        {" "}
                        <span> Back </span>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
