const EngageCard = ({ id, page_name, data_cy, url }) => {
  const identifier = `rt-${id}`;

  return (
    <div className="lead-card">
      <div className="text-center">
        <p style={{ fontSize: "1.1rem" }}>{page_name}</p>
        <button
          id={identifier}
          type="button"
          className="trends-btn global-hover"
          onClick={() => {
            window.open(url, "_blank");
          }}
          data-cy={data_cy}
        >
          Try Out
        </button>
      </div>
    </div>
  );
};

export default EngageCard;
