import Cookies from "js-cookie";
import { apiServer } from "../../config";


export const  callSaveSimpleHistoryOnly = async (jsonBody)=> {
  const apiHeader = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  };
  
  const res = await fetch(`${apiServer}/search/person/save/simple_history`, {
    method: "POST",
    headers: apiHeader,
    body: JSON.stringify(jsonBody),
  });

  return res
}
