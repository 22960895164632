import React, { useEffect, useState } from "react";
import "./Styles/SavedList.css";
import Cookies from "js-cookie";
import Modal from "../SharedComponent/Modal";
import SearchSpecificUser from "../DetailedInfo/SearchSpecificUser";
import { useSnackbar } from "notistack";
import { Link, useLocation, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";
import { apiServer } from "../../config";
import dayjs from "dayjs";
import Pagination from "../Utils/Pagination";
import { fetchSavedListsNames, removeList } from "./SavedListQueries";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { Spinner } from "../Utils/utils";
import SavedListConfirmationDialog from "./SavedListConfirmationDialog";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const SavedListNames = () => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveListNames, setSaveListNames] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteListName, setDeleteListName] = useState("");
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  console.log("locationState", location.state);

  const itemsPerPage = 10;

  const trimLongListName = (word, maxWordLength) => {
    return word.length > maxWordLength
      ? word.substring(0, maxWordLength) + "..."
      : word;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    try {
      if (location.state) {
        setPage(location.state.page);
        setToggleSearch(location.state.toggleSearch);
        setSearchText(location.state.searchText);
        setTotalCount(location.state.totalCount);
        setLoading(true);
        const data = await fetchSavedListsNames();
        setSaveListNames(data?.["list_names"]);

        if (location.state.toggleSearch && location.state.searchText) {
          const newFilteredNames = data?.["list_names"].filter((item) =>
            item.list_name
              .toLowerCase()
              .includes(location.state.searchText.toLowerCase())
          );
          console.log("newFiltered", newFilteredNames);
          setFilteredNames(newFilteredNames);
        } else {
          setFilteredNames(data?.["list_names"]);
        }
        setLoading(false);
      } else {
        setLoading(true);
        const data = await fetchSavedListsNames();
        setLoading(false);
        console.log("save_list_names : ", data);
        setSaveListNames(data?.["list_names"]);
        setFilteredNames(data?.["list_names"]);
        setTotalCount(data?.["count"]);
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar("There was an error fetching saved lists...", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(e);
    } finally {
      history.replace({ ...history.location, state: undefined });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setToggleSearch(true);
    setSearchText(searchText.toLowerCase());
    const newFilteredNames = saveListNames.filter((item) =>
      item.list_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setPage(0);
    if (searchText === "") {
      setFilteredNames(saveListNames);
      setTotalCount(saveListNames.length);
      return;
    }
    setFilteredNames(newFilteredNames);
    setTotalCount(newFilteredNames.length);
  };

  const handleDeleteList = async (listName) => {
    try {
      setDeleteModalLoading(true);
      const data = await removeList(listName);
      if (data?.["message"] === "success") {
        setOpenModal(false);
        enqueueSnackbar("List removed successfully..", {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar("An error occured while removing the list..", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("An error occured while removing the list..", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setPage(0);
      setDeleteListName("");
      setOpenModal(false);
      setDeleteModalLoading(false);
      setToggleSearch(false);
      fetchData();
    }
  };

  return (
    <>
      <motion.div
        className="text-center p-4 my-3 container-fluid"
        {...fadeInFadeOut}
      >
        <div className="global-width user-lead-top head-btn-style mb-3 global-shadow">
          <div className="d-flex align-items-center">
            <Link to="/dashboard" className="me-1">
              <h5 className="m-0">
                <img
                  src="assets/images/new_icons/Back.png"
                  alt="#"
                  style={{
                    width: "22px",
                    height: "22px",
                    marginRight: "0.25rem",
                  }}
                />
                Saved Lists
              </h5>
            </Link>
          </div>
          <form className="search-form-sm" onSubmit={handleSearch}>
            <div className="input-group">
              <input
                id="shared-history-search-input"
                type="text"
                className="form-control"
                placeholder="Search list names.."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (toggleSearch) {
                    setFilteredNames(saveListNames);
                    setPage(0);
                    setToggleSearch(false);
                    setTotalCount(saveListNames.length);
                  }
                }}
              />
              <button
                className="btn btn-danger"
                type="submit"
                onClick={handleSearch}
              >
                <img src="/assets/images/small-search-icon.png" alt="title" />
              </button>
            </div>
          </form>
        </div>
        <div
          className="pt-0 lead-accordion accordion-body global-width"
          id="accordionExample2"
        >
          <div className="accordion-item mb-3 ">
            <div
              id="collapseOne"
              className="accordion-collapse show collapse"
              data-bs-parent="#accordionExample2"
            >
              <div style={{ padding: "0rem" }} className="accordion-body">
                {loading ? (
                  <Spinner />
                ) : filteredNames.length > 0 ? (
                  filteredNames
                    .slice(
                      page * itemsPerPage,
                      page * itemsPerPage + itemsPerPage
                    )
                    .map((data, index) => {
                      const convertUTCToLocal = (date) => {
                        return new Date(
                          date.getTime() - date.getTimezoneOffset() * 60 * 1000
                        );
                      };
                      const date = convertUTCToLocal(
                        new Date(data?.["last_updated"])
                      );
                      const time = new Date(date).toLocaleTimeString("en-US");
                      return (
                        <div key={index}>
                          <div className="container-style mt-3 global-shadow">
                            <div className="save-list-names-container">
                              <div
                                style={{
                                  flexBasis: "20%",
                                  marginLeft: "2%",
                                }}
                              >
                                <div className="save-list-names-item-listname">
                                  <p className="save-list-names-item-text">
                                    Name:
                                  </p>
                                  <Tooltip title={data?.["list_name"]}>
                                    <p className="save-list-names-itemname">
                                      {trimLongListName(
                                        data?.["list_name"],
                                        15
                                      )}
                                    </p>
                                  </Tooltip>
                                </div>
                              </div>
                              <div
                                className="save-list-names-last-modified"
                                style={{
                                  gap: "1rem",
                                  flexBasis: "20%",
                                }}
                              >
                                <p className="save-list-names-item-text">
                                  Last Modified:
                                </p>
                                <div className="save-list-names-value">
                                  <p>
                                    {dayjs(data?.["last_updated"]).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  <p>{time}</p>
                                </div>
                              </div>
                              <div
                                className="save-list-names-last-modified"
                                style={{
                                  gap: "1rem",
                                  width: "15%",
                                }}
                              >
                                <p className="save-list-names-item-text">
                                  No of records:
                                </p>
                                <div className="save-list-names-value">
                                  <p>{data?.["num_records"]}</p>
                                </div>
                              </div>
                              <div style={{ flexBasis: "20%" }}>
                                <p className="view-btn" align="center">
                                  <NavLink
                                    to={{
                                      pathname: `/savedLists/${data["list_name"]}`,
                                      state: {
                                        totalCount: totalCount,
                                        filteredNames: filteredNames,
                                        page: page,
                                        toggleSearch: toggleSearch,
                                        searchText: searchText,
                                        saveListNames: saveListNames,
                                      },
                                    }}
                                    className="button btn"
                                    style={{ color: "black", width: "65%" }}
                                  >
                                    View List
                                  </NavLink>
                                </p>
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <IconButton
                                  onClick={() => {
                                    setOpenModal(true);
                                    setDeleteListName(data?.["list_name"]);
                                  }}
                                  marginRight="1.5rem"
                                >
                                  <DeleteIcon color="primary" />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <h5 className="save_list_notFound">NO LISTS FOUND</h5>
                )}
                <Pagination
                  onPageChange={({ selected }) => setPage(selected)}
                  pageNumber={page}
                  pageCount={Math.ceil(totalCount / itemsPerPage)}
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      {openModal && (
        <SavedListConfirmationDialog
          openModal={openModal}
          setOpenModal={setOpenModal}
          deleteListName={deleteListName}
          deleteModalLoading={deleteModalLoading}
          handleDeleteList={handleDeleteList}
        />
      )}
    </>
  );
};

export default SavedListNames;
