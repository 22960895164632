import React from "react";

const SelectAllLeads = (props) => {
  console.log("In SelectAllLeads: ", props);
  const handleLeadSelectAll = async () => {
    props.setIsCheckAll(!props.isCheckAll);
    if (props.isCheckAll) {
      props.setSelectedLeads([]);
    } else {
      props.setSelectedLeads(props.props.pageLeadsData);
      props.setCustomCount(props.props.pageLeadsData.length);
    }
  };
  return (
    <input
      className="ms-3 me-3"
      type="checkbox"
      data-cy="selectAll-checkbox"
      id="selectAll"
      name="selectAll"
      disabled={!props.isExport}
      onChange={handleLeadSelectAll}
      checked={props.isCheckAll}
    />
  );
};
export default SelectAllLeads;
