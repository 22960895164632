import React, { useState } from "react";
import CompanyViewProfile from "./CompanyViewProfile";
import CompanyButton from "./CompanyButton";
import SavedListButton from "../SavedList/SavedListButton";
import CompanyProfile from "./CompanyProfile";
import {
  fetchCompanyData,
  fetchCompanyId,
  fetchProspectCompanyData,
} from "./companyQueries";

const CompanySelectLeads = ({ data, selectedLeads, setSelectedLeads }) => {
  const handleSelectedLeads = (e) => {
    const { id, checked } = e.target;
    setSelectedLeads([...selectedLeads, id]);
    !checked && setSelectedLeads(selectedLeads.filter((item) => item !== id));
  };

  return (
    <input
      className="box"
      id={data?.organization_linkedin_numerical_urls}
      type="checkbox"
      name={data.organization_name}
      checked={selectedLeads.includes(
        data?.organization_linkedin_numerical_urls
      )}
      onChange={(e) => handleSelectedLeads(e)}
    />
  );
};

const CompanySearchResultItem = ({
  data,
  itemIndex,
  currentUnlockButtonId,
  setCurrentUnlockButtonId,
  buttonOpenState,
  setButtonOpenState,
  saveListNames,
  setSaveListNames,
  saved,
  pageNumber,
}) => {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);

  const handleCompanyProfile = async (id) => {
    let interval;
    const data = {
      identifier: id,
      search_id: null,
      use_thirdparty_api: false,
      use_history: false,
    };

    setLoading(true);

    const companyId = await fetchCompanyId(data);

    const handleSuccess = async () => {
      const companyResponse = await fetchProspectCompanyData(companyId);
      switch (companyResponse.status) {
        case "success":
          setProfileData(companyResponse?.search_result);
          setLoading(false);
          clearInterval(interval);
          break;
        case "running":
          return;
        case "failed":
          clearInterval(interval);
          setLoading(false);
          setProfileData([]);
          console.log("failed");
          break;
      }
    };

    const companyData = async () =>
      (interval = setInterval(() => handleSuccess(), 5 * 1000));

    if (companyId) companyData();
  };

  const saveListData = {
    id: data?.id,
    job: data?.industry,
    company_name: data?.company_name,
    full_name: data?.company_name,
    location: data?.headquarters,
    details_url: `${data?.social_url}/`,
    image_url: data?.image_url,
  };

  return (
    <>
      <div className="user-widget-box my-3">
        <div className="search-container mb-2">
          <div className="px-3">
            <div
              key={itemIndex}
              className="search-user-container"
              data-cy="search-results"
            >
              {/*Hidden along with export bar */}

              {/*<CompanySelectLeads*/}
              {/*  data={data}*/}
              {/*  selectedLeads={selectedLeads}*/}
              {/*  setSelectedLeads={setSelectedLeads}*/}
              {/*/>*/}

              <CompanyProfile data={data} flexBasis="70%" />

              <CompanyButton
                data={data}
                setButtonOpenState={setButtonOpenState}
                itemIndex={itemIndex}
                buttonOpenState={buttonOpenState}
                handleCompanyProfile={handleCompanyProfile}
                currentUnlockButtonId={currentUnlockButtonId}
                setCurrentUnlockButtonId={setCurrentUnlockButtonId}
              />

              <SavedListButton
                pageNumber={pageNumber}
                saved={saved}
                data={saveListData}
                selectedButton={selectedLeads}
                setSelectedButton={setSelectedLeads}
                saveListNames={saveListNames}
                setSaveListNames={setSaveListNames}
                itemType="company"
              />
            </div>
          </div>
        </div>
        {currentUnlockButtonId === data.id ? (
          <div className="company-profile-container">
            <CompanyViewProfile
              data={profileData}
              loading={loading}
              itemIndex={itemIndex}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CompanySearchResultItem;
