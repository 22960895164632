import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "../SharedComponent/Style/style.css";
import { useSnackbar } from "notistack";
import dateFormat from "dateformat";
import Pagination from "../Utils/Pagination";
import { Loader } from "../Utils/utils";
import { apiServer } from "../../config";
import CreditExport from "./CreditExport";

const CreditLog = ({ onRepeatedPage = false }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [waiting, setWaiting] = useState(true);
  const [allLeads, setAllLeads] = useState([]);
  const [currentLeads, setCurrentLeads] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const paginate = async (pageNumber) => {
    setCurrentLeads([]);
    pageNumber = pageNumber.selected;
    setCurrentPage(pageNumber);
    console.log("pageNumber: ", pageNumber, currentLeads);

    setCurrentLeads(
      allLeads && Array.isArray(allLeads)
        ? allLeads.slice((pageNumber + 1) * 10 - 10, (pageNumber + 1) * 10)
        : 0
    );
  };

  async function getHistoryRecords(offset) {
    console.log("I am running with offset", offset);
    try {
      const response = await fetch(
        apiServer + `/credit_transaction/creditLogs/chunk`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
        }
      );

      async function handleSuccess(response) {
        let json_res = await response.json();
        console.log(
          "json res: ",
          json_res && Array.isArray(json_res) ? json_res.slice(0, 10) : []
        );
        if (json_res) {
          console.log("in success: ", json_res.length);
          setAllLeads(json_res);
          setCurrentLeads(json_res ? json_res.slice(0, 10) : []);
          setTotalRecords(json_res?.length);
        }
        setWaiting(false);
      }

      function handleNoRecord() {
        setAllLeads(null);
        setCurrentLeads(null);
        setWaiting(false);
      }

      function handleUnAuthorized() {
        setAllLeads(null);
        setCurrentLeads(null);
        setWaiting(false);
        console.log("User is UnAuthorized in credit logs");
        alert("Please Logout and LogIn Again");
      }

      function handleError(response) {
        setAllLeads(null);
        setCurrentLeads(null);
        setWaiting(false);
        console.error(`Got HTTP Error ${response.status}`);
      }

      console.log("response: ", response);
      switch (response.status) {
        case 200:
          return await handleSuccess(response);
        case 204:
          return handleNoRecord();
        case 401:
          return handleUnAuthorized();
        default:
          return handleError(response);
      }
    } catch (err) {
      setAllLeads(null);
      setCurrentLeads(null);
      console.error("Error: ", err);
    }
  }

  useEffect(() => {
    (async () => {
      await getHistoryRecords();
    })();
  }, []);

  const handleDownload = async (e) => {
    e.preventDefault();
    const response_url = await fetch(
      apiServer + `/credit_transaction/creditLogs/export/excel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
      }
    );
    if (response_url.status === 200) {
      let fileName = await response_url.json();
      let url = `${apiServer}/${fileName.replace('"', "").replace('"', "")}`;
      try {
        const fetchPromise = await fetch(url);
        switch (fetchPromise.status) {
          case 200:
            if (fileName) {
              enqueueSnackbar(
                "We have received your Downloading Request. Download will start automatically.",
                {
                  variant: "info",
                }
              );
              window.open(url, "_blank");
            } else {
              enqueueSnackbar("Error in Downloading File!", {
                variant: "error",
              });
              console.error("Error in download link");
            }
            break;
          case 404:
            enqueueSnackbar(
              "Unable to reach Leadzen servers, please retry in a few minutes. " +
                "If this issue persists, please reach out to our customer support team ",
              { variant: "error" }
            );
            console.error("Error 404 Not found");
            return;
          default:
            enqueueSnackbar(
              "Unable to reach Leadzen servers, please retry in a few minutes. " +
                "If this issue persists, please reach out to our customer support team ",
              { variant: "error" }
            );
            console.log(fetchPromise);
        }
      } catch (e) {
        console.error("Exception", e);
        enqueueSnackbar(
          "Unable to reach Leadzen servers, please retry in a few minutes. " +
            "If this issue persists, please reach out to our customer support team ",
          { variant: "error" }
        );
      }
    } else {
      enqueueSnackbar("Record not found.", {
        variant: "info",
      });
    }
  };

  const getValuesOnly = (search_term) => {
    const obj = JSON.parse(search_term);
    let values = Object.values(obj);
    let outputStr = "";
    values.map((item) => {
      if (Array.isArray(item)) {
        item.map((x) => {
          outputStr = `${outputStr} ${x} ,`;
        });
      } else {
        outputStr = `${outputStr} ${item} ,`;
      }
    });
    return outputStr;
  };

  return (
    <>
      <div className="text-center p-4 my-3 container-fluid">
        <div className="col-md-8 col-lg-12 mx-auto">
          <div className="text-center my-3">
            <CreditExport />
            <div className="head-btn-style d-flex justify-content-between global-shadow align-items-center">
              <div>
                <h5 className="m-0">Transaction Log</h5>
              </div>
              <div className="d-flex">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginBottom: "0.5rem",
                  }}
                  className="d-flex"
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginBottom: "0.5rem",
                  }}
                  className="d-flex"
                >
                  <button className="export-btn" onClick={handleDownload}>
                    Download{" "}
                    <img
                      className="ps-3"
                      src="/assets/images/export.png"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="user-widget-box  my-3 ">
            <div className="search-container  mb-2 px-2 py-2">
              <table>
                <thead>
                  <tr>
                    {/* <th className="box ms-3 me-3"></th> */}
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Timestamp
                    </th>
                    {/*<th className="box ms-3 me-3 font-weight-bold text-center">*/}
                    {/*  Searched For*/}
                    {/*</th>*/}
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Description
                    </th>
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Product
                    </th>
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Category
                    </th>
                    {/* <th className="box ms-3 me-3 font-weight-bold text-center">
                      Source
                    </th> */}
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Credit Transaction
                    </th>
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Credits Utilised
                    </th>
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Available Credit
                    </th>
                    {/* <th className="box ms-3 me-3 font-weight-bold text-center">
                      Profile Current Balance
                    </th>
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Mail Current Balance
                    </th>
                    <th className="box ms-3 me-3 font-weight-bold text-center">
                      Total Current Balance
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentLeads && !waiting && currentLeads.length > 0 ? (
                    currentLeads.map((data, index) => {
                      const convertUTCToLocal = (date) => {
                        return new Date(
                          date.getTime() - date.getTimezoneOffset() * 60 * 1000
                        );
                      };

                      const date = convertUTCToLocal(new Date(data.created_on));
                      const time = new Date(date).toLocaleTimeString("en-US");
                      const uniqueDate =
                        new Date(data.created_on).getTime() + index;
                      return (
                        <tr key={uniqueDate} className="border-bottom">
                          <td>
                            <small
                              style={{ fontSize: "0.65rem" }}
                              className="d-block"
                            >
                              {dateFormat(data.created_on, "mmm dS yyyy")}
                              <br />
                              {time}
                            </small>
                          </td>
                          {/*<td className="text-center">*/}
                          {/*  {data?.search_term === null*/}
                          {/*    ? "N/A"*/}
                          {/*    : data?.category?.includes("BULK EXPORT")*/}
                          {/*    ? "Bulk"*/}
                          {/*    : isStringValidJSON(data?.search_term)*/}
                          {/*    ? getValuesOnly(data?.search_term)*/}
                          {/*    : data?.search_term}*/}
                          {/*</td>*/}
                          <td className="text-center">{data.description}</td>
                          <td className="text-center">{data.product}</td>
                          <td className="text-center">{data.category}</td>
                          {/* <td className="text-center">{data.source}</td> */}
                          <td className="text-center">
                            {data.credit_transaction}
                          </td>
                          {/* <td className="text-center">{data.credit_transaction}</td> */}
                          {/* <td className="text-center">
                            {data.credit_transaction === "credit_type_1" ? "PROFILE" : ""}
                            {data.credit_transaction === "credit_type_2" ? "EMAIL" : ""}
                          </td> */}

                          <td className="text-center">
                            {data.credit_deducted}
                          </td>
                          <td className="text-center  font-weight-bold">
                            {data.available_credit}
                          </td>

                          {/* <td
                            className={
                              data.email_txn?.includes("-")
                                ? "text-center text-danger"
                                : "text-center"
                            }
                          >
                            {data.email_txn}
                          </td>
                          <td className="text-center">
                            {data.total_profile_credits}
                          </td>
                          <td className="text-center">
                            {data.total_email_credits}
                          </td>

                          <td className="text-center font-weight-bold">
                            {data.total_credits}
                          </td> */}
                        </tr>
                      );
                    })
                  ) : !waiting &&
                    (currentLeads === [] ||
                      currentLeads.length === 0 ||
                      false) ? (
                    <div className="text-center">
                      <tr>Record not found</tr>
                    </div>
                  ) : (
                    <div className="text-center">
                      <tr>
                        <Loader />
                      </tr>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {currentLeads && currentLeads.length > 0 ? (
            <div
              className={`${
                !onRepeatedPage
                  ? "global-width d-flex justify-content-center"
                  : "d-flex justify-content-center ml-2"
              }`}
              style={{ textAlign: "center" }}
            >
              <Pagination
                pageCount={Math.ceil(totalRecords / 10)}
                pageNumber={currentPage}
                onPageChange={paginate}
              />
            </div>
          ) : (
            currentLeads
          )}
        </div>
      </div>
      {/* pagination loading  */}
    </>
  );
};

export default CreditLog;
