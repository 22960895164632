import LeadCard from "./LeadCard";
import LeadCards from "./LeadCards.json";
import { useState } from "react";
import "./Style/style.css";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";

const RealTimePage = () => {
  const [disabled, setDisabled] = useState(false);

  return (
    <div>
      <motion.div {...fadeInFadeOut}>
        <div className="container">
          <div className="map-section">
            <div className="row">
              <div className="col-md-6">
                <img src="/assets/images/realtime (9).png" alt="" />
              </div>
              <div className="col-md-6 ">
                <div className="trending-lead global-shadow">
                  <img src="/assets/images/realtime (5).png" alt="" />
                  <h4>Real Time Trending Leads</h4>
                  <p>Get Real Time Verified Leads With LeadZen.ai</p>
                  <p>Grab your Leads Now !</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="trends container mt-0 mb-6 global-shadow"
          data-cy="realtime-leads"
        >
          <div className="marketing-trend">
            <div className="mb-2 head-btn-style">
              <div className="row">
                <div className="col-8 px-4 ">
                  <div className="">
                    <h5 className="m-0">Real Time Leads</h5>
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-end"></div>
              </div>
            </div>
            <div
              className="container"
              id="collapseOne"
              style={disabled ? { pointerEvents: "none", opacity: "0.8" } : {}}
            >
              <div className="row">
                {/* Sme leads */}
                <div className="col-md-4 py-3">
                  <LeadCard
                    id={"smeCompanies"}
                    page_name={"SME Companies In India"}
                    data_cy={"sme-companies"}
                    disable={setDisabled}
                  />
                </div>
                {/* Rest of the trending leads */}
                {LeadCards.map((card, index) => (
                  <div className="col-md-4 py-3" key={index}>
                    <LeadCard
                      id={index}
                      page_name={card.page_name}
                      index={card.index}
                      data_cy={card.data_cy}
                      disable={setDisabled}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
export default RealTimePage;
