import { Box, Dialog, createTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { fetchPost } from "../../Utils/helper";
import MessageBox from "./MessageBox";
import CustomLoader from "./Skeleton";
import { personalizedEmail, personalizedPwd } from "../../config";
import PersonalizedForm from "./PersonalizedForm";

const MessageDialog = (props) => {
  const { open, onClose, title, data } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [dialogWidth, setDialogWidth] = useState("550px");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await fetchPost("initialise", {
        email: personalizedEmail,
        password: personalizedPwd,
      });
      if (response === "error") {
        setLoading(false);
        setLoginError(true);
        return enqueueSnackbar(
          `Please try again later.Error generating messages`,
          {
            variant: "error",
          }
        );
      }
      if (response?.data.token) {
        setLoading(false);
        Cookies.set("template_token", response?.data.token);
      }
    } catch (error) {
      setLoading(false);
      setLoginError(true);
      return enqueueSnackbar(
        `Please try again later.Error generating messages`,
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (title) {
        setEndPoint(title);
      }
    })();
    if (!Cookies.get("template_token")) {
      handleLogin();
    }
  }, [open, title, handleLogin]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth < 760 ? "450px" : "550px";
      setDialogWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            zIndex: "1000 !important",
            padding: "1rem",
            width: "100%",
            maxWidth: "850px",
            maxHeight: `${dialogWidth} !important`,
            marginTop: `${
              dialogWidth === "450px" ? "14rem !important" : "3rem !important"
            }`,
          },
        },
      }}
    >
      <Box>
        {loading && <CustomLoader />}
        {!loading && !message && (
          <PersonalizedForm
            url = {props.url}
            setMessage={setMessage}
            open={open}
            title={title}
            onClose={onClose}
            endPoint={endPoint}
            setLoading={setLoading}
            loginError={loginError}
          />
        )}
        {!loading && message && (
          <MessageBox
            data={data}
            title={title}
            setMessage={setMessage}
            message={message}
            open={open}
            onClose={onClose}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default MessageDialog;
