import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiServer } from "../../../config";
import Cookies from "js-cookie";

import { useSnackbar } from "notistack";
import {
  ValidateLinkedinUrl,
  validateCompanyName,
  validateName,
  validatePhoneNumber,
} from "../../Utils/utils";

const apiHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${Cookies.get("user_token")}`,
};
const UserPersonalInformation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const fetchResponse = await fetch(apiServer + `/users/me`, {
          headers: apiHeader,
        });
        const data = await fetchResponse.json();
        console.log("data: ", data);
        setUserDetails(data);
        setUserData(data);
      } catch (err) {
        console.log("");
      }
    })();
  }, []);
  const handleInput = (e) => {
    const { name, value } = e.target;
    console.log("name, value: ", name, value);

    if (name === "username") {
      setUserData({
        ...userData,
        username: userDetails?.username,
        parent: userDetails?.parent,
        children: userDetails?.children,
        meta_data: userDetails?.meta_data,
        [name]: value,
      });
    }
    setUserData({
      ...userData,
      username: userDetails?.username,
      [name]: value,
    });
  };
  const handleUserUpdate = async (e) => {
    console.log("in handleUserUpdate:", e.target.value);
    console.log("userData: ", userData);
    if (!userData) {
      enqueueSnackbar("Input the values");
      return;
    }
    if (userData?.username === "") {
      enqueueSnackbar("Please enter User Name");
      setUserData("");
      return;
    }
    if (userData?.username && !validateName(userData?.username)) {
      enqueueSnackbar("Enter Valid User Name");
      setUserData("");
      return;
    }
    if (
      userData?.phone_number &&
      !validatePhoneNumber(userData?.phone_number)
    ) {
      enqueueSnackbar("Enter Valid Phone Number");
      setUserData("");
      return;
    }
    if (
      userData?.company_name &&
      !validateCompanyName(userData?.company_name)
    ) {
      enqueueSnackbar("Enter Valid Company Name");
      setUserData("");
      return;
    }
    if (
      userData?.linkedin_url &&
      !ValidateLinkedinUrl(userData?.linkedin_url)
    ) {
      enqueueSnackbar("Enter Valid Linkedin Url");
      setUserData("");
      return;
    }
    try {
      const userResponse = await fetch(apiServer + `/users/me`, {
        method: "GET",
        headers: apiHeader,
      });
      const user = await userResponse.json();
      console.log("user: ", user?.username, user);
      console.log("final userData: ", userData);
      const fetchResponse = await fetch(apiServer + `/users/me`, {
        method: "PATCH",
        headers: apiHeader,
        body: JSON.stringify(userData),
      });
      const data = await fetchResponse.json();
      console.log("data: ", data);
      setUserDetails(data);
      setUserData("");
      enqueueSnackbar("User Details Updated Successfully!!");
    } catch (err) {
      console.log("");
    }
  };

  return (
    <div
      className="user-widget-box p-4 mt-3 text-center"
      // style={{textAlign: "center", width: "100%"}}
      data-cy="user_profile_item"
    >
      <Box>
        <Box display="flex" gap={3} borderBottom="1px solid #CCE1FF" pb={2}>
          <Box ml={16}>
            <Box mt={3} display={"flex"} flexDirection={"column"} gap={2}>
              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                justifyContent={"space-between"}
                fullWidth
                textAlign={"left"}
              >
                <Typography sx={{ width: "100px" }}>Name</Typography>
                <TextField
                  required
                  name="username"
                  value={
                    userData?.username || userDetails?.username
                      ? userData?.username
                      : ""
                  }
                  onChange={(e) => handleInput(e)}
                  size="small"
                  label={
                    userData.username || userDetails?.username
                      ? ""
                      : "Enter full name"
                  }
                  sx={{
                    label: { color: "grey" },
                  }}
                  onInput={handleInput}
                />
              </Box>
              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                justifyContent={"space-between"}
                fullWidth
                textAlign={"left"}
              >
                <Typography sx={{ width: "100px" }}>Email Id</Typography>
                <TextField
                  required
                  name="email"
                  value={userDetails?.email}
                  size="small"
                  disabled={true}
                  sx={{ label: { color: "grey" } }}
                />
              </Box>

              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                justifyContent={"space-between"}
                fullWidth
                textAlign={"left"}
              >
                <Typography sx={{ width: "100px" }}>Phone no.</Typography>

                <TextField
                  name="phone_number"
                  size="small"
                  value={
                    userData?.phone_number || userDetails?.phone_number
                      ? userData?.phone_number
                      : ""
                  }
                  label={
                    userData?.phone_number || userDetails?.phone_number
                      ? ""
                      : "Enter Phone Number"
                  }
                  sx={{ label: { color: "grey" } }}
                  onChange={handleInput}
                  onInput={handleInput}
                />
              </Box>
              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                justifyContent={"space-between"}
                fullWidth
                textAlign={"left"}
              >
                <Typography sx={{ width: "100px" }}>Company Name</Typography>
                <TextField
                  name="company_name"
                  size="small"
                  value={
                    userData?.company_name || userDetails?.company_name
                      ? userData?.company_name
                      : ""
                  }
                  label={
                    userData?.company_name || userDetails?.company_name
                      ? ""
                      : "Enter Company Name"
                  }
                  sx={{ label: { color: "grey" } }}
                  onChange={handleInput}
                  onInput={handleInput}
                />
              </Box>
              <Box
                display={"flex"}
                gap={3}
                alignItems="center"
                justifyContent={"space-between"}
                fullWidth
                textAlign={"left"}
              >
                <Typography sx={{ width: "100px" }}>LinkedIn Url</Typography>
                <TextField
                  name="linkedin_url"
                  size="small"
                  value={
                    userData.linkedin_url || userDetails?.linkedin_url
                      ? userData?.linkedin_url
                      : ""
                  }
                  label={
                    userData?.linkedin_url || userDetails?.linkedin_url
                      ? ""
                      : "Enter Linkedin Url"
                  }
                  sx={{ label: { color: "grey" } }}
                  onChange={handleInput}
                  onInput={handleInput}
                />
              </Box>
              <Button
                type={"submit"}
                sx={{
                  textAlign: "center",
                  paddingY: ".7rem",
                  paddingX: "2rem",
                  backgroundColor: "#fb3e3e",
                  color: "#fff",
                  maxWidth: "190px",
                  marginX: "auto",
                  marginY: "10px",
                  borderRadius: "50px",
                  "&:hover": {
                    backgroundColor: "#fb3e3e",
                    color: "#fff",
                  },
                }}
                onClick={(e) => handleUserUpdate(e)}
              >
                Update Details
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UserPersonalInformation;