import React, { useState } from "react";
import Cookies from "js-cookie";
import { Spinner } from "../../Utils/utils";
import { apiServer } from "../../../config";

const CompaniesHiringRecently = ({
  companyPageRecord,
  jobUrlList,
  setJobUrlList,
  pathName,
}) => {
  const [loading, setLoading] = useState(false);

  async function handleProfile(e) {
    setLoading(true);
    try {
      const fetchResponse = await fetch(apiServer + "/realtime/job_url", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify({ job_id: companyPageRecord.job_id }),
      });
      const response = await fetchResponse.json();
      setLoading(false);
      if (response.urls.length > 0) {
        const isDuplicate = jobUrlList.some(
          (e) => e.index === companyPageRecord.job_id
        );
        if (!isDuplicate) {
          setJobUrlList([
            ...jobUrlList,
            { index: companyPageRecord.job_id, link: response.urls[0].link },
          ]);
        }
      }
    } catch (e) {}
  }

  return (
    <>
      <div className="ml-2 realtime-result__name">
        <h6 style={{ fontWeight: "normal" }}>
          {typeof companyPageRecord?.company_name === "object" &&
          companyPageRecord?.company_name?.length > 0
            ? companyPageRecord?.company_name[0]
            : companyPageRecord?.company_name
            ? companyPageRecord?.company_name
            : "-"}
        </h6>
      </div>
      <div className="realtime-result__child">
        <>
          Location
          <br />
          <span className="text-red">
            {typeof companyPageRecord?.location === "object" &&
            companyPageRecord?.location[0].length > 0
              ? companyPageRecord?.location[0]
              : companyPageRecord?.location
              ? companyPageRecord?.location
              : "-"}
          </span>
        </>
      </div>
      <div className="realtime-result__child">
        <>
          Opening For
          <br />
          <span className="text-red">
            {typeof companyPageRecord.title === "object" &&
            companyPageRecord.title[0]?.length > 0
              ? companyPageRecord.title[0]
              : companyPageRecord.title
              ? companyPageRecord.title
              : "-"}
          </span>
        </>
      </div>
      {pathName === "/realtime/filter" || pathName === "/realtime_history" ? (
        <div className="realtime-result__child">
          <>
            Posted On
            <br />
            <span className="text-red">
              {typeof companyPageRecord?.detected_extensions?.posted_at ===
                "object" &&
              companyPageRecord.detected_extensions?.posted_at[0]?.length > 0
                ? companyPageRecord?.detected_extensions?.posted_at[0]
                : companyPageRecord?.detected_extensions?.posted_at
                ? companyPageRecord?.detected_extensions?.posted_at
                : "-"}
            </span>
          </>
        </div>
      ) : (
        ""
      )}

      <div
        className="realtime-result__child"
        style={{ textTransform: "capitalize" }}
      >
        JobURL
        <br />
        {pathName === "/realtime/filter" || pathName === "/realtime_history" ? (
          !jobUrlList.some((e) => e.index === companyPageRecord.job_id) ? (
            loading ? (
              <Spinner color="darkgrey" />
            ) : (
              <>
                <button
                  className="btn button v-btn"
                  data-toggle="collapse"
                  type="button"
                  aria-expanded="false"
                  onClick={(e) => {
                    handleProfile(e);
                  }}
                >
                  Get Job Url
                </button>
              </>
            )
          ) : (
            jobUrlList?.map((spec) =>
              spec.index === companyPageRecord.job_id ? (
                <button
                  className="btn button v-btn"
                  style={{
                    backgroundColor: "#FF6852",
                  }}
                >
                  <a
                    className="realtime-job-result__anchor"
                    href={spec.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get the Job
                  </a>
                </button>
              ) : (
                //
                ""
              )
            )
          )
        ) : companyPageRecord.job_url ? (
          <button
            className="btn button v-btn"
            style={{
              backgroundColor: "#FF6852",
            }}
          >
            <a
              className="realtime-job-result__anchor"
              href={
                typeof companyPageRecord?.job_url === "object" &&
                companyPageRecord?.job_url[0]?.length > 0
                  ? companyPageRecord?.job_url[0]
                  : companyPageRecord?.job_url
                  ? companyPageRecord?.job_url
                  : "-"
              }
              target="_blank"
              rel="noreferrer"
            >
              Get The Job
            </a>
          </button>
        ) : (
          "Not Found"
        )}
      </div>
    </>
  );
};
export default CompaniesHiringRecently;
