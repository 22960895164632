import React from "react";
import "./style.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import SecondAnsWebp from "../../images/second_ans.webp";
import ThirdAnsWebp from "../../images/third_ans.webp";
import FourAnsWebp from "../../images/four_ans.webp";
import FiveAnsWebp from "../../images/five_ans.webp";
import SixAnsWebp from "../../images/six_ans.webp";
import SevenAnsWebp from "../../images/seven_ans.webp";
import EightAnsWebp from "../../images/eight_ans.webp";
import NineAnsWebp from "../../images/nine_ans.webp";
import Fram2887 from "../../images/Frame_2887.webp";
import Frame2888 from "../../images/Frame_2888.webp";
import Group2885 from "../../images/Group_2885.webp";
import Group2892 from "../../images/Group_2892.webp";
import Frame2906 from "../../images/Frame_2906.webp";
import DetailsClick from "../../images/detailsClick.webp";
import SiteAccess from "../../images/siteAccess.webp";
import Right from "../../images/right.webp";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const questions = [
    "How Can I Install The Leadzen Extension ?",
    "How to Search For a contact with leadzen extension ?",
    "What are credits ?",
    "How are my credits utilized ?",
    "How can I buy more credits ?",
    "Am I charged with a credit every time I look over a profile ?",
    "What is a bulk search feature ?",
    "How can I export my contacts from leadzen saved list ? ",
    "Why is my leadzen extension not working on chrome ?",
    "What is a Parent Account ?",
    "What is a Child Account ?",
    "What privileges does a Parent Account typically have over Child Accounts ?",
    "How are Child Accounts created and managed ?",
    "What are the typical use cases for Parent and Child Accounts ?",
    "How credit deduction works if using child account ?",
    "Is it possible to create child account if user has not signed up from platform but on Leadzen Extension or Leadzen Outreach Linkedin tool ?",
    "What happens to Child Accounts if the Parent Account deletes or suspends a child account?",
    "How can Parent Accounts manage the subscriptions of Child Accounts ?",
    "How does the sharing of Credits and Subscription Plans work between Parent and Child Accounts ?",
    "Can Child Accounts independently purchase additional credits or subscription plans ?",
    "How can Parent Accounts manage the subscriptions of Child Accounts ?",
    "Can Parent revert the credits given to child account?",
    "Can parent check the unlocked history of child?",
    "Can child check history of Parent or another child?",
    "How to Allocate the Credits?",
  ];

  return (
    <div>
      <motion.div className="faq-container" {...fadeInFadeOut}>
        <div className="faq-heading">
          {isWebpSupported() ? (
            <img src={Group2885} alt="" />
          ) : (
            <img src="/assets/images/new_icons/Group_2885.png" alt="" />
          )}
          <h1>FAQ Help Center</h1>
        </div>
        <div className="faq-content">
          <div className="questions">
            {questions.map((question, index) => {
              return (
                <div
                  className={
                    activeIndex === index ? "ques-btn active-que" : "ques-btn"
                  }
                  key={index}
                  onClick={() => {
                    setActiveIndex(index);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <div className="mx-4">
                    {isWebpSupported() ? (
                      <img src={Frame2888} alt="" />
                    ) : (
                      <img
                        src="/assets/images/new_icons/Frame_2888.png"
                        alt=""
                      />
                    )}
                    {/* <img src="assets/images/new_icons/Frame_2888.png" alt="#" /> */}
                  </div>
                  <div>
                    <p>{question}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="answers">
            {activeIndex === 0 ? (
              <div className="indx-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>How Can I Install Leadzen Extension ?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    The Leadzen chrome extension can be directly installed from
                    the Chrome Web Store.
                    <br />
                    You can visit the Chrome Web Store and search for Leadzen,
                    or use this <Link to="#">Link</Link> to go directly to the
                    Leadzen Chrome Store page
                  </p>
                  {isWebpSupported() ? (
                    <img src={Group2892} alt="" />
                  ) : (
                    <img src="/assets/images/new_icons/Group_2892.png" alt="" />
                  )}

                  <p>
                    On Leadzen.ai’s extension page, click on the "{" "}
                    {isWebpSupported() ? (
                      <img
                        src={Frame2906}
                        alt=""
                        style={{ width: "90px", height: "25px" }}
                      />
                    ) : (
                      <img
                        src="/assets/images/new_icons/Frame_2906.png"
                        alt=""
                        style={{ width: "90px", height: "25px" }}
                      />
                    )}{" "}
                    " button to download the extension and you’re good to go.
                  </p>
                </div>
                <div className="index-one-ans">
                  <p>
                    After download the extension. Please follow the following
                    steps for smooth working of extension:-
                  </p>
                  <p>
                    <li
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      :- Go to browser top right corner and click on 3 dots{" "}
                    </li>
                    <li
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      :- Then go to extension section and click on manage
                      extensions.<br></br>
                      <br></br>
                      {isWebpSupported() ? (
                        <img src={Right} alt="" />
                      ) : (
                        <img src="/assets/images/new_icons/right.png" alt="" />
                      )}
                    </li>
                    <li
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      :- After that click on Leadzen.ai extension's details
                      button <br></br> <br></br>
                      {isWebpSupported() ? (
                        <img src={DetailsClick} alt="" />
                      ) : (
                        <img
                          src="/assets/images/new_icons/detailsClick.png"
                          alt=""
                        />
                      )}{" "}
                      <br></br>
                      <br></br>
                      :- After that go the <code>Site access</code> tab{" "}
                      <br></br>
                      {isWebpSupported() ? (
                        <img src={SiteAccess} alt="" />
                      ) : (
                        <img
                          src="/assets/images/new_icons/siteAccess.png"
                          alt=""
                        />
                      )}{" "}
                      <br></br>
                      <br></br>
                      :- Select the "Allow this extension to read and change all
                      your data on websites you visit:" to{" "}
                      <code>On all sites</code>
                    </li>
                  </p>
                </div>
              </div>
            ) : activeIndex === 1 ? (
              <div className="index-two">
                <div className="index-two-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>How to Search For A contact with Leadzen Extension?</h3>
                </div>
                <div className="index-two-ans">
                  {/* <Image
                    src={require({SecondAnsWebp})}
                    webp={require({ SecondAnsWebp })}
                  /> */}
                  {isWebpSupported() ? (
                    <img src={SecondAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/second_ans.jpg" alt="" />
                  )}

                  {/* <img src={ SecondAnsWebp } alt='#' /> */}
                </div>
              </div>
            ) : activeIndex === 2 ? (
              <div className="index-three">
                <div className="index-three-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}

                  <h3>What Are Credits ?</h3>
                </div>
                <div className="index-three-ans">
                  {isWebpSupported() ? (
                    <img src={ThirdAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/third_ans.jpg" alt="" />
                  )}

                  {/* <img src="assets/images/thirdans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex === 3 ? (
              <div className="index-four">
                <div className="index-four-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>How are my credits utilized ?</h3>
                </div>
                <div className="index-four-ans">
                  {isWebpSupported() ? (
                    <img src={FourAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/four_ans.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/fourthans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex === 4 ? (
              <div className="index-five">
                <div className="index-five-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>How can I buy more credits ?</h3>
                </div>
                <div className="index-five-ans">
                  {isWebpSupported() ? (
                    <img src={FiveAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/five_ans.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/fifthans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex === 5 ? (
              <div className="index-six">
                <div className="index-six-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>
                    Am I charged with a credit every time I look over a profile
                    ?
                  </h3>
                </div>
                <div className="index-six-ans">
                  {isWebpSupported() ? (
                    <img src={SixAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/six_ans.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/sixthans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex === 6 ? (
              <div className="index-seven">
                <div className="index-seven-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>What is a bulk search feature ?</h3>
                </div>
                <div className="index-seven-ans">
                  {isWebpSupported() ? (
                    <div>
                      <img src={SevenAnsWebp} alt="" />
                      <p className="py-2">
                        <a
                          className="text-danger"
                          href="/assets/Bulk Search Template.csv"
                        >
                          Click here to download csv format
                        </a>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <img src="assets/images/seven_ans.jpg" alt="" />
                      <p className="py-2">
                        <a
                          className="text-danger"
                          href="/assets/Bulk Search Template.csv"
                        >
                          Click here to download csv format
                        </a>
                      </p>
                    </div>
                  )}

                  {/* <img src="assets/images/seventhans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex === 7 ? (
              <div className="index-eight">
                <div className="index-eight-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>
                    How can I export my contacts from leadzen saved list ?
                  </h3>
                </div>
                <div className="index-eight-ans">
                  {isWebpSupported() ? (
                    <img src={EightAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/eight_ans.jpg" alt="" />
                  )}

                  {/* <img src="assets/images/eighthans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex === 8 ? (
              <div className="index-nine">
                <div className="index-nine-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  {/* <img src="assets/images/new_icons/Frame_2887.png" alt="#" /> */}
                  <h3>Why is my leadzen extension not working on chrome ?</h3>
                </div>
                <div className="index-nine-ans">
                  {isWebpSupported() ? (
                    <img src={NineAnsWebp} alt="" />
                  ) : (
                    <img src="/assets/images/nine_ans.jpg" alt="" />
                  )}

                  {/* <img src="assets/images/ninethans.png" alt="#" /> */}
                </div>
              </div>
            ) : activeIndex == 9 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>What is a Parent Account ?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    A Parent Account is an overarching account that typically
                    has control or administrative privileges over one or more
                    Child Accounts.
                  </p>
                </div>
              </div>
            ) : activeIndex == 10 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>What is a Child Account ?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    A Child Account is an account that is associated with and
                    managed by a Parent Account. It may have restricted
                    privileges and may be subject to the rules and permissions
                    set by the Parent Account.
                  </p>
                </div>
              </div>
            ) : activeIndex == 11 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    What privileges does a Parent Account typically have over
                    Child Accounts ?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    The privileges of a Parent Account include the ability to:
                    <br />
                    <br />
                    Manage Child Accounts.
                    <br />
                    Allocate and Revert the Credits with Children.
                    <br />
                    Buy a Subscription Plan.
                    <br />
                    Monitor and view activities of Child Accounts.
                  </p>
                </div>
              </div>
            ) : activeIndex == 12 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>How are Child Accounts created and managed ?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    Child Accounts are created and managed by the Parent
                    Account. To add a child to the parent account, the child
                    account should be already registered on Leadzen using
                    https://app.leadzen.ai. Then, the email id of the child is
                    used to connect parent account to a child account.
                    <a
                      href="https://app.leadzen.ai"
                      style={{ color: "#fb3e3e" }}
                    >
                      https://app.leadzen.ai
                    </a>{" "}
                    only.
                  </p>
                </div>
              </div>
            ) : activeIndex == 13 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    What are the typical use cases for Parent and Child
                    Accounts?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    Parent and Child Accounts are commonly used in scenarios
                    where there is a need for hierarchical management of
                    accounts.
                  </p>
                </div>
              </div>
            ) : activeIndex == 14 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    How does credit deduction work, when using a child account?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    Parent has to allocate/transfer credits to the child
                    account: both profile and work email credits. After the
                    child is allocated credits, the child account can use the
                    credits in the usual manner.
                  </p>
                </div>
              </div>
            ) : activeIndex == 15 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    Is it possible to create child account if user has not
                    signed up from platform but on Leadzen Extension or Leadzen
                    Outreach Linkedin tool ?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    No. To create child account both the child and parent should
                    be signed up using Platform only.
                  </p>
                </div>
              </div>
            ) : activeIndex == 16 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    What happens to Child Accounts if the Parent Account deletes
                    or suspends a child account?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    Child accounts will transition to a free account. All
                    history of unlocked profiles and work emails will not be
                    accessible. Any credits that was available prior will get
                    reverted back in Parent's account. Child will loose his
                    Previous credit(credits before becoming the child).
                  </p>
                </div>
              </div>
            ) : activeIndex == 17 ? (
              <div className="idex-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    How can Parent Accounts manage the subscriptions of Child
                    Accounts ?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>It shares the same subscription as the parent.</p>
                </div>
              </div>
            ) : activeIndex == 18 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    How does the sharing of Credits and Subscription Plans work
                    between Parent and Child Accounts ?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    After the monthly subscription renewal, the Parent will get
                    all credits. Parent should allocate credits to each child
                    separately as it sees fit for the child account. And child
                    accounts can use those credits to unlock details.
                  </p>
                </div>
              </div>
            ) : activeIndex == 19 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    Can Child Accounts independently purchase additional credits
                    or subscription plans?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>No</p>
                </div>
              </div>
            ) : activeIndex == 20 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>
                    How can Parent Accounts manage the subscriptions of Child
                    Accounts?
                  </h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    All child accounts receive the credits from the parent’s
                    account separately & child can use those credits to unlock
                    details.
                  </p>
                </div>
              </div>
            ) : activeIndex == 21 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>Can Parent revert the credits given to child account?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    Yes. Parent can revert credits from any of his child. In
                    this process child's credits get deducted and added to
                    Parent's account.
                  </p>
                </div>
              </div>
            ) : activeIndex == 22 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>Can parent check the unlocked history of child?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    Yes, In 'profile settings {">"} Admin settings' parent can
                    check the unlocked history of each child.
                  </p>
                </div>
              </div>
            ) : activeIndex == 23 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>Can child check history of Parent or another child?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    No. Child does not have access to check history of another
                    child.
                  </p>
                </div>
              </div>
            ) : activeIndex == 24 ? (
              <div className="index-one">
                <div className="index-one-que">
                  {isWebpSupported() ? (
                    <img src={Fram2887} alt="" />
                  ) : (
                    <img src="/assets/images/Frame_2887.jpg" alt="" />
                  )}
                  <h3>How to Allocate the Credits?</h3>
                </div>
                <div className="index-one-ans">
                  <p>
                    First you must be a parent account. Then go to Profile
                    settings {">"} Admin settings using Nav Bar. You can see the
                    child list. Click on '+' button to allocate credit to
                    associated child. Enter the number of credits you want to
                    allocate and click on Allocate button. Here Parent number of
                    credits allocated to child will be deducted from Parent
                    account and will be Added in child account.
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Faq;
