import React, { useState } from "react";
import "./Style/style.css";
import { Link, useHistory } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import CookieConsent from "react-cookie-consent";
import { useSnackbar } from "notistack";
import eyeOff from "../../images/eye-off.png";
import eyeOn from "../../images/eye-on.png";
import { apiServer } from "../../config";
import axios from "axios";
import Cookies from "js-cookie";
import { setUser } from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";

const SignUp = () => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [userRegistration, setUserRegistration] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [userVerifiedStatus, setUserVerifiedStatus] = useState(true);
  const [response, setResponse] = useState({ ok: null, message: null });
  const [showPass, setShowPass] = useState(false);
  const [userLogin, setUserLogin] = useState({
    email: userRegistration.email || "",
    password: "",
    error: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  const Robot = () => {
    if (response.message) {
      return (
        <div className="col-md-6 order-md-12">
          <div className="sign-up-page-robot">
            {response.message === "User or Email already exists" ? (
              <p className="fw-bold">
                <img
                  style={{ width: "20px" }}
                  src="/assets/images/Group 2221.png"
                  alt=""
                />
                Hey {userRegistration.email}, <br />
                looks like you’ve been taking the ‘lead’ already. <br />
                {response.message}
                <br />
                Try{" "}
                <Link to="/login" className="text-danger">
                  logging in
                </Link>{" "}
                instead
              </p>
            ) : (
              <p className="fw-bold">
                <img
                  style={{ width: "20px" }}
                  src="/assets/images/Group 2221.png"
                  alt=""
                />
                Hey {userRegistration.email}, <br />
                {response.message}
                <br />
                Try Again{" "}
              </p>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="col-md-6 robot-container order-md-12">
        <div className="sign-up-page-robot">
          <h3>Hi</h3>
          <p className="fw-bold">
            I am
            <span className="text-danger"> Jarv</span>
            <br /> & I will give you a sneak peek into the most intelligent AI
            Powered lead generation platform
          </p>
          {/* <div className="text-center">
            <Link to="/howToUse">
              <span className="small text-danger text-decoration-underline">
                Learn how to use credits
              </span>
            </Link>
          </div> */}
        </div>
      </div>
    );
  };

  function handleError(status) {
    console.error(`Got HTTP Error ${status}`);
    enqueueSnackbar(
      "Connection to our server has been lost. Please try after some time",
      { variant: "error" }
    );
  }

  async function handleUserExists(fetchResponse) {
    const data = await fetchResponse.json();
    if (data.detail === "REGISTER_USER_ALREADY_EXISTS") {
      setResponse({ ...response, message: "User or Email already exists" });
    }
  }

  const fetchData = async () => {
    let userReg = {
      ...userRegistration,
      email: userRegistration.email.toLowerCase(),
      signup_source: "platform",
      onboarded: false,
    };

    const formData = new FormData();
    formData.set("username", userRegistration.email);
    formData.set("password", userRegistration.password);
    try {
      const fetchResponse = await fetch(apiServer + "/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(userReg),
      });

      async function handleSuccess(fetchResponse) {
        const data = await fetchResponse.json();
        console.log("Data>>>>>>>>>>>", data);

        if ("id" in data) {
          const fetchResponse = await axios.post(
            apiServer + "/auth/jwt/login",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          async function handleSuccess(fetchResponse) {
            let json_res = await fetchResponse.data;
            try {
              const userStatusResponse = await axios.get(
                apiServer + "/users/me",
                {
                  headers: {
                    Authorization: `Bearer ${json_res.access_token}`,
                  },
                }
              );

              async function handleUserSuccess() {
                const userStatus = await userStatusResponse;

                console.log("userStatus>>>>>>>>", userStatus);

                setResponse({ ...response, ok: true });

                if (userStatus.data.is_verified === false) {
                  setUserVerifiedStatus(false);
                } else {
                  Cookies.set("user_email", userStatus.data.email, {
                    expires: 30,
                  });
                  Cookies.set("user_id", userStatus.data.id, { expires: 30 });
                  Cookies.set("user_token", json_res.access_token, {
                    expires: 1,
                  });
                  Cookies.set("username", userStatus.data.username, {
                    expires: 30,
                  });
                  console.log("User Logged In and is Verified");
                  setUser({
                    email: userLogin.email,
                    username: userStatus.data.username,
                    userId: userStatus.data.id,
                  });
                  amplitude.logEvent("Login", {
                    role: "New User",
                    userName: userRegistration.email,
                  });
                  history.push({
                    pathname: "/payment/platform",
                  });
                }
              }

              switch (userStatusResponse.status) {
                case 200:
                  return await handleUserSuccess(userStatusResponse);
                default:
                  return handleError(userStatusResponse);
              }
            } catch (err) {
              handleError(err);
            }
          }

          switch (fetchResponse.status) {
            case 200:
              return await handleSuccess(fetchResponse);
            default:
              return handleError(fetchResponse);
          }
          // setResponse({ ...response, ok: true });
          // history.push({
          //   state: { userRegistrationEmail: userRegistration.email },
          //   pathname: "/login",
          // });
        }
      }

      switch (fetchResponse.status) {
        case 201:
          return await handleSuccess(fetchResponse);
        case 400:
          return handleUserExists(fetchResponse);
        default:
          return handleError(fetchResponse);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = "";
    if (!checked) {
      enqueueSnackbar("Please agree to the terms and conditions");
      document.querySelector(".term-box").classList.add("highlight");
      document
        .querySelector(".condition-txt")
        .classList.add("border-highlight");
      return;
    } else {
      document.querySelector(".term-box").classList.remove("highlight");
      document
        .querySelector(".condition-txt")
        .classList.remove("border-highlight");
    }

    if (!isEmail(userRegistration.email)) {
      error += "Email, ";
    }
    if (userRegistration.password.length < 8) {
      error += "Password ";
    }
    setUserRegistration({ ...userRegistration, error: error });

    if (error) {
      setResponse({ ...response, message: error + "Invalid" });
    } else {
      setIsDisabled(true);
      setResponse({ ok: null, message: null });
      fetchData();
    }
  };

  return (
    <div className="container-body">
      <div className="main-content-area overflow-hidden">
        <div className="main-wrapper">
          <div className="container-fluid">
            <div className="form-container">
              <div className="signup-wrapper py-3 px-md-6">
                <div className="row align-items-center">
                  <Robot />
                  <div className="col-md-6 order-md-1">
                    <div className="sign-up-form">
                      <div className="text-center pt-1">
                        <h2>Create a Free Account</h2>
                        <h5 className="text-danger mb-3">
                          Get 10 Free Credits for Leads Now !
                        </h5>
                      </div>
                      <form className="sign-up-form" onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="w-100"
                            autoComplete="off"
                            value={userRegistration.username}
                            onChange={handleInput}
                            onInput={handleInput}
                            name="username"
                            placeholder="Enter your name"
                            id="username"
                            data-cy="username"
                            disabled={isDisabled}
                            minLength={3}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <input
                            type="email"
                            className="w-100"
                            autoComplete="off"
                            value={userRegistration.email}
                            onChange={handleInput}
                            onInput={handleInput}
                            name="email"
                            placeholder="Enter your email"
                            id="email"
                            data-cy="email"
                            disabled={isDisabled}
                            required
                          />
                        </div>

                        <div className="mb-3 password-input">
                          <input
                            type={showPass ? "text" : "password"}
                            className="w-100"
                            autoComplete="off"
                            value={userRegistration.password}
                            onChange={handleInput}
                            onInput={handleInput}
                            name="password"
                            placeholder="Enter your password"
                            id="password"
                            minLength={8}
                            required
                            data-cy="password"
                            disabled={isDisabled}
                          />
                          <a href="#">
                            <img
                              src={showPass ? eyeOn : eyeOff}
                              alt=""
                              style={{
                                position: "absolute",
                                top: "13px",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              }}
                              id="visibility-img"
                              data-cy="visibility-img"
                              onMouseEnter={() => setShowPass(true)}
                              onMouseLeave={() => setShowPass(false)}
                            />
                          </a>
                        </div>
                        <div className="cookie p-1 mt-2 mb-2">
                          <div className="cookie-message d-flex justify-content-center align-items-center">
                            <CookieConsent>
                              This site uses cookies
                            </CookieConsent>
                            <div className="mx-2">
                              <input
                                type="checkbox"
                                onClick={() => {
                                  setChecked(!checked);
                                }}
                                className="term-box"
                                checked={checked}
                                style={{
                                  border: "2px solid crimson !important",
                                  width: "25px",
                                }}
                                data-cy="TnC"
                              />
                            </div>
                            <p className="text-center m-2 condition-txt">
                              We use cookies to improve your browsing
                              experience. By accepting this, you agree to our{" "}
                              <a
                                href="https://leadzen.ai/privacy-policy/"
                                className="text-danger text-decoration-underline"
                              >
                                Privacy Policy
                              </a>{" "}
                              ,{" "}
                              <a
                                href="https://leadzen.ai/our-data/"
                                className="text-danger text-decoration-underline"
                              >
                                {" "}
                                Data Policy{" "}
                              </a>{" "}
                              and{" "}
                              <a
                                href="https://leadzen.ai/cookie-policy/"
                                className="text-danger text-decoration-underline"
                              >
                                {" "}
                                Cookie Policy{" "}
                              </a>
                            </p>
                          </div>
                        </div>

                        <div className="ripple-effect">
                          <button
                            type="submit"
                            className="text-white w-100 og-btn ripple"
                            data-cy="submit"
                          >
                            Grab Your 10 Free Credits Now
                          </button>
                        </div>
                      </form>
                      <div className="text-center">
                        <p className="text-secondary m-3">
                          Already have an account?{" "}
                          <Link
                            to="/login"
                            className="text-danger text-decoration-none"
                          >
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
