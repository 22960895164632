import { isObjectValuesEmpty } from "../Utils/utils";
import { getFetch, postFetch } from "../../Config/facade";
import Emitter from "../Utils/emitter";

/**
 * Setting the record to process in the backend for detailed information
 * @param payload
 * @return {Promise<*>}
 */
export const fetchPersonDetailed = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/search/person/detailed/", payload);
};

/**
 * Fetches detailed information of work_email credit value
 * @param payload
 * @return {Promise<*>}
 */
export const fetchPersonDetailedWorkEmail = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/search/person/detailed/work_email/", payload);
};

/**
 * Fetching at an interval to get the results when the process is completed
 * @param payload
 * @return {Promise<*>}
 */
export const fetchPersonDetailedInfo = async (payload) => {
  if (payload) {
    const response = await getFetch(
      `/search/person/detailed/result/${payload}`
    );

    Emitter.emit("updateCredits", true);
    return response;
  }
};

/**
 * Fetches verified or unverified work_email for the given linkedin_url
 * @param payload
 * @return {Promise<*>}
 */
export const fetchWorkEmail = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/email/work_email", payload);
};

/**
 * Fetches verified or unverified work_email without linkedin from es db
 * @param payload
 * @return {Promise<*>}
 */
export const fetchWorkEmailFromElastic = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/realtime/unlock_email", payload);
};

/**
 * Fetches metadata of unlocked info on profile/email
 * @param payload
 * @return {Promise<*>}
 */
export const fetchMetaData = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/history/metadata", payload);
};

/**
 * Fetches real time lead without LinkedIn url from databases
 * @param hash_key
 * @return {Promise<*>}
 */
export const fetchRealtimeWithoutLinkedInURL = async (hash_key) => {
  return await getFetch(
    `/realtime/get_realtime_profile_without_linkedin_url?search_hash_key=${hash_key}`
  );
};

export const fetchPhoneFromElastic = async (payload) => {
  if (!isObjectValuesEmpty(payload))
    return await postFetch("/realtime/unlock_phone", payload);
};
