import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { isObjectValuesEmpty, isObjectValuesEmptyArray } from "../Utils/utils";
import { Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import ProspectFilterLabel from "../SharedComponent/Prospecting/ProspectFilterLabel";
import ProspectFilterBasic from "../SharedComponent/Prospecting/FilterType/ProspectFilterBasic";
import ProspectFilterList from "../SharedComponent/Prospecting/FilterType/ProspectFilterList";
import ProspectFilterAutoSuggest from "../SharedComponent/Prospecting/FilterType/ProspectFilterAutoSuggest";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import ProspectMatchCount from "../SharedComponent/Prospecting/ProspectMatchCount";

const CompanyFilters = (props) => {
  const maxSearchResultLimit =
    1000 ||
    parseInt(process.env.REACT_APP_CONFIG_API_PROSPECT_SEARCH_RESULT_LIMIT);
  const searchClient = instantMeiliSearch(
    `${process.env.REACT_APP_CONFIG_API_MEILISEARCH_URL}`,
    `${process.env.REACT_APP_CONFIG_API_MEILISEARCH_KEY}`,
    {
      placeholderSearch: false,
      primaryKey: "id",
    }
  );
  const { enqueueSnackbar } = useSnackbar();

  const maxSearchItemsPerFilter = 5;

  const filters = {
    companyNames: [],
    companyHQCities: [],
    companyHQCountry: [],
    industries: [],
    keywords: [],
    companyEmployeeCounts: [],
    foundedYear: [],
    categories: [],
    specialties: [],
    companyType: [],
    domains: [],
    funded: [],
    // Exclude list
    excludeCompanyNames: [],
    excludeCompanyHQCities: [],
    excludeCompanyHQCountry: [],
    excludeIndustries: [],
    excludeSpecialties: [],
    excludeCategories: [],
    excludeFoundedYear: [],
    excludeDomains: [],
    excludeKeywords: [],
  };

  const [queryState, setQueryState] = useState(filters);
  const queryContainer = useRef(null);
  const queryParentContainer = useRef(null);
  const history = useHistory();

  const handleCustomSubmit = () => {
    const data = {
      company_name: queryState?.companyNames,
      industry: queryState?.industries,
      founded: queryState?.foundedYear,
      company_size: queryState?.companyEmployeeCounts,
      city: queryState?.companyHQCities,
      country: queryState?.companyHQCountry,
      categories: queryState?.categories,
      specialties: queryState?.specialties,
      company_type: queryState?.companyType,
      domain: queryState?.domains,
      keywords: queryState?.keywords,
      funded: queryState?.funded,

      //exclude fields
      exclude_company_name: queryState?.excludeCompanyNames,
      exclude_city: queryState?.excludeCompanyHQCities,
      exclude_country: queryState?.excludeCompanyHQCountry,
      exclude_industry: queryState?.excludeIndustries,
      exclude_categories: queryState?.excludeCategories,
      exclude_specialties: queryState?.excludeSpecialties,
      exclude_founded: queryState?.excludeFoundedYear,
      exclude_domain: queryState?.excludeDomains,
      exclude_keywords: queryState?.excludeKeywords,
      limit: maxSearchResultLimit,
    };

    if (
      !data.company_name.length &&
      !data.company_size.length &&
      !data.founded.length &&
      !data.city.length &&
      !data.country.length &&
      !data.categories.length &&
      !data.specialties.length &&
      !data.keywords.length &&
      !data.company_type.length &&
      !data.domain.length &&
      !data.funded.length &&
      !data.industry.length &&
      //   exclude
      !data.exclude_company_name.length &&
      !data.exclude_city.length &&
      !data.exclude_country.length &&
      !data.exclude_industry.length &&
      !data.exclude_categories.length &&
      !data.exclude_specialties.length &&
      !data.exclude_founded.length &&
      !data.exclude_domain.length &&
      !data.exclude_keywords.length
    ) {
      enqueueSnackbar("Select one of the filters", {
        variant: "error",
      });
      return;
    }

    history.push({
      pathname: "/CompanySearch",
      state: {
        searchInput: data,
      },
    });
  };

  // useEffect(() => {
  //   if (props.customSearch) {
  //     setCustomSearch(props.customSearch);
  //   }
  // }, [props]);

  const checkValidityOfNewValueForSubState = (subStates, newValue) => {
    if (subStates?.length >= maxSearchItemsPerFilter) {
      enqueueSnackbar(
        `Upto ${maxSearchItemsPerFilter} Filter Items Can be Selected At A Time`,
        {
          variant: "warning",
        }
      );
      return false;
    }

    if (newValue && subStates?.includes(newValue)) {
      enqueueSnackbar(`Ignoring ${newValue}, It Was Already Added`, {
        variant: "warning",
      });
      return false;
    }

    return true;
  };
  console.log(queryState)
  const handleTargetValueSet = (value, stateKey, oppositeKey) => {
    if (
      !checkValidityOfNewValueForSubState(queryState[stateKey], value) ||
      !checkValidityOfNewValueForSubState(queryState[oppositeKey], value)
    ) {
      return;
    }
    
    console.log(queryState)

    queryState[stateKey].push(value);
    setQueryState({
      ...queryState,
      [stateKey]: queryState[stateKey],
    });
    console.log(queryState) 
  };

  const swapFieldValues = ({ field, swapField, value }) => {
    setQueryState((prevQueryState) => {
      const updatedQueryState = { ...prevQueryState };

      // Remove value
      updatedQueryState[field] = updatedQueryState[field].filter(
        (item) => item !== value
      );

      // Add in new field
      if (!updatedQueryState[swapField].includes(value)) {
        updatedQueryState[swapField].push(value);
      }

      return updatedQueryState;
    });
  };

  const handleSwapFilters = () => {
    setQueryState((prevQueryState) => {
      const updatedQueryState = { ...prevQueryState };

      // List of fields to swap
      const fieldsToSwap = [
        "companyNames",
        "companyHQCities",
        "companyHQCountry",
        "industries",
        "categories",
        "specialties",
        "foundedYear",
        "domains",
        "keywords",
      ];

      fieldsToSwap.forEach((field) => {
        const excludeField = `exclude${field
          .charAt(0)
          .toUpperCase()}${field.slice(1)}`;

        if (updatedQueryState[excludeField] !== undefined) {
          const temp = updatedQueryState[field];
          updatedQueryState[field] = updatedQueryState[excludeField];
          updatedQueryState[excludeField] = temp;
        }
      });
      return updatedQueryState;
    });
  };

  const handleClearAllSelectedFilters = () => {
    const element = queryContainer.current;
    element.classList.add("fadeout-active");
    setTimeout(() => {
      element.classList.remove("fadeout-active");
      setQueryState(filters);
    }, 400);
  };

  return (
    <>
      <Button
        type="button"
        className="btn btn-light position-relative"
        style={{ cursor: "default" }}
      >
        <p className="fs-4 text-danger">Company Search</p>
      </Button>
      <div
        style={{
          transition: "all 2s linear",
        }}
      >
        <div className="px-4">
          <div className="query-parent">
            <div
              className="query-container d-flex flex-wrap gap-2 mb-2"
              id="query_container"
              // ref={queryContainer}
              data-cy="query-container"
            >
              <ProspectFilterLabel
                collection={queryState?.companyNames}
                iconUrl={"/assets/images/new_icons/company_name.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, companyNames: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "companyNames",
                    swapField: "excludeCompanyNames",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.companyEmployeeCounts}
                iconUrl={"/assets/images/new_icons/Employee Count.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, companyEmployeeCounts: x })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.companyHQCities}
                iconUrl={"/assets/images/new_icons/Location.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, companyHQCities: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "companyHQCities",
                    swapField: "excludeCompanyHQCities",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.companyHQCountry}
                iconUrl={"/assets/images/new_icons/Location.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, companyHQCountry: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "companyHQCountry",
                    swapField: "excludeCompanyHQCountry",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.companyType}
                iconUrl={"/assets/images/new_icons/Education.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, companyType: x })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.industries}
                iconUrl={"/assets/images/new_icons/Industry.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, industries: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "industries",
                    swapField: "excludeIndustries",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.categories}
                iconUrl={"/assets/images/new_icons/bx_category.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, categories: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "categories",
                    swapField: "excludeCategories",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.specialties}
                iconUrl={"/assets/images/new_icons/Job Title.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, specialties: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "specialties",
                    swapField: "excludeSpecialties",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.funded}
                iconUrl={"/assets/images/new_icons/Funded.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, funded: x })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.foundedYear}
                iconUrl={"/assets/images/bx_bx-calendar.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, foundedYear: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "foundedYear",
                    swapField: "excludeFoundedYear",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.domains}
                iconUrl={"/assets/images/new_icons/Link.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, domains: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "domains",
                    swapField: "excludeDomains",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.keywords}
                iconUrl={"/assets/images/new_icons/keywords.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, keywords: x })
                }
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "keywords",
                    swapField: "excludeKeywords",
                    value,
                  })
                }
              />
            </div>
            {/* Exclude lables */}
            <div
              className="query-container d-flex flex-wrap gap-2 "
              id="query_container"
              ref={queryContainer}
              data-cy="query-container"
            >
              <ProspectFilterLabel
                collection={queryState?.excludeCompanyNames}
                iconUrl={"/assets/images/new_icons/company_name.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeCompanyNames: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeCompanyNames",
                    swapField: "companyNames",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeIndustries}
                iconUrl={"/assets/images/new_icons/Industry.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeIndustries: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeIndustries",
                    swapField: "industries",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeCompanyHQCities}
                iconUrl={"/assets/images/new_icons/Location.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeCompanyHQCities: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeCompanyHQCities",
                    swapField: "companyHQCities",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeCompanyHQCountry}
                iconUrl={"/assets/images/new_icons/Location.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeCompanyHQCountry: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeCompanyHQCountry",
                    swapField: "companyHQCountry",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeCategories}
                iconUrl={"/assets/images/new_icons/bx_category.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeCategories: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeCategories",
                    swapField: "categories",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeSpecialties}
                iconUrl={"/assets/images/new_icons/Job Title.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeSpecialties: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeSpecialties",
                    swapField: "specialties",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeFoundedYear}
                iconUrl={"/assets/images/bx_bx-calendar.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeFoundedYear: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeFoundedYear",
                    swapField: "foundedYear",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeDomains}
                iconUrl={"/assets/images/new_icons/Link.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeDomains: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeDomains",
                    swapField: "domains",
                    value,
                  })
                }
              />
              <ProspectFilterLabel
                collection={queryState?.excludeKeywords}
                iconUrl={"/assets/images/new_icons/keywords.png"}
                onCloseIconClicked={(x) =>
                  setQueryState({ ...queryState, excludeKeywords: x })
                }
                type={"exclude"}
                onSwapFieldClicked={(value) =>
                  swapFieldValues({
                    field: "excludeKeywords",
                    swapField: "keywords",
                    value,
                  })
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-link text-decoration-none p-0 text-danger cursor-pointer"
              onClick={handleSwapFilters}
              disabled={isObjectValuesEmptyArray(queryState)}
              data-cy="swap-filters-btn"
            >
              Invert All
            </button>
            <button
              style={{ color: "#FB3E3E !important" }}
              className="btn btn-link text-decoration-none p-0 text-danger cursor-default"
              onClick={handleClearAllSelectedFilters}
              disabled={isObjectValuesEmptyArray(queryState)}
              data-cy="clear-all-btn"
            >
              Clear All
            </button>
          </div>
          <div className="d-flex justify-content-center">
            {console.log(queryState)}
            <ProspectMatchCount queryState={queryState} type={"company"} />
          </div>
        </div>

        <div
          className="sidebar-search-for sidebar-widget px-4 pb-3 my-3"
          style={{ minHeight: "375px" }}
          data-cy="sidebar-search-filter-container"
        >
          <div
            className="sidebar-accordion accordion"
            id="accordionExample"
            data-cy="filters"
          >
            <ProspectFilterBasic
              displayName={"Company Name"}
              iconUrl={"/assets/images/new_icons/company_name.png"}
              inputPlaceholder={"e.g., HDFC"}
              tooltipText={"Enter search and add up to five searches"}
              onTargetValueSet={(v) =>
                handleTargetValueSet(v, "companyNames", "excludeCompanyNames")
              }
              showExcludeSearch={true}
              onExcludeTargetValueSet={(v) =>
                handleTargetValueSet(v, "excludeCompanyNames", "companyNames")
              }
            />
            <ProspectFilterList
              displayName={"Employee Size"}
              tooltipText={"Select Employee Size"}
              iconUrl={"/assets/images/new_icons/Employee Count.png"}
              stateName={"companyEmployeeCounts"}
              queryState={queryState}
              collection={[
                "1",
                "2-10",
                "11-50",
                "51-200",
                "201-500",
                "501-1000",
                "1001-5000",
                "5001-10000",
                "10001+",
              ]}
              onTargetValueSet={(v) => {
                if (
                  !checkValidityOfNewValueForSubState(
                    queryState.companyEmployeeCounts,
                    v
                  )
                ) {
                  return;
                }
                queryState.companyEmployeeCounts.push(v);
                setQueryState({
                  ...queryState,
                  companyEmployeeCounts: queryState.companyEmployeeCounts,
                });
              }}
            />
            <ProspectFilterAutoSuggest
              displayName={"Company HQ City"}
              iconUrl={"/assets/images/new_icons/Location.png"}
              inputPlaceholder={"e.g., Bengaluru"}
              indexName={"organization_cities"}
              indexAttribute={"city"}
              searchClient={searchClient}
              onTargetValueSet={(v) => {
                handleTargetValueSet(
                  v,
                  "companyHQCities",
                  "excludeCompanyHQCities"
                );
              }}
              showExcludeSearch={true}
              onExcludeValueSet={(v) => {
                handleTargetValueSet(
                  v,
                  "excludeCompanyHQCities",
                  "companyHQCities"
                );
              }}
            />
            <ProspectFilterAutoSuggest
              displayName={"Company HQ Country"}
              iconUrl={"/assets/images/new_icons/Location.png"}
              inputPlaceholder={"e.g., India"}
              indexName={"organization_countries"}
              indexAttribute={"country"}
              searchClient={searchClient}
              onTargetValueSet={(v) => {
                handleTargetValueSet(
                  v,
                  "companyHQCountry",
                  "excludeCompanyHQCountry"
                );
              }}
              showExcludeSearch={true}
              onExcludeValueSet={(v) => {
                handleTargetValueSet(
                  v,
                  "excludeCompanyHQCountry",
                  "companyHQCountry"
                );
              }}
            />
            <ProspectFilterBasic
              displayName={"Founded Year"}
              iconUrl={"/assets/images/bx_bx-calendar.png"}
              tooltipText={"Enter the founded year of the company"}
              inputPlaceholder={"e.g. 2018"}
              onTargetValueSet={(v) => {
                handleTargetValueSet(v, "foundedYear", "excludeFoundedYear");
              }}
              showExcludeSearch={true}
              onExcludeTargetValueSet={(v) => {
                handleTargetValueSet(v, "excludeFoundedYear", "foundedYear");
              }}
            />
            <ProspectFilterList
              displayName={"Company Type"}
              tooltipText={"Select company type"}
              iconUrl={"/assets/images/new_icons/Education.png"}
              stateName={"companyType"}
              queryState={queryState}
              collection={[
                "Public Company",
                "Privately Held",
                "Partnership",
                "Self-Owned",
                "Self-Employed",
                "Government Agency",
                "Nonprofit",
                "Educational",
              ]}
              onTargetValueSet={(v) => {
                if (
                  !checkValidityOfNewValueForSubState(queryState.companyType, v)
                ) {
                  return;
                }
                queryState.companyType.push(v);
                setQueryState({
                  ...queryState,
                  companyType: queryState.companyType,
                });
              }}
            />
            <ProspectFilterAutoSuggest
              displayName={"Industry"}
              iconUrl={"/assets/images/new_icons/Industry.png"}
              inputPlaceholder={"e.g., banking"}
              tooltipText={
                "Enter the first letter and choose upto five from the list"
              }
              indexName={"industries_1"}
              indexAttribute={"industry"}
              searchClient={searchClient}
              onTargetValueSet={(v) => {
                console.log(v)
                handleTargetValueSet(v, "industries", "excludeIndustries");
              }}
              showExcludeSearch={true}
              onExcludeValueSet={(v) => {
                handleTargetValueSet(v, "excludeIndustries", "industries");
              }}
            />
            <ProspectFilterAutoSuggest
              displayName={"Categories"}
              iconUrl={"/assets/images/new_icons/bx_category.png"}
              inputPlaceholder={"e.g., Information Services"}
              tooltipText={
                "Enter the first letter and choose upto five from the list"
              }
              indexName={"company_categories"}
              indexAttribute={"category"}
              searchClient={searchClient}
              onTargetValueSet={(v) => {
                handleTargetValueSet(v, "categories", "excludeCategories");
              }}
              showExcludeSearch={true}
              onExcludeValueSet={(v) => {
                handleTargetValueSet(v, "excludeCategories", "categories");
              }}
            />
            <ProspectFilterAutoSuggest
              displayName={"Specialties"}
              iconUrl={"/assets/images/new_icons/Job Title.png"}
              inputPlaceholder={"e.g., Marketing & Sales"}
              tooltipText={
                "Enter the first letter and choose upto five from the list"
              }
              indexName={"company_specialities"}
              indexAttribute={"speciality"}
              searchClient={searchClient}
              onTargetValueSet={(v) => {
                handleTargetValueSet(v, "specialties", "excludeSpecialties");
              }}
              showExcludeSearch={true}
              onExcludeValueSet={(v) => {
                handleTargetValueSet(v, "excludeSpecialties", "specialties");
              }}
            />
            <ProspectFilterList
              displayName={"Funding"}
              tooltipText={"Select funded companies"}
              iconUrl={"/assets/images/new_icons/Funded.png"}
              stateName={"funded"}
              queryState={queryState}
              collection={["Yes", "No"]}
              onTargetValueSet={(v) => {
                if (v === "Yes") {
                  if (
                    !checkValidityOfNewValueForSubState(
                      queryState.funded,
                      "Yes"
                    )
                  ) {
                    return;
                  }
                  setQueryState({
                    ...queryState,
                    funded: ["Yes"],
                  });
                } else {
                  if (
                    !checkValidityOfNewValueForSubState(queryState.funded, "No")
                  ) {
                    return;
                  }
                  setQueryState({
                    ...queryState,
                    funded: ["No"],
                  });
                }
              }}
            />
            <ProspectFilterBasic
              displayName={"Domain"}
              iconUrl={"/assets/images/new_icons/Link.png"}
              inputPlaceholder={"e.g., hdfc, hdfc.com"}
              tooltipText={"Enter the domain of the company"}
              onTargetValueSet={(v) => {
                handleTargetValueSet(v, "domains", "excludeDomains");
              }}
              showExcludeSearch={true}
              onExcludeTargetValueSet={(v) => {
                handleTargetValueSet(v, "excludeDomains", "domains");
              }}
            />
            <ProspectFilterBasic
              displayName={"Keywords"}
              iconUrl={"/assets/images/new_icons/keywords.png"}
              inputPlaceholder={"e.g., Options trader"}
              tooltipText={"General Query that dont fit anywhere else"}
              onTargetValueSet={(v) => {
                handleTargetValueSet(v, "keywords", "excludeKeywords");
              }}
              showExcludeSearch={true}
              onExcludeTargetValueSet={(v) => {
                handleTargetValueSet(v, "excludeKeywords", "keywords");
              }}
            />
          </div>
          <button
            onClick={handleCustomSubmit}
            className="btn text-white og-red global-hover"
            type="submit"
            data-cy="search"
          >
            <span className="pe-1 text-center d-flex align-items-center">
              <img
                src="/assets/images/new_icons/search.png"
                alt="title"
                className="search-icon"
                style={{ width: "15px", height: "15px" }}
              />
              Search
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CompanyFilters;
