import React from "react";

const SelectedLeads = ({ data, selectedLeads, setSelectedLeads }) => {
  const handleLeadSelectionChange = (e, data) => {
    const { id, checked } = e.target;
    setSelectedLeads(
      checked
        ? [...selectedLeads, data]
        : data?.meta_data?.sheet_name === "realtime-financial_intermediaries"
        ? selectedLeads.filter(
            (item) =>
              `${item.full_name}-${item.company_name}-${item.job}` !==
              `${data.full_name}-${data.company_name}-${data.job}`
          )
        : selectedLeads.filter((item) => item.details_url !== data.details_url)
    );
  };
  console.log(selectedLeads);
  return (
    <input
      className="box"
      id={data.id}
      type="checkbox"
      name={data.name}
      checked={selectedLeads.some((lead) =>
        data?.meta_data?.sheet_name === "realtime-financial_intermediaries"
          ? `${lead.full_name}-${lead.company_name}-${lead.job}` ===
            `${data.full_name}-${data.company_name}-${data.job}`
          : lead.details_url === data.details_url
      )}
      onChange={(e) => handleLeadSelectionChange(e, data)}
    />
  );
};

export default SelectedLeads;
