import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { AppContext } from "../../Context";

const UserSearch = ({ onResultPage = false }) => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const [searchText, setSearchText] = useState({ text: null });

  const handleHeadSearch = (e) => {
    setSearchText({ ...searchText, text: e.target.value });
  };

  const handleHeadSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "upsetHistorySearchTerm" });
    dispatch({ type: "upsetDemo" });

    if (!searchText.text) {
      alert("Enter details");
      return;
    }
    let requestTexAu = {
      name: searchText.text,
    };
    history.push({
      pathname: "/company_result_by_name",
      state: { requestTexAu },
    });
  };

  return (
    <div style={{ pointerEvents: "none", opacity: "0.4" }}>
      <div
        className="user-widget-box p-4"
        style={!onResultPage ? { marginTop: "6em" } : null}
        data-cy="searchbar"
      >
        <div className="user-search-wrapper">
          <img
            className="search-promote-image"
            src="/assets/images/user-success-image.png"
            alt="title"
          />
          <div className="search-promote-content">
            <form
              onSubmit={handleHeadSubmit}
              className={
                !onResultPage
                  ? "search-form4 d-flex ml-2 mb-3"
                  : "search-form4 d-flex ml-2 mt-3"
              }
            >
              <div className="input-group">
                <div className="input-placeholder">
                  <input
                    id="user-search-input"
                    className="ps-3"
                    name="searchString"
                    onChange={handleHeadSearch}
                    required
                    data-cy="search"
                  />
                  <div className="placeholder">
                    Enter <span className="text-danger">company name</span>
                  </div>
                </div>
                <button
                  className="btn text-white global-hover"
                  // onClick={handleHeadSubmit}
                  type="submit"
                >
                  <span className="pe-1">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>{" "}
                  Search
                </button>
              </div>
            </form>
            {
              //   !onResultPage
              //   &&
              //   <p className="fst-italic ml-2">
              //   Hey, Get started by putting a{" "}
              //   <span className="text-danger">
              //     company name, company website URL
              //   </span>{" "}
              //   or <br /> state your requirement above
              // </p>
            }
            {
              // !onResultPage
              // &&
              // <Link to="/userGuide" className="text-danger ml-2">
              //   Learn more
              // </Link>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
