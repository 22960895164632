import React, { useContext, useEffect, useState } from "react";
import "./Style/style.css";
import Cookies from "js-cookie";
import { digestMessage } from "./SearchResultTexAu";
import { AppContext } from "../../Context";
import LinkedUserDemo from "./LinkedUserDemo";
import { useSnackbar } from "notistack";
import Emitter from "../Utils/emitter";
import { apiServer } from "../../config";

const SearchResult = (props) => {
  const { isDemo } = useContext(AppContext);

  if (isDemo) {
    props.location.state = {};
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/app.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const [customSearch, setCustomSearch] = useState({
    location: null,
    industry: null,
    job_title: null,
    education: null,
    company_name: null,
    keywords: null,
    csv_file: null,
  });
  const [specificUserDetails, setSpecificUserDetails] = useState([
    { index: null, details: null },
  ]);
  const [unlockEmailDetails, setUnlockEmailDetails] = useState([
    { index: null, details: null },
  ]);

  const [searchId, setSearchId] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLeads, setCurrentLeads] = useState([]);
  const [myLeads, setMyLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedLeadHashKey, setSelectedLeadHashKey] = useState([]);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const postsPerPage = 10;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  const paginate = (pageNumber) => {
    setCurrentLeads([]);
    setCurrentPage(pageNumber);

    setCurrentLeads(myLeads?.slice(pageNumber * 10 - 10, pageNumber * 10));
    console.log(
      "currentLeads>>>",
      currentLeads,
      pageNumber * 10 - 10,
      pageNumber * 10,
      myLeads?.slice(pageNumber * 10 - 10, pageNumber * 10)
    );
  };
  today = dd + "/" + mm + "/" + yyyy;
  useEffect(() => {
    (async () => {
      if (props?.location?.pathname?.includes("/searchResult")) {
        console.log("Request..", props?.location?.state?.reqJsonPipl);

        try {
          // TODO: remove direct calls to pipl
          const response = await fetch(apiServer + "/pipl/search", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
            body: JSON.stringify(props?.location?.state?.reqJsonPipl),
          });

          let json_res = await response.json();

          console.log("Data>>>>>>>>>>>loading..", json_res, loading);
          setLoading(false);
          if (!json_res.detail) {
            setMyLeads(json_res);
            await saveSearchedRecord(json_res, "PIPL");
          }
        } catch (err) {
          console.error("Error: ", err);
        }
      }
      if (props.location.pathname.includes("/search_by_history_type2")) {
        console.log(
          "in result from hisry pipl>>>>",
          props.location.state.details
        );
        try {
          const response = await fetch(
            apiServer + `/history/id/${props.location.state.details.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("user_token")}`,
              },
            }
          );

          let json_res = await response.json();

          console.log("Data>>>>>>>>>>>loading..", json_res, loading);
          setLoading(false);
          setSearchId(json_res.id);

          setMyLeads(json_res.search_results);
        } catch (err) {
          console.error("Error: ", err);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      paginate(1);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLeads]);

  const handleCSVFile = (e) => {
    setCustomSearch({ ...customSearch, csv_file: e.target.files[0] });
  };

  const saveSearchedRecord = async (response, searchType) => {
    console.log("In saveSearchedRecord");
    let search_term = "";
    if (props.location.pathname.includes("/searchResult")) {
      search_term = props.location.state.reqJsonPipl.searchTerm;
    }

    let requestForSaveSearch = {
      search_type: searchType,
      search_term: search_term,
      search_results: response,
    };
    console.log("In saveSearchedRecord...", requestForSaveSearch);
    try {
      const response = await fetch(apiServer + "/history/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestForSaveSearch),
      });

      const result = response.json();
      result.then((value) => {
        console.log("value >>>>> ", value);
        if (value) setSearchId(value.search_id);
      });
      console.log("response from saveResult>>>", result, result.search_id);
    } catch (e) {
      console.error("Exception>>", e);
    }
  };
  const addBukEmail = async (emailIds, index) => {
    let requestForSaveEmailCredit = {
      search_id: searchId,
      email_addresses: emailIds,
      search_index: parseInt(`${currentPage}${index}`),
    };
    try {
      // TODO: Test and remove this call as this is unused and undefined
      const response = await fetch(apiServer + "/credits/email/bulk_add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestForSaveEmailCredit),
      });

      const result = response.json();

      console.log("response from saveResult>>>", result, result.search_id);
    } catch (e) {
      console.error("Exception>>", e);
    }
  };
  const handleUnlockEmail = async (e, index, data) => {
    e.preventDefault();
    console.log("in handle unlock>>>>", data);
    // try {
    const targetId = e.currentTarget.id;
    document.getElementById(targetId).innerHTML = "please wait...";
    let isDuplicate = false;
    let email = "";
    unlockEmailDetails.forEach((spec) => {
      console.log("spec email>>>", spec.index);
      if (spec.index === `${currentPage}${index}`) {
        isDuplicate = true;
      }
    });
    console.log("isDuplicate>>>>", isDuplicate);
    if (isDuplicate === false) {
      const organization = data.jobs ? data.jobs[0].organization : "";
      const firstName = data.names ? data.names[0].first : "";
      const lastName = data.names ? data.names[0].last : "";
      const personData = {
        firstName: firstName,
        lastName: lastName,
        companyName: organization,
      };
      try {
        // TODO: remove direct calls to snov from here
        const response = await fetch(
          apiServer + "/snov/email_from_person_details",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(personData),
          }
        );

        if (response.ok) {
          const result = await response.json();
          console.log("Email result is", result.email);
          if (result.email) {
            email = result.email;
          } else {
            if (result.emails.length > 0) {
              email = result.emails[0].email;
              const emails = result.emails.map((emails) => emails.email);
              await addBukEmail(emails, index);
              Emitter.emit("updateCredits", true);
            } else {
              email = "No email found";
            }
          }
        } else {
          console.log("bad response", response);
          email = "No email found";
        }
      } catch (e) {
        console.error("Exception>>", e);
        email = "No email found";
      }
      setUnlockEmailDetails((prev) => [
        ...prev,
        {
          index: `${currentPage}${index}`,
          // details: {email: `email_${currentPage}${index}@test.com`},
          details: { email: email },
        },
      ]);
    } else {
      console.table(unlockEmailDetails);
    }
    document.getElementById(targetId).innerHTML = "";
  };

  const handleProfile = async (index, data) => {
    console.log(
      "in Handle profile...",
      `${currentPage}${index}`,
      "DAta>>>",
      data
    );
    try {
      let isDuplicate = false;

      specificUserDetails.forEach((spec) => {
        console.log("spec>>>", spec.index);
        if (spec.index === `${currentPage}${index}`) {
          isDuplicate = true;
        }
      });
      console.log("isDuplicate>>>>", isDuplicate);
      if (isDuplicate === false) {
        let phones = [];
        if (data && data.phones) {
          console.log("in data>>>>", data.phones);
          let hash_key = await digestMessage(JSON.stringify(data));
          console.log("hash_key>>>>>>>>>>", hash_key);
          let reqJsonPipl = {
            hash_key: hash_key,
            type: "PIPL_REC",
            result: [data],
          };
          // TODO: remove direct call to pipl from here
          const response = await fetch(apiServer + "/pipl/search", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
            body: JSON.stringify(reqJsonPipl),
          });

          if (response.status === 402) {
            enqueueSnackbar(
              "You have insufficient profile credit. Buy Credits to get details.",
              { variant: "warning" }
            );
            return;
          }
          if (response.status === 500) {
            console.log("Not able to get Details");
          }
          if (response.status === 200) {
            console.log("PIPL response>>>>", response);
            for (let j = 0; j < data.phones.length; j++) {
              phones.push(data.phones[j].number);
            }
            console.log("Phones>>>>>>", phones);

            let requestForSaveProfileCredit = {
              search_id: searchId,
              phone_numbers: phones,
              search_index: `${currentPage}${index}`,
            };
            try {
              const response = await fetch(
                apiServer + "/credits/profile/bulk_add",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${Cookies.get("user_token")}`,
                  },
                  body: JSON.stringify(requestForSaveProfileCredit),
                }
              );

              const result = response.json();
              Emitter.emit("updateCredits", true);
              console.log("response from saveResult>>>", result);
            } catch (e) {
              console.error("Exception>>", e);
            }
            setSpecificUserDetails((prev) => [
              ...prev,
              { index: `${currentPage}${index}`, details: data },
            ]);
          }
        }
        console.log("In setSpecificUserDetails else");
        setSpecificUserDetails((prev) => [
          ...prev,
          { index: `${currentPage}${index}`, details: "Record Not Found" },
        ]);
        console.log(
          "In setSpecificUserDetails else ress....",
          specificUserDetails
        );
      }

      console.log("specificUser>>>>>>>", specificUserDetails);
    } catch (err) {
      console.error("Error: ", err);
    }
    // window.location.reload(false);
  };

  const handleLeadSelectionChange = async (e, data, index) => {
    console.log("in check changed state.....");
    const { id, checked } = e.target;
    let tempJson = {};
    let index_json = {};
    let hash_key = null;
    console.log("ID$ ", id, index, data);
    setSelectedLeads([...selectedLeads, id]);

    hash_key = await digestMessage(JSON.stringify(data));
    tempJson[JSON.stringify(data)] = hash_key;
    console.log("In >> hash keys ", tempJson);
    index_json[hash_key] = `${currentPage}${index}`;
    setSelectedLeadHashKey([...selectedLeadHashKey, tempJson]);
    setSelectedLeadIndex([...selectedLeadIndex, index_json]);
    if (!checked) {
      setSelectedLeads(selectedLeads.filter((item) => item !== id));
      setSelectedLeadHashKey(
        selectedLeadHashKey.filter(
          (item) => item[JSON.stringify(data)] !== hash_key
        )
      );
      setSelectedLeadIndex(
        selectedLeadIndex.filter((item) => item[hash_key] !== id)
      );
    }
    console.log("hash keys ", hash_key);
  };

  const closeModal = () => {
    document.getElementById("searchError").style.display = "none";
  };

  const Popup = () => {
    return (
      <>
        <div
          className="modal"
          style={{ display: "block" }}
          id="searchError"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <div class="d-flex justify-content-center">
                      <img
                        style={{ height: "130px", width: "70px" }}
                        src="/assets/images/g10 (2).png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="text-center">
                      <p class="text-danger ">Oops</p>
                      <p>
                        Hey! Looks like the person you searched may not be very
                        socially active. I checked over 500+ sources for contact
                        data. Lets try another person
                      </p>
                      <button
                        style={{ background: "#fb3e3e" }}
                        class="btn text-white"
                        onClick={closeModal}
                      >
                        Retry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (myLeads.length === 0) {
    <Popup />;
  }

  return (
    <div>
      <LinkedUserDemo
        handleCSVFile={handleCSVFile}
        currentPage={currentPage}
        selectedLeads={selectedLeads}
        handleLeadSelectionChange={handleLeadSelectionChange}
        unlockEmailDetails={unlockEmailDetails}
        handleUnlockEmail={handleUnlockEmail}
        handleProfile={handleProfile}
        specificUserDetails={specificUserDetails}
        postsPerPage={postsPerPage}
        paginate={paginate}
      />
    </div>
  );
};

export default SearchResult;
