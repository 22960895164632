import { createSlice } from '@reduxjs/toolkit';

export const leadsDataSlice = createSlice({
  name: 'leads',
  initialState: {
    leadsData: {},
    leadsForMapMarker:[]
  },
   reducers: {
    saveLeadsData:(state,action) => {
      state.leadsData = action.payload;
    },
    saveLeadsForMapMarker:(state,action) => {
      state.leadsForMapMarker = action.payload;
    },
  },
});

export const { saveLeadsData, saveLeadsForMapMarker } = leadsDataSlice.actions;

export const selectLeadsData = (state) => state.leads.leadsData;
export const selectLeadsForMapMarker = (state) => state.leads.leadsForMapMarker;

export default leadsDataSlice.reducer;
