import dayjs from "dayjs";
import validator from "validator";

/**
 * Checks if all values are empty in an object
 * @param object - Pass the object that you want to check
 * @return {boolean}
 */
export const isObjectValuesEmpty = (object) => {
  for (const key in object) {
    if (object[key]) return false;
  }
  return true;
};

/**
 * Checks if all value or values in array are empty in an object
 * @param object - Pass the object that you want to check
 * @return {boolean}
 */
export const isObjectValuesEmptyArray = (object) => {
  for (const key in object) {
    if (Array.isArray(object[key]) && object[key]?.length > 0) {
      return false;
    } else if (!Array.isArray(object[key]) && object[key]) {
      return false;
    }
  }
  return true;
};

/**
 * To validate a URL as a proper LinkedIn URL input
 * @param url
 * @return {string|*}
 * @constructor
 */
export const ValidateLinkedinUrl = (url) => {
  if (!url || !url?.toString()?.includes("linkedin")) return null;
  try {
    const myURLObj = new URL(url);
    if (!myURLObj?.hostname?.includes("linkedin")) return null;
    if (!myURLObj?.pathname?.endsWith("/"))
      return "https://www.linkedin.com".concat(myURLObj?.pathname).concat("/");
    return "https://www.linkedin.com".concat(myURLObj?.pathname);
  } catch (e) {
    console.error("Exception while validate input: ", e);
    return null;
  }
};

/**
 * Convert a number into readable time string
 * @param duration
 * @return {string}
 */
export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds, ~~ represents Math.floor()
  const hours = ~~(duration / 3600);
  const minutes = ~~((duration % 3600) / 60);
  const seconds = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59" or "1h 39m 05s"
  let time = "";

  if (hours > 0) {
    time += "" + hours + "h " + (minutes < 10 ? "0" : "");
  }

  time += "" + minutes + "m " + (seconds < 10 ? "0" : "");
  time += "" + seconds + "s";
  return time;
};

/**
 * Countdown timer for parameters of a function
 * @param func - callback function
 * @param num - number of times to repeat the execution
 * @param delay - delay between each execution
 */
export const callNTimes = (func, num, delay = 1000) => {
  if (!num) return;
  func(num);
  setTimeout(function () {
    callNTimes(func, num - 1, delay);
  }, delay);
};

/**
 * Parses string containing JSON and converts to string
 * @param string
 * @return {string|any}
 */
export const isJSON = (string) => {
  try {
    const parsedObject = JSON.parse(string);
    let selectedItem = "";
    if (typeof parsedObject === "object") {
      // eslint-disable-next-line array-callback-return
      Object?.values(parsedObject)?.map((item) => {
        if (item.length > 0 && typeof item[0] === "object") {
          if (item[0]?.hasOwnProperty("start_date")) {
            selectedItem =
              selectedItem +
              `${dayjs(item[0]?.start_date)?.format("YYYY-MM-DD")} `;
          }
          if (item[0]?.hasOwnProperty("end_date")) {
            selectedItem =
              selectedItem +
              `To ${dayjs(item[0]?.end_date)?.format("YYYY-MM-DD")} ,`;
          }
        } else if (item?.length > 0 && typeof item === "object") {
          selectedItem = selectedItem + ` ${item[0]} ,`;
        } else {
          selectedItem = selectedItem + ` ${item},`;
        }
      });
      return selectedItem.substring(0, selectedItem?.length - 1);
    } else {
      return parsedObject;
    }
  } catch (e) {
    return string;
  }
};

/**
 * Spinner to be used for smaller wait times at loading components
 * @param color
 * @return {JSX.Element}
 * @constructor
 */
export const Spinner = ({ color = "red" }) => {
  return (
    <div className="text-center">
      <div style={{ color: color }} className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const Loader = () => {
  return (
    <div className="loader-container pb-6">
      <div className="loader">
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--text"></div>
      </div>
    </div>
  );
};

// Function  for funded/deal amount to convert in readable format like 4.5M
export const fundingAmountFormatter = (fundingAmount) =>
  Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
    notation: "compact",
  }).format(fundingAmount);

/**
 * For a given filename using regex we remove "-{UUID}"
 * @param fileName - File name to remove UUID from
 * @return {*} - a clean file name for the user
 */
export const cleanFileNameWithUUID = (fileName) => {
  console.log("filename: ", fileName);
  if (fileName) {
    const regexForUUID =
      /-\p{Hex_Digit}{8}(?:-\p{Hex_Digit}{4}){3}-\p{Hex_Digit}{12}/u;

    return fileName?.replace(regexForUUID, "");
  }
};

export const isStringValidJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    return typeof JSON.parse(text) === "object";
  } catch (error) {
    return false;
  }
};

export const transformSmeData = (inputData) => {
  console.log("inputData", inputData);
  const outputData = { ...inputData };
  // Check if "search_results" is an array and transform it
  if (
    Array.isArray(inputData?.search_results) &&
    inputData?.search_results?.length > 0
  ) {
    const transformedResults = [];

    for (const result of inputData?.search_results) {
      const transformedResult = {};
      // Transform each key and value dynamically
      for (const key of Object?.keys(result)) {
        const value = result[key][0];
        transformedResult[key] = value === " " ? " " : value;
      }
      transformedResults.push(transformedResult);
    }

    outputData.search_results = transformedResults;
  }
  return outputData;
};

export const checkForSpecialCharacters = (searchText) => {
  const specialCharsPattern = /[!@#$%^&*()_+{}\[\]:;<>,'.?~\\/]/;

  if (specialCharsPattern?.test(searchText)) {
    return true;
  } else {
    return false;
  }
};

export const validatePhoneNumber = (number) => {
  return validator.isMobilePhone(number);
};

export const validateName = (name) => {
  return validator.isAlphanumeric(name, ["en-US"], { ignore: " " });
};

export const validateUserName = (name) => {
  return validator.isAlpha(name);
};

export const validateCompanyName = (company_name)=>{
    const regexPattern = /^[a-zA-Z0-9.&* ]+$/;
    return validator.matches(company_name, regexPattern)
}
// Stop words github url
// https://gist.github.com/sebleier/554280
export const stop_word_list = [
  "i",
  "me",
  "my",
  "myself",
  "we",
  "our",
  "ours",
  "ourselves",
  "you",
  "your",
  "yours",
  "yourself",
  "yourselves",
  "he",
  "him",
  "his",
  "himself",
  "she",
  "her",
  "hers",
  "herself",
  "it",
  "its",
  "itself",
  "they",
  "them",
  "their",
  "theirs",
  "themselves",
  "what",
  "which",
  "who",
  "whom",
  "this",
  "that",
  "these",
  "those",
  "am",
  "is",
  "are",
  "was",
  "were",
  "be",
  "been",
  "being",
  "have",
  "has",
  "had",
  "having",
  "do",
  "does",
  "did",
  "doing",
  "a",
  "an",
  "the",
  "and",
  "but",
  "if",
  "or",
  "because",
  "as",
  "until",
  "while",
  "of",
  "at",
  "by",
  "for",
  "with",
  "about",
  "against",
  "between",
  "into",
  "through",
  "during",
  "before",
  "after",
  "above",
  "below",
  "to",
  "from",
  "up",
  "down",
  "in",
  "out",
  "on",
  "off",
  "over",
  "under",
  "again",
  "further",
  "then",
  "once",
  "here",
  "there",
  "when",
  "where",
  "why",
  "how",
  "all",
  "any",
  "both",
  "each",
  "few",
  "more",
  "most",
  "other",
  "some",
  "such",
  "no",
  "nor",
  "not",
  "only",
  "own",
  "same",
  "so",
  "than",
  "too",
  "very",
  "s",
  "t",
  "can",
  "will",
  "just",
  "don",
  "should",
  "now",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "A",
  "about",
  "above",
  "across",
  "after",
  "again",
  "against",
  "all",
  "almost",
  "alone",
  "along",
  "already",
  "also",
  "although",
  "always",
  "am",
  "among",
  "an",
  "and",
  "another",
  "any",
  "anyone",
  "anything",
  "anywhere",
  "are",
  "aren't",
  "around",
  "as",
  "at",
  "b",
  "B",
  "back",
  "be",
  "became",
  "because",
  "become",
  "becomes",
  "been",
  "before",
  "behind",
  "being",
  "below",
  "between",
  "both",
  "but",
  "by",
  "c",
  "C",
  "can",
  "cannot",
  "can't",
  "could",
  "couldn't",
  "d",
  "D",
  "did",
  "didn't",
  "do",
  "does",
  "doesn't",
  "doing",
  "done",
  "don't",
  "down",
  "during",
  "e",
  "E",
  "each",
  "either",
  "enough",
  "even",
  "ever",
  "every",
  "everyone",
  "everything",
  "everywhere",
  "f",
  "F",
  "few",
  "find",
  "first",
  "for",
  "four",
  "from",
  "full",
  "further",
  "g",
  "G",
  "get",
  "give",
  "go",
  "h",
  "H",
  "had",
  "hadn't",
  "has",
  "hasn't",
  "have",
  "haven't",
  "having",
  "he",
  "he'd",
  "he'll",
  "her",
  "here",
  "here's",
  "hers",
  "herself",
  "he's",
  "him",
  "himself",
  "his",
  "how",
  "however",
  "how's",
  "i",
  "I",
  "i'd",
  "if",
  "i'll",
  "i'm",
  "in",
  "interest",
  "into",
  "is",
  "isn't",
  "it",
  "it's",
  "its",
  "itself",
  "i've",
  "j",
  "J",
  "k",
  "K",
  "keep",
  "l",
  "L",
  "last",
  "least",
  "less",
  "let's",
  "m",
  "M",
  "made",
  "many",
  "may",
  "me",
  "might",
  "more",
  "most",
  "mostly",
  "much",
  "must",
  "mustn't",
  "my",
  "myself",
  "n",
  "N",
  "never",
  "next",
  "no",
  "nobody",
  "noone",
  "nor",
  "not",
  "nothing",
  "now",
  "nowhere",
  "o",
  "O",
  "of",
  "off",
  "often",
  "on",
  "once",
  "one",
  "only",
  "or",
  "other",
  "others",
  "ought",
  "our",
  "ours",
  "ourselves",
  "out",
  "over",
  "own",
  "p",
  "P",
  "part",
  "per",
  "perhaps",
  "put",
  "q",
  "Q",
  "r",
  "R",
  "rather",
  "s",
  "S",
  "same",
  "see",
  "seem",
  "seemed",
  "seeming",
  "seems",
  "several",
  "shan't",
  "she",
  "she'd",
  "she'll",
  "she's",
  "should",
  "shouldn't",
  "show",
  "side",
  "since",
  "so",
  "some",
  "someone",
  "something",
  "somewhere",
  "still",
  "such",
  "t",
  "T",
  "take",
  "than",
  "that",
  "that's",
  "the",
  "their",
  "theirs",
  "them",
  "themselves",
  "then",
  "there",
  "therefore",
  "there's",
  "these",
  "they",
  "they'd",
  "they'll",
  "they're",
  "they've",
  "this",
  "those",
  "though",
  "three",
  "through",
  "thus",
  "to",
  "together",
  "too",
  "toward",
  "two",
  "u",
  "U",
  "under",
  "until",
  "up",
  "upon",
  "us",
  "v",
  "V",
  "very",
  "w",
  "W",
  "was",
  "wasn't",
  "we",
  "we'd",
  "we'll",
  "well",
  "we're",
  "were",
  "weren't",
  "we've",
  "what",
  "what's",
  "when",
  "when's",
  "where",
  "where's",
  "whether",
  "which",
  "while",
  "who",
  "whole",
  "whom",
  "who's",
  "whose",
  "why",
  "why's",
  "will",
  "with",
  "within",
  "without",
  "won't",
  "would",
  "wouldn't",
  "x",
  "X",
  "y",
  "Y",
  "yet",
  "you",
  "you'd",
  "you'll",
  "your",
  "you're",
  "yours",
  "yourself",
  "yourselves",
  "you've",
  "z",
  "Z",
];
