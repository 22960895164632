import React, { useEffect, useState } from "react";
//COMPONENT
import MapboxAutocomplete from "../mapboxAutocomplete/MapboxAutocomplete";
//HTTP-SERVICE
import { httpService } from "../geolocatorHttpServices/geolocatorHttpServices";
// RXJS
import { forkJoin } from "rxjs";
//REDUX
import { useImmer } from "use-immer";
import { selectMapBoxAutocomplete } from "../../../_reducer/areaDetail";
import {
  saveLeadsData,
  saveLeadsForMapMarker,
  selectLeadsForMapMarker,
} from "../../../_reducer/leads";
import {
  saveLoaderState,
  saveCategorySelected,
  selectCategorySelected,
  saveClearSearchResult,
  selectClearSearchResult,
  saveLeadsId,
} from "../../../_reducer/shared";
import { useSelector, useDispatch } from "react-redux";
//CSS
import "./CategorySelect.css";
import { useSnackbar } from "notistack";

function CategorySelect({
  setSimpleSearchId,
  center,
  category,
  setCategory,
  allCoordinates,
  mapSelectedLeads,
  setMapSelectedLeads,
}) {
  const [dropdown, setdropdown] = useState(true);
  const [selectCategory, setSelectedCategory] = useState("");
  const mapBoxAutocomplete = useSelector(selectMapBoxAutocomplete);
  const categorySelected = useSelector(selectCategorySelected);
  const clearSearchResult = useSelector(selectClearSearchResult);
  const leadsForMapMarker = useSelector(selectLeadsForMapMarker);

  const { enqueueSnackbar } = useSnackbar();
  const [leadsToShowOnMap, setLeadsToShowOnMap] = useImmer(leadsForMapMarker);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("In leadsToShowOnMap useEffect", leadsToShowOnMap);
    if (leadsToShowOnMap.length > 0) {
      dispatch(saveLeadsForMapMarker(leadsToShowOnMap));
    } else {
      dispatch(saveLeadsForMapMarker([]));
    }
  }, [leadsToShowOnMap]);

  useEffect(() => {
    try {
      if (clearSearchResult) {
        console.log("in clear result 1");
        if (selectCategory) {
          document.getElementById(selectCategory).checked = false;
          dispatch(saveClearSearchResult(false));
          dispatch(saveLoaderState(false));
          dispatch(saveCategorySelected(""));
          dispatch(saveLeadsData({}));
          dispatch(saveLeadsForMapMarker([]));
          setCategory("");
          setSelectedCategory("");
          setLeadsToShowOnMap((leads) => {
            while (leads.length > 0) {
              leads.pop();
            }
          });
        }
      }
    } catch (e) {
      console.error("Error in clear search result");
    }
  }, [clearSearchResult]);

  useEffect(() => {
    if (Object.keys(mapBoxAutocomplete).length === 0) {
      setSelectedCategory("");
    }
  }, [mapBoxAutocomplete]);

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    setMapSelectedLeads([]);
    if (Object.keys(mapBoxAutocomplete).length <= 0 && checked) {
      enqueueSnackbar("Please Select Region");
      return;
    }
    dispatch(saveLoaderState(true));
    if (checked) {
      setSelectedCategory(value);
    } else {
      setSelectedCategory("");
      dispatch(saveLoaderState(false));
      dispatch(saveCategorySelected(""));
      dispatch(saveLeadsData({}));
    }
    if (Object.keys(mapBoxAutocomplete).length > 0) {
      if (checked) {
        console.log(
          "mapBoxAutocomplete: in category select: ",
          mapBoxAutocomplete
        );
        let { bbox, place_name, type, center } = mapBoxAutocomplete;
        console.log("mapBoxAutocomplete: ", type, allCoordinates);
        if (!value.includes("sme")) {
          handleGetLeadsAndSaveHistory(
            bbox,
            place_name,
            value,
            type,
            center,
            allCoordinates
          );
          enqueueSnackbar("Please scroll down to view the leads!!");
        } else dispatch(saveLoaderState(false));
        dispatch(saveCategorySelected(value));
      } else if (value === categorySelected) {
        dispatch(saveLoaderState(false));
        dispatch(saveCategorySelected(""));
        dispatch(saveLeadsData({}));
        setLeadsToShowOnMap((leads) => {
          let index = leads.findIndex((item) => item.category === value);
          leads.splice(index, 1);
        });
      } else {
        setLeadsToShowOnMap((leads) => {
          let index = leads.findIndex((item) => item.category === value);
          leads.splice(index, 1);
        });
      }
    } else {
      dispatch(saveLoaderState(false));
      dispatch(saveCategorySelected(""));
      dispatch(saveLeadsData({}));
    }
    // enqueueSnackbar("Please scroll down to view the leads!!");
  };

  const handleGetLeadsAndSaveHistory = (
    bbox,
    place_name,
    value,
    type,
    center,
    allCoordinates
  ) => {
    console.log("bboxNo", bbox);
    console.log(
      "in handleGetLeadsAndSaveHistory: ",
      bbox,
      place_name,
      value,
      center
    );
    setLeadsToShowOnMap([]);

    const latLonList = allCoordinates?.map((item) => {
      return {
        lat: item[1],
        lon: item[0],
      };
    });

    let data = {
      data_layer_type: value,
      latitude_north_w: type === "Feature" ? bbox[3] : null,
      longitude_north_w: type === "Feature" ? bbox[0] : null,
      latitude_south_e: type === "Feature" ? bbox[1] : null,
      longitude_south_e: type === "Feature" ? bbox[2] : null,
      lat_lon_list: type === "Polygon" ? latLonList : null,
      search_term:
        type === "Polygon" ? `${JSON.stringify(latLonList)}` : place_name,
      center: center,
      type: type,
      offset: 0,
    };
    httpService
      .saveToHistory(data)
      .then(async (data) => {
        let { id } = data;
        if (id) {
          dispatch(saveLeadsId(id));
          setSimpleSearchId(id);

          const intervalId = setInterval(async () => {
            httpService.getLeads(id).then((data) => {
              let { search_results, status } = data;

              if (status === "success") {
                dispatch(saveLeadsData(search_results));
                dispatch(saveCategorySelected(value));
                console.log(
                  "type of search result: ",
                  typeof search_results,
                  search_results
                );
                setLeadsToShowOnMap((leads) => {
                  leads.push({
                    category: value,
                    data: search_results.search_result,
                  });
                });
                clearInterval(intervalId);
              } else if (status === "failed") {
                dispatch(saveLeadsData({}));
                dispatch(saveLoaderState(false));
                clearInterval(intervalId);
              }
            });
          }, 5 * 1000);
        } else {
          dispatch(saveLeadsData({}));
          dispatch(saveLoaderState(false));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="category_mainContainer">
      <MapboxAutocomplete setCategory={setCategory} />
      <div className="dropdownCat" data-cy="map-category-dropwown">
        <div className="drop-down-items__child shadow">
          <div
            className="drop-down-items__item"
            data-cy="dataLayer-dropdown-btn"
            onClick={() => setdropdown(!dropdown)}
          >
            <h3 className="drop-down__heading">Data Layers</h3>
            <img
              className="dropdown_d"
              src="/assets/images/leadzen/chevron-down.png"
              alt="chevron-down"
            />
          </div>
        </div>

        {dropdown && (
          <>
            {/* Hide people due to lack of data */}
            {/* <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="people">People </label>
                <input
                  type="checkbox"
                  id="people"
                  value="people"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "people"}
                  data-cy="dataLayer-people-input"
                />
              </div>
            </div> */}

            {/* <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="sme">SME </label>
                <input
                  type="checkbox"
                  id="sme"
                  value="sme"
                  onChange={handleChange}
                  disabled
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "sme"}
                  data-cy="dataLayer-sme-input"
                />
              </div>
            </div> */}

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="retails">Retails </label>
                <input
                  type="checkbox"
                  id="retails"
                  value="retails"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "retails"}
                  data-cy="dataLayer-retail-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="education">Educational</label>
                <input
                  type="checkbox"
                  id="education"
                  value="education"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "education"}
                  data-cy="dataLayer-education-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="restaurants">
                  Restaurants, Bars, Eateries{" "}
                </label>
                <input
                  type="checkbox"
                  id="restaurants"
                  value="restaurants"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "restaurants"}
                  data-cy="dataLayer-restaurants-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="banks">Banks</label>
                <input
                  type="checkbox"
                  id="banks"
                  value="banks"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "banks"}
                  data-cy="dataLayer-banks-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="atm">ATM </label>
                <input
                  type="checkbox"
                  id="atm"
                  value="atm"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "atm"}
                  data-cy="dataLayer-atm-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="hotels">Hotels and Dorms </label>
                <input
                  type="checkbox"
                  id="hotels"
                  value="hotels"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "hotels"}
                  data-cy="dataLayer-hotels-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="medical">Medical Facilities </label>
                <input
                  type="checkbox"
                  id="medical"
                  value="medical"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "medical"}
                  data-cy="dataLayer-medical-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="professional">
                  Professionals- CA, Lawyer, etc.
                </label>
                <input
                  type="checkbox"
                  id="professional"
                  value="professional"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "professional"}
                  data-cy="dataLayer-professionals-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="appartment">Apartments </label>
                <input
                  type="checkbox"
                  id="appartment"
                  value="appartment"
                  onChange={handleChange}
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "appartment"}
                  data-cy="dataLayer-apartments-input"
                />
              </div>
            </div>

            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="government">Government </label>
                <input
                  type="checkbox"
                  id="government"
                  value="government"
                  onChange={handleChange}
                  data-cy="dataLayer-govt-input"
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "government"}
                />
              </div>
            </div>
            <div className="drop-down-items__child shadow">
              <div className="drop-down-items__item">
                <label htmlFor="others">Others</label>
                <input
                  type="checkbox"
                  id="others"
                  value="others"
                  onChange={handleChange}
                  data-cy="dataLayer-others-input"
                  className="drop-down-items__checkbox"
                  checked={selectCategory === "others"}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CategorySelect;
