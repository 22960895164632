import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { digestMessage } from "../SearchResult/SearchResultTexAu";
import Cookies from "js-cookie";
import { AppContext } from "../../Context";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import Popup from "../Utils/Popup";
import Emitter from "../Utils/emitter";
import { apiServer } from "../../config";

const CompanyDetailInfo = ({ data, index, dict_key }) => {
  console.log("debugging companydetail info", dict_key, data);

  const [popupVisible, setPopupVisible] = useState(false);
  const [seeLess, setSeeLess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { dispatch } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [specificUserDetails, setSpecificUserDetails] = useState([
    { index: null, details: null },
  ]);
  const history = useHistory();
  const location = useLocation();
  const realTimeProfileUrl = "https://www.linkedin.com/in/real_time_unlock";
  const realTimeCompanyUrl = "https://www.linkedin.com/company/realTimeCompany";
  const saveSearchedRecord = async (response, searchType) => {
    if (location.pathname.includes("result_by_history_type4")) {
      return;
    }
    console.log("In saveSearchedRecord...searchTerm", response, searchType);
    let search_term = data[dict_key?.section1];
    let resp = {
      name: data[dict_key?.section1],
      companyUrl: realTimeCompanyUrl,
      logoUrl: "",
      cin: data[dict_key?.cin],
      category: data[dict_key?.category],
      roc: data[dict_key?.roc],
      companyAddress: data[dict_key?.company_address],
      subCategory: data[dict_key?.sub_category],
      class: data[dict_key?.class],
      description: data[dict_key?.activity],
      authorisedCapital: data[dict_key?.auth_capital],
      paidCapital: data[dict_key?.paid_capital],
      activity: data[dict_key?.activity],
      director: data[dict_key?.section2],
      directorUrl: data[dict_key?.linkedin_link_1],
      phone: data[dict_key?.phone],
      email: data[dict_key?.email],
    };
    let requestForSaveSearch = {
      search_id: uuidv4(),
      search_type: searchType,
      search_term: JSON.stringify(search_term),
      search_results: [resp],
    };
    console.log("In saveSearchedRecord...", requestForSaveSearch);
    try {
      const response = await fetch(apiServer + "/history/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestForSaveSearch),
      });
    } catch (e) {
      console.error("Exception>>", e);
    }
  };

  useEffect(() => {
    saveSearchedRecord(data, "realTimeCompany");
  }, [data]);

  const checkExecutionStatus = async (searchId = null, requestBody) => {
    if (!searchId) {
      console.warn("searchId is Null");
      return;
    }
    console.log("requestbody for saving to history", requestBody);
    try {
      const response = await fetch(`${apiServer}/realtime/save_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestBody),
      });
      switch (response.status) {
        case 200:
          console.log("Realtime unlock saved in history");
          break;
        case 422:
          console.log("Unprocessable entity in realtime to save in history");
          break;
        default:
          console.log("Error in saving realtime unlock to history");
      }
    } catch (error) {
      enqueueSnackbar(
        "Hey!, looks like something went wrong. We were unable to save your search to history.",
        { variant: "error" }
      );
      console.error("Error while showing search result on platform");
    }
    let timeoutId;
    const intervalId = setInterval(async () => {
      console.log("Checking For Results Every 5 Seconds");

      try {
        const response = await fetch(
          apiServer + `/search/person/detailed/result/${searchId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
          }
        );

        console.log("api response: ", response);

        function handleError() {
          if (timeoutId) clearTimeout(timeoutId);
          clearInterval(intervalId);
        }

        function handleUnAuthorized() {
          console.log("User is UnAuthorized");
          enqueueSnackbar("Please Logout and LogIn Again");
        }

        async function handleSuccess(response) {
          const json = await response.json();
          if (!json) {
            console.warn("Invalid Data");
            return handleError();
          }

          console.log("json: ", json);

          switch (json.status) {
            case "success": {
              console.log(
                "json.status = success, json.search_result = ",
                json.search_result
              );

              if (timeoutId) clearTimeout(timeoutId);
              Emitter.emit("updateCredits", true);
              clearInterval(intervalId);
              dispatch({
                type: "setSpecificUserInfo",
                payload: json.search_result,
              });
              break;
            }
            case "running": {
              console.log("json.status = running");
              return;
            }
            case "failed": {
              dispatch({
                type: "setSpecificUserInfo",
                payload: "failed/unknown",
              });
              break;
            }
            default: {
              console.log("json.status = failed/unknown");
              dispatch({
                type: "setSpecificUserInfo",
                payload: "failed/unknown",
              });

              handleError();
            }
          }
        }

        switch (response.status) {
          case 200:
            return handleSuccess(response);
          case 401:
            clearInterval(intervalId);
            return handleUnAuthorized(response);
          case 404:
          default:
            return handleError();
        }
      } catch (e) {
        console.error("Exception", e);
      }
    }, 5 * 1000);

    // stop checking after 5 minutes
    timeoutId = setTimeout(function () {
      console.error("record not found within 5 Min");
      clearInterval(intervalId);
    }, 5 * 60 * 1000);
  };

  const save_real_time_data = async (requestBody) => {
    try {
      const response = await fetch(
        `${apiServer}/realtime/save_realtime_profile_without_linkedin_url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
      switch (response.status) {
        case 200:
          console.log("Realtime unlock (without linkin) saved in history");
          break;
        case 422:
          console.log(
            "Unprocessable entity in realtime (without linkin) to save in history"
          );
          break;
        default:
          console.log(
            "Error in saving realtime unlock (without linkin) to history"
          );
      }
    } catch (error) {
      enqueueSnackbar(
        "Hey!, looks like something went wrong. We were unable to save your realtime unlock to history.",
        { variant: "error" }
      );
      console.error("Error while saving realtime unlocks on platform");
    }
  };

  const handleProfileWithoutLink = async (index, data) => {
    console.log("debug in handle without link", data, dict_key);
    dispatch({ type: "setPersonLoading" });
    setCurrentPage(`${currentPage}${index}`);
    let hash_key = await digestMessage(data);
    console.log("hash_key>>>>>>>>>>", hash_key);
    dispatch({
      type: "setUserName",
      payload: {
        location: null,
        name: data[dict_key?.section2],
        profile_url: "real_time_page_mca",
        summary: "director",
      },
    });
    let json = data;
    json["phones"] = [{ number: data[dict_key?.phone], verified: true }];
    json["emails"] = [{ email: data[dict_key?.email], verified: true }];
    json["name"] = data[dict_key?.section2];
    json["profile_url"] = "real_time_page_mca";
    let searchId = await digestMessage(json.name);
    let inputBody = {
      person_name: json.name,
      simple_search_id: searchId,
      cin: data[dict_key?.cin],
      verified_phones: json.phones,
      work_emails: json.emails,
      meta_data: [],
    };
    let requestBody = {
      realtime_search_id: hash_key,
      url: realTimeProfileUrl,
      current_leads: {
        id: inputBody.simple_search_id,
        full_name: json.name,
        location: data[dict_key?.location],
        image_url: null,
        job: null,
        details_url: realTimeProfileUrl,
        phones: json.phones,
        emails: json.emails,
        meta_data: null,
        company_name: null,
      },
      hash_key: hash_key,
      search_results: {
        id: uuidv4(),
        full_name: json.name,
        image_url: null,
        phones: json.phones,
        usernames: null,
        emails: json.emails,
        addresses: null,
        languages: null,
        probable_urls: null,
        gender: null,
        locations: data[dict_key?.location],
        jobs: null,
        educations: null,
        related: null,
        meta_data: null,
      },
    };
    // console.log("here is the requestbody for deduction", inputBody);
    try {
      const response = await fetch(
        apiServer + "/credits/profile/deduct_credit_for_google_sheet_profile",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(inputBody),
        }
      );

      async function handleSuccess(response) {
        console.log("requestbody to save in realtime", requestBody);
        await save_real_time_data(requestBody);
        dispatch({ type: "setSpecificUserInfo", payload: json });
      }

      function handleNotFound() {
        console.log("Not Found Yet, Waiting...");
        setSpecificUserDetails((prev) => [
          ...prev,
          { index: `${currentPage}${index}`, details: "Record Not Found" },
        ]);
      }

      function handleError(status) {
        console.error(`Got HTTP Error ${status}`);
        setSpecificUserDetails((prev) => [
          ...prev,
          { index: `${currentPage}${index}`, details: "Record Not Found" },
        ]);
      }

      function handleCookieError(response) {
        console.log("Response cookie error", response.statusText);
        handleError();
      }

      function handleUnAuthorized(response = null) {
        console.log("User is UnAuthorized in specificCompany");
        handleError();
        setPopupVisible(true);
        // enqueueSnackbar("Please Logout and LogIn Again");
      }

      function handleInsufficientBalance(response) {
        console.error("Insufficient credits...", response);
        enqueueSnackbar("Insufficient Credits");
        handleError();
      }

      switch (response.status) {
        case 200:
          return await handleSuccess(response);
        case 401:
          return handleUnAuthorized(response);
        case 402:
          return handleInsufficientBalance(response);
        case 403:
          return handleCookieError();
        case 404:
          return handleNotFound();
        default:
          return handleError();
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const handleProfile = async (index, data) => {
    dispatch({ type: "setPersonLoading" });
    setCurrentPage(`${currentPage}${index}`);
    let hash_key = await digestMessage(data);
    console.log("hash_key>>>>>>>>>>", hash_key);
    dispatch({
      type: "setUserName",
      payload: {
        location: null,
        name: data[dict_key?.section2],
        profile_url: data[dict_key?.linkedin_link_1],
        summary: "director",
      },
    });
    let json = {
      details_url: data[dict_key?.linkedin_link_1],
      search_id: uuidv4(),
      phones: null,
      emails: null,
      meta_data: null,
      category: "REALTIME",
    };
    let requestBody = {
      realtime_search_id: json.search_id,
      current_leads: {
        id: json.search_id,
        full_name: data[dict_key?.section2],
        location: data[dict_key?.location],
        image_url: null,
        job: null,
        details_url: data[dict_key?.linkedin_link_1],
        phones: null,
        emails: null,
        meta_data: null,
        company_name: null,
        product_name: "platform",
      },
    };
    history.push({
      state: { id: json.search_id },
    });
    console.log("in Handle profile...", `${currentPage}${index}`, data);
    try {
      let isDuplicate = false;

      specificUserDetails.forEach((spec) => {
        console.log("spec>>>", spec.index);
        if (spec.index === `${currentPage}${index}`) {
          isDuplicate = true;
        }
      });
      console.log("isDuplicate>>>>", isDuplicate);
      if (isDuplicate === false) {
        const response = await fetch(apiServer + "/search/person/detailed/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(json),
        });

        async function handleSuccess(response) {
          let json = await response.json();
          if (!json) {
            handleError(response);
          }

          console.log("json", json);
          checkExecutionStatus(json.id, requestBody);
        }

        function handleNotFound() {
          console.log("Not Found Yet, Waiting...");
          setSpecificUserDetails((prev) => [
            ...prev,
            { index: `${currentPage}${index}`, details: "Record Not Found" },
          ]);
        }

        function handleError(status) {
          console.error(`Got HTTP Error ${status}`);
          setSpecificUserDetails((prev) => [
            ...prev,
            { index: `${currentPage}${index}`, details: "Record Not Found" },
          ]);
        }

        function handleCookieError(response) {
          console.log("Response cookie error", response.statusText);
          handleError();
        }

        function handleUnAuthorized(response = null) {
          console.log("User is UnAuthorized in specificCompany");
          handleError();
          setPopupVisible(true);
          // enqueueSnackbar("Please Logout and LogIn Again");
        }

        function handleInsufficientBalance(response) {
          console.error("Insufficient credits...", response);
          enqueueSnackbar("Insufficient Credits");
          handleError();
        }

        switch (response.status) {
          case 200:
            return await handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 402:
            return handleInsufficientBalance(response);
          case 403:
            return handleCookieError();
          case 404:
            return handleNotFound();
          default:
            return handleError();
        }
      }

      console.log("specificUser>>>>>>>", specificUserDetails);
      specificUserDetails?.forEach((spec) => {
        console.log(
          "Check details>>>>",
          spec.index,
          spec.details === "Record Not Found"
        );
      });
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  console.log(dict_key);
  console.log(data);

  return (
    <>
      {popupVisible && (
        <Popup
          message="
            Hey! Looks like the company you searched for is quite
            hard to find. I checked over 500+ sources for contact
            data but to no avail Let's try another company"
          closePopup={setPopupVisible}
        />
      )}
      {data ? (
        seeLess ? null : (
          <div
            id={"collapseExample_" + index}
            style={{ width: "95%", margin: "0 auto" }}
          >
            <section className="item-section mt-3">
              <div className="phone-child-div ">
                <div className="px-3">
                  <div
                    style={{ display: data[dict_key?.cin] ? "block" : "none" }}
                  >
                    <p className="specific-page-title">Company CIN</p>
                    <div>
                      {data[dict_key?.cin] ? (
                        data[dict_key?.cin]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data[dict_key?.company_address]
                        ? "block"
                        : "none",
                    }}
                  >
                    <p className="specific-page-title">Company Address</p>
                    <div>
                      {data[dict_key?.company_address] ? (
                        data[dict_key?.company_address]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data[dict_key?.auth_capital] ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Authorized Capital</p>
                    <div>
                      {data[dict_key?.auth_capital] ? (
                        data[dict_key?.auth_capital]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data[dict_key?.paid_capital] ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Paid Capital</p>
                    <div>
                      {data[dict_key?.paid_capital] ? (
                        data[dict_key?.paid_capital] === -1 ?
                          "none": data[dict_key?.paid_capital]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div
                    style={{
                      display: data[dict_key?.category] ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Category</p>
                    <div>
                      {data[dict_key?.category] ? (
                        data[dict_key?.category]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data[dict_key?.sub_category] ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Sub Category</p>
                    <div>
                      {data[dict_key?.sub_category] ? (
                        data[dict_key?.sub_category]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data[dict_key?.class] ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Class</p>
                    <div>
                      {data[dict_key?.class] ? (
                        data[dict_key?.class]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data[dict_key?.activity] ? "block" : "none",
                    }}
                  >
                    <p className="specific-page-title">Activity</p>
                    <div>
                      {data[dict_key?.activity] ? (
                        data[dict_key?.activity]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div
                    style={{ display: data[dict_key?.roc] ? "block" : "none" }}
                  >
                    <p className="specific-page-title">ROC</p>
                    <div>
                      {data[dict_key?.roc] ? (
                        data[dict_key?.roc]
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="item-section">
              <p className="specific-page-title">Teams/Key members</p>
              <Swiper
                spaceBetween={50}
                slidesPerView={4}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
              >
                {data[dict_key?.section2] ? (
                  <SwiperSlide key={index} className="my-5">
                    <div
                      style={{ textAlign: "center" }}
                      className="text-center d-flex flex-column align-items-center"
                      key={index}
                    >
                      <div className="d-flex justify-content-center">
                        <img
                          className=""
                          src="/assets/images/user-author-pic.png"
                          alt=""
                        />
                      </div>
                      <p
                        style={{ textAlign: "center", fontWeight: "bold" }}
                        className="d-block mt-3"
                      >
                        {data[dict_key?.section2] || ""}
                      </p>

                      <p
                        className="d-block mt-2"
                        style={{
                          maxWidth: "8rem",
                          margin: "auto",
                          minHeight: "80px",
                          wordBreak: "break-word",
                        }}
                      >
                        Director
                      </p>

                      <p
                        className="search-view-btn"
                        style={{ position: "fixed", bottom: "0" }}
                      >
                        <Link
                          className="btn button"
                          role="button"
                          onClick={() =>
                            data[dict_key?.linkedin_link_1]
                              ? handleProfile(index, data)
                              : handleProfileWithoutLink(index, data)
                          }
                          to="/specificUserInfo"
                          style={
                            data.meta_data?.["unlocked_profile"] && {
                              backgroundColor: "#FF6852",
                              color: "white",
                              fontSize: "14px",
                            }
                          }
                        >
                          {data.meta_data?.["unlocked_profile"]
                            ? "View Profile"
                            : "Unlock Profile"}
                        </Link>
                      </p>
                    </div>
                  </SwiperSlide>
                ) : (
                  <p> Record Not Founds </p>
                )}
              </Swiper>
            </section>
            <div className="text-center">
              <a
                className="btn button"
                data-toggle="collapse"
                href={"#collapseExample_" + index}
                data-target={"#collapseExample_" + index}
                role="button"
                aria-expanded="false"
                aria-controls={"collapseExample_" + index}
                onClick={() => {
                  setSeeLess(true);
                }}
              >
                See Less
              </a>
            </div>
          </div>
        )
      ) : (
        <div style={{ width: "95%", margin: "0 auto" }}>
          <section
            className="item-section w-100"
            style={{ textAlign: "center" }}
          >
            Data Not Found
          </section>
        </div>
      )}
    </>
  );
};

export default CompanyDetailInfo;
