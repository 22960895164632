import Cookies from "js-cookie";
import {apiServer} from "../config";

/**
 * Common Error Handling for all REST API calls
 * @param response
 * @return {*}
 */
const errorHandling = (response) => {
  switch (response.status) {
    case 200 || 201 || 204:
      return;

    case 400: // BAD_REQUEST
      throw new Error(
        "Please contact support@leadzen.ai and our team will resolve this as soon as possible. Thanks"
      );

    case 401: // UNAUTHORIZED
      // Deletes all cookies and Navbar Component forcefully logs out the User
      Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));
      throw new Error("Please Logout and Login again.");

    case 402: // PAYMENT_REQUIRED
      throw new Error(
        "Please buy credits to access leads, You have insufficient credits!"
      );

    case 403: // FORBIDDEN
      throw new Error("You do not have access to this resource.");

    case 404: // NOT_FOUND
      throw new Error(
        "The resource that you're trying to access was not found!"
      );

    case 500: // SERVER_ERROR
      throw new Error(
        "There's an error with one of our servers, Please contact support@leadzen.ai " +
          "and our will team will resolve this as soon as possible"
      );

    default:
      if (!response.ok) {
        throw new Error("There's an unknown error while accessing information");
      }
  }
};

/**
 * Global fetch function for GET call
 * @param endpoint - API Endpoint that needs to be accessed
 * @param params - query parameters object if any
 * @return {Promise<any>}
 */
export const getFetch = async (endpoint, params = {}) => {
  // Handles query parameters
  const queryString = Object.entries(params)
    .map((param) => {
      return `${param[0]}=${param[1]}`;
    })
    .join("&");

  const url = `${apiServer + endpoint}?${queryString}`;

  let response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.get("user_token")}`,
    },
  });

  errorHandling(response);

  if (response) {
    const contentType = response.headers.get("content-type");

    if (contentType !== "application/json") {
      return await response.blob();
    }

    if (response.status === 204) {
      return {};
    }

    return await response.json();
  }
};

/**
 * Global fetch function for POST call
 * @param endpoint - API Endpoint that needs to be accessed
 * @param payload - JSON payload object for the API
 * @return {Promise<any>}
 */
export const postFetch = async (endpoint, payload) => {
  let response = await fetch(apiServer + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.get("user_token")}`,
    },
    body: JSON.stringify(payload),
  });

  errorHandling(response);

  if (response) {
    const contentType = response.headers.get("content-type");

    if (contentType !== "application/json") {
      return await response.blob();
    }
    return await response.json();
  }
};
