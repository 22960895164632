import Cookies from "js-cookie";
import objectHash from "object-hash";
import PureCache from "pure-cache";
import * as Sentry from "@sentry/react";

const apiServerUrl = `${process.env.REACT_APP_CONFIG_API_SERVER}`;
const apiResponseCacheStore = new PureCache({ expiryCheckInterval: 500 });
const onExpiry = ({ key, data: { value, expiryAt } }) => {
  // Do something with expired key
  console.log(`Key:${key} with value:${value} expired at ${expiryAt}.`);
};
apiResponseCacheStore.on("expiry", onExpiry);

export const cachedPostForExecutionAndGetId = async (
  apiEndpoint,
  apiRequestBody,
  cacheTimeoutInMilliSeconds = 60 * 60 * 1000
) => {
  if (!apiEndpoint) {
    console.error("Invalid apiRequestUrl");
    return null;
  }

  const apiRequestUrl = apiServerUrl + apiEndpoint;

  const requestHash = objectHash.sha1({
    apiRequestUrl: apiRequestUrl,
    apiType: "POST",
    apiRequestBody: apiRequestBody,
  });

  const cachedResponse = apiResponseCacheStore.get(requestHash);
  if (cachedResponse) {
    return cachedResponse.value;
  }

  const freshResponse = await postForExecutionAndGetId(
    apiRequestUrl,
    apiRequestBody
  );
  if (freshResponse) {
    apiResponseCacheStore.put(
      requestHash,
      freshResponse,
      cacheTimeoutInMilliSeconds
    );
    return freshResponse;
  }

  return null;
};

export const postForExecutionAndGetId = async (
  apiRequestUrl,
  apiRequestBody
) => {
  if (!apiRequestUrl) {
    console.error("Invalid apiRequestUrl");
    return null;
  }

  //ensure cookies are re-read
  const apiRequestHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  };

  try {
    const response = await fetch(apiRequestUrl, {
      method: "POST",
      headers: apiRequestHeaders,
      body: apiRequestBody,
      cache: "default",
    });

    switch (response.status) {
      case 200:
        const data = await response.json();
        return data?.id;
      default:
        Sentry.setContext("API Details", {
          apiRequestBody: apiRequestBody,
          apiRequestUrl: apiRequestUrl,
          responseStatus: response.status,
        });
        Sentry.captureMessage(`Error Getting API ${apiRequestUrl}`, "error");

        return null;
    }
  } catch (e) {
    console.error(`Error Fetching data from ${apiRequestUrl}`);
    console.table({
      apiUrl: apiRequestUrl,
      apiRequestBody: apiRequestBody,
      error: e,
    });

    Sentry.setContext("Api Details", {
      apiRequestBody: apiRequestBody,
      apiRequestUrl: apiRequestUrl,
    });
    Sentry.captureException(e);

    return null;
  }
};

export const cachedGetResultsByPolling = (
  apiEndpoint,
  onSuccess,
  onError,
  onNotFound,
  cacheTimeoutInMilliSeconds = 60 * 60 * 1000
) => {
  if (!apiEndpoint) {
    console.error("Invalid apiRequestUrl");
    return null;
  }

  const apiRequestUrl = apiServerUrl + apiEndpoint;

  const requestHash = objectHash.sha1({
    apiRequestUrl: apiRequestUrl,
    apiType: "GET",
  });

  const cachedResponse = apiResponseCacheStore.get(requestHash);
  if (cachedResponse) {
    onSuccess(cachedResponse.value);
    return null;
  }

  const onUpdatedSuccess = (result) => {
    apiResponseCacheStore.put(requestHash, result, cacheTimeoutInMilliSeconds);
    onSuccess(result);
  };

  getResultsByPolling(apiRequestUrl, onUpdatedSuccess, onError, onNotFound);
};

export const getResultsByPolling = (
  apiRequestUrl,
  onSuccess,
  onError,
  onNotFound,
  pollingIntervalInMilliSeconds = 5 * 1000,
  timeoutIntervalInMilliSeconds = 5 * 60 * 1000
) => {
  if (!apiRequestUrl) {
    console.error("Invalid apiUrl");
    return null;
  }

  let timeoutId;

  // check for results every 5 seconds, for 5 minutes
  const intervalId = setInterval(async () => {
    function handleError(error) {
      if (timeoutId) clearTimeout(timeoutId);
      clearInterval(intervalId);

      console.error(error);

      Sentry.setContext("API Details", {
        apiRequestUrl: apiRequestUrl,
        error: error,
      });
      Sentry.captureMessage(
        `Error Getting API Results ${apiServerUrl}`,
        "error"
      );

      if (onError) onError(error);
    }

    function handleNotFound() {
      if (onNotFound) onNotFound();
    }

    async function handleSuccess(response) {
      try {
        const data = await response.json();
        if (!data) {
          return handleError("Invalid Fetch Data");
        }

        switch (data.status) {
          case "success": {
            if (timeoutId) clearTimeout(timeoutId);
            clearInterval(intervalId);
            if (onSuccess) onSuccess(data);
            break;
          }
          case "running": {
            return;
          }
          case "failed":
          default: {
            return handleError("Request Failed");
          }
        }
      } catch (e) {
        console.error("Exception In JSON Conversion", e);
        Sentry.setContext("API Details", {
          apiRequestUrl: apiRequestUrl,
        });
        Sentry.captureException(e);

        return handleError("Exception In JSON Conversion");
      }
    }

    //ensure cookies are re-read
    const apiRequestHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.get("user_token")}`,
    };

    try {
      const response = await fetch(apiRequestUrl, {
        method: "GET",
        headers: apiRequestHeaders,
        cache: "default",
      });

      switch (response.status) {
        case 200:
          return handleSuccess(response);
        case 404:
          clearInterval(intervalId);
          return handleNotFound();
        default:
          clearInterval(intervalId);
          return handleError(response.status);
      }
    } catch (e) {
      console.error("Exception in Fetch", e);
    }
  }, pollingIntervalInMilliSeconds);

  // stop checking after 5 minutes
  timeoutId = setTimeout(function () {
    console.error(
      `Record not found within ${timeoutIntervalInMilliSeconds / 60} Min`
    );
    clearInterval(intervalId);

    Sentry.setContext("API Details", {
      apiRequestUrl: apiRequestUrl,
    });
    Sentry.captureMessage("Timeout Getting Prospect Results", "error");

    if (onError) onError("Call Timeout");
  }, timeoutIntervalInMilliSeconds);
};
