import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IsEmpty, Map } from "react-lodash";
import SearchSpecificUser from "../../DetailedInfo/SearchSpecificUser";
import SearchResultItem from "./SearchResultItem";
import { Loader } from "../../Utils/utils";
import {
  fetchSavedListsNames,
  getSaveListMetadata,
} from "../../SavedList/SavedListQueries";
import { useLocation } from "react-router-dom";
import { UnlockedProfileAndEmailDetails } from "../../DetailedInfo/UnlockedProfileAndEmailDetails";
import {
  fetchPhoneFromElastic,
  fetchWorkEmailFromElastic,
} from "../SearchQueries";
import { useSnackbar } from "notistack";

const SearchResultItems = ({
  loading,
  pageLeadsData,
  isExport,
  selectedLeads,
  setSelectedLeads,
  simpleSearchId,
  onViewProfileOrWorkEmail,
  currentPage,
  isRealTime,
  hash_key,
  currentLeads,
  setCurrentLeads,
}) => {
  console.log(
    loading,
    pageLeadsData,
    isExport,
    selectedLeads,
    setSelectedLeads,
    simpleSearchId,
    onViewProfileOrWorkEmail,
    currentPage,
    isRealTime,
    hash_key,
    setCurrentLeads,
    currentLeads
  );
  const realTimeSearchResults = useParams();
  const [isOpen, setIsOpen] = useState("");
  const [searchId, setSearchId] = useState("");
  const [workEmailId, setWorkEmailId] = useState("");
  const [savedData, setSavedData] = useState([]);
  const [savedButtonList, setSavedButtonList] = useState([]);
  const [saveListNames, setSaveListNames] = useState([]);
  const [saveListMetadata, setSaveListMetadata] = useState([]);
  const [workEmailLoading, setWorkEmailLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const location = useLocation();

  const [userUnlockedDetails, setUserUnlockedDetails] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const realtimeNonLinkedinProfileEmailUnlock = async (payload, type) => {
    if (type === "email") {
      try {
        setWorkEmailLoading(true);
        const emailUnlockResponse = await fetchWorkEmailFromElastic(payload);
        setWorkEmailId(true);
        setUserUnlockedDetails({
          workEmailDetails: {
            email: emailUnlockResponse.data?.email,
            verified: emailUnlockResponse.data?.verified,
          },
        });
      } catch (err) {
        console.log("CHECK THIS LOG --->", err.message);
        enqueueSnackbar(err.message, { variant: "error" });
        setUserUnlockedDetails({});
        setSearchId({});
        setIsOpen("");
      } finally {
        setWorkEmailLoading(false);
        onViewProfileOrWorkEmail();
      }
    } else if (type === "phone") {
      try {
        setPhoneLoading(true);
        const fetchResponse = await fetchPhoneFromElastic(payload);
        setSearchId(fetchResponse?.search_id);
        setUserUnlockedDetails({
          phones: fetchResponse?.phones,
          addresses: fetchResponse?.addresses,
          jobs: fetchResponse?.jobs,
          ...fetchResponse?.meta_data,
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        setUserUnlockedDetails({});
        setSearchId({});
        setIsOpen("");
      } finally {
        setPhoneLoading(false);
        onViewProfileOrWorkEmail();
      }
    }
  };

  // details var used when unlock profile from hisotry page result
  const details = location.state?.details ?? "";
  console.log("Simple search id: ", simpleSearchId);
  console.log("pageLeadsData", pageLeadsData);

  useEffect(() => {
    (async () => {
      let saveListNames = await fetchSavedListsNames();
      console.log("saveListNames", saveListNames);

      if (saveListNames?.["list_names"]) {
        setSaveListNames(saveListNames["list_names"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      console.log("pgdataa", pageLeadsData);
      if (pageLeadsData.length === 0) return;
      const hashKeyInputs = pageLeadsData.map(
        (lead) => lead?.details_url ?? lead?.id
      );
      const saveListMetadata = await getSaveListMetadata(hashKeyInputs);
      setSaveListMetadata(saveListMetadata);
      console.log("saveListMetadata", saveListMetadata);
    })();
  }, [pageLeadsData]);

  useEffect(() => {
    if (saveListMetadata) setSaveListMetadata([]);
  }, [currentPage]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="user-widget-box  my-3">
          <div className="search-container mb-2" data-cy="search-container">
            <IsEmpty
              value={pageLeadsData}
              yes={() => (
                <section
                  className="item-section"
                  style={{ textAlign: "center" }}
                >
                  Record Not found{" "}
                </section>
              )}
              no={() => (
                <ul className="px-3" data-cy="search-list-container">
                  <Map
                    collection={pageLeadsData}
                    iteratee={(data, index) => (
                      <div key={index}>
                        <SearchResultItem
                          index={index}
                          data={data}
                          currentPage={currentPage}
                          isExport={isExport}
                          selectedLeads={selectedLeads}
                          setSelectedLeads={setSelectedLeads}
                          // simpleSearchId={simpleSearchId}
                          simpleSearchId={
                            details?.search_id
                              ? details?.search_id
                              : simpleSearchId
                          }
                          setSearchId={setSearchId}
                          workEmailId={workEmailId}
                          setWorkEmailId={setWorkEmailId}
                          realtimeNonLinkedinProfileEmailUnlock={
                            realtimeNonLinkedinProfileEmailUnlock
                          }
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          hash_key={hash_key}
                          isRealTime={isRealTime}
                          onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                          savedData={savedData}
                          saveListNames={saveListNames}
                          selectedButton={savedButtonList}
                          setSelectedButton={setSavedButtonList}
                          setSaveListNames={setSaveListNames}
                          saved={saveListMetadata?.[index]?.["saved"]}
                        />

                        {isOpen === data.details_url ? (
                          <div
                            style={{
                              background: "white",
                              borderRadius: "20px",
                              padding: "20px",
                            }}
                          >
                            <SearchSpecificUser
                              index={index}
                              data={data}
                              currentPage={currentPage}
                              searchId={searchId}
                              setSearchId={setSearchId}
                              workEmailId={workEmailId}
                              setWorkEmailId={setWorkEmailId}
                              setIsOpen={setIsOpen}
                              realTimeSearchResults={realTimeSearchResults}
                              onViewProfileOrWorkEmail={
                                onViewProfileOrWorkEmail
                              }
                              currentLeads={currentLeads}
                              setCurrentLeads={setCurrentLeads}
                            />
                          </div>
                        ) : isOpen === data.id ? (
                          <UnlockedProfileAndEmailDetails
                            isRealTime={isRealTime}
                            userDetails={userUnlockedDetails}
                            onViewProfileOrWorkEmail={onViewProfileOrWorkEmail}
                            workEmailId={workEmailId}
                            workEmailLoading={workEmailLoading}
                            phoneLoading={phoneLoading}
                          />
                        ) : null}
                      </div>
                    )}
                  />
                </ul>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SearchResultItems;
