import React, { useContext, useState } from "react";
import userRobotIcon from "../../images/user-robot-icon.png";
import { AppContext } from "../../Context";
import { useHistory } from "react-router-dom";

const LinkedInSalesNavigatorSearch = (props) => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const [salesNavigatorLink, setSalesNavigatorLink] = useState("");

  const handleSubmit = (e) => {
    if(!window.navigator.onLine){
      props.setpopup(true)
      return;
    }
    e.preventDefault();
    dispatch({ type: "upsetHistorySearchTerm" });
    if (salesNavigatorLink) {
      console.log(
        `LinkedInSalesNavigatorSearch: salesNavigatorLink= ${salesNavigatorLink}`
      );
      history.push({
        state: {
          searchInput: { search_text: salesNavigatorLink },
        },
        pathname: "/SalesNavigatorSearch",
      });
      setSalesNavigatorLink("");
    } else {
      console.warn("LinkedInSalesNavigatorSearch: salesNavigatorLink is empty");
    }
  };

  return (
    <div className="common-type-search">
      <div className="title">
        <div className="title-img">
          <img src={userRobotIcon} alt="#" />
        </div>
        <div className="title-content">
          <h2>Get LinkedIn Profiles</h2>
          <h3>With Sales Navigator Link</h3>
          <small>(LinkedIn Premium Needed)</small>
        </div>
      </div>
      <div className="link-search-container">
        <form onSubmit={handleSubmit} className="type-search-frm">
          <input
            type="text"
            placeholder="Enter LinkedIn Sales Navigator Link here"
            onChange={(e) => setSalesNavigatorLink(e.target.value)}
            onInput={(e) => setSalesNavigatorLink(e.target.value)}
            value={salesNavigatorLink}
            required
            data-cy="sales-link"
          />
          {/* <button type="submit" className="type-search-frm-button mr-2 og-red global-hover">
            <img
              src={linkImage}
              alt="#"
              style={{
                width: "15px",
                height: "15px",
                paddingRight: ".2em",
              }}
              className="mr-2"
            />
            Get URLs
          </button> */}
          <button
            className="btn text-white og-red global-hover"
            type="submit"
            data-cy="search"
          >
            <span
              className="pe-1 text-center d-flex align-items-center justify-content-between"
              style={{ width: "105%" }}
            >
              <img
                src="/assets/images/new_icons/search.png"
                alt="title"
                className="search-icon"
                style={{ width: "15px", height: "15px" }}
              />
              Search
            </span>{" "}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LinkedInSalesNavigatorSearch;
