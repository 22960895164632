import { useState } from "react";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";
import HistoryListView from "./HistoryListView";
import "../SharedComponent/Style/style.css";
import { Link } from "react-router-dom";
import HistoryButtons from "./HistoryButtons";

const History = ({ onRepeatedPage = false, child }) => {
  const [toggleButton, setToggleButton] = useState("people");
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleSearch = async (e) => {
    setSearchText(e.target.value.trim());
    setToggleSearch(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchText) {
      setToggleSearch(true);
    } else {
      alert("Please enter any search term");
    }
  };

  return (
    <motion.div
      className="text-center p-4 my-3 container-fluid"
      {...fadeInFadeOut}
    >
      <div
        className={`${
          !onRepeatedPage
            ? "user-lead-top mb-2 head-btn-style global-shadow global-width"
            : "user-lead-top mb-2 head-btn-style global-shadow"
        }`}
      >
        <div className="d-flex align-items-center">
          <h5 className="m-0">
            <Link to="/dashboard" className="text-decoration-none text-dark">
              <span className="me-1">
                <img
                  src="/assets/images/new_icons/Back.png"
                  alt="title"
                  style={{ width: "22px", height: "22px" }}
                />
              </span>{" "}
              History
            </Link>
            <span
              className="tool"
              tooltip-data="Avoid using special characters for the search"
            >
              <img
                src="/assets/images/new_icons/i_button.png"
                alt="#"
                className="mx-2 tool"
                style={{ width: "15px", height: "15px", marginTop: "-.1em" }}
              />
            </span>
          </h5>
        </div>

        <form
          action="react/react_app/src/Components/History/SharedHistory#"
          className="search-form-sm"
        >
          <div className="input-group">
            <input
              id="shared-history-search-input"
              type="text"
              className="form-control"
              onChange={handleSearch}
              placeholder="Search"
              data-cy="search-input"
            />
            <button
              className="btn btn-danger"
              onClick={handleSubmit}
              type="submit"
              data-cy="submit"
            >
              <img src="/assets/images/small-search-icon.png" alt="title" />
            </button>
          </div>
        </form>
      </div>

      <div className="mt-3">
        <HistoryButtons toggle={toggleButton} setToggle={setToggleButton} />
      </div>

      <div
        className={`pt-0 ${
          !onRepeatedPage
            ? "accordion-body global-child-width"
            : "accordion-body history-on-repeated"
        }`}
        data-cy="history-list"
      >
        {toggleSearch ? (
          <HistoryListView
            onRepeatedPage={onRepeatedPage}
            type="search"
            search_term={searchText}
            child={child}
            toggle={toggleButton}
          />
        ) : (
          <HistoryListView
            onRepeatedPage={onRepeatedPage}
            type={toggleButton}
            child={child}
          />
        )}
      </div>
    </motion.div>
  );
};

export default History;
