import { IsEmpty, IsNil, Map } from "react-lodash";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const FilterLabelInternal = (props) => {
  const identifier = uuidv4();
  const [isHovered, setIsHovered] = useState(false);

  const handleCloseIconClicked = () => {
    let e = document.getElementById(`prospecting-filter-label-${identifier}`);
    e?.classList.add("fadeout-active");
    setTimeout(() => {
      e?.classList.remove("fadeout-active");
      props?.onCloseIconClicked();
    }, 400);
  };

  const handleSwapClick = () => {
    props?.onSwapFieldClicked(props.label);
  };

  return (
    <>
      <IsNil
        value={props.label}
        no={() => (
          <div
            className={
              "position-relative d-flex justify-content-around align-items-center"
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className={`d-flex align-items-center justify-content-between gap-1  ${
                props?.type === "exclude" ? "top-search-exclude" : "top-search"
              }`}
              key={`prospecting-filter-label-${identifier}`}
              style={{ zIndex: 100 }}
            >
              <div>
                {props?.type === "exclude" ? (
                  "-"
                ) : (
                  <img
                    style={{ width: "18px" }}
                    src={
                      props.iconUrl || "/assets/images/new_icons/Industry.png"
                    }
                    alt=""
                    className="mb-1"
                  />
                )}
              </div>
              <span
                className={`${
                  props?.type === "exclude" ? "text-exclude" : "text-control"
                }`}
              >
                {props.label}
              </span>

              <div
                onClick={handleCloseIconClicked}
                style={{ cursor: "pointer", width: "16px" }}
                className=""
              >
                <img
                  src="/assets/images/new_icons/close.svg"
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
            </div>
            {isHovered && props?.onSwapFieldClicked && (
              <div
                onClick={handleSwapClick}
                style={{
                  cursor: "pointer",
                  // backgroundColor: "#e8f0ff",
                  marginLeft: "-5px",

                  zIndex: 10,
                }}
                className="pl-3"
              >
                <img
                  src="/assets/images/new_icons/swap.svg"
                  alt=""
                  className="swap-icon"
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
            )}
          </div>
        )}
      />
    </>
  );
};

const ProspectFilterLabel = (props) => {
  return (
    <>
      <IsEmpty
        value={props.collection}
        no={() => (
          <Map
            collection={props.collection}
            iteratee={(x) => (
              <FilterLabelInternal
                key={x}
                label={x}
                iconUrl={props.iconUrl}
                onCloseIconClicked={() => {
                  const updatedCollection = props.collection?.filter(
                    (y) => y !== x
                  );
                  props?.onCloseIconClicked(updatedCollection);
                }}
                onSwapFieldClicked={props?.onSwapFieldClicked}
                type={props?.type}
              />
            )}
          />
        )}
      />
    </>
  );
};

export default ProspectFilterLabel;
