import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../Context";
import "./Style/style.css";

const SpecificSearchBtn = ({ peopleCompanyFilter, setPeopleCompanyFilter }) => {
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const [toggleCheck, setToggleCheck] = useState(true);

  //   useEffect(() => {
  //     console.log("details...>>>>", peopleCompanyFilter);

  //     if (peopleCompanyFilter === true) {
  //       setToggleCheck(true);
  //     } else {
  //       setToggleCheck(false);
  //     }
  //     //  eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  const handleChange = async (e, event) => {
    // console.log("e.target>>>", e.target);
    if (event === true) {
      setToggleCheck(() => !toggleCheck);
      setPeopleCompanyFilter(!peopleCompanyFilter);
      setPeopleCompanyFilter(!peopleCompanyFilter);
      console.log("true....People");
    } else {
      console.log("false....company");
      setToggleCheck(() => !toggleCheck);
    }
    console.log("toggleCheck outside>>>", event);
  };
  return (
    // this opt will toggle the people to company

    <div className="mt-3">
      <div className="btnn btn-1">
        <input
          type="checkbox"
          name="switch"
          id="switch"
          checked={toggleCheck}
          onChange={(e) => handleChange(e, e.target.checked)}
          data-cy="switch"
        />
        <label htmlFor="switch" />
      </div>
    </div>
  );
};

export default SpecificSearchBtn;
