import React from "react";
import { Link } from "react-router-dom";

const Modal = () => {
  return (
    <>
      <div
        class="modal"
        style={{ backdropFilter: " blur(8px)", display: "block" }}
        id="searchError"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row d-flex align-items-center">
                <div class="col-4">
                  <div class="d-flex justify-content-center">
                    <img
                      style={{ height: "130px", width: "70px" }}
                      src="/assets/images/g10 (2).png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-7">
                  <div class="text-center">
                    <p class="text-danger ">
                      <strong>Oops!</strong>
                    </p>
                    <p>
                      Looks like your session has expired.
                      <br />
                      Please Log Out and Log In Again.
                    </p>

                    <Link
                      to="/login"
                      style={{ background: "#fb3e3e", border: "#fb3e3e" }}
                      className="btn btn-primary"
                    >
                      Login Again
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
