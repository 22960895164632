import React, { useContext, useEffect, useState } from "react";
import "./Style/style.css";
import Cookies from "js-cookie";
import { digestMessage } from "../SearchResult/SearchResultTexAu";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../Context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import CompanyDetailInfo from "../RealTime/CompanyDetailInfo.jsx";
import Emitter from "../Utils/emitter";
import { apiServer } from "../../config";

const SpecificCompany = ({ data, url, index }) => {
  const location = useLocation();
  const { dispatch } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [specificUserDetails, setSpecificUserDetails] = useState([
    { index: null, details: null },
  ]);

  const [seeLess, setSeeLess] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const realTimeCompanyUrl = "https://www.linkedin.com/company/realTimeCompany";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/app.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const saveSearchedRecord = async (response, searchType) => {
    if (location.pathname.includes("result_by_history_type4")) {
      return;
    }
    console.log("In saveSearchedRecord...searchTerm", response, searchType);
    let search_term = response.name;
    let desc,
      companyUrl = "";
    if (response.tagline) {
      desc = response.tagline;
    } else {
      desc = response.description;
    }
    if (url) {
      companyUrl = url;
    } else {
      companyUrl = data.url;
    }
    let resp = {
      name: response.name,
      companyUrl: companyUrl,
      logoUrl: response.profile_pic_url,
      description: desc,
      category: "Companies",
    };
    let requestForSaveSearch = {
      search_id: uuidv4(),
      search_type: searchType,
      search_term: JSON.stringify(search_term),
      search_results: [resp],
    };
    console.log("In saveSearchedRecord...", requestForSaveSearch);
    try {
      const response = await fetch(apiServer + "/history/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestForSaveSearch),
      });
    } catch (e) {
      console.error("Exception>>", e);
    }
  };

  useEffect(() => {
    saveSearchedRecord(data, "realTimeCompany");
  }, [data]);

  const checkExecutionStatus = async (searchId = null, requestBody) => {
    if (!searchId) {
      console.warn("searchId is Null");
      return;
    }
    console.log("requestBody for realtime profile unlock>>>", requestBody);
    try {
      const response = await fetch(`${apiServer}/realtime/save_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(requestBody),
      });
      switch (response.status) {
        case 200:
          console.log("Realtime unlock saved in history");
        case 422:
          console.log("Unprocessable entity in realtime to save in history");
        default:
          console.log("Error in saving realtime unlock to history");
      }
    } catch (error) {
      enqueueSnackbar(
        "Hey!, looks like something went wrong. We were unable to save your search to history.",
        { variant: "error" }
      );
      console.error("Error while saving search result on platform");
    }
    let timeoutId;
    const intervalId = setInterval(async () => {
      console.log("Checking For Results Every 5 Seconds");

      try {
        const response = await fetch(
          apiServer + `/search/person/detailed/result/${searchId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
          }
        );

        console.log("api response: ", response);

        function handleError() {
          if (timeoutId) clearTimeout(timeoutId);
          clearInterval(intervalId);
        }

        function handleUnAuthorized(response = null) {
          console.log("User is UnAuthorized");
          enqueueSnackbar("Please Logout and LogIn Again");
        }

        async function handleSuccess(response) {
          const json = await response.json();
          if (!json) {
            console.warn("Invalid Data");
            return handleError();
          }

          console.log("json: ", json);

          switch (json.status) {
            case "success": {
              console.log(
                "json.status = success, json.search_result = ",
                json.search_result
              );

              if (timeoutId) clearTimeout(timeoutId);
              Emitter.emit("updateCredits", true);

              clearInterval(intervalId);
              dispatch({
                type: "setSpecificUserInfo",
                payload: json?.search_result,
              });
              break;
            }
            case "running": {
              console.log("json.status = running");
              return;
            }
            case "failed": {
              dispatch({
                type: "setSpecificUserInfo",
                payload: "failed/unknown",
              });
              break;
            }
            default: {
              console.log("json.status = failed/unknown");
              dispatch({
                type: "setSpecificUserInfo",
                payload: "failed/unknown",
              });

              handleError();
            }
          }
        }

        switch (response.status) {
          case 200:
            return handleSuccess(response);
          case 401:
            clearInterval(intervalId);
            return handleUnAuthorized(response);
          case 404:
          default:
            return handleError();
        }
      } catch (e) {
        console.error("Exception", e);
      }
    }, 5 * 1000);

    // stop checking after 5 minutes
    timeoutId = setTimeout(function () {
      console.error("record not found within 5 Min");
      clearInterval(intervalId);
    }, 5 * 60 * 1000);
  };

  const handleProfile = async (index, data) => {
    dispatch({ type: "setPersonLoading" });
    setCurrentPage(`${currentPage}${index}`);
    let hash_key = await digestMessage(data);
    console.log("hash_key>>>>>>>>>>", hash_key);
    console.log("data in real time lead>>>>>", data);
    dispatch({
      type: "setUserName",
      payload: {
        location: null,
        name: data?.firstName + data?.lastName,
        job: data?.job || data?.position,
        profile_url: data?.sourcePage,
        summary: data?.position,
      },
    });
    let json = {
      details_url: data?.sourcePage,
      search_id: uuidv4(),
      phones: null,
      emails: null,
      meta_data: null,
    };
    let requestBody = {
      realtime_search_id: json?.search_id,
      current_leads: {
        id: json?.search_id,
        full_name: data?.firstName + " " + data?.lastName,
        location: null,
        image_url: null,
        job: data?.position,
        details_url: data?.sourcePage,
        phones: null,
        emails: null,
        meta_data: null,
        company_name: data?.companyName,
        product_name: "platform",
      },
    };
    history.push({
      state: { id: json.search_id },
    });
    console.log("in Handle profile...", `${currentPage}${index}`, data);
    try {
      let isDuplicate = false;

      specificUserDetails.forEach((spec) => {
        console.log("spec>>>", spec.index);
        if (spec.index === `${currentPage}${index}`) {
          isDuplicate = true;
        }
      });
      console.log("isDuplicate>>>>", isDuplicate);
      if (isDuplicate === false) {
        const response = await fetch(apiServer + "/search/person/detailed/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(json),
        });

        async function handleSuccess(response) {
          let json = await response.json();
          if (!json) {
            handleError(response);
          }

          checkExecutionStatus(json.id, (requestBody = requestBody));
          Emitter.emit("updateCredits", true);
        }

        function handleNotFound() {
          console.log("Not Found Yet, Waiting...");
          setSpecificUserDetails((prev) => [
            ...prev,
            { index: `${currentPage}${index}`, details: "Record Not Found" },
          ]);
        }

        function handleError(status) {
          console.error(`Got HTTP Error ${status}`);
          setSpecificUserDetails((prev) => [
            ...prev,
            { index: `${currentPage}${index}`, details: "Record Not Found" },
          ]);
        }

        function handleCookieError(response) {
          console.log("Response cookie error", response.statusText);
          handleError();
        }

        function handleUnAuthorized(response = null) {
          console.log("User is UnAuthorized in specificCompany");
          handleError();
          setPopupVisible(true);
          // enqueueSnackbar("Please Logout and LogIn Again");
        }

        function handleInsufficientBalance(response) {
          console.error("Insufficient credits...", response);
          enqueueSnackbar("Insufficient Credits");
          handleError();
        }

        switch (response.status) {
          case 200:
            return handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 402:
            return handleInsufficientBalance(response);
          case 403:
            return handleCookieError();
          case 404:
            return handleNotFound();
          default:
            return handleError();
        }
      }

      console.log("specificUser>>>>>>>", specificUserDetails);
      specificUserDetails?.forEach((spec) => {
        console.log(
          "Check details>>>>",
          spec.index,
          spec.details === "Record Not Found"
        );
      });
    } catch (err) {
      console.error("Error: ", err);
    }
  };
  console.log("specificUserDetails", specificUserDetails);

  const handleCompany = async (user) => {
    dispatch({ type: "setCompanyLoading" });
    let reqJsonPipl = {
      url: user.link,
    };

    try {
      // FIXME: Undefined endpoint, remove this code or update /company/detailed/ process
      const resp = await fetch(apiServer + "/proxycurl/company_search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(reqJsonPipl),
      });

      const handleSuccess = async (resp) => {
        let data = await resp.json();
        data.url = user.link;
        dispatch({ type: "setCustomCompanyInfo", payload: data });
      };

      function handleUnAuthorized(resp = null) {
        console.log("User is UnAuthorized in searchResultCompany");
        setPopupVisible(true);
      }

      function handleCookieError() {
        // got cookie error - no need to check again, results will not change
        console.log("Response cookie error", resp.statusText);
      }

      function handleNotFound() {
        console.log("NOT FOUND YET SEARCHING...");
      }

      if (resp.status === 200) {
        return handleSuccess(resp);
      } else if (resp.status === 401) {
        return handleUnAuthorized(resp);
      } else if (resp.status === 403) {
        return handleCookieError();
      } else if (resp.status === 404) {
        return handleNotFound();
      } else {
        return handleNotFound();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    document.getElementById("searchError").style.display = "none";
  };

  const Popup = () => {
    return (
      <>
        <div
          class="modal"
          style={{ display: "block" }}
          id="searchError"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="d-flex justify-content-center">
                      <img
                        style={{ height: "130px", width: "70px" }}
                        src="/assets/images/g10 (2).png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="text-center">
                      <p class="text-danger ">Oops</p>
                      <p>
                        Hey! Looks like the company you searched for is quite
                        hard to find. I checked over 500+ sources for contact
                        data but to no avail Let's try another company
                      </p>
                      <button
                        style={{ background: "#fb3e3e" }}
                        class="btn text-white"
                        onClick={closeModal}
                      >
                        Retry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  let companyJson,
    rowValues,
    linkinUrl = {};
  let isRealTimeCompany = false;
  if (data?.search_results !== undefined) {
    if (data?.search_results[0]?.companyUrl === realTimeCompanyUrl) {
      isRealTimeCompany = true;
      companyJson = {
        CIN: data?.search_results[0].cin,
        "Newly Registered Company Name": data?.search_results[0].name,
        "Newly Registered Company Founder/ Director Name":
          data?.search_results[0].director,
        "Newly Registered Company LinkedIn":
          data?.search_results[0].directorUrl,
        Phone: data?.search_results[0].phone,
        Emails: data?.search_results[0].email,
        "Company Address": data?.search_results[0].companyAddress,
        ROC: data?.search_results[0].roc,
        CATEGORY: data?.search_results[0].category,
        "SUB CATEGORY": data?.search_results[0].subCategory,
        CLASS: data?.search_results[0].class,
        AUTHORIZED_CAPITAL: data?.search_results[0].authorisedCapital,
        "PAID CAPITAL": data?.search_results[0].paidCapital === -1 ? "none": data?.search_results[0].paidCapital ,
        ACTIVITY_DESCRIPTION: data?.search_results[0].activity,
      };
      rowValues = {
        cin: "CIN",
        category: "CATEGORY",
        roc: "ROC",
        company_address: "Company Address",
        sub_category: "SUB CATEGORY",
        class: "CLASS",
        activity: "ACTIVITY_DESCRIPTION",
        paid_capital: "PAID CAPITAL",
        auth_capital: "AUTHORIZED_CAPITAL",
        section2: "Newly Registered Company Founder/ Director Name",
        linkedin_link_1: "Newly Registered Company LinkedIn",
        phone: "Phone",
        email: "Emails",
      };
      linkinUrl = "ru";
    }
  }

  console.log("%cThis is working...", "background: #121212; color: crimson");
  console.log(data, " : ", seeLess);
  return (
    <>
      {popupVisible ? <Popup /> : null}
      {data ? (
        seeLess ? null : !isRealTimeCompany ? (
          <div
            id={"collapseExample_" + index}
            style={{ width: "95%", margin: "0 auto" }}
          >
            <section className="item-section mt-3">
              <div className="phone-child-div ">
                <div className="px-3">
                  <div style={{ display: data?.tagline ? "block" : "none" }}>
                    <p className="specific-page-title">Tag Line</p>
                    <div>
                      {data?.tagline ? data?.tagline : <p>Not Found</p>}
                    </div>
                  </div>
                  <div
                    style={{ display: data?.founded_year ? "block" : "none" }}
                  >
                    <p className="specific-page-title">Founded Year</p>
                    <div>
                      {data?.founded_year ? (
                        data?.founded_year
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: data?.hq ? "block" : "none" }}>
                    <p className="specific-page-title">Company Headquarter</p>
                    <div>
                      {data?.hq ? (
                        <React.Fragment>
                          {data?.hq?.line_1 ? data?.hq?.line_1 + "," : null}{" "}
                          {data?.hq?.city ? data?.hq?.city + "," : null}{" "}
                          {data?.hq?.state ? data?.hq?.state + "," : null}{" "}
                          {data?.hq?.country ? data?.hq?.country : null}
                        </React.Fragment>
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: data?.industry ? "block" : "none" }}>
                    <p className="specific-page-title">Industry</p>
                    <div>
                      {data?.industry ? data?.industry : <p>Not Found</p>}
                    </div>
                  </div>

                  <div
                    style={{ display: data?.specialities ? "block" : "none" }}
                  >
                    <p className="specific-page-title">Specialities</p>
                    <div>
                      {data?.specialities ? (
                        data?.specialities?.join(", ")
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: data?.categories ? "block" : "none" }}>
                    <p className="specific-page-title">Categories</p>
                    <div>
                      {data?.categories ? (
                        data?.categories.join(", ")
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div
                    style={{ display: data?.company_type ? "block" : "none" }}
                  >
                    <p className="specific-page-title">Company Type</p>
                    <div>
                      {data?.company_type ? (
                        <>
                          {data?.company_type === "PUBLIC_COMPANY" ||
                          "PRIVATELY_HELD" ? (
                            <p>Public Company</p>
                          ) : (
                            data?.company_type
                          )}
                        </>
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>

                  <div style={{ display: data?.website ? "block" : "none" }}>
                    <p className="specific-page-title">Company Website</p>
                    <div>
                      <a
                        href={data?.website ? data?.website : <p>Not Found</p>}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data?.website ? data?.website : <p>Not Found</p>}
                      </a>
                    </div>
                  </div>
                  <div
                    style={{ display: data?.follower_count ? "block" : "none" }}
                  >
                    <p className="specific-page-title">Company Followers</p>
                    <div>
                      {data?.follower_count ? (
                        data?.follower_count
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: data?.company_size_on_linkedin
                        ? "block"
                        : "none",
                    }}
                  >
                    <p className="specific-page-title">Employee count</p>
                    <div>
                      {data?.company_size_on_linkedin ? (
                        data?.company_size_on_linkedin
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.contact_email
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">Contact Email</p>
                    <div>
                      {data?.extra && data?.extra?.contact_email ? (
                        data?.extra?.contact_email
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.phone_number
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">Contact Phone</p>
                    <div>
                      {data?.extra && data?.extra?.phone_number ? (
                        data?.extra?.phone_number
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>

                  <div
                    style={{ display: data?.acquisitions ? "block" : "none" }}
                  >
                    <p className="specific-page-title">Acquisitions</p>
                    <div>
                      {data?.acquisitions ? (
                        data?.acquisitions
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.total_funding_amount
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">
                      Number of Funding Rounds
                    </p>
                    <div>
                      {data?.extra && data?.extra?.total_funding_amount ? (
                        data?.extra?.total_funding_amount
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.number_of_lead_investors
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">Lead Investors</p>
                    <div>
                      {data?.extra && data?.extra?.number_of_lead_investors ? (
                        data?.extra?.number_of_lead_investors
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.number_of_investors
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">Number of Investors</p>
                    <div>
                      {data?.extra && data?.extra?.number_of_investors ? (
                        data?.extra?.number_of_investors
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.total_fund_raised
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">Total Fund Raise</p>
                    <div>
                      {data?.extra && data?.extra?.total_fund_raised ? (
                        data?.extra?.total_fund_raised
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <p
                    style={{
                      display:
                        data?.extra && data?.extra?.number_of_investments
                          ? "block"
                          : "none",
                    }}
                    className="specific-page-title"
                  >
                    Number of Investments
                  </p>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.number_of_investments
                          ? "block"
                          : "none",
                    }}
                  >
                    {data?.extra && data?.extra?.number_of_investments ? (
                      data?.extra?.number_of_investments
                    ) : (
                      <p>Not Found</p>
                    )}
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.number_of_lead_investments
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">
                      Number of Lead Investments
                    </p>
                    <div>
                      {data?.extra &&
                      data?.extra?.number_of_lead_investments ? (
                        data?.extra?.number_of_lead_investments
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        data?.extra && data?.extra?.number_of_acquisitions
                          ? "block"
                          : "none",
                    }}
                  >
                    <p className="specific-page-title">
                      Number of Acquisitions
                    </p>
                    <div>
                      {data?.extra && data?.extra?.number_of_acquisitions ? (
                        data?.extra?.number_of_acquisitions
                      ) : (
                        <p>Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="item-section">
              <div className="row">
                <div
                  style={{
                    display:
                      data?.description && data?.description !== "Spam"
                        ? "block"
                        : "none",
                    textAlign: "justify",
                  }}
                  className="col-md-6 pe-5"
                >
                  <p className="specific-page-title">Business Description</p>
                  {data?.description && data?.description !== "Spam" ? (
                    data?.description
                  ) : (
                    <p>Not Found</p>
                  )}
                </div>
                <div className="col-md-6">
                  <img
                    src={
                      data?.profile_pic_url ? (
                        data?.profile_pic_url
                      ) : (
                        <p>Not Found</p>
                      )
                    }
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section className="item-section">
              <p className="specific-page-title">Teams/Key members</p>
              <Swiper
                breakpoints={{
                  668: {
                    slidesPerView : 2 
                  },
                  800: {
                    slidesPerView: 3
                  },
                  1024: {
                    slidesPerView: 4
                  }
                }}
                slidesPerView={4}
                spaceBetween={50}
                onSlideChange={(e) => console.log(e)}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
              >
                {data?.emails ? (
                  data?.emails.map((user, index) => (
                    <SwiperSlide key={index} className="my-5">
                      <div
                        style={{ textAlign: "center" }}
                        className="text-center d-flex flex-column align-items-center"
                        key={index}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className=""
                            src="/assets/images/user-author-pic.png"
                            alt=""
                          />
                        </div>
                        <p
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          className="d-block mt-3"
                        >
                          {user?.firstName + " " + user?.lastName || ""}
                        </p>

                        <p
                          className="d-block mt-2"
                          style={{
                            maxWidth: "8rem",
                            margin: "auto",
                            minHeight: "80px",
                            wordBreak: "break-word",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: user?.position }}
                          ></div>
                        </p>

                        <p
                          className="search-view-btn"
                          style={{ position: "fixed", bottom: "0" }}
                        >
                          <Link
                            className="btn button mb-2"
                            role="button"
                            onClick={() => handleProfile(index, user)}
                            to="/specificUserInfo"
                            style={
                              user?.meta_data?.["unlocked_profile"] && {
                                backgroundColor: "#FF6852",
                                color: "white",
                              }
                            }
                          >
                            {user?.meta_data?.["unlocked_profile"]
                              ? "View Profile"
                              : "Unlock Profile"}
                          </Link>
                        </p>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  <p> Record Not Founds </p>
                )}
              </Swiper>
            </section>

            <section className="item-section">
              <p className="specific-page-title">Similar Company </p>
              <Swiper
                breakpoints={{
                  668: {
                    slidesPerView : 2 
                  },
                  800: {
                    slidesPerView: 3
                  },
                  1024: {
                    slidesPerView: 4
                  }
                }}
                slidesPerView={4}
                spaceBetween={50}
                onSlideChange={(e) => console.log(e)}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
              >
                {data?.similar_companies?.map((user, index) => (
                  <SwiperSlide key={index} className="my-5">
                    <div
                      className="text-center d-flex flex-column align-items-center"
                      key={index}
                    >
                      <div className="d-flex justify-content-center">
                        <a href={user?.link} target="_blank" rel="noreferrer">
                          <img
                            className=""
                            src="/assets/images/Group 2367.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          maxWidth: "10rem",
                          margin: "auto",
                          wordBreak: "break-word",
                        }}
                        className="d-block mt-3"
                      >
                        {user?.name || ""}
                      </p>
                      <p
                        style={{
                          maxWidth: "10rem",
                          margin: "auto",
                          wordBreak: "break-word",
                        }}
                        className="d-block mt-3"
                      >
                        {user?.industry || ""}
                      </p>
                      <p
                        style={{
                          maxWidth: "8rem",
                          margin: "auto",
                          wordBreak: "break-word",
                        }}
                        className="d-block mt-3"
                      >
                        {user?.location}
                      </p>
                      <p
                        className="search-view-btn "
                        style={{ position: "fixed", bottom: "0" }}
                      >
                        <Link
                          className="btn button mb-2"
                          data-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          to="/detailInfoCompany"
                          onClick={() => {
                            handleCompany(user);
                          }}
                        >
                          View Details
                        </Link>
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
            <div className="text-center">
              <a
                className="btn button"
                data-toggle="collapse"
                href={"#collapseExample_" + index}
                data-target={"#collapseExample_" + index}
                role="button"
                aria-expanded="false"
                aria-controls={"collapseExample_" + index}
                onClick={() => {
                  setSeeLess(true);
                }}
              >
                See Less
              </a>
            </div>
          </div>
        ) : (
          <CompanyDetailInfo
            data={companyJson}
            index={index}
            dict_key={rowValues}
            url={linkinUrl}
          />
        )
      ) : (
        <div style={{ width: "95%", margin: "0 auto" }}>
          <section
            className="item-section w-100"
            style={{ textAlign: "center" }}
          >
            Data Not Found
          </section>
        </div>
      )}
    </>
  );
};

export default SpecificCompany;
