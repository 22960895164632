import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../Context";
import BulkSearch from "../SharedComponent/BulkSearch";
import ExtractContacts from "../SharedComponent/ExtractContacts";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import CombinedSearchBar from "../SharedComponent/CombinedSearchBar";

const UserGuide = () => {
  const { dispatch } = useContext(AppContext);

  const [customSearch, setCustomSearch] = useState({
    location: null,
    industry: null,
    job_title: null,
    education: null,
    company_name: null,
    keywords: null,
    csv_file: null,
  });
  const handleCSVFile = (e) => {
    setCustomSearch({ ...customSearch, csv_file: e.target.files[0] });
  };

  return (
    <div>
      <div className="modal" id="bulkmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-dialog">
          <div className="modal-message">
            <p>
              <i className="text-danger">Format to follow:</i> Ensure that the
              first column has the unique values you’re searching for. Download
              the sample below for better understanding.{" "}
            </p>
            <Link>
              <i className="text-danger text-decoration-underline">
                Click here to download csv format
              </i>
            </Link>
          </div>
          <div className="modal-content">
            <form action="/upload" id="mydrop" className="dropzone">
              <div className="dz-message needsclick">
                <button type="button" className="dz-button">
                  Drag and Drop File
                </button>
                <br />
                <button type="button" className="dz-button">
                  OR{" "}
                </button>
                <br />
                <span className="note needsclick">
                  <input type="file" accept=".csv" onChange={handleCSVFile} />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="main-content-area pb-6 pt-2">
        <div className="main-wrapper container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <SpecificSearchBtn details={true} />
              <div className="sidebar-search-for sidebar-widget pt-4 mt-2">
                <ProspectSearch />
              </div>
              <BulkSearch />
              <SidebarExtractContact />
            </div>
            <div className="col-md-8 col-lg-9">
              <CombinedSearchBar />
              <div className="user-widget-box text-center p-4 my-3">
                <div className="user-widget-title">
                  <h5>Getting Started</h5>
                  <h6 className="text-muted mb-4">
                    Check out some example queries to get familiar with
                    Leadzen.ai search !
                  </h6>
                  <p className="text-dark">Try these examples</p>
                </div>
                <div className="row">
                  <div className="col-md-12 m-auto">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                      }}
                    >
                      <div className="col-sm-5">
                        <div className="user-guide-box p-3 my-3">
                          <button className="user-btn  mb-3">
                            <img
                              src="/assets/images/new_icons/Link.png"
                              alt="title"
                              className="black-img icon"
                            />{" "}
                            https://www.linkedin.c...
                          </button>
                          <p className="text-dark mb-4">
                            Search by LinkedIn Profile URL
                          </p>
                          <Link to="/searchResult">
                            <button
                              className="btn try-it-btn"
                              onClick={() => {
                                dispatch({
                                  type: "setDemo",
                                  payload: "linkedUrl",
                                });
                              }}
                            >
                              {" "}
                              Try it!{" "}
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="user-guide-box p-3 my-3">
                          <button className="user-btn mb-3">
                            <img
                              src="/assets/images/new_icons/Person.png"
                              alt="title"
                              className="black-img icon"
                            />{" "}
                            Karan Gupta
                          </button>
                          <p className="text-dark mb-4">
                            Search Specific contact by Name
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="user-guide-box p-3 my-3">
                          <button
                            className="user-btn mb-3 mx-1"
                            style={{ width: "102px" }}
                          >
                            <img
                              src="/assets/images/new_icons/Job Title.png"
                              alt="title"
                              className="black-img icon"
                            />{" "}
                            Auditor
                          </button>
                          <button
                            className="user-btn mb-3 mx-1"
                            style={{ width: "102px" }}
                          >
                            <img
                              src="/assets/images/new_icons/Location.png"
                              alt="title"
                              className="black-img icon"
                            />{" "}
                            Mumbai
                          </button>
                          <p className="text-dark mb-4">
                            Search for Auditor in Mumbai
                          </p>
                          <Link to="/advanceSearch">
                            <button
                              className="btn try-it-btn"
                              onClick={() => {
                                dispatch({ type: "setDemo", payload: "tags" });
                              }}
                            >
                              {" "}
                              Try it!{" "}
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="user-guide-box p-3 my-3">
                          <button
                            className="user-btn  mb-3"
                            style={{ width: "302px" }}
                          >
                            Give me list of employees in Amazon
                          </button>
                          <p className="text-dark mb-4">
                            Search as per your requirement
                          </p>
                          <Link to="/advanceSearch">
                            <button
                              className="btn try-it-btn"
                              onClick={() => {
                                dispatch({
                                  type: "setDemo",
                                  payload: "company",
                                });
                              }}
                            >
                              {" "}
                              Try it!{" "}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  href="https://tidycal.com/sonakshi"
                  target="_blank"
                  className="text-danger"
                  rel="noreferrer"
                >
                  <u>Book a Demo</u>
                </a>
              </div>
              <ExtractContacts />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGuide;
