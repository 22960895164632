import { createContext, useReducer, useEffect } from 'react'

export const LeadsContext = createContext()

export const LeadsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LEADS':
      return { leads: action.payload }
    case 'RESET_LEADS':
      return { leads: null }
    default:
      return state
  }
}

export const LeadsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LeadsReducer, { 
    leads: null
  })

  useEffect(() => {
    const leads = JSON.parse(localStorage.getItem('leads'))

    if (leads) {
      dispatch({ type: 'LOGIN', payload: leads }) 
    }
  }, [])

  console.log('LeadsContext state:', state)
  
  return (
    <LeadsContext.Provider value={{ ...state, dispatch }}>
      { children }
    </LeadsContext.Provider>
  )

}