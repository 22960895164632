import { createSlice } from '@reduxjs/toolkit';

export const areaDetailSlice = createSlice({
  name: 'areaDetail',
  initialState: {
    pincodeDetail: {},
    apartmentDetail: {},
    currentSavingDetail: {},
    mapBoxAutocomplete: {},
  },
   reducers: {
    savePincodeDetail:(state,action) => {
      state.pincodeDetail = action.payload;
    },
    saveApartmentDetail:(state,action) => {
      state.apartmentDetail = action.payload;
    },
    saveCurrentSavingDetail:(state,action) => {
      state.currentSavingDetail = action.payload;
    },
    saveMapBoxAutocomplete:(state,action) => {
      state.mapBoxAutocomplete = action.payload;
    }
  },
});

export const { savePincodeDetail, saveApartmentDetail, saveCurrentSavingDetail, saveMapBoxAutocomplete } = areaDetailSlice.actions;

export const selectPincodeDetail = (state) => state.areaDetail.pincodeDetail;
export const selectApartmentDetail = (state) => state.areaDetail.apartmentDetail;
export const selectCurrentSavingDetail = (state) => state.areaDetail.currentSavingDetail;
export const selectMapBoxAutocomplete = (state) => state.areaDetail.mapBoxAutocomplete;

export default areaDetailSlice.reducer;
