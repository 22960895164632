import React from "react";

export const MapName = ({ name }) => {
  return (
    <div className="result-container__result-info overflow-hidden">
      <span>{name ? name : "Not Found"}</span>
    </div>
  );
};

export default MapName;
