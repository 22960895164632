import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import ModalPopup from "./Modal";
import BulkModal from "./BulkModal";
import { useSnackbar } from "notistack";
import SelectBulkExportTypePopup from "../Utils/SelectBulkExportTypePopup";
import { apiServer } from "../../config";

const BulkSearch = (data, props) => {
  const [uploadedCSV, setUploadedCSV] = useState("");
  const [sampleChecked, setSampleChecked] = useState(false);
  const [fileChecked, setFileChecked] = useState();
  const [typeChecked, setTypeChecked] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [exportType, setExportType] = useState("phone");
  const [bulkFromOnlyHistory, setBulkFromOnlyHistory] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCsvUpload = () => {
    document.getElementById("csv-input").click();
    console.log(uploadedCSV);
  };

  const handleFileType = (e) => {
    console.log(e);
    let filePath = e?.target?.files[0]?.name;
    e.value = "";
    console.log(filePath);
    let allowedExtensions = /(\.xlsx|\.csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      setTypeChecked(true);
      console.log("not valid");
      document.getElementById("csv-input").click();
      setUploadedCSV("");
    }
    setUploadedCSV(e?.target?.files[0]);
  };

  const uploadCsv = async () => {
    if (!window.navigator.onLine) {
      props.setpopup(true);
      return;
    }
    const element = document?.getElementById("csv-input");
    const file = element.files[0];
    if (file) {
      console.log(`file.size:${file.size} Bytes`);

      if (file.size > 2 * 1024 * 1024) {
        console.warn(`file size larger than : ${2 * 1024 * 1024} Bytes`);
        setErrorMessage("Cannot Accept File Sizes larger than 2MB");
        setError(true);

        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("phone_or_email", exportType);
      formData.append("use_only_history", bulkFromOnlyHistory);
      formData.append("use_history", false);

      async function handleError(response = null) {
        if (response) {
          const data = await response?.json();
          console.error(`Error Uploading File: status: ${response?.status}`);

          setError(true);
          if (response?.status === "status: 402") {
            setErrorMessage(
              `Your credits are not sufficient. Try to increase your credits or reduce items in your file`
            );
          } else {
            setErrorMessage(() => data?.detail);
          }
        }
      }

      try {
        const response = await fetch(apiServer + "/bulk_upload/csv", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
        });

        async function handleSuccess(response) {
          const json = await response?.json();
          console.log("json", json);
          if (!json) {
            return handleError();
          }
          console.log(json);

          const username = Cookies.get("username");
          const key = enqueueSnackbar(
            <div className="d-flex">
              <div className="mx-1">
                Hey {username}! We have started working on your file. <br />
                We will take some time to revert back. <br />
                Be rest assured, You'll get an email once it is ready.
                <br />
                After exporting, please ensure to refresh your credit for
                seamless service. Thank you!
              </div>
              <button
                style={{
                  background: "none",
                  cursor: "pointer",
                  border: "none",
                  color: "white",
                  fontSize: "0.75rem",
                }}
                onClick={() => closeSnackbar(key)}
              >
                X
              </button>
            </div>,
            { variant: "info", autoHideDuration: 10000 }
          );
        }

        function handleUnAuthorized() {
          console.log("User is UnAuthorized in bulkSearch");
          setPopupVisible(true);
        }

        switch (response.status) {
          case 200:
            return await handleSuccess(response);
          case 401:
            return handleUnAuthorized();
          default:
            return handleError(response);
        }
      } catch (err) {
        console.error("Error: ", err);
        await handleError();
      }
    } else {
      setFileChecked(true);
    }
  };

  useEffect(() => {}, [uploadedCSV, sampleChecked]);

  const closeModal = () => {
    document.getElementById("searchError").style.display = "none";
    setFileChecked(false);
  };

  const closeTypeModal = () => {
    setTypeChecked(false);
  };

  return (
    <>
      {popupVisible ? <ModalPopup /> : null}
      {error ? <BulkModal errorMessage={errorMessage} /> : null}
      <div
        className="user-widget-box p-4 mt-3 text-center"
        style={{ textAlign: "center", width: "100%" }}
        data-cy="bulk-search"
      >
        <h5
          className="text-danger tool"
          tooltip-data="Upload your .xlsx or csv file with the given format or a LinkedIn URL and fill your database with verified email id and phone numbers."
        >
          Try Bulk Search Now
          <img
            src="/assets/images/new_icons/i_button.png"
            alt="#"
            className="mx-2"
            style={{ width: "15px", height: "15px", marginTop: "-.1em" }}
          />
        </h5>
        <p className="text-dark mb-3">
          <span className="text-danger">Format to follow : </span>
          The format to follow for your input is given below
        </p>
        <a
          className="text-danger"
          href="/assets/Bulk_Search_Template.csv"
          onClick={() => setSampleChecked(true)}
          data-cy="sample-xlsx"
        >
          {data.data ? "" : "Click here to download csv format"}
        </a>

        <input
          id="csv-input"
          type="file"
          accept=".csv,.xlsx"
          defaultValue={uploadedCSV}
          onChange={async (e) => handleFileType(e)}
          style={{ display: "none" }}
          onClick={(event) => {
            event.target.value = null;
          }}
          data-cy="bulk-upload"
        />

        <div className="browse-file">
          <button
            className="browse-btn"
            onClick={handleCsvUpload}
            disabled={data.data}
          >
            Browse
          </button>
          <p className="bulk-filename">{uploadedCSV && uploadedCSV.name}</p>
        </div>

        {/* Fetching information in bulk using only history, This feature is only for Stage Environment */}
        {(process.env.REACT_APP_CONFIG_API_ENVIRONMENT === "stage" ||
          process.env.REACT_APP_CONFIG_API_ENVIRONMENT === "local") && (
          <div className="d-flex justify-content-center my-2">
            <input
              type="checkbox"
              id="history"
              name="bulk-unlock-from-history"
              checked={bulkFromOnlyHistory}
              onChange={() => {
                setBulkFromOnlyHistory(!bulkFromOnlyHistory);
              }}
              style={{ zoom: "1.5" }}
            />
            <span style={{ fontSize: "1.25rem", paddingLeft: "1rem" }}>
              Use Only History
            </span>
          </div>
        )}

        <SelectBulkExportTypePopup
          exportType={exportType}
          setExportType={setExportType}
          handleSubmit={async () => await uploadCsv()}
          dataTarget="bulk-modal"
        />

        <button
          data-toggle="modal"
          data-target="#bulk-modal"
          // className="export-btn"
          // data-cy="export-btn"
          className="btn text-white og-red global-hover"
          type="submit"
          // onClick={async () => uploadCsv()}
          disabled={data.data}
          data-cy="Bulk-data-search"
        >
          <span
            className="pe-1 d-flex align-items-center justify-content-between"
            style={{ width: "105%" }}
          >
            <img
              src="/assets/images/new_icons/search.png"
              alt="title"
              style={{ width: "15px", height: "15px" }}
            />
            Search
          </span>{" "}
        </button>
        {fileChecked ? (
          <div
            className="modal popup-size"
            style={{ display: "block", margin: "auto" }}
            id="searchError"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row d-flex align-items-center">
                    <div className="col-4">
                      <div className="d-flex justify-content-center">
                        <img
                          className="popup-img"
                          src="/assets/images/g10 (2).png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="text-center">
                        <p className="text-danger ">Oops</p>
                        <p>Please Upload a File </p>
                        <button
                          className="btn text-white og-red global-hover"
                          onClick={closeModal}
                        >
                          Retry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {typeChecked ? (
          <div
            className="modal popup-size"
            style={{ display: "block", margin: "auto" }}
            id="searchError"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row d-flex align-items-center">
                    <div className="col-4">
                      <div className="d-flex justify-content-center">
                        <img
                          className="popup-img"
                          src="/assets/images/g10 (2).png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="text-center">
                        <p className="text-danger ">Oops</p>
                        <p>Uploaded file type is not Acceptable</p>
                        <button
                          className="btn text-white og-red global-hover"
                          onClick={closeTypeModal}
                        >
                          Retry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BulkSearch;
