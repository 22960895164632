import React, { useEffect } from "react";
import NavBar from "./NavBar";
import Image from "react-image-webp";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

export function generateRandomString(length) {
  let randomString = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}

const Header = () => {
  const userToken = Cookies.get("user_id");
  const { pathname } = useLocation();
  const navbar = ["/login", "/forgotpassword", "/signup", "/paymentsuccess"];

  let doNotRenderNavbar = navbar.includes(pathname.toLowerCase());
  if (pathname.toLowerCase().includes("/resetpassword")) {
    doNotRenderNavbar = true;
  }

  useEffect(() => {
    if (userToken) {
      window.BrevoConversationsSetup = {
        visitorId: userToken,
      };
    } else {
      window.BrevoConversationsSetup = {
        visitorId: generateRandomString(20),
      };
    }

    window.BrevoConversationsID = "645397b5a426dd78423bfe98";
    window["BrevoConversations"] =
      window["BrevoConversations"] ||
      function () {
        (window["BrevoConversations"].q =
          window["BrevoConversations"].q || []).push(arguments);
      };
    let scriptCreated = document.createElement("script");
    scriptCreated.async = true;
    scriptCreated.src =
      "https://conversations-widget.brevo.com/brevo-conversations.js";
    if (document.head) document.head.appendChild(scriptCreated);

    window.BrevoConversations("openChat", true);
    window.BrevoConversations("minimizeWidget");
  }, [userToken]);

  useEffect(() => {
    const handleClickOutside = () =>
      window.BrevoConversations("minimizeWidget");
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (pathname.toLowerCase().includes("/faq")) {
      window.BrevoConversations("openChat", true);
      window.BrevoConversations("expandWidget", true);
    }
  }, []);
  return (
    <div>
      <header className="header-area">
        <nav className="header-navbar navbar navbar-expand-xl bg-light">
          <div className="container-fluid" style={{ paddingRight: "0px" }}>
            {doNotRenderNavbar ? (
              <Link
                to={{ pathname: "https://leadzen.ai/" }}
                className="navbar-brand"
                target="_blank"
              >
                <Image
                  src="/assets/images/logo-without-BG.png"
                  webp="/assets/images/logo-without-BG.webp"
                />
              </Link>
            ) : (
              <>
                <Link className="navbar-brand" to="/dashboard">
                  <Image
                    src="/assets/images/logo-without-BG.png"
                    webp="/assets/images/logo-without-BG.webp"
                  />
                </Link>
                <NavBar />
              </>
            )}
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
